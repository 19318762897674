import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './MarketplaceDevelopmentPage.css';

const SectionMainFeatures  = props => {

    return (
        <div className={css.customerStoriesSection}>
            <div className={css.sectionWrapper}>
                <div className={css.customerStoriesTop}>
                    <h3 className={css.customerStoriesTitle}><FormattedMessage id={'MarketplaceDevelopmentPage.mainFeatures'} /></h3>
                </div>
                <div className={css.customerStoriesContainer}>
                    <div className={css.customerStoriesItem}>
                        <div className={css.customerStoriesImageContainer}>
                        <img src={'/static/images/MarketplaceDevelopmentPage/mainFeature1.png'} alt="Main Features"/>
                        </div>
                        <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.searchAnything'} /></span>
                        <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.SearchWithCustomFilters'} /></p>
                    </div>
                    <div className={css.customerStoriesItem}>
                        <div className={css.customerStoriesImageContainer}>
                        <img src={'/static/images/MarketplaceDevelopmentPage/mainFeature2.png'} alt="Main Features"/>
                        </div>
                        <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.impressiveDesign'} /></span>
                        <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.landingPagesDesigned'} /></p>
                    </div>
                    <div className={css.customerStoriesItem}>
                        <div className={css.customerStoriesImageContainer}>
                        <img src={'/static/images/MarketplaceDevelopmentPage/mainFeature3.png'} alt="Main Features"/>
                        </div>
                        <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.manageYourMarketplace'} /></span>
                        <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.ourStrongestPart'} /></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionMainFeatures;
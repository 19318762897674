import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  customDescriptionValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelect,
  HeadingFormFieldWrapper,
  LocationAutocompleteInputField,
} from '../../components';
import config from '../../config';

import css from './EditListingDescriptionForm.css';
import { LISTING_TYPES } from 'util/constants';
import { accreditationTypes } from 'marketplace-custom-config';

const identity = v => v;

const TITLE_MAX_LENGTH = 60;
const PROVIDER_MAX_LENGTH = 30;
const YEARS_MAX_LENGTH = 3;

const titleMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.title',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryTitle',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyTitle',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.classTitle',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityTitle',
};

const titlePlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.titlePlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryTitlePlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyTitlePlaceholder',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.classTitlePlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityTitlePlaceholder',
};

const providerMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.provider',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryProvider',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.enquiryProvider',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.enquiryProvider',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.enquiryProvider',
};

const providerPlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.providerPlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryProviderPlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.enquiryProviderPlaceholder',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.providerPlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.providerPlaceholder',
};

const descriptionMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.description',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryDescription',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyDescription',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.description',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityDescription',
};

const descriptionPlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.descriptionPlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryDescriptionPlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyDescriptionPlaceholder',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.classDescriptionPlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityDescriptionPlaceholder',
};

class EditListingDescriptionFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
    };
  }

  componentDidMount() {
    this.handleCategoryChange(this.props.category);
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  render() {
    const { listingType } = this.props;
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            subCategory,
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
          } = formRenderProps;

          const titleMessage = intl.formatMessage({
            id: titleMessages[listingType],
          });
          const titlePlaceholderMessage = intl.formatMessage({
            id: titlePlaceholderMessages[listingType],
          });
          const titleRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.titleRequired',
          });
          const maxLengthMessage = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: TITLE_MAX_LENGTH,
            }
          );

          const providerMessage = intl.formatMessage({
            id: providerMessages[listingType],
          });

          const averageResponseMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.averageResponse',
          });

          const SLAWindowMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.SLAWindow',
          });

          const collaborationWindowMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.collaborationWindow',
          });

          const providerPlaceholderMessage = intl.formatMessage({
            id: providerPlaceholderMessages[listingType],
          });

          const averageResponsePlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.averageResponsePlaceholder',
          });

          const SLAWindowPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.SLAWindowPlaceholder',
          });

          const collaborationWindowPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.collaborationWindowPlaceholder',
          });

          const providerRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.providerRequired',
          });

          const averageResponseRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.averageResponseRequired',
          });

          const SLAWindowRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.SLAWindowRequired',
          });

          const collaborationWindowRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.collaborationWindowRequired',
          });

          const descriptionMessage = intl.formatMessage({
            id: descriptionMessages[listingType],
          });

          const descriptionPlaceholderMessage = intl.formatMessage({
            id: descriptionPlaceholderMessages[listingType],
          });
          const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
          const maxLength30Message = maxLength(maxLengthMessage, PROVIDER_MAX_LENGTH);
          const descriptionRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.descriptionRequired',
          });

          const selectPaymentMethodRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectPaymentMethodRequired',
          });

          const selectTripTypeSpecialisationRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectTripTypeSpecialisationRequired',
          });

          const selectExpertLanguageSpokenRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectExpertLanguageSpokenRequired',
          });

          const selectTravellerTypeRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectTravellerTypeRequired',
          });
          const selectReasonForTripRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectReasonForTripRequired',
          });

          const categoryRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.categoryRequired',
          });

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          //Travel_business
          const travelBusinessMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.travelBusiness',
          });

          const travelBusinessPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.travelBusinessPlaceholder',
          });

          const travelBusinessSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.travelBusinessSupport',
          });

          const travelBusinessRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.travelBusinessRequired',
          });

          //Travel_business_busnumber
          const businessNumberMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessNumber',
          });

          const businessNumberPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessNumberPlaceholder',
          });

          const businessNumberSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessNumberSupport',
          });

          const businessNumberRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessNumberRequired',
          });

          //Travel_business_address
          const registeredAddressMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddress',
          });

          const registeredAddressPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddressPlaceholder',
          });

          const registeredAddressSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddressSupport',
          });

          const registeredAddressRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddressRequiredMessage',
          });

          const registeredAddressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddressNotRecognizedMessage',
          });

          //Travel_business_buyers
          const yearsInBusinessMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearsInBusinessNumber',
          });

          const yearsInBusinessPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearsInBusinessPlaceholder',
          });

          const yearsInBusinessSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearsInBusinessSupport',
          });

          const yearsInBusinessRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearsInBusinessRequired',
          });

          const yearsLengthMessage = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: YEARS_MAX_LENGTH,
            }
          );

          const yearsLength3Message = maxLength(yearsLengthMessage, YEARS_MAX_LENGTH);

          //Travel_business_acreditation
          const accreditationMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.accreditation',
          });
          const accreditationSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.accreditationSupport',
          });

          const paymentsAcceptedMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.paymentsAccepted',
          });
          const webAddressMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.webAddress',
          });

          const paymentsAcceptedPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.paymentsAcceptedPlaceholder',
          });
          const webAddressPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.webAddressPlaceholder',
          });

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;
          const linkToCompanyLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.linkToCompanyLabel',
          });

          const companyNamePlaceholder = intl.formatMessage({
            id: 'EditListingDescriptionForm.companyNamePlaceholder',
          });

          const bankDetailsLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.bankDetailsLabel',
          });
          const bankDetailsPlaceholder = intl.formatMessage({
            id: 'EditListingDescriptionForm.bankDetailsPlaceholder',
          });

          const selectPaymentmethodLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectPaymentmethodLabel',
          });

          const selectReasonForTripLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectReasonForTripLabel',
          });

          const selectTripTypeSpecialisationLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectTripTypeSpecialisationLabel',
          });

          const selectTripExpertLanguageSpokenLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectTripExpertLanguageSpokenLabel',
          });

          const selectTravellerTypeLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectTravellerTypeLabel',
          });

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.btnWrapper}>
                <div>
                  <h1 className={css.headingTitle}>{'Business'}</h1>
                </div>
                <Button
                  rootClassName={css.topSaveButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <div className={css.formGroup}>
                <FieldTextInput
                  id="Travel_business"
                  name="Travel_business"
                  className={css.title}
                  type="text"
                  label={travelBusinessMessage}
                  supportText={travelBusinessSupportMessage}
                  placeholder={travelBusinessPlaceholderMessage}
                  validate={composeValidators(required(travelBusinessRequiredMessage))}
                />

                <FieldTextInput
                  id="Travel_business_busnumber"
                  name="Travel_business_busnumber"
                  className={css.title}
                  type="text"
                  label={businessNumberMessage}
                  supportText={businessNumberSupportMessage}
                  placeholder={businessNumberPlaceholderMessage}
                  validate={composeValidators(required(businessNumberRequiredMessage))}
                />

                <LocationAutocompleteInputField
                  className={css.locationAddress}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.desktopPredictions}
                  validClassName={css.validLocation}
                  autoFocus
                  autocomplete="off"
                  name="Travel_business_address"
                  locationTypes={['address']}
                  needContext={true}
                  id="Travel_business_address"
                  label={registeredAddressMessage}
                  supportText={registeredAddressSupportMessage}
                  placeholder={registeredAddressPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  // valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(registeredAddressRequiredMessage),
                    autocompletePlaceSelected(registeredAddressNotRecognizedMessage)
                  )}
                />

                <FieldTextInput
                  id="Travel_business_busyears"
                  name="Travel_business_busyears"
                  className={css.title}
                  type="number"
                  label={yearsInBusinessMessage}
                  supportText={yearsInBusinessSupportMessage}
                  placeholder={yearsInBusinessPlaceholderMessage}
                  validate={composeValidators(
                    required(yearsInBusinessRequiredMessage),
                    yearsLength3Message
                  )}
                />

                <HeadingFormFieldWrapper
                  label={accreditationMessage}
                  supportText={accreditationSupportMessage}
                >
                  {[...Array(5)].map((_, index) => {
                    return (
                      <FieldTextInput
                        id={`Travel_business_acreditation_${index + 1}`}
                        name={`Travel_business_acreditation[${index}]`}
                        className={css.title}
                        type="text"
                        list="acreditation"
                        autoComplete="off"
                        placeholder={intl.formatMessage(
                          {
                            id: 'EditListingDescriptionForm.accreditationPlaceholder',
                          },
                          { count: index + 1 }
                        )}
                      />
                    );
                  })}
                  <datalist id="acreditation">
                    {accreditationTypes.map(accreditationType => (
                      <option value={accreditationType} />
                    ))}
                  </datalist>
                </HeadingFormFieldWrapper>

                {listingType === 'listing' ? (
                  <>
                    <FieldSelect
                      className={css.customField}
                      type="select"
                      name="trip_reason"
                      id="trip_reason"
                      label={selectReasonForTripLabel}
                      validate={composeValidators(required(selectReasonForTripRequiredMessage))}
                    >
                      <option value="">I want to ... </option>
                      {config.custom.tripReasonOptions.map(reason => (
                        <option key={reason.key} value={reason.key}>
                          {reason.label}
                        </option>
                      ))}
                    </FieldSelect>

                    <FieldSelect
                      className={css.customField}
                      type="select"
                      name="suggestion_category"
                      id="suggestion_category"
                      label={selectTripTypeSpecialisationLabel}
                      validate={composeValidators(
                        required(selectTripTypeSpecialisationRequiredMessage)
                      )}
                    >
                      <option value="">Suggestion Categories</option>
                      {config.custom.suggestionCategoryOptions.map(category => (
                        <option key={category.key} value={category.key}>
                          {category.label}
                        </option>
                      ))}
                    </FieldSelect>

                    <FieldSelect
                      className={css.customField}
                      type="select"
                      name="expert_language_spoken"
                      id="expert_language_spoken"
                      label={selectTripExpertLanguageSpokenLabel}
                      validate={composeValidators(
                        required(selectExpertLanguageSpokenRequiredMessage)
                      )}
                    >
                      <option value="">Select language</option>
                      {config.custom.languageFilterConfig.map(language => (
                        <option key={language.key} value={language.key}>
                          {language.label}
                        </option>
                      ))}
                    </FieldSelect>

                    <FieldSelect
                      className={css.customField}
                      type="select"
                      name="traveller_type"
                      id="traveller_type"
                      label={selectTravellerTypeLabel}
                      validate={composeValidators(required(selectTravellerTypeRequiredMessage))}
                    >
                      <option value="">Select traveller type</option>
                      {config.custom.travellerTypeOptions.map(type => (
                        <option key={type.key} value={type.key}>
                          {type.label}
                        </option>
                      ))}
                    </FieldSelect>

                    <FieldTextInput
                      id="average_response"
                      name="average_response"
                      className={css.providerName}
                      type="text"
                      label={averageResponseMessage}
                      placeholder={averageResponsePlaceholderMessage}
                      maxLength={PROVIDER_MAX_LENGTH}
                      validate={composeValidators(
                        required(averageResponseRequiredMessage),
                        maxLength30Message
                      )}
                    />

                    <FieldTextInput
                      id="SLA_window"
                      name="SLA_window"
                      className={css.providerName}
                      type="text"
                      label={SLAWindowMessage}
                      placeholder={SLAWindowPlaceholderMessage}
                      validate={composeValidators(required(SLAWindowRequiredMessage))}
                    />

                    <FieldTextInput
                      id="collaboration_window"
                      name="collaboration_window"
                      className={css.providerName}
                      type="text"
                      label={collaborationWindowMessage}
                      placeholder={collaborationWindowPlaceholderMessage}
                      validate={composeValidators(required(collaborationWindowRequiredMessage))}
                    />
                  </>
                ) : null}

                {/* {listingType === 'company' ? (
                  <>
                    <FieldTextInput
                      id="payments_accepted"
                      name="payments_accepted"
                      className={css.title}
                      type="text"
                      label={paymentsAcceptedMessage}
                      placeholder={paymentsAcceptedPlaceholderMessage}
                    />
                    <FieldTextInput
                      id="web_address"
                      name="web_address"
                      className={css.title}
                      type="text"
                      label={webAddressMessage}
                      placeholder={webAddressPlaceholderMessage}
                    />
                  </>
                ) : null} */}

                {/* <FieldTextInput
                  id="description"
                  name="description"
                  className={css.description}
                  type="textarea"
                  label={descriptionMessage}
                  placeholder={descriptionPlaceholderMessage}
                  validate={composeValidators(customDescriptionValidators(descriptionRequiredMessage))}
                /> */}

                <Button
                  rootClassName={css.submitButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import config from '../../config';
import * as log from '../../util/log';
import axios from 'axios';

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProgressReportPage/SET_INITIAL_STATE';
export const ADD_PROGRESS_REPORTS_REQUEST = 'app/ProgressReportPage/ADD_PROGRESS_REPORTS_REQUEST';
export const ADD_PROGRESS_REPORTS_SUCCESS = 'app/ProgressReportPage/ADD_PROGRESS_REPORTS_SUCCESS';
export const ADD_PROGRESS_REPORTS_ERROR = 'app/ProgressReportPage/ADD_PROGRESS_REPORTS_ERROR';

export const FETCH_TRANSACTION_REQUEST = 'app/ProgressReportPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_REQUEST_SUCCESS = 'app/ProgressReportPage/FETCH_TRANSACTION_REQUEST_SUCCESS';
export const FETCH_TRANSACTION_REQUEST_ERROR = 'app/ProgressReportPage/FETCH_TRANSACTION_REQUEST_ERROR';

// ================ Reducer ================ //

const initialState = {
  addProgressReportsError: null,
  addProgressReportsInProgress: false,
  progressReports: [],

  fetchTransactionRequestError: null,
  fetchTransactionRequestInProgress: false,
  transactions: [],
};

export default function progressReportPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case ADD_PROGRESS_REPORTS_REQUEST:
      return {
        ...state,
        addProgressReportsError: null,
        addProgressReportsInProgress: true,
      };
    case ADD_PROGRESS_REPORTS_SUCCESS:
      return {
        ...state,
        addProgressReportsError: null,
        addProgressReportsInProgress: false,
        progressReports: payload
      };
    case ADD_PROGRESS_REPORTS_ERROR:
      return {
        ...state,
        addProgressReportsError: payload,
        addProgressReportsInProgress: false,
      };
    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchTransactionRequestError: null,
        fetchTransactionRequestInProgress: true,
      };
    case FETCH_TRANSACTION_REQUEST_SUCCESS:
      return {
        ...state,
        fetchTransactionRequestError: null,
        fetchTransactionRequestInProgress: false,
        transactions: [...state.transactions, payload]
      };
    case FETCH_TRANSACTION_REQUEST_ERROR:
      return {
        ...state,
        fetchTransactionRequestError: payload,
        fetchTransactionRequestInProgress: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const addProgressReportsRequest = () => ({ type: ADD_PROGRESS_REPORTS_REQUEST });

export const addProgressReportsRequestSuccess = progressReports => ({
  type: ADD_PROGRESS_REPORTS_SUCCESS,
  payload: progressReports,
});

export const addProgressReportsRequestError = e => ({
  type: ADD_PROGRESS_REPORTS_ERROR,
  payload: e,
  error: true,
});

export const fetchTransactionRequest = () => ({ type:  FETCH_TRANSACTION_REQUEST});

export const fetchTransactionRequestSuccess = tx => ({
  type: FETCH_TRANSACTION_REQUEST_SUCCESS,
  payload: tx,
});

export const fetchTransactionRequestError = e => ({
  type: FETCH_TRANSACTION_REQUEST_ERROR,
  payload: e,
  error: true,
});


// ================ Thunks ================ //

export const addProgressReports = (values, attachments) => (dispatch, getState, sdk) => {
  var params = []

  values && values.progress_report && Object.keys(values.progress_report).map((txId) => {
    const attachment = attachments.filter(att => att.id === txId)[0];
    const attachmentUrl = attachment ? attachment.attachment : null;
    const attachmentType = attachment ? attachment.resource_type : null;
    params = [...params, {
      transaction_id: txId,
      attachment: attachmentUrl,
      notes: values.progress_report[txId].notes,
      attachment_type: attachmentType
    }]
    return null;
  })

  const progressReportParams = {
    progress_reports: params
  }

  dispatch(addProgressReportsRequest());

  const addProgressReportEndPoint = 'progress_reports'
  const url = config.serverBaseUrl + addProgressReportEndPoint
  const headers = {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
    };

  return axios
    .post(url, progressReportParams, headers)
    .then(response => {
      dispatch(addProgressReportsRequestSuccess(response.data));
      return response;
    })
    .catch(e => {
      log.error(storableError(e), 'add-progress-reports-failed');
      dispatch(addProgressReportsRequestError(storableError(e)));
    });
};

export const fetchTransaction = (id) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionRequestSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionRequestError(storableError(e)));
      throw e;
    });
};

export const fetchTransactions = page => async (dispatch, getState, sdk) => {
  const state = getState();
  const {isAnyPendingProgressReport, pendingProgressReportTxIds} = state.InboxPage;

  if (isAnyPendingProgressReport && pendingProgressReportTxIds.length > 0 ){
    pendingProgressReportTxIds.map(id => dispatch(fetchTransaction(id)));
  }
};


export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([dispatch(fetchTransactions())]);

};


import React from 'react';
import './TransactionsTable.css';

function TransactionsTable(props) {
  const { transactions } = props;

  return (
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Stripe account</th>
          <th>Currency</th>
          <th>Amount</th>
          <th>Transaction ID</th>
          <th>Paid</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(transaction => (
          <tr key={transaction?.id}>
            <td>{transaction?.id}</td>
            <td>{transaction?.destination}</td>
            <td>{transaction?.currency}</td>
            <td>{transaction?.amount ? `${Number(transaction?.amount) / 100}` : 'no amount'}</td>
            <td>{transaction?.transaction_id}</td>
            <td>{transaction?.paid}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TransactionsTable;

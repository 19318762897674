import React, { useState, useEffect } from 'react';

import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BusinessStatusBar,
} from '../../components';
import StaticPage from '../../containers/StaticPage/StaticPage';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { post, get } from '../../util/api';

import css from './UsersDashboardPage.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import moment from 'moment';
import { Skeleton, Backdrop, CircularProgress } from '@mui/material';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const UsersDashboardPageComponent = props => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  const [businesses, setBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const isDev = process.env.REACT_APP_ENV === 'development';
    sdk.currentUser
      .show()
      .then(resp => {
        const currentUser = resp.data.data;

        if ((currentUser && currentUser?.attributes?.profile?.protectedData?.admin) || isDev) {
          get('/api/provider-business/get-page').then(data => {
            const businesses = data.providerBusinesses;
            setBusinesses(businesses);
            setIsLoading(false);
            setInProgress(false);
          });
        }
      })
      .catch(e => {
        console.log(e);
        setIsLoading(false);
      });
  }, [reload]);

  const handleAction = (id, action) => {
    setInProgress(true);
    return post('/api/provider-business/set-approval', { id, action })
      .then(resp => {
        setReload(!reload);
      })
      .catch(e => {
        setInProgress(false);
        console.log(e);
      });
  };

  return (
    <StaticPage
      title="PartnerAdminPage"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'UsersDashboardPage',
        description: 'PartnerAdminPage',
        name: 'PartnerAdminPage',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <center>
            <h1>Staff Admin</h1>
          </center>
          <div className={css.contentContainer}>
            {isLoading ? (
              // <>
              //   <Skeleton variant="rounded" width="100%" height={160} />
              //   <Skeleton variant="rounded" width="100%" height={160} />
              // </>
              <div>
                <p>Loading...</p>
              </div>
            ) : businesses.length > 1 ? (
              businesses.map(b => {
                const {
                  id,
                  sharetribeListingId,
                  approvalStatus,
                  approvalRequested,
                  createdDate,
                  listingObj,
                } = b;
                const { title } = listingObj.attributes;
                const requestedDate = moment(createdDate).format('DD/MM/YY');
                return (
                  <BusinessStatusBar
                    id={id}
                    listingId={sharetribeListingId}
                    title={title}
                    status={approvalStatus}
                    requested={approvalRequested}
                    date={requestedDate}
                    handleAction={handleAction}
                    inProgress={inProgress}
                    setInProgress={setInProgress}
                  />
                );
              })
            ) : (
              <div>{'There is no partners available now.'}</div>
            )}
            {/* <Backdrop
              sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
              open={inProgress}
            >
              <CircularProgress color="inherit" />
            </Backdrop> */}
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  return {};
};

const UsersDashboardPage = compose(
  connect(mapStateToProps),
  injectIntl
)(UsersDashboardPageComponent);

export default UsersDashboardPage;

import React from 'react';
import {
  LocationAutocompleteInputField,
  Form,
  PrimaryButton,
  FieldSelect,
  IconSearch,
} from 'components';
import { parse } from '../../../util/urlHelpers';
import { Form as FinalForm } from 'react-final-form';
import css from './SectionSearchHeader.css';
import config from '../../../config';
import classNames from 'classnames';
import smileIcon from "../../../assets/Landing_Pages/Smile_Icon.svg";
import mapIcon from "../../../assets/Landing_Pages/Map_Icon.svg";


const identity = v => v;

const PromoCategories = config.custom.tripReasonOptions;

function SearchHeaderForm(props) {
  const { location, selectFieldClass } = props;
  const selectField = classNames(selectFieldClass, css.selectField);
  const urlParams = location && location.search && parse(location.search);
  /* const type = (urlParams && urlParams.pub_category) || '';
  const subCategoryLabel =
    urlParams && urlParams.pub_sub_category && Array.isArray(urlParams.pub_sub_category)
      ? urlParams.pub_sub_category[0]
      : urlParams.pub_sub_category;*/
  const pub_listing_type_key = urlParams?.pub_trip_reason;
  const address = (urlParams && urlParams.address) || '';
  let defaultValue = PromoCategories.filter(cat => cat.key === pub_listing_type_key)[0];
  let initialValues = {
    location: address ? { search: address } : { search: '' } ,
    promoCategory: defaultValue ? JSON.stringify(defaultValue) : '',
  };

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      render={({ values, handleSubmit }) => {
        const isValueChange = JSON.stringify(values) !== JSON.stringify(initialValues);
        return (
          <Form className={css.form} onSubmit={handleSubmit}>
            <div className={selectField}>
              {/* <IconPractice className={css.practiceIcon} /> */}
              <img src={smileIcon} className={css.practiceIcon} />
              <FieldSelect
                id="promoCategory"
                name="promoCategory"
                selectClassName={css.fieldSelect}
              >
                <option disabled value="">
                  {defaultValue ? defaultValue?.label : ''} Getaway
                </option>
                {PromoCategories.map(cat => (
                  <option value={JSON.stringify(cat)} key={cat.key}>
                    {cat.label}
                  </option>
                ))}
                {/*<optgroup label="Kids">
                  {PromoKidsCategories.map(cat => (
                    <option value={JSON.stringify(cat)} key={cat.key}>
                      {cat.label}
                    </option>
                  ))}
                </optgroup>*/}
              </FieldSelect>
            </div>

            <div className={css.field}>
              <LocationAutocompleteInputField
                className={css.location}
                iconClassName={css.locationIcon}
                autoFocus
                name="location"
                placeholder={'Select location'}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.location}
                iconComponent={
                  <img
                    src={mapIcon}
                    className={css.practiceIcon}
                  />
                }
              />
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.67383L7 6.67383L13 1.67383"
                  stroke="#0D1624"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="white"
                />
              </svg>
            </div>

            {isValueChange && (
              // <PrimaryButton type="submit" className={css.submit}>
              //   Search
              // </PrimaryButton>
              <button type="submit" className={css.submit}>
                {/* <IconSearch rootClassName={css.searchIcon} />  */}
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css.searchIcon}
                >
                  <path
                    d="M9.35928 17.6738C13.976 17.6738 17.7186 14.0921 17.7186 9.67383C17.7186 5.25555 13.976 1.67383 9.35928 1.67383C4.74258 1.67383 1 5.25555 1 9.67383C1 14.0921 4.74258 17.6738 9.35928 17.6738Z"
                    fill="#1DB1A9"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.809 19.6742L15.2637 15.3242"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Search
              </button>
            )}
          </Form>
        );
      }}
    />
  );
}

export default SearchHeaderForm;

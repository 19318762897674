import ascottLogo from 'assets/landingPage/ascottLogo.png';
import googleLogo from 'assets/landingPage/googleLogo.png';
import shangriLogo from 'assets/landingPage/shangriLogo.png';
import standartCharteredLogo from 'assets/landingPage/standartCharteredLogo.png';
import cns from 'classnames';
import { routeConfiguration } from 'index';
import { bool, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createResourceLocatorString } from 'util/routes';
import { createSlug } from 'util/urlHelpers';
import IconPostRequestMobile from '../../assets/landingPage/icons/postRequestIcon.png';
import TripExpertSectionImage from '../../assets/TripExpertPage/TripExpertSectionImage1.svg';
import TripExpertSectionImage2 from '../../assets/TripExpertPage/TripExpertSectionImage2.svg';
import PricingBackgroundImage from '../../assets/TripExpertPage/PricingBackgroundImage1.png';
import PricingBackgroundImage2 from '../../assets/TripExpertPage/PricingBackgroundImage2.png';
import PricingBackgroundImage3 from '../../assets/TripExpertPage/PricingBackgroundImage3.png';
import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import CheckIcon from '../../assets/TripExpertPage/Check_Icon.svg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LazyLoadingImages,
  NamedLink,
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionRentSpace,
  SectionTopIconDesktop,
  SectionWhyUs,
  LandingPageCategoryListSection,
  LandingPageExtraordinarySection,
  LandingPageHowItsWorksSection,
  LandingPageExpertMarketPlaceSection,
  LandingPageTripExpertSection,
  TripExpertForm,
} from '../../components';
import PhoneNumberModal from '../../components/PhoneNumberModal/PhoneNumberModal';
import config from '../../config';
import { TopbarContainer } from '..';
// import {TripExpertForm} from "../../components/TripExpertForm/TripExpertForm";
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './BecomeTripExpertPage.css';
import { loadData, searchListings, queryPromotedListings } from '../LandingPage/LandingPage.duck';
import classNames from 'classnames';
import { FieldRadioButton } from 'examples';
import { pushToPath } from '../../util/urlHelpers';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import * as validators from '../../util/validators';
import arrayMutators from 'final-form-arrays';
import { Button, FieldCheckboxGroup } from '../../components';
import processDiagram from '../../assets/TripExpertPage/Assited_Trip_Planning_Graphic.svg';

const PROMO_LOGOS = [
  {
    id: 'shangri',
    src: shangriLogo,
  },
  {
    id: 'google',
    src: googleLogo,
  },
  {
    id: 'standardChartered',
    src: standartCharteredLogo,
  },
  {
    id: 'ascott',
    src: ascottLogo,
  },
];

const CHECKLIST = [
  { id: '1', description: 'Get Paid for Creating Itineraries' },
  { id: '2', description: 'Recieve qualified Travel Agent leads' },
  { id: '3', description: 'Set your own service pricing' },
  { id: '4', description: 'Setup a profile quickly' },
  { id: '5', description: 'Focus on specialised areas you love' },
  { id: '6', description: 'Reach New International markets ' },
  { id: '7', description: 'Use your existing IT Systems', italic: '(GDS, Booking tools)' },
  {
    id: '8',
    description: 'Use purpose built Travel App to communicate',
    italic: '(instead of email & phone calls)',
  },
  { id: '9', description: 'No up front costs' },
  { id: '10', description: 'No comissions on Travel bookings' },
  {
    id: '11',
    description: 'Small Fee on qualified leads',
    italic: '(Great Conversion Vs GAds, FB etc.) ',
  },
];

class BecomeTripExpertPageComponent extends React.Component {
  constructor(props) {
    super(props);
    // const { attributes: { profile: { publicData: { phoneNumber = "" } = {} } = {} } = {} } = props.currentUser

    this.state = {
      isOpen: false,
      isReviewFetch: false,
      selectedIcon: 'Private Session',
      selectedCategories: [],
    };
    this.postRequestGifRef = React.createRef();
    this.handleProviderQueryChange = this.handleProviderQueryChange.bind(this);
    this.onSelectCategoriesHandler = this.onSelectCategoriesHandler.bind(this);
  }

  toggleModal = () => {
    this.setState({
      isOpen: false,
    });
  };
  onOkay = () => {
    this.setState({
      isOpen: false,
    });
    this.props.history.push('/account/contact-details');
  };
  handleProviderQueryChange(providerQueryValue) {
    this.props.searchListings({
      keywords: providerQueryValue,
    });
  }

  onSelectCategoriesHandler(value) {
    const { onFetchPromotedListing } = this.props;
    let updateValue = [...this.state.selectedCategories];

    if (updateValue.includes(value)) {
      updateValue.splice(updateValue.indexOf(value), 1);
    } else {
      updateValue.push(value);
    }

    onFetchPromotedListing(updateValue);
    this.setState({ selectedCategories: updateValue });
  }

  handleRebookClick = tx => e => {
    const { history } = this.props;
    const title = tx.listing.attributes.title;
    const listingId = tx.listing.id.uuid;
    const url = createResourceLocatorString(
      'ListingPage',
      routeConfiguration(),
      { slug: createSlug(title), id: listingId },
      {}
    );
    history.push(url);
  };

  onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    console.log('Toggling ModalInMobile - currently:', componentId, scrollingDisabled);
  };

  render() {
    const {
      history,
      intl,
      location,
      scrollingDisabled,
      suggestedListings,
      promotedListings,
    } = this.props;
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const onSubmit = values => {
      pushToPath('/c/new');
    };

    const expertOptions = [
      {
        key: '1',
        label:
          'I am a Travel Agent and work for or represent a Partner Travel Business already on the Marketplace.',
      },
      {
        key: '2',
        label:
          'I am a Travel Agent and the business I work for is not a Partner Travel Business yet.',
      },
      {
        key: '3',
        label:
          'I am not a Travel Agent but would like to investigate becoming one to offer Trip Expert Services.',
      },
    ];

    const partnerOptions = [
      {
        key: '1',
        label:
          'I own or represent a Travel Agency registered in one of these <a>supported countries</a> and would like to become a Partner to add Trip Experts to the Marketplace.',
      },
      {
        key: '2',
        label:
          'I can provide detalied commercial and financial information to verify the business as part of the application.',
      },
    ];

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        isBottomMenuRequire={true}
        title={'Become Trip Expert'}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="BecomeTripExpertPage" />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <section id="benefits">
              <div className={css.topHeadContainer}>
                <div className={css.topHeadSection}>
                  <h1>
                    <FormattedMessage id="BecomeTripExpertPage.hireTripExpert" />
                  </h1>
                  <p>
                    <FormattedMessage id="BecomeTripExpertPage.hireTripExpertInfo" />
                  </p>
                  <p>
                    <FormattedMessage id="BecomeTripExpertPage.hireTripExpertInfo2" />
                  </p>
                </div>
              </div>
            </section>

            <div className={css.postRequestsection}>
              <div className={css.lpContentWrapper}>
                <LandingPageTripExpertSection title={false} />
              </div>
            </div>

            <div className={css.tripExpertDefinition}>
              <div className={css.reverseSection} style={{ marginBottom: 50 }}>
                <div>
                  <h1>
                    <FormattedMessage id="BecomeTripExpertPage.whoAreTripExperts" />
                  </h1>
                  <p className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.whoAreTripExpertsInfo" />
                  </p>

                  <p className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.whoAreTripExpertsInfo2" />
                  </p>
                  <p className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.whoAreTripExpertsInfo3" />
                  </p>
                </div>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={TripExpertSectionImage}
                    alt="sectionImage3"
                  />
                </div>
              </div>
            </div>

            <div id="process" className={css.processSection}>
              <div className={css.howItWorksection}>
                <div className={css.lpworksContentWrapper}>
                  <h1 className={css.processTitle}>
                    <FormattedMessage id="TopbarDesktop.process" />
                  </h1>
                  <div className={css.processDiagramWrapper}>
                    <img src={processDiagram} alt="process Diagram" width="100%" />
                  </div>
                </div>
                <div className={css.lpworksContentWrapper}>
                  <LandingPageExpertMarketPlaceSection />
                </div>
              </div>
            </div>

            <section id="pricing" className={css.pricingSection}>
              <div className={css.uniqueDesignSection}>
                <h1>
                  <FormattedMessage id="BecomeTripExpertPage.Pricing" />
                </h1>
                <section id="tripExpertService" className={css.topSpace}>
                  <p className={css.topSpan}>
                    <strong>
                      <FormattedMessage id="BecomeTripExpertPage.tripExpertServiceTitle" />
                    </strong>
                  </p>
                  <div className={css.priceImage}>
                    <img src={PricingBackgroundImage} alt="priceInfo1" />
                  </div>
                  <p className={css.topSpan}>
                    <FormattedMessage
                      id="BecomeTripExpertPage.tripExpertServiceInfo"
                      values={{
                        bold: header => <span className={css.boldText}>{header}</span>,
                      }}
                    />
                  </p>
                  <p className={css.topSpan}>
                    <FormattedMessage
                      id="BecomeTripExpertPage.tripExpertServiceInfo2"
                      values={{
                        bold: header => <span className={css.boldText}>{header}</span>,
                      }}
                    />
                  </p>
                  <div className={css.topSpan}>
                    <ul>
                      <li>
                        <strong>1. Number of itinerary items</strong>
                        <p>
                          <FormattedMessage id="BecomeTripExpertPage.tripExpertServiceInfoNumberOfItiItem" />
                        </p>
                      </li>
                      <li>
                        <strong>2. Number of Trip Participants</strong>
                        <p>
                          <FormattedMessage id="BecomeTripExpertPage.tripExpertServiceInfoNumberOfTriParti" />
                        </p>
                      </li>
                      <li>
                        <strong>3. Delivery Window</strong>
                        <p>
                          <FormattedMessage id="BecomeTripExpertPage.tripExpertServiceInfoDeliWindow" />
                        </p>
                      </li>
                      <li>
                        <strong>4. Collaboration Window</strong>
                        <p>
                          <FormattedMessage id="BecomeTripExpertPage.tripExpertServiceInfoCollaWindow" />
                        </p>
                      </li>
                    </ul>
                  </div>
                  <p className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.tripExpertServiceInfo3" />
                  </p>
                  <p className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.tripExpertServiceInfo4" />
                  </p>
                </section>
                <section id="servicePricing" className={css.topSpace}>
                  <p className={css.topSpan}>
                    <strong>
                      <FormattedMessage id="BecomeTripExpertPage.servicePricingTitle" />
                    </strong>
                  </p>
                  <div className={css.priceImage}>
                    <img src={PricingBackgroundImage2} alt="priceInfo2" />
                  </div>
                  <p className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo" />
                  </p>
                  <p className={css.topSpan}>
                    <FormattedMessage
                      id="BecomeTripExpertPage.servicePricingInfo2"
                      values={{
                        a: chunks => (
                          <NamedLink name="CommercialModelPage" target="_blank">
                            {chunks}
                          </NamedLink>
                        ),
                      }}
                    />
                  </p>
                  <p className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3" />
                  </p>
                  <p className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List1" />
                    <br />
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List2" />
                    <br />
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List3" />
                    <br />
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List4" />
                    <br />
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List5" />
                    <br />
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List6" />
                    <br />
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List7" />
                    <br />
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List8" />
                    <br />
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo3List9" />
                  </p>
                  {/* <p style={{ fontStyle: "italic" }} className={css.topSpan}>
                    <FormattedMessage id="BecomeTripExpertPage.servicePricingInfo4" />
                  </p> */}
                </section>
                <section id="optionalTravelAgentServices" className={css.topSpace}>
                  <div>
                    <p className={css.topSpan}>
                      <strong>
                        <FormattedMessage id="BecomeTripExpertPage.optionalTravelAgentServicesTitle" />
                      </strong>
                    </p>
                    <div className={css.priceImage}>
                      <img src={PricingBackgroundImage3} alt="priceInfo3" />
                    </div>
                    <p className={css.topSpan}>
                      <FormattedMessage id="BecomeTripExpertPage.optionalTravelAgentServicesInfo1" />
                    </p>
                    <p className={css.topSpan}>
                      <FormattedMessage id="BecomeTripExpertPage.optionalTravelAgentServicesInfo2" />
                    </p>
                    <p className={css.topSpan}>
                      <FormattedMessage id="BecomeTripExpertPage.optionalTravelAgentServicesInfo3" />
                    </p>
                    <p className={css.topSpan}>
                      <FormattedMessage id="BecomeTripExpertPage.optionalTravelAgentServicesInfo4" />
                    </p>
                    <p className={css.topSpan}>
                      <FormattedMessage id="BecomeTripExpertPage.optionalTravelAgentServicesInfo5" />
                    </p>
                  </div>
                  <div className={css.sectionImageContainer3}></div>
                </section>
                <div id="trip-expert-partner"></div>
              </div>
            </section>

            <div className={css.expertOrPartnerSection}>
              <section className={classNames(css.section)}>
                <div className={css.lpExpertContentWrapper}>
                  <div className={css.normalDesignSection}>
                    <h1>
                      <FormattedMessage id="BecomeTripExpertPage.tripExpertOrTravelPartner" />
                    </h1>
                    <p className={css.titleSpan}>
                      <FormattedMessage id="BecomeTripExpertPage.tripExpertOrTravelPartnerInfo" />
                    </p>

                    <ul>
                      {CHECKLIST.map(item => (
                        <li className={css.topSpan}>
                          <div className={css.checklist}>
                            <img src={CheckIcon} />
                            <span>{item.description}</span>
                          </div>
                        </li>
                      ))}
                    </ul>

                    <div className={css.sectionImagesContainer}>
                      <img
                        className={css.sectionImages}
                        src={TripExpertSectionImage2}
                        alt="sectionImage3"
                      />
                    </div>
                  </div>
                  {/* <div className={css.tripExpertForm}> */}
                  {/* <TripExpertForm /> */}
                  <div className={css.sectionForm}>
                    <FinalForm
                      onSubmit={onSubmit}
                      mutators={{ ...arrayMutators }}
                      render={fieldRenderProps => {
                        const {
                          handleSubmit,
                          invalid,
                          pristine,
                          submitting,
                          formName,
                          emailSend,
                          values,
                          form,
                        } = fieldRenderProps;

                        const required = validators.required('This field is required');
                        const submitDisabled =
                          !values.partnerOptions ||
                          (values.partnerOptions && values.partnerOptions.length < 2);

                        let nextActionMessage = (
                          <i style={{ fontSize: '20px', lineHeight: '32px' }}>
                            Select checkbox above
                          </i>
                        );

                        if (values.expertOptions === '1') {
                          nextActionMessage = (
                            <p>
                              <span>
                                Contact your Businesses Admin and ask them for an invitation to
                                create your profile page on the marketplace.
                              </span>
                            </p>
                          );
                        }

                        if (values.expertOptions === '2') {
                          nextActionMessage = (
                            <p>
                              <span>
                                Contact the owner or administrator of your Travel Agency and suggest
                                they complete a Partnership Request (below), get approved, then add
                                you as a Trip Expert.{' '}
                              </span>
                            </p>
                          );
                        }

                        if (values.expertOptions === '3') {
                          nextActionMessage = (
                            <p>
                              <span>
                                Please email support@lyfshort.com with your name and contact details
                                (including phone number) to be connected with a partner business
                                looking for representatives.
                              </span>
                            </p>
                          );
                        }

                        const onChange = formValues => {
                          // if (formValues.values.partnerOptions && formValues.values.expertOptions) {
                          //   form.change('expertOptions', []);
                          // }
                        };

                        return (
                          <form
                            className={css.form}
                            onSubmit={e => {
                              e.preventDefault();
                              handleSubmit(e);
                            }}
                          >
                            <center className={css.tripExpertTitle}>
                              <h2>Trip Expert</h2>
                            </center>

                            <FormSpy onChange={onChange} />

                            <FieldCheckboxGroup
                              id="expertOptions"
                              name="expertOptions"
                              options={expertOptions}
                              twoColumns={false}
                              radio={true}
                            />

                            <div className={!!values.expertOptions && css.nextActionWrapper}>
                              <h2>Next action:</h2>
                              {nextActionMessage}
                            </div>

                            <center className={css.travelBusinessTitle}>
                              <h2>Travel Business</h2>
                            </center>

                            <FieldCheckboxGroup
                              id="partnerOptions"
                              name="partnerOptions"
                              options={partnerOptions}
                              twoColumns={false}
                            />

                            <Button className={css.submit} type="submit" disabled={submitDisabled}>
                              Start Partnership Request
                            </Button>
                          </form>
                        );
                      }}
                    />
                  </div>
                  {/* </div> */}
                </div>
              </section>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
        {/* <PhoneNumberModal
          id="ReviewOrderModal"
          isOpen={this.state.isOpen}
          onOkay={this.onOkay}
          onCloseModal={this.toggleModal}
          onManageDisableScrolling={this.onManageDisableScrolling}
          rootClassName={''}
        /> */}
      </Page>
    );
  }
}

BecomeTripExpertPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
  promotedListingRefs: [],
  promotedListingReviews: [],
};

BecomeTripExpertPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.listing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    currentUserWasFetched,
    currentUserNotificationCount,
    currentUserPackagesNotificationCount,
    recentTransactions,
    recentTransactionsLoading,
  } = state.user;
  const {
    promotedListingRefs,
    promotedListingReviews,
    suggestedListings,
    companiesRefs,
    companiesLoading,
    listingAllReviews,
  } = state.LandingPage;
  const { saveContactDetailsInProgress } = state.ContactDetailsPage;
  const promotedListings = getMarketplaceEntities(state, promotedListingRefs);
  const companies = getMarketplaceEntities(state, companiesRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    promotedListings,
    suggestedListings,
    recentTransactions,
    recentTransactionsLoading,
    currentUser,
    currentUserWasFetched,
    companies,
    companiesLoading,
    currentUserNotificationCount,
    currentUserPackagesNotificationCount,
    promotedListingReviews,
    promotedListingRefs,
    companiesRefs,
    listingAllReviews,
    saveContactDetailsInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  onFetchPromotedListing: categories => dispatch(queryPromotedListings(categories)),
  // onQueryPromotedListingReviews: listingRef => dispatch(queryPromotedListingReviews(listingRef))
});

const BecomeTripExpertPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BecomeTripExpertPageComponent);

BecomeTripExpertPage.loadData = loadData;

export default BecomeTripExpertPage;

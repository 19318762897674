import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { addProgressReports, loadData } from './ProgressReportPage.duck.js'
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  GenericMessage,
} from '../../components';

import { ProgressReportForm } from './../../forms'
import config from '../../config';
import { TopbarContainer } from '../../containers';

import css from './ProgressReportPage.css';

const { UUID } = sdkTypes;

class ProgressReportPageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progressReportsAttachments: []
    }

    this.uploadWidget = this.uploadWidget.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  uploadWidget(e, id) {
    const _this = this;
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.cloudinary.openUploadWidget(
        { cloud_name: 'movementbuddy', upload_preset: 'movementbuddy', maxFiles: 1 },
        function (error, result) {
          if (result && result.info && result.info.secure_url) {
            _this.setState({
              progressReportsAttachments: [..._this.state.progressReportsAttachments, { id: id, attachment: result.info.secure_url, resource_type: result.info.resource_type }],
            });
          }
        }
      );
    }
  }

  handleSubmit(values) {
    const { onAddProgressReports } = this.props;
    onAddProgressReports(values, this.state.progressReportsAttachments)
  }

  render() {
    const {
      scrollingDisabled,
      intl,
      isAnyPendingProgressReport,
      pendingProgressReportTxIds,
      addProgressReportsError,
      addProgressReportsInProgress,
      progressReports,
      transactions
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ProgressReportPage.schemaTitle' }, { siteTitle });
    const errorMessage = intl.formatMessage({ id: 'ProgressReportPage.error' });
    const successMessage = intl.formatMessage({ id: 'ProgressReportPage.successMessage' });

    return (
      <Page title={schemaTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="ProgressReportPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.progressReports}>
              <h1 className={css.title}>
                <FormattedMessage id="ProgressReportPage.heading" />
              </h1>
              <div className={css.ProgressReport}>
                {
                  addProgressReportsError ?
                    <GenericMessage show={addProgressReportsError} message={errorMessage} /> : null
                }

                {
                  progressReports && progressReports.length > 0 ?
                    <GenericMessage show={progressReports && progressReports.length > 0} message={successMessage} /> : null
                }

                <ProgressReportForm
                  isAnyPendingProgressReport={isAnyPendingProgressReport}
                  pendingProgressReportTxIds={pendingProgressReportTxIds}
                  onSubmit={this.handleSubmit}
                  uploadWidget={this.uploadWidget}
                  attachments={this.state.progressReportsAttachments}
                  inProgress={addProgressReportsInProgress}
                  progressReports={progressReports}
                  transactions={transactions}
                />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProgressReportPageComponent.defaultProps = {};

ProgressReportPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    addProgressReportsError,
    addProgressReportsInProgress,
    progressReports,
  } = state.ProgressReportPage;

  const {
    isAnyPendingProgressReport,
    pendingProgressReportTxIds,
  } = state.InboxPage


  const transactionRefs = pendingProgressReportTxIds.map(id => {
    return {
      id: new UUID(id),
      type: 'transaction',
    };
  })

  const transactions = getMarketplaceEntities(state, transactionRefs);

  return {
    addProgressReportsError,
    addProgressReportsInProgress,
    progressReports,
    scrollingDisabled: isScrollingDisabled(state),
    isAnyPendingProgressReport: isAnyPendingProgressReport,
    pendingProgressReportTxIds: pendingProgressReportTxIds,
    transactions,
  };
};

const mapDispatchToProps = dispatch => ({
  onAddProgressReports: (values, attachments) => dispatch(addProgressReports(values, attachments)),
});

const ProgressReportPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProgressReportPageComponent);

ProgressReportPage.loadData = loadData;

export default ProgressReportPage;

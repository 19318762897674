import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  formatDateStringToTz,
  getDefaultTimeZoneOnBrowser,
} from '../../util/dates';
import config from '../../config';
import { LISTING_TYPES } from 'util/constants';
import axios from 'axios';
import omit from 'lodash/omit';
import qs from 'qs';
import { ensureCURLListings, denormalisedResponseEntities } from 'util/data';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/PromoPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/PromoPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/PromoPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_CLASS_LISTINGS_REQUEST = 'app/PromoPage/SEARCH_CLASS_LISTINGS_REQUEST';
export const SEARCH_CLASS_LISTINGS_SUCCESS = 'app/PromoPage/SEARCH_CLASS_LISTINGS_SUCCESS';
export const SEARCH_CLASS_LISTINGS_ERROR = 'app/PromoPage/SEARCH_CLASS_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/PromoPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/PromoPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/PromoPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/PromoPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const QUERY_REVIEWS_REQUEST = 'app/PromoPage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/PromoPage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/PromoPage/QUERY_REVIEWS_ERROR';

export const FETCH_ALL_LISTING_REVIEWS = 'app/SearchPage/FETCH_ALL_LISTING_REVIEWS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  reviews: [],
  queryReviewsError: null,
  listingAllReviews: {},
};

const resultIds = data => data.data.map(l => l.id);

const promoPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        currentPageResultIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    // reviews
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };
    case FETCH_ALL_LISTING_REVIEWS:
      return { ...state, listingAllReviews: payload };
    default:
      return state;
  }
};

export default promoPageReducer;

// ================ Action creators ================ //

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const allListingReviews = (reviewData) => ({
  type: FETCH_ALL_LISTING_REVIEWS,
  payload: reviewData,
});

const getBounds = listingParams => {
  if (!listingParams.bounds) return undefined;

  const { ne, sw } = listingParams.bounds;
  const { lat: nelat, lng: nelng } = ne;
  const { lat: swlat, lng: swlng } = sw;

  return `${nelat},${nelng},${swlat},${swlng}`;
};

const searchClassListings = listingParams => async (dispatch, getState, sdk) => {
  try {
    const boundsString = getBounds(listingParams);

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
      params: {
        ...omit(listingParams, [
          'fields.listing',
          'fields.user',
          'fields.image',
          'include',
          'per_page',
        ]),
        bounds: boundsString,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    const ensureResponse = ensureCURLListings(response);
    dispatch(addMarketplaceEntities(ensureResponse));
    dispatch(searchListingsSuccess(ensureResponse));
    return ensureResponse;
  } catch (e) {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  }
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const availabilityParams = (datesParam, minDurationParam, timesParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const timeValues = timesParam ? timesParam.split(',') : [];
    const hasTimeValues = timeValues && timeValues.length === 2;
    const startDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[0]} ${timeValues[0]}`
        : hasDateValues
          ? dateValues[0]
          : null; //"2020-06-12 12:00"//;
    const endDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[1]} ${timeValues[1]}`
        : hasDateValues
          ? dateValues[1]
          : null; //"2020-06-12 13:00"//;

    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};

    const defaultTimeZone = () =>
      typeof window !== 'undefined'
        ? getDefaultTimeZoneOnBrowser()
        : config.custom.dateRangeLengthFilterConfig.searchTimeZone;

    const timeZone = defaultTimeZone();
    return hasDateValues
      ? {
        start: formatDateStringToTz(startDate, timeZone),
        end: formatDateStringToTz(endDate, timeZone),

        // When we have `time-partial` value in the availability, the
        // API returns listings that don't necessarily have the full
        // start->end range available, but enough that the minDuration
        // (in minutes) can be fulfilled.
        //
        // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering

        availability: 'time-partial',
        ...minDurationMaybe,
      }
      : {};
  };

  const { perPage, price, dates, minDuration, times, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const availabilityMaybe = availabilityParams(dates, minDuration, times);

  const params = {
    ...rest,
    ...priceMaybe,
    ...availabilityMaybe,
    per_page: perPage,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.square-small2x',
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  if (params.pub_listing_type === LISTING_TYPES.CLASS) {
    return dispatch(searchClassListings(params));
  }

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const fetchAllListingReviews = listingRef => (dispatch, getState, sdk) => {
  let listingReview = {}
  listingRef.forEach(async (ref, index) => {
    try {
      const resp = await sdk.reviews
        .query({
          listing_id: ref,
          type: 'ofProvider',
          state: 'public',
        })
      if (resp.data.data.length !== 0) {
        listingReview[ref.uuid] = resp.data.data
      }
    } catch (error) {
      console.log(error)
    }
    if (index === listingRef.length - 1) {
      dispatch(allListingReviews(listingReview))
    }
  })
};

import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { HitpaySettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  saveHitpaySettings,
} from './HitpaySettingsPage.duck';
import css from './HitpaySettingsPage.css';

export const HitpaySettingsPageComponent = props => {
  const {
    saveHitpayKeyError,
    saveHitpayKeyInProgress,
    currentUser,
    scrollingDisabled,
    onSubmitHitpaySettings,
    intl,
    updated,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const { profile } = user.attributes;
          
  const privateData = profile.privateData || {};
  const hitpayKey = privateData.hitpayKey && privateData.hitpayKey.substr(privateData.hitpayKey.length - 4);
  const hitpayKeySalt = privateData.hitpayKeySalt && privateData.hitpayKeySalt.substr(privateData.hitpayKeySalt.length - 4);

  const hitpaySettingsform = user.id ? (
    <HitpaySettingsForm
      className={css.form}
      initialValues={{ hitpayKey:  hitpayKey ? `•••••••••••••••••••••••• ${hitpayKey}` : null , hitpayKeySalt: hitpayKeySalt ? `•••••••••••••••••••••••• ${hitpayKeySalt}` : null }}
      saveHitpayKeyError={saveHitpayKeyError}
      inProgress={saveHitpayKeyInProgress}
      currentUser={currentUser}
      onSubmit={values => onSubmitHitpaySettings({ ...values })}
      hitpayKeyCurrentValue={hitpayKey}
      hitpayKeySaltCurrentValue={hitpayKeySalt}
      ready={updated}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'HitpaySettingsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="HitpaySettingsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="HitpaySettingsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="HitpaySettingsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="HitpaySettingsPage.heading" />
            </h1>
            {hitpaySettingsform}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

HitpaySettingsPageComponent.defaultProps = {
  saveHitpayKeyError: null,
  saveHitpayKeyInProgress: null,
  currentUser: null,
};

HitpaySettingsPageComponent.propTypes = {
  saveHitpayKeyError: propTypes.error,
  saveHitpayKeyInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onSubmitHitpaySettings: func.isRequired,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
  } = state.user;
  const {
    saveHitpayKeyError,
    saveHitpayKeyInProgress,
    updated,
  } = state.HitpaySettingsPage;
  
  const userMatches = currentUser && currentUser.id && getMarketplaceEntities(state, [{ type: 'user', id: currentUser.id }]);

  return {
    currentUser: userMatches && userMatches.length === 1 ? userMatches[0] : currentUser,
    saveHitpayKeyError,
    saveHitpayKeyInProgress,
    updated,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitHitpaySettings: values => dispatch(saveHitpaySettings(values)),
});

const HitpaySettingsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(HitpaySettingsPageComponent);

HitpaySettingsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default HitpaySettingsPage;

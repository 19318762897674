import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import Slider from '@mui/material/Slider';
import css from './PriceFilterOnPopup.css';
import styled from 'styled-components';

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#00ab99', //color of the slider between thumbs
  '& .MuiSlider-thumb': {
    backgroundColor: '#00ab99', //color of thumbs
  },
  '& .MuiSlider-rail': {
    color: '#00bfe6', ////color of the slider outside  teh area between thumbs
  },
}));

const PriceFilterOnPopup = props => {
  const { handleFiltersChange, currentQueryParams } = props;


  //slider logic
  const priceFromQueryString = currentQueryParams?.price;
  const rangeInitialValue = priceFromQueryString ? priceFromQueryString.split(',') : [1, 500];
  const [range, setRange] = useState(rangeInitialValue);

  const handleChange = (event, newValue) => {
    setRange(newValue);
  };

  const handleSubmit = (event, value) => {
    const newValue = {
      price: `${value[0]},${value[1]}`,
    };
    handleFiltersChange(newValue);
  };

  function valuetext(value) {
    return `$${value}`;
  }

  //---

  return (
    <div className={classNames(css.wrapper, css.disableSelect)}>
      <p className={css.popupLabel}>
        Price range{' '}
        <span className={css.rangeValue}>
          ${range[0]} - ${range[1]}
        </span>
      </p>
      <div className={css.sliderWrapper}>
        <CustomSlider
          getAriaLabel={() => 'Temperature range'}
          value={range}
          onChange={handleChange}
          onChangeCommitted={handleSubmit}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          valueLabelFormat={valuetext}
          className={css.slider}
          min={1}
          max={2000}
        />
      </div>
    </div>
  );
};

export default PriceFilterOnPopup;

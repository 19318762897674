import React from 'react';
import { string, bool, node } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, HeadingFormFieldWrapper } from '../../components';
import FieldStandardTime from './FieldStandardTime'
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  unitDivisor,
  convertUnitToSubUnit,
  convertMoneyToNumber,
  ensureDotSeparator,
  formatMoney,
} from '../../util/currency';

import css from './CustomBookingForm.css';

const { Money } = sdkTypes;

const textRequired = value => (value ? undefined : 'Required');

// Convert unformatted value (e.g. 10,00) to Money (or null)
const getPrice = (unformattedValue, currency) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : new Money(
        convertUnitToSubUnit(unformattedValue, unitDivisor(currency)),
        currency
      );
  } catch (e) {
    return null;
  }
};

const getStandardTime = (items, participants) => {
  if (items >= 31 || participants >= 31)
    return 120;
  else if ((items >= 21 && items <= 30) || (participants >= 16 && participants <= 30))
    return 96;
  else if ((items >= 9 && items <= 20) || (participants >= 7 && participants <= 15))
    return 72;
  else if (items <= 8 || participants <= 6)
    return 48;
  else
    return 48;
}
const CustomBookingFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        handleSubmit,
        inProgress,
        intl,
        authorDisplayName,
        sendEnquiryError,
        listing,
        tabName,
        values,
        form,
      } = fieldRenderProps;

      const packages = (listing?.attributes?.publicData?.packages) || [];
      const baseCurrency = (listing?.attributes?.publicData?.base_currency) || "USD"
      const customePackageData = (packages && packages[2] ? packages[2] : {})
      const itemPrice = new Money((customePackageData?.items?.amount || 0), baseCurrency)
      const participantsPrice = new Money((customePackageData?.participants?.amount || 0), baseCurrency)

      const totalItems = parseInt(values?.items || 0);
      const itemsPriceInNumber = itemPrice ? convertMoneyToNumber(itemPrice) : 0;
      const totalItemsPrice = totalItems * itemsPriceInNumber;
      const formattedItemsPrice = getPrice(ensureDotSeparator(totalItemsPrice.toString()), baseCurrency)
      const formattedItemsPriceString = formatMoney(intl, formattedItemsPrice);

      const totalParticipants = values?.participants || 0;
      const participantsPriceInNumber = itemPrice ? convertMoneyToNumber(participantsPrice) : 0;
      const totalParticipantsPrice = parseInt(totalParticipants) * participantsPriceInNumber;
      const formattedParticipantsPrice = getPrice(ensureDotSeparator(totalParticipantsPrice.toString()), baseCurrency)
      const formattedParticipantsPriceString = formatMoney(intl, formattedParticipantsPrice);

      const TotalItemAndParticipants = formattedItemsPrice.amount + formattedParticipantsPrice.amount;

      const standardTime = getStandardTime(totalItems, totalParticipants);
      const standardDeliveryTime = parseInt(standardTime || 48);
      const standardCollaborationTime = parseInt(standardTime || 48)


      const deliveryTimevalue = parseInt(values?.deliveryTime?.hours || standardDeliveryTime);
      const deliveryTimePercentage = parseInt(values?.deliveryTime?.percentage || 0);
      const deliveryTimeAmount = deliveryTimePercentage ? (TotalItemAndParticipants * parseFloat(deliveryTimePercentage / 100).toFixed(2)) : 0;
      const deliveryTimePrice = new Money(deliveryTimeAmount, baseCurrency);
      const formattedDeliveryTimePriceString = formatMoney(intl, deliveryTimePrice);


      const collaborationTimeValue = parseInt(values?.collaborationTime?.hours || standardCollaborationTime);
      const collaborationTimePercentage = parseInt(values?.collaborationTime?.percentage || 0);
      const collaborationTimeAmount = collaborationTimePercentage ? (TotalItemAndParticipants * parseFloat(collaborationTimePercentage / 100).toFixed(2)) : 0;
      const collaborationTimePrice = new Money(collaborationTimeAmount, baseCurrency)
      const formattedCollaborationTimePriceString = formatMoney(intl, collaborationTimePrice);

      const TotalPrice = formattedItemsPrice.amount + formattedParticipantsPrice.amount + deliveryTimePrice.amount + collaborationTimePrice.amount;
      const grandTotal = new Money(TotalPrice, baseCurrency);
      const formattedGrandTotal = formatMoney(intl, grandTotal);

      const showBreakDown = values.items && values.participants && values.collaborationTime && values.deliveryTime

      const messageLabel = intl.formatMessage(
        {
          id: 'EnquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);


      //items
      const itemLabel = intl.formatMessage({
        id: 'CustomBookingForm.package1ItemLabel',
      });
      const itemsPlaceholderMessage = intl.formatMessage({
        id: 'CustomBookingForm.itemsPlaceholder',
      });
      const itemsSupportMessage = intl.formatMessage({
        id: 'CustomBookingForm.itemsSupport',
      });

      // participants
      const ParticipantsLabel = intl.formatMessage({
        id: 'CustomBookingForm.participantsLabel',
      });
      const participantsPlaceholderMessage = intl.formatMessage({
        id: 'CustomBookingForm.participantsPlaceholder',
      });
      const participantsSupportMessage = intl.formatMessage({
        id: 'CustomBookingForm.participantsSupport',
      });

      //deliveryTime
      const deliveryTimeLabel = intl.formatMessage({
        id: 'CustomBookingForm.deliveryTimeLabel',
      });
      const deliveryTimePlaceholderMessage = intl.formatMessage({
        id: 'CustomBookingForm.deliveryTimePlaceholder',
      });
      const deliveryTimeSupportMessage = intl.formatMessage({
        id: 'CustomBookingForm.deliveryTimeSupport',
      });

      //collaborationTime
      const collaborationTimeLabel = intl.formatMessage({
        id: 'CustomBookingForm.collaborationTimeLabel',
      });
      const collaborationTimePlaceholderMessage = intl.formatMessage({
        id: 'CustomBookingForm.collaborationTimePlaceholder',
      });
      const collaborationTimeSupportMessage = intl.formatMessage({
        id: 'CustomBookingForm.collaborationTimeSupport',
      });


      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            id='items'
            name='items'
            className={css.field}
            type='number'
            label={itemLabel}
            supportText={itemsSupportMessage}
            placeholder={itemsPlaceholderMessage}
            validate={textRequired}
          />

          <FieldTextInput
            id='participants'
            name='participants'
            className={css.field}
            type='number'
            label={ParticipantsLabel}
            supportText={participantsSupportMessage}
            placeholder={participantsPlaceholderMessage}
            validate={textRequired}
          />

          <FieldStandardTime
            id='delivery_time'
            name='deliveryTime'
            className={css.field}
            type='number'
            form={form}
            defaultValue={standardDeliveryTime}
            standardTime={standardDeliveryTime}
            discout24hrs={parseInt(customePackageData?.delivery24Slower || 0)}
            discout48hrs={parseInt(customePackageData?.delivery48Slower || 0)}
            premium24hrs={parseInt(customePackageData?.delivery24quicker || 0)}
            premium48hrs={parseInt(customePackageData?.delivery48quicker || 0)}
            label={deliveryTimeLabel}
            placeholder={deliveryTimePlaceholderMessage}
            supportText={deliveryTimeSupportMessage}
            validate={textRequired}
          />

          <FieldStandardTime
            id='collaboration_time'
            name='collaborationTime'
            className={css.field}
            type='number'
            form={form}
            defaultValue={standardCollaborationTime}
            standardTime={standardCollaborationTime}
            discout24hrs={parseInt(customePackageData?.collaboration24Shorter || 0)}
            discout48hrs={parseInt(customePackageData?.collaboration48Shorter || 0)}
            premium24hrs={parseInt(customePackageData?.collaboration24Longer || 0)}
            premium48hrs={parseInt(customePackageData?.collaboration48Longer || 0)}
            label={collaborationTimeLabel}
            placeholder={collaborationTimePlaceholderMessage}
            supportText={collaborationTimeSupportMessage}
            validate={textRequired}
          />

          {showBreakDown && (<div className={css.breakdownWrapper}>

            <h3 className={css.orderBreakdownTitle}>
              <FormattedMessage id="TransactionPanel.orderBreakdownTitle" />
            </h3>
            <div className={css.infoWrapper}>
              <div>
                <span className={css.infoText}>{`Up to ${totalItems} itinerary items`}</span>
              </div>
              <div>
                <span className={css.infoText}>{formattedItemsPriceString}</span>
              </div>
            </div>

            <div className={css.infoWrapper}>
              <div>
                <span className={css.infoText}>{`Up to ${totalParticipants} participants`}</span>
              </div>
              <div>
                <span className={css.infoText}>{formattedParticipantsPriceString}</span>
              </div>
            </div>

            <div className={css.infoWrapper}>
              <div>
                <span className={css.infoText}>{`${deliveryTimevalue}hr delivery`}{deliveryTimePercentage ? deliveryTimePercentage < 0 ? ` (${deliveryTimePercentage}% Discount)` : ` (${deliveryTimePercentage}% Premium)` : ""}</span>
              </div>
              <div>
                <span className={css.infoText}>{formattedDeliveryTimePriceString}</span>
              </div>
            </div>

            <div className={css.infoWrapper}>
              <div>
                <span className={css.infoText}>{`${collaborationTimeValue}hr collaboration`} {collaborationTimePercentage ? collaborationTimePercentage < 0 ? ` (${collaborationTimePercentage}% Discount)` : ` (${collaborationTimePercentage}% Premium)` : ""}</span>
              </div>
              <div>
                <span className={css.infoText}>{formattedCollaborationTimePriceString}</span>
              </div>
            </div>

            <div className={css.totalPrice}>
              <div>
                <span className={css.infoText}>Total price</span>
              </div>
              <div>
                <span>{formattedGrandTotal}</span>
              </div>
            </div>
          </div>)}

          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton type="submit"
              onClick={() => {
                form.change('packageLable', tabName);
                form.change('price', grandTotal);
                form.change('itemsUnitPrice', formattedItemsPrice)
                form.change('participantsUnitPrice', formattedParticipantsPrice)
                form.change('deliveryTime', { hours: deliveryTimevalue, percentage: deliveryTimePercentage })
                form.change('collaborationTime', { hours: collaborationTimeValue, percentage: collaborationTimePercentage })
              }}
              inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="CustomBookingForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

CustomBookingFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

CustomBookingFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: node.isRequired,
  authorDisplayName: string,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CustomBookingForm = compose(injectIntl)(CustomBookingFormComponent);

CustomBookingForm.displayName = 'CustomBookingForm';

export default CustomBookingForm;

import React, { useState } from 'react';
import CheckBox from 'rc-checkbox';

import moreIcon from '../../assets/more.png';
import Heading from '../Heading';
import css from './jobsCard.css';
import Button from 'components/Button/Button';

const Level_Of_Seller = [
  {
    key: 'open',
    label: 'Open',
    color: 'green',
  },
  {
    key: 'closed',
    label: 'Closed',
    color: 'red',
  },
];

export default function JobsCard(props) {
  const {
    title = 'Nill',
    description = 'Nill',
    authorDisplayName,
    currentAddress = 'Nill',
    weekDayAvailable = 'Nill',
    onClick,
  } = props;
  const [selectedSellerLevels, setSelectedSellerLevels] = useState(['Open']);
  const [checked, setChecked] = useState(false);

  const onClickCheckbox = () => {
    setChecked(!checked);
  };

  const onSelectSellerLevelHandler = value => {
    setSelectedSellerLevels(value);
  };

  return (
    <div className={css.cardContainer}>
      <div class={css.jobSelected}>
        <CheckBox checked={checked} onChange={onClickCheckbox} />
      </div>
      <div class={css.jobTitle}>
        <div class="innerCont">
          <Heading title={title} />
          <Heading title="Singapore" headingClass={css.gameDetails} />
        </div>
      </div>
      <div class={css.jobDescription}>
        <div class="innerCont">
          <Heading title="Description" headingClass={css.title} />

          <Heading title={description} headingClass={css.subTitle} />
        </div>
      </div>
      <div class={css.jobTimes}>
        <div class="innerCont">
          <Heading title="Times" headingClass={css.title} />
          <Heading title={weekDayAvailable?.toUpperCase()} headingClass={css.timing} />
        </div>
      </div>
      <div class={css.jobLocation}>
        <div class="innerCont">
          <Heading title="Location" headingClass={css.title} />
          <Heading title={currentAddress} headingClass={css.subHeading} />{' '}
        </div>
      </div>
      <div class={css.jobStatus}>
        <div class="innerCont">
          <Heading title="Status" headingClass={css.title} />
          <Button rootClassName={css.bookButton} onClick={onClick}>
            <div className={css.statusIcon}></div>
            Open
          </Button>
        </div>
      </div>
      <div class={css.jobMoreIcon}>
        <img src={moreIcon} className={css.moreIcon} alt="More Icon Image" />
      </div>
    </div>
  );
}

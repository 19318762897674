import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import Slider from '@mui/material/Slider';
import css from './PriceFilter.module.css';
import styled from 'styled-components';
import { Badge } from '@mui/material';

// .MuiSlider-thumb{
//     color: green;
//   }
//   .MuiSlider-rail{
//     color:lightgreen;
//   }
//   .MuiSlider-track{
//     color: green;
//   }

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#00ab99', //color of the slider between thumbs
  '& .MuiSlider-thumb': {
    backgroundColor: '#00ab99', //color of thumbs
  },
  '& .MuiSlider-rail': {
    color: '#00bfe6', ////color of the slider outside  teh area between thumbs
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 6,
    top: 6,
    border: `1px solid #fff`,
    padding: '0 4px',
  },
}));

const PriceFilter = props => {
  const { handleFiltersChange, currentQueryParams } = props;

  //popup logic
  const wrapperRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  // ---

  //slider logic
  const priceFromQueryString = currentQueryParams?.price;
  const rangeInitialValue = priceFromQueryString ? priceFromQueryString.split(',') : [1, 500];
  const [range, setRange] = useState(rangeInitialValue);

  const handleChange = (event, newValue) => {
    setRange(newValue);
  };

  const handleSubmit = (event, value) => {
    const newValue = {
      price: `${value[0]},${value[1]}`,
    };
    handleFiltersChange(newValue);
  };

  function valuetext(value) {
    return `$${value}`;
  }

  //---

  return (
    <div className={classNames(css.wrapper, css.disableSelect)} ref={wrapperRef}>
      <StyledBadge className={css.badgeWrapperClass} badgeContent={priceFromQueryString ? 1 : 0} color="success" max={9}>
        <div className={(showPopup || priceFromQueryString) ? css.filterLabelSelected : css.filterLabel} onClick={() => setShowPopup(!showPopup)}>
          <span>Price</span>
        </div>
      </StyledBadge>
      {showPopup && (
        <div className={css.popupWrapper}>
          <p className={css.popupLabel}>
            Price range{' '}
            <span className={css.rangeValue}>
              ${range[0]} - ${range[1]}
            </span>
          </p>
          <div className={css.sliderWrapper}>
            <CustomSlider
              getAriaLabel={() => 'Temperature range'}
              value={range}
              onChange={handleChange}
              onChangeCommitted={handleSubmit}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              valueLabelFormat={valuetext}
              className={css.slider}
              min={1}
              max={2000}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceFilter;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import isEqual from 'lodash/isEqual';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { subUnitDivisors } from '../../currency-config';
import { Form, PrimaryButton, FieldSelect } from '../../components';

import css from './CurrencyDetailsForm.css';


class CurrencyDetailsFormComponent extends Component {

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            intl,
            rootClassName,
            className,
            currentUser,
            handleSubmit,
            inProgress,
            values,
            ready,
            invalid,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          //Marketplace Currency
          const marketplaceCurrencyLabel = intl.formatMessage({
            id: 'CurrencyDetailsForm.marketplaceCurrencyLabel',
          });

          //Currency
          const currencyLabel = intl.formatMessage({
            id: 'CurrencyDetailsForm.currencyLabel',
          });

          const classes = classNames(rootClassName || css.root, className);
          const pristineSinceLastSubmit = isEqual(values, fieldRenderProps.initialValues);
          const submitDisabled = invalid || pristineSinceLastSubmit || inProgress;

          return (
            <Form
              className={classes}
              onSubmit={handleSubmit}
            >
              <div>
                <FieldSelect
                  className={css.currencyField}
                  type="select"
                  name="marketplaceCurrency"
                  id="marketplaceCurrency"
                  label={marketplaceCurrencyLabel}
                  defaultValue={'USD'}
                >
                  <option value="USD">USD</option>
                </FieldSelect>

                <FieldSelect
                  className={css.currencyField}
                  type="select"
                  name="currency"
                  id="currency"
                  label={currencyLabel}
                  defaultValue={'USD'}
                >
                  {Object.keys(subUnitDivisors).map(unit => {
                    return (
                      <option key={unit} value={unit}>
                        {unit}
                      </option>
                    )
                  })}
                </FieldSelect>


                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={ready}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="PasswordChangeForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form >
          );
        }}
      />
    );
  }
}

CurrencyDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { bool, string } = PropTypes;

CurrencyDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
};

const CurrencyDetailsForm = compose(injectIntl)(CurrencyDetailsFormComponent);
CurrencyDetailsForm.displayName = 'CurrencyDetailsForm';

export default CurrencyDetailsForm;

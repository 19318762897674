export function sequence(tasks, fn) {
  return tasks.reduce((promise, task) => promise.then(() => fn(task)), Promise.resolve());
}

export const getCombinedLocationSearchString = listing => {
  const publicData = listing?.attributes?.publicData;
  let searchString = '';

  //favourite continent
  const continent = publicData?.continent;
  if (continent) {
    searchString += ` ${continent}`;
  }

  //born in
  const bornIn = publicData?.nationality?.search;
  if (bornIn) {
    searchString += ` ${bornIn}`;
  }

  //countries specialized in
  const countries =
    typeof publicData?.countries === 'string'
      ? publicData?.countries?.replaceAll(':', ' ').split(', ')
      : publicData?.countries;
  if (countries) {
    searchString += ` ${countries}`;
  }
  return searchString;
};

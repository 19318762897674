import React, { Component } from 'react';
import { string, bool, node } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, SecondaryButton, FieldTextInput, AvatarLarge, NamedLink} from '../../components';
import { propTypes } from '../../util/types';
import { getExtensionFromUrl } from 'util/urlHelpers';
import _ from 'lodash';

import css from './ProgressReportForm.css';

export class ProgressReportFormComponent extends Component {


  render() {
    const {
      uploadWidget,
      attachments,
      transactions,
      progressReports,
    } = this.props;

    return (
      <FinalForm
        {...this.props}
        initialValues={{
          
        }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            inProgress,
            intl,
            isAnyPendingProgressReport,
            pendingProgressReportTxIds,
          } = fieldRenderProps;

          const progressReportLabel = intl.formatMessage(
            {
              id: 'ProgressReportForm.progressReport',
            }
          );
          const progressReportPlaceholder = intl.formatMessage(
            {
              id: 'ProgressReportForm.progressReportPlaceholder',
            }
          );

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = submitInProgress || progressReports.length > 0;
          return (
            <Form className={classes} onSubmit={handleSubmit}> 
              {
                (isAnyPendingProgressReport && pendingProgressReportTxIds.length > 0) ? 
                  <div className={css.progressReports}>  
                    {_.uniq(pendingProgressReportTxIds).map((id, index) => {
                      const attachment = attachments.filter(attachment => attachment.id === id)[0]
                      const transaction = transactions.filter(tx => tx.id.uuid === id)[0]
                      if(!transaction)
                        return null;
                      const listing=transaction.listing
                      // const slug = createSlug(listing.attributes.title);
                      return(
                        <div className={css.progressReport}>
                          <div className={css.header}>
                            <div className={css.avatarWrapperProviderDesktop}>
                              <AvatarLarge user={transaction.customer} className={css.avatarDesktop} />
                            </div>
                            <div className={css.headerLinks}>
                              
                              <div className={css.saleDetails}>
                                <NamedLink
                                  name='SaleDetailsPage'
                                  params={{ id: id}}
                                >
                                  <span className={css.clientName}>{transaction.customer.attributes.profile.displayName}</span>
                                  <span className={css.listingTitle}>{listing.attributes.title}</span>
                                </NamedLink>
                              </div>
                            </div>
                          </div>

                          <FieldTextInput
                            className={css.notes}
                            type="textarea"
                            name={`progress_report[${id}][notes]`}
                            id={`${id}.notes`}
                            label={progressReportLabel}
                            placeholder={progressReportPlaceholder}
                          />

                          {
                            (attachment) ? 
                              <div className={css.attachment}>
                                {
                                  attachment.resource_type === "image" ?
                                    <a
                                    // eslint-disable-next-line 
                                    target="_blank" href={attachment.attachment}>
                                      <img src={attachment.attachment} alt="attachment"/>
                                    </a> :
                                  attachment.resource_type === "video" ?
                                    (
                                      <video src={attachment.attachment} controls width="100%">
                                        <source
                                          src={attachment.attachment}
                                          type={`video/${getExtensionFromUrl(attachment.attachment)}`}
                                        />
                                      </video>
                                    ) : null
                                }
                                
                              </div>
                              : null
                          }

                          <SecondaryButton className={css.uploadBtn} disabled={submitDisabled} onClick={(e) => uploadWidget(e, id)}>
                            <FormattedMessage id="ProgressReportForm.UploadBtn" />
                          </SecondaryButton>
                        </div>
                      )})}

                    <div className={css.submitBtnWrapper}>
                      <PrimaryButton className={css.submit} type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                        <FormattedMessage id="ProgressReportForm.submitButtonText" />
                      </PrimaryButton>
                    </div>
                  </div>
                   : null
              }
            </Form>
          );
        }}
      />
    )
  }
}

ProgressReportFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  sendEnquiryError: null,
};

ProgressReportFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  inProgress: bool,

  listingTitle: node.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProgressReportForm = compose(injectIntl)(ProgressReportFormComponent);

export default ProgressReportForm;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { PromotedListing, SectionBanner, HorizontalScroll, ListingCard } from '../../components';
import css from '../../containers/LandingPage/LandingPagePromotedListings.css';

const SectionPromotedListings = props => {
  const {
    rootClassName,
    className,
    intl,
    promotedListings,
    preHighlightTitle,
    highlightTitle,
    postHighlightTitle,
    subTitle,
    showAll,
    allRelatedQuery,
    bannerClass,
    isListingPage,
    allListingReview,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <div className={isListingPage ? css.newheader : css.header}>
        <div className={css.title}>
          {preHighlightTitle && <FormattedMessage id={preHighlightTitle} />}
          {highlightTitle && (
            <span className={css.titleHighlight}>
              <FormattedMessage id={highlightTitle} />
            </span>
          )}
          {postHighlightTitle && <FormattedMessage id={postHighlightTitle} />}
        </div>
        {isListingPage && (
          <span className={css.PromotedListingLength}>
            {promotedListings && promotedListings.length} Listing
          </span>
        )}
      </div>

      {subTitle && (
        <p className={css.subTitle}>
          <FormattedMessage id={subTitle} />
        </p>
      )}

      {bannerClass ? <SectionBanner className={bannerClass} /> : null}

      <div>
        {promotedListings ? (
          <HorizontalScroll isSmallScrollArrow={true} className={css.promotedListingContainer}>
            {promotedListings.map(pl => (
              <ListingCard
                className={css.listingCard}
                key={pl.id.uuid}
                listing={pl}
                renderSizes={cardRenderSizes}
                setActiveListing={() => { }}
                reviews={(allListingReview && allListingReview[pl.id.uuid]) || []}
                viewButtonClass={css.viewButtonClass}
                authorNameClass={css.authorNameClass}
                startingAtClass={css.startingAtClass}
                cardContainerClass={css.cardContainerClass}
                imageWrapperClass={css.imageWrapperClass}
                addressWrapperClass={css.addressWrapperClass}
                reviewsWrapperClass={css.reviewsWrapperClass}
                descriptionClass={css.descriptionClass}
                buttonWrapperClass={css.buttonWrapperClass}
              />

              // <PromotedListing
              //   key={pl.id.uuid}
              //   pl={pl}
              //   intl={intl}
              //   reviews={allListingReview ? allListingReview[pl.id.uuid] : []}
              // />
            ))}
          </HorizontalScroll>
        ) : null}
      </div>

      {/*{allRelatedQuery && showAll && (
        <div className={css.buttonWrap}>
          <a href={`/s?pub_category=${allRelatedQuery}`}>
            <p className={css.showAll}>
              <FormattedMessage id={showAll} /> 
            </p>
          </a>
        </div>
      )}*/}
    </div>
  );
};

SectionPromotedListings.defaultProps = {
  rootClassName: null,
  className: null,
  isListingPage: false,
};

const { string } = PropTypes;

SectionPromotedListings.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPromotedListings;

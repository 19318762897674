import React, { Component } from 'react';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    PrimaryButton,
    NamedLink,
    PromotedListing,
} from 'components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { TopbarContainer } from 'containers/index';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { loadData } from '../LandingPage/LandingPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import GetQuoteForm from '../../forms/GetQuoteForm/GetQuoteForm';
import css from './ArticlePage.css';

export class ArticlePageComponent extends Component {
    // constructor(props) {
    //     super(props);
    // }



    componentDidMount() {
    }

    render() {

        const {
            intl,
            promotedListings = [],
            listingAllReviews,
        } = this.props;

        const promotedTrainers = promotedListings.filter(
            pl => pl.attributes.publicData.category === 'trainers'
        );

        const topPromotedListings = promotedTrainers.splice(0, 5)

        return (
            <Page title={"Article Page"}>
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar >
                        <TopbarContainer isDarkMode={true} />
                    </LayoutWrapperTopbar>

                    <LayoutWrapperMain >
                        <div className={css.articleTopContainer}>
                            <div className={css.containerWrapper}>
                                <NamedLink name="TopBlogsPage" className={css.backToBlogLink}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <path d="M6.25 15H23.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.25 15L11.25 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.25 15L11.25 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <FormattedMessage id="ArticlePage.backToBlog" />
                                </NamedLink>
                                <h1 className={css.articleTitle}><FormattedMessage id="ArticlePage.whatPerfectGym" /></h1>
                                <span className={css.articleSubTitle}><FormattedMessage id="ArticlePage.Experience" /></span>
                            </div>
                        </div>

                        <div className={css.articleDetailContainer}>
                            <div className={css.containerWrapper}>
                                <div className={css.articleImageWrapper}>
                                    <img src={'/static/images/articlePage/howMuchEarnFitnessFreelancer.png'} alt=''/>
                                </div>
                                <div>
                                    <div className={css.timeAndLinkWrapper}>
                                        <div className={css.articleTime}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                <path d="M14 0C6.28027 0 0 6.28027 0 14C0 21.7198 6.28027 28 14 28C21.7198 28 28 21.7197 28 14C28 6.28027 21.7198 0 14 0ZM14 24.9399C7.96758 24.9399 3.06011 20.0324 3.06011 14C3.06011 7.96788 7.96758 3.06011 14 3.06011C20.0324 3.06011 24.9399 7.96788 24.9399 14C24.9399 20.0324 20.0324 24.9399 14 24.9399Z" fill="black" />
                                                <path d="M15.2118 14.0167V8.14898C15.2118 7.49381 14.6809 6.96289 14.0259 6.96289C13.3708 6.96289 12.8398 7.49381 12.8398 8.14898V14.3956C12.8398 14.4143 12.8444 14.4318 12.8453 14.4504C12.8297 14.7729 12.9408 15.1004 13.1872 15.3467L17.6045 19.7637C18.0678 20.227 18.8187 20.227 19.2817 19.7637C19.7447 19.3004 19.745 18.5495 19.2817 18.0864L15.2118 14.0167Z" fill="black" />
                                            </svg>
                                            <FormattedMessage id="ArticlePage.articleTime" />
                                        </div>
                                        <div className={css.articleSocialLinks}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                                <rect x="1" y="1" width="41.7179" height="41.7179" rx="20.859" stroke="#0095B3" stroke-width="2" />
                                                <path d="M32 15.8925C31.2875 16.212 30.5512 16.4332 29.7675 16.5315C30.575 16.0399 31.1925 15.2535 31.4775 14.2949C30.7175 14.7619 29.8862 15.0814 29.0075 15.278C28.295 14.4916 27.2738 14 26.1575 14C23.9963 14 22.2625 15.8187 22.2625 18.0307C22.2625 18.3502 22.2863 18.6452 22.3575 18.9401C19.1275 18.7926 16.2775 17.1705 14.3538 14.7373C12.9763 17.2934 14.52 19.4071 15.5413 20.1198C14.9238 20.1198 14.3063 19.9232 13.7838 19.6283C13.7838 21.619 15.1375 23.2657 16.895 23.6344C16.515 23.7573 15.66 23.831 15.1375 23.7081C15.6363 25.3057 17.085 26.4854 18.7713 26.51C17.4413 27.5914 15.4937 28.4516 13 28.1813C14.7337 29.3364 16.7762 30 18.985 30C26.1575 30 30.0525 23.8556 30.0525 18.5469C30.0525 18.3748 30.0525 18.2028 30.0287 18.0307C30.8362 17.4163 31.5012 16.7035 32 15.8925Z" fill="#0095B3" />
                                            </svg>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                                <rect x="1" y="1" width="41.7179" height="41.7179" rx="20.859" stroke="#0095B3" stroke-width="2" />
                                                <path d="M31.7955 29.4108V22.7148C31.7955 19.424 31.0871 16.9102 27.2478 16.9102C25.3967 16.9102 24.1626 17.9157 23.6598 18.8755H23.6141V17.2072H19.9805V29.4108H23.7741V23.3547C23.7741 21.755 24.0712 20.2239 26.0365 20.2239C27.9791 20.2239 28.0019 22.0293 28.0019 23.4461V29.388H31.7955V29.4108Z" fill="#0095B3" />
                                                <path d="M13.8086 17.209H17.6022V29.4125H13.8086V17.209Z" fill="#0095B3" />
                                                <path d="M15.7056 11.1289C14.4944 11.1289 13.5117 12.1116 13.5117 13.3228C13.5117 14.534 14.4944 15.5396 15.7056 15.5396C16.9168 15.5396 17.8995 14.534 17.8995 13.3228C17.8995 12.1116 16.9168 11.1289 15.7056 11.1289Z" fill="#0095B3" />
                                            </svg>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                                <rect x="1" y="1" width="41.7179" height="41.7179" rx="20.859" stroke="#0095B3" stroke-width="2" />
                                                <path d="M22.7732 33.8883V23.5074H26.2563L26.7788 19.4606H22.7732V16.8773C22.7732 15.706 23.0972 14.9078 24.7787 14.9078L26.9198 14.9069V11.2873C26.5495 11.2392 25.2785 11.1289 23.7991 11.1289C20.71 11.1289 18.5951 13.0145 18.5951 16.4765V19.4606H15.1016V23.5074H18.5951V33.8883H22.7732Z" fill="#0095B3" />
                                            </svg>
                                        </div>
                                    </div>
                                    <p className={css.articleDescription}>
                                        So, is there such a thing as a perfect gym? The problem with the word prefect is it sounds like something is finished. In the fitness world, everything is evolving and innovating. If you finish evolving, you run the risk of being left behind. You could have the perfect gym one minute and then become irrelevant if you start to take it easy and fall behind changing consumer behavior.
                                    </p>
                                    <p className={css.articleDescription}>
                                        The drive to create a perfect gym comes from having the ability to provide exceptional service to your customers. Successful gyms really do come in all shapes and sizes. By evaluating what makes a gym successful, you can take away lessons and advice from major brands in the industry. You can then apply these to your fitness business and be on your way to establishing the perfect gym.
                                    </p>
                                    <p className={css.articleDescription}><FormattedMessage id="ArticlePage.SkipAheadTo" /></p>

                                    <div className={css.articleQuestionsWrapper}>
                                        <span className={css.question}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
                                            <path d="M-7.43094e-07 0L0 17L12 8.5L-7.43094e-07 0Z" fill="#0095B3" />
                                        </svg>
                                            <FormattedMessage id="ArticlePage.doesPerfectGymReallyExist" />
                                        </span>
                                        <span className={css.question}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
                                                <path d="M-7.43094e-07 0L0 17L12 8.5L-7.43094e-07 0Z" fill="#0095B3" />
                                            </svg>
                                            <FormattedMessage id="ArticlePage.WellnessIndustry" />
                                        </span>
                                        <span className={css.question}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
                                            <path d="M-7.43094e-07 0L0 17L12 8.5L-7.43094e-07 0Z" fill="#0095B3" />
                                        </svg>
                                            <FormattedMessage id="ArticlePage.whatTypeGymMostSuccessful" />
                                        </span>
                                        <span className={css.question}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
                                                <path d="M-7.43094e-07 0L0 17L12 8.5L-7.43094e-07 0Z" fill="#0095B3" />
                                            </svg>
                                            <FormattedMessage id="ArticlePage.whatFeaturesSuccessfulGym" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={css.articleFAQSection}>
                            <div className={css.containerWrapper}>
                                <h3 className={css.articleFAQ}>What features make a successful gym?</h3>
                                <p className={css.articleDescription}>There could be such a thing as a perfect gym, but the better question is can anything truly be perfect? There could be a perfect gym for a specific target audience, but that may not look perfect for everyone. You can judge a business on its financial success, but what about the value it brings its members, how do you evaluate that? Considering there are so many different types of gyms, fitness clubs, and studios, it’s not a simple answer.</p>
                                <p className={css.articleDescription}>Although different businesses work to create success, they do have some similarities. Firstly, successful health clubs know their numbers. They know what they need to focus on and what questions to ask to create a successful fitness business. Factors like member retention, total revenue, reputation, branding, and experience all come into play when talking about the perfect gym.</p>
                            </div>
                        </div>

                        <div className={css.articleFAQSection}>
                            <div className={css.containerWrapper}>
                                <h3 className={css.articleFAQ}>Wellness industry: hybrid fitness, connectivity, and inclusivity
                                </h3>
                                <p className={css.articleDescription}>In the last year, health awareness has skyrocketed. The pandemic showed how important it is to stay fit, healthy, and to move your body. Nowadays, an increasing number of people are living a healthier, more active lifestyle. </p>
                                <p className={css.articleDescription}>Social distancing protocols and Covid-19 restrictions have shifted the fitness industry. With a clear and growing focus on health, the fitness industry is resiliant and looking to support members now and into the future. Here are a couple of standout statistics to show where the industry is heading as a whole: </p>
                                <p className={css.articleDescription}>The pandemic has highlighted the need to adapt to changing consumer behavior and priorities. Fitness businesses that could provide digital fitness services throughout multiple lockdowns generated a source of income despite being closed. Now, digital fitness platforms are becoming an expected service. This shows how you could be the perfect business one moment and without adapting and shifting your business, you could fall behind the competition.</p>
                                <p className={css.articleDescription}>While the pandemic drags on, it’s clear that consumers have an increased awareness of health. Hybrid fitness, connectivity, and inclusivity will continue to be key elements of the industry and consumer decision-making going forward. From wearables and online fitness to personal training and digital coaching, there’s a lot of opportunities to create a unique member experience to support your members’ current needs.</p>
                                <p className={css.articleDescription}>Exceptional customer service is a key part of creating a great gym business. For expert insight, listen to Fred Hoffman, an international fitness expert, on The Fitness Founders Podcast. He talks about customer service in the digital age and how different digital channels and customer touchpoints work. </p>
                            </div>
                        </div>


                        <div className={css.articleFAQGreySection}>
                            <div className={css.containerWrapper}>
                                <h3 className={css.articleFAQ}>What type of gym business is the most successful?
                                </h3>
                                <p className={css.articleDescription}>There’s a lot of variety in today’s fitness industry. Gyms come in all different styles and locations offering a range of training opportunities. From budget big box gyms to boutique fitness experiences, there are several gym niches. The type of gym business that will be the most successful depends on so many factors. Your market research, location, offering, and market fit need to align to achieve any type of success.</p>
                                <p className={css.articleDescription}>To give you an idea of what makes some of the top gym brands successful, we’re going to look at different types of gyms and how those brands have evolved over time. </p>
                                <div className={css.articleFAQTopicsWrapper}>
                                    <div className={css.topicWrapper}>
                                        <div className={css.articleFAQTopic}>
                                            <h4>Big box gyms </h4>
                                            <p className={css.articleDescription}>When you walk into a gym and see rows of cardio equipment, rowing machines, and free weights, you are likely in a big box gym. The fitness market is saturated with boutique gyms that offer personalized experiences. So, how do big box gyms stay competitive? These gyms tend to have an army of loyal customers due to the sheer size of the brand. By staying innovative and offering a fun curriculum of training, they can make sure members never get bored.</p>
                                            <p className={css.articleDescription}>Brands like LA Fitness, Planet Fitness, and Gold’s Gym fit into this category. They aim to offer affordable access to a wide variety of equipment. Typically, membership includes access to a variety of weight lifting equipment, training props, and group classes.</p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>Boot camps</h4>
                                            <p className={css.articleDescription}>Boot camps are not new, but there is a renewed focus on them. Typically, boot camps are military-style circuits performed outside with exercises like pushups, situps, and burpees. During the pandemic, interest in outdoor exercise and fitness skyrocketed. When you take fitness outside, you have all the fresh air circulating and can make use of any extra space for social distancing. Popular brands include Warrior Fitness Boot Camp and Switch Playground who offer a unique bootcamp experience. </p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>Training gyms</h4>
                                            <p className={css.articleDescription}>Training gyms create a space for people to focus on a specific sport. From MMA gyms to rock climbing gyms, there’s a huge variety of training areas. These facilities cater to a range of abilities from beginner to expert. Interestingly, these types of places tend to gather a very loyal following as they focus on creating a hub centered around a love of a specific sport. </p>
                                        </div>
                                    </div>
                                    <div className={css.topicWrapper}>
                                        <div className={css.articleFAQTopic}>
                                            <h4>Boutique gyms</h4>
                                            <p className={css.articleDescription}>Boutique gyms tend to be more expensive than standard gyms. They offer a more personalized and unique approach to fitness. Typically, they niche down and focus on something specific, but they do this very well. Here are some examples of major boutique gym brands: </p>
                                            <p className={css.articleDescription}>Orangetheory Fitness – the boutique brand integrates technology seamlessly into their workout. Not only is the workout itself very efficient, but the user experience is smooth. By using heart rate monitors, non-fitness experts can perform a very effective workout that delivers results. <br />
                                                Pure Barre – the barre brand originally targeted the stay-at-home mom group, but now includes a much larger clientele. One of the reasons it’s so successful is that it fits a gap for those looking for a low-impact exercise that gets results without needing to do a ton of cardio or weights. The market fit is ideal and the brand continues to evolve and expand with its on-demand workout platform, GO. </p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>Group training</h4>
                                            <p className={css.articleDescription}>Group training is hugely popular. It combines a great workout with a human connection. Many people love to work out alongside a group to help motivate them to keep pushing. You can see how group training is a major element of the fitness industry and how some of the best group training brands continue to innovate. </p>
                                            <p className={css.articleDescription}>DanceBody – they have several studio and pop-up locations as well as online workouts and streaming. The dance-based group classes range from beginner to advanced and focus on creating an enjoyable full-body workout. In 2020, they launched a digital platform DanceBody LIVE for on-demand and live streaming classes. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={css.playBookWrapper}>
                            <FormattedMessage id="ArticlePage.theCustomerEngagementPlaybook" />
                            <PrimaryButton className={css.discoverMoreButton}>
                                <FormattedMessage id="ArticlePage.discoverMore" />
                            </PrimaryButton>
                        </div>

                        <div className={css.articleFAQGreySection}>
                            <div className={css.containerWrapper}>
                                <h3 className={css.articleFAQ}>What features make a successful gym? </h3>
                                <p className={css.articleDescription}>As gyms and fitness studios come in all shapes and sizes, the perfect gym can look a little different in each niche. However, there are certain elements that some of the most successful gyms share that help them to grow and establish themselves within the industry. </p>
                                <div className={css.articleFAQTopicsWrapper}>
                                    <div className={css.topicWrapper}>
                                        <div className={css.articleFAQTopic}>
                                            <h4>1. Unique experience</h4>
                                            <p className={css.articleDescription}>Whether it’s a rock climbing gym or a multi-location gym franchise, the best brands create a unique member experience. When you’re trying to determine what people want at a gym, the member experience should be at the top of the list. It’s what stays with customers long after they leave your gym or finish a stream. It creates a sense of loyalty and increases member retention. </p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>2. Gym equipment and facilities </h4>
                                            <p className={css.articleDescription}>Your gym equipment and facilities impact your overall experience. Poor equipment maintenance and digital user experience can leave your members wanting more. From lockers, shower rooms, and point of sale to the ability to connect wearables to your apps and machines, brands that leverage technology both in and out of the gym tend to come out on top. </p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>4. Aesthetically-pleasing </h4>
                                            <p className={css.articleDescription}>While it’s important to have a workout that delivers results within your gym niche, the facility should be aesthetically pleasing. Alongside a gold standard of hygiene and self-service options, equipment should be well-organized.</p>
                                            <p className={css.articleDescription}>If you’re creating a premium, boutique gym then the aesthetic should align with the demands of your target clientele. This also applies to your branding as a whole, the look and feel of your website, mobile app, branding, and gym should support your business goals and objectives.</p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>7. Personalized fitness programs</h4>
                                            <p className={css.articleDescription}>Nowadays, digital fitness is a key part of a successful gym. Consumers want the option to work out from home, in the park, or at the gym. By creating a digital arm of your business, you can create an additional stream of income and add value to your club members. This also puts you in a stronger position going forward if new Covid-19 restrictions come into place.</p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>8. Member communication and marketing automation</h4>
                                            <p className={css.articleDescription}>Nowadays, digital fitness is a key part of a successful gym. Consumers want the option to work out from home, in the park, or at the gym. By creating a digital arm of your business, you can create an additional stream of income and add value to your club members. This also puts you in a stronger position going forward if new Covid-19 restrictions come into place.</p>
                                        </div>
                                    </div>

                                    <div className={css.topicWrapper}>
                                        <div className={css.articleFAQTopic}>
                                            <h4>2. Club membership management</h4>
                                            <p className={css.articleDescription}>Gym management software can help you to navigate and automate several elements within the business. By doing this, you can spend more time connecting with members and finding ways to elevate your services. Club management software includes functionality that takes out the need for lengthy admin tasks, establishes a client portal, and helps you to streamline your business as a whole.</p>
                                            <p className={css.articleDescription}>The perfect gym software can look different depending on your needs. The main element to look for is a streamlined dashboard and ease of use. From there, you can decide what sort of capabilities you need.</p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>3. Fitness community </h4>
                                            <p className={css.articleDescription}>Your fitness community is a great tool for attracting and retaining members. Creating a sense of community at your gym is essential. Some of the top gyms and fitness studios have a tribe-like feel to them. A sense of community establishes a feeling of belonging that is a great driver for motivation. When you feel like you have to show up for your community and work out, it’s useful in keeping members on track. </p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>5. Digital fitness </h4>
                                            <p className={css.articleDescription}>Nowadays, digital fitness is a key part of a successful gym. Consumers want the option to work out from home, in the park, or at the gym. By creating a digital arm of your business, you can create an additional stream of income and add value to your club members. This also puts you in a stronger position going forward if new Covid-19 restrictions come into place. </p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>6. Convenience and flexibility </h4>
                                            <p className={css.articleDescription}>The demand for convenience and flexibility is noticeable in all areas of life. From immediate transport and shopping to food deliveries and workouts, consumers want to be in control and they want results fast.</p>
                                            <p className={css.articleDescription}>Services such as same-day delivery mean that consumers don’t just demand convenience, they expect it. That means creating flexible membership options, having the ability to take a range of payments, and providing a service that fits around your members’ lives. </p>
                                        </div>

                                        <div className={css.articleFAQTopic}>
                                            <h4>9. Inspire and motivate members </h4>
                                            <p className={css.articleDescription}>How you inspire and motivate members will underpin your success. If you can’t motivate members to achieve results, they will leave. Creating inspirational and motivational content and social media posts helps to support members outside of the gym. Rockstar instructors who can motivate both in-person and online are essential for member retention. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={css.summarySection}>
                            <div className={css.containerWrapper}>
                                <h2 className={css.summaryTitle}><FormattedMessage id="ArticlePage.inSummary" /></h2>
                                <p className={css.articleDescription}>
                                    <FormattedMessage id="ArticlePage.whilePerfectBadThing" />
                                </p>
                            </div>
                            <div className={css.playbookSummaryWrapper}>
                                <div className={css.playbookSummaryInfoSection}>
                                    <span className={css.playbookSummaryInfoTitle}>
                                        <FormattedMessage id="ArticlePage.theCustomerEngagementPlaybook" />
                                    </span>
                                    <FormattedMessage id="ArticlePage.customerEngagementBrand" tagName="p" />
                                    <PrimaryButton className={css.discoverMoreButton}>
                                        <FormattedMessage id="ArticlePage.discoverMore" />
                                    </PrimaryButton>
                                </div>
                                <div className={css.playbookSummaryImageWrapper}>
                                    <img src={'/static/images/articlePage/posterMarketPlace.png'} alt=''/>
                                </div>
                            </div>
                            <div className={css.authorImageSection}>
                                <div>
                                    <div className={css.authorImage}>
                                        <img src={'/static/images/articlePage/jennyWeller.png'} alt='' />
                                    </div>
                                    <div className={css.circleIn}>in</div>
                                </div>
                                <h4 className={css.authorName}>Jenny Weller</h4>
                                <span className={css.authorPost}>Sports and Wellnes  Writer</span>
                            </div>

                        </div>


                        {topPromotedListings && topPromotedListings.length > 0 ? (
                                <div className={css.ourTopExampleContainer}>
                                    <h3 className={css.ourTopExampleTitle}><FormattedMessage id="BecomeVendorPage.topExample" /></h3>
                                    <div className={css.sectionPromotedListings}>
                                        {topPromotedListings.map(pl => (
                                            <PromotedListing key={pl.id.uuid} pl={pl} intl={intl} reviews={listingAllReviews ? listingAllReviews[pl.id.uuid] : []} />
                                        ))}
                                    </div>
                                </div>
                            ) : null}

                        <div className={css.getQuoteSection}>
                            <div className={css.getQuoteSectionWrapper}>
                                <div className={css.getQuoteTextContainer}>
                                    <h3 className={css.getQuoteHeading}><FormattedMessage id={'TopBlogsPage.GetQuote'} /></h3>
                                    <span className={css.getQuoteSubHeading}><FormattedMessage id={'TopBlogsPage.takeAdvantageOurFlexiblePricing'} /></span>
                                    <span className={css.getQuoteTextInfo}><FormattedMessage id={'TopBlogsPage.weAlignSuccessOurCustomers'} /></span>

                                    <div className={css.personDetailContainer}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
                                                <circle cx="34" cy="34" r="34" fill="white" />
                                            </svg>
                                        </div>
                                        <div className={css.personTextSection}>
                                            <span className={css.personName}><FormattedMessage id={'TopBlogsPage.conorOLoughlin'} /></span>
                                            <span className={css.personPost}><FormattedMessage id={'TopBlogsPage.CEO'} /></span>
                                        </div>
                                    </div>

                                    <div className={css.growthContainer}>
                                        <div className={css.growthWrapper}>
                                            <span className={css.growthNumber}>4k</span>
                                            <span className={css.growthType}><FormattedMessage id={'TopBlogsPage.businessesGrowing'} /></span>
                                        </div>
                                        <div className={css.growthWrapper}>
                                            <span className={css.growthNumber}>60+</span>
                                            <span className={css.growthType}><FormattedMessage id={'TopBlogsPage.countriesGrowing'} /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className={css.getQuoteFormContainer}>
                                    <GetQuoteForm onSubmit={() => { }} />
                                </div>
                            </div>
                        </div>

                    </LayoutWrapperMain>

                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page >
        )
    };
}

const mapStateToProps = state => {
    const {
        promotedListingRefs,
        listingAllReviews,
    } = state.LandingPage;
    const promotedListings = getMarketplaceEntities(state, promotedListingRefs);
    return {
        promotedListings,
        promotedListingRefs,
        listingAllReviews,
    };
};

const ArticlePage = compose(
    connect(mapStateToProps),
    injectIntl
)(ArticlePageComponent)

ArticlePage.loadData = loadData;

export default ArticlePage;

import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import config from '../../config';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  GenericMessage,
} from '../../components';
import {
  IconRoundCheckGreen,
  IconRoundCheckWhite,
} from '../../components/IconBecomeVendor/IconBecomeVendor';
import classNames from 'classnames';

import css from './SubscriptionPage.css';

const SubscriptionPageComponent = props => {
  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {
      if (typeof window !== 'undefined') {
        window.Chargebee.init({
          site: config.cbSite,
        });
        window.Chargebee.registerAgain();
      }
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);
  }, []);

  const { canCreateListing, createListingNotAllowedMessage } = props;

  return (
    <StaticPage
      title="Subscription"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'SubscriptionPage',
        description: 'Lyfshort subscriptions',
        name: 'Subscription page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          {/* Subscription message */}
          {!canCreateListing && createListingNotAllowedMessage ? (
            <GenericMessage message={createListingNotAllowedMessage} show={true} />
          ) : null}
          <section className={css.planGreyWrap}>
            <div className={css.selectPricesection}>
              <div className={css.pricingHeading}>
                <h2>Simple pricing for Every Team</h2>
                <span>
                  From Freelancers to fitness studio’s. We help you Generate more bookings , Manage
                  your client payments, Packages and ultimately increase your Revenue.
                </span>
              </div>
              <div className={css.planContainer}>
                <div className={css.planSection}>
                  <div className={css.planHeading}>
                    <div>
                      <h3>Pro Plan</h3>
                      <span>freelancer</span>
                    </div>
                    <div className={css.planPriceWrapper}>
                      <h4>$19.99</h4>
                      <span>/Month</span>
                      <FormattedMessage id="BecomeVendorPage.1MonthFreeTrial" />
                    </div>
                  </div>
                  <div className={css.planLists}>
                    <h3>Features</h3>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> One host page listing
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> Multiple Class listings
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> New lead matchmaking
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> direct User messaging & Booking Request inbox
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> Booking Management Tools
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> Freelancer profile setup assistance
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> Social media reach
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> Instagram feed embedded
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckGreen /> 0% commission
                    </span>
                  </div>
                  <div className={css.action}>
                    <div className={css.actionBtn}>
                      {/* eslint-disable-next-line */}
                      <a
                        href={void 0}
                        data-cb-type="checkout"
                        data-cb-item-0="Basic-Plan-Monthly-USD-Monthly"
                        data-cb-item-0-quantity="1"
                        data-cb-customer-cf-user-id="123"
                      >
                        Get Pro Plan
                      </a>
                    </div>
                    <span className={css.billedMonthly}>Billed monthly</span>
                  </div>
                </div>
                <div className={css.planSection2}>
                  <div className={classNames(css.planHeading, css.planHeadingWhite)}>
                    <div>
                      <h3>Team Plan</h3>
                      <span>Company Team</span>
                    </div>
                    <div className={css.planPriceWrapper}>
                      <h4>$29.99</h4>
                      <span>/Month</span>
                      <FormattedMessage id="BecomeVendorPage.1MonthFreeTrial" />
                    </div>
                  </div>
                  <div className={classNames(css.planLists, css.planListsWhite)}>
                    <h3>Features</h3>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> One Company page listing
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> Up to 10 host Listings
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> Multiple Class listings
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> direct User messaging & Booking Request inbox
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> Booking Management Tools
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> New Lead Matching
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> Social media reach
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> Instagram feed embedded
                    </span>
                    <span>
                      {' '}
                      <IconRoundCheckWhite /> 0% commission
                    </span>
                  </div>
                  <div className={css.action}>
                    <div className={css.primaryActionBtn}>
                      {/* eslint-disable-next-line */}
                      <a
                        href={void 0}
                        data-cb-type="checkout"
                        data-cb-item-0="Premium-Plan-Monthly-USD-Monthly"
                        data-cb-item-0-quantity="1"
                      >
                        Get Team Plan
                      </a>
                    </div>
                    <span className={classNames(css.billedMonthly, css.whiteText)}>
                      Billed monthly
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <h1 className={css.pageTitle}>Listing options that are right for you</h1>
          <p className={css.description}>
            From Freelancers to Fitness studio's, we help you generate more bookings,
            manage your client payments, packages and ultimately increase your revenue.</p>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <div className={css.plans}>
                <div className={css.plan}>
                  <div className={css.planName}>Basic Plan / Freelancer</div>
                  <div className={css.planPrice}>
                    <span className={css.price}>$9.99</span>
                    <span className={css.billing}> / month</span>
                  </div>
                  <div className={css.planFeatures}>
                    <ul>
                      <li><IconCheckmark size="small" />One Vendor Page Listing</li>
                      <li><IconCheckmark size="small" />Multiple Class Listings</li>
                      <li><IconCheckmark size="small" />New Lead, Matchmaking</li>
                      <li><IconCheckmark size="small" />Direct User Messaging & Booking Request Inbox</li>
                      <li><IconCheckmark size="small" />Booking Management Tools</li>
                      <li><IconCheckmark size="small" />Marketing Reach</li>
                      <li><IconCheckmark size="small" />Social Media Reach</li>
                      <li><IconCheckmark size="small" />Dedicated Service & Suport</li>
                      <li><IconCheckmark size="small" />Instagram Feed Embeded</li>
                      <li><IconCheckmark size="small" />0% Commission</li>
                    </ul>
                  </div>
                  <div className={css.action}>
                    <div className={css.actionBtn}>
                      <a href={void (0)}
                        data-cb-type="checkout"
                        data-cb-item-0="basic_plan-USD-Monthly"
                        data-cb-item-0-quantity="1"
                        data-cb-customer-cf-user-id="123"
                      >GET BASIC</a>
                    </div>
                    <div className={css.billingDetails}>Billed monthly</div>
                  </div>
                </div>

                <div className={css.plan}>
                  <div className={`${css.planName} ${css.highlightPlanName}`}>Premium Plan / Company</div>
                  <div className={css.planPrice}>
                    <span className={css.price}>$19.99</span>
                    <span className={css.billing}> / month</span>
                  </div>
                  <div className={css.planFeatures}>
                    <ul>
                      <li><IconCheckmark size="small" />One Company Page Listing</li>
                      <li><IconCheckmark size="small" />Upto 10 Vendor Listings</li>
                      <li><IconCheckmark size="small" />Multiple Class Listings</li>
                      <li><IconCheckmark size="small" />New Lead, Matchmaking</li>
                      <li><IconCheckmark size="small" />Direct User Messaging & Booking Request Inbox</li>
                      <li><IconCheckmark size="small" />Booking Management Tools</li>
                      <li><IconCheckmark size="small" />Marketing Reach</li>
                      <li><IconCheckmark size="small" />Social Media Reach</li>
                      <li><IconCheckmark size="small" />Dedicated Service & Suport</li>
                      <li><IconCheckmark size="small" />Instagram Feed Embeded</li>
                      <li><IconCheckmark size="small" />0% Commission</li>
                    </ul>
                  </div>
                  <div className={css.action}>
                    <div className={css.primaryActionBtn}>
                      <a href={void (0)} data-cb-type="checkout" data-cb-item-0="Premium-Plan-USD-Monthly" data-cb-item-0-quantity="1" >GET PREMIUM</a>
                    </div>
                    <div className={css.billingDetails}>Billed monthly</div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { canCreateListing, createListingNotAllowedMessage } = state.EditListingPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    canCreateListing,
    createListingNotAllowedMessage,
  };
};

const SubscriptionPage = compose(
  withRouter,
  connect(mapStateToProps)
)(injectIntl(SubscriptionPageComponent));

export default SubscriptionPage;

import React, { Component } from 'react';
import cns from 'classnames';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    PrimaryButton,
    NamedLink,
} from 'components';
import { TopbarContainer } from 'containers/index';
import { FormattedMessage } from '../../util/reactIntl';

import SectionCustomerStories from './SectionCustomerStories';
import SectionBlogs from './SectionBlogs';
import SectionPodcasts from './SectionPodcasts';
import SectionEBook from './SectionEBook';
import GetQuoteForm from '../../forms/GetQuoteForm/GetQuoteForm'
import css from './TopBlogsPage.css';

export class TopBlogsPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedstateBtn: "all",
        };
    }



    componentDidMount() {
    }


    renderMobileDropDown = (stateBtn) => {
        return (
            <select className={css.stateDropDown} onChange={(e) => {
                this.setState({ selectedstateBtn: e.target.value })
            }} defaultValue={this.state.selectedstateBtn}>
                {stateBtn.map((btn) => (
                    <option key={btn.key} value={btn.key}>{btn.label}</option>
                ))}
            </select>
        )
    }

    render() {

        const STATE_BTN = [
            { key: 'all', label: "All" },
            { key: "blog", label: "Blog" },
            { key: "customer_stories", label: "Customer Stories" },
            { key: "podcasts", label: "Podcasts" },
            { key: "ebooks", label: "Ebooks" },
        ]

        return (
            <Page title={"Top 10 Blogs"}>
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar >
                        <TopbarContainer isDarkMode={true} />
                    </LayoutWrapperTopbar>

                    <LayoutWrapperMain className={css.topBlogPageWrapper}>
                        <div className={css.topContainer}>
                            <div className={css.topContainerWrapper}>
                                <h2><FormattedMessage id={'TopBlogsPage.topTitle'} /></h2>
                                <div className={css.stateButtonContainer}>
                                    <div className={css.stateButtonWrapper}>
                                        {STATE_BTN.map((btn, index) => (
                                            <div onClick={() => { this.setState({ selectedstateBtn: btn.key }) }} className={cns(css.stateButton, { [css.selectedStateButtonContainer]: btn.key === this.state.selectedstateBtn })} key={btn.key}>{btn.label}</div>
                                        ))}
                                    </div>
                                    <div>
                                        {this.renderMobileDropDown(STATE_BTN)}
                                    </div>
                                    <div className={css.searchSVGWrapper}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
                                            <path d="M16.8196 28.5914C23.3215 28.5914 28.5923 23.1324 28.5923 16.3983C28.5923 9.66415 23.3215 4.20508 16.8196 4.20508C10.3177 4.20508 5.04688 9.66415 5.04688 16.3983C5.04688 23.1324 10.3177 28.5914 16.8196 28.5914Z" stroke="#0095B3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M32 32L25.2266 25.2266" stroke="#0095B3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className={css.articalContainer}>
                                <NamedLink name="ArticlePage" params={{id:'101'}} className={css.articalWrapper}>
                                    <div className={css.articleImageContainer}>
                                        <img src={'/static/images/topBlogsPage/fitnessFreelancer.png'} alt="Fitness Freelancer" />
                                    </div>
                                    <span className={css.articalHeading}><FormattedMessage id={'TopBlogsPage.article'} /></span>
                                    <p className={css.articalInfo} ><FormattedMessage id={'TopBlogsPage.howMuchEarnFitnessFreelancer'} /></p>
                                    <span className={css.articalTime}><FormattedMessage id={'TopBlogsPage.articleTime'} /></span>
                                </NamedLink>
                                <NamedLink name="ArticlePage" params={{id:'102'}} className={css.articalWrapper}>
                                    <div className={css.articleImageContainer}>
                                        <img src={'/static/images/topBlogsPage/coachingClassesCost.png'} alt="Coaching classes cost" />
                                    </div>
                                    <span className={css.articalHeading}><FormattedMessage id={'TopBlogsPage.article'} /></span>
                                    <p className={css.articalInfo} ><FormattedMessage id={'TopBlogsPage.howMuchFitnessCoachingClassesCost'} /></p>
                                    <span className={css.articalTime}><FormattedMessage id={'TopBlogsPage.articleTime'} /></span>
                                </NamedLink>
                            </div>
                        </div>

                        <SectionCustomerStories />

                        <div className={css.recommendedEbookSection}>
                            <div className={css.recommendedEbookDetailContainer}>
                                <div className={css.recommendedEbookImageWrapper}></div>
                                <div className={css.recommendedEbookTextWrapper}>
                                    <span className={css.recommendedEbookText}><FormattedMessage id={'TopBlogsPage.recommendedEbook'} /></span>
                                    <span className={css.recommendedEbookInfo}><FormattedMessage id={'TopBlogsPage.recommendedEbookInfo'} /></span>
                                </div>
                            </div>
                            <PrimaryButton className={css.GetEBookButton}>
                                <FormattedMessage id={'TopBlogsPage.getEBook'} />
                            </PrimaryButton>
                        </div>

                        <SectionBlogs />

                        <SectionPodcasts />

                        <SectionEBook />

                        <div className={css.getQuoteSection}>
                            <div className={css.getQuoteSectionWrapper}>
                                <div className={css.getQuoteTextContainer}>
                                    <h3 className={css.getQuoteHeading}><FormattedMessage id={'TopBlogsPage.GetQuote'} /></h3>
                                    <span className={css.getQuoteSubHeading}><FormattedMessage id={'TopBlogsPage.takeAdvantageOurFlexiblePricing'} /></span>
                                    <span className={css.getQuoteTextInfo}><FormattedMessage id={'TopBlogsPage.weAlignSuccessOurCustomers'} /></span>

                                    <div className={css.personDetailContainer}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
                                                <circle cx="34" cy="34" r="34" fill="white" />
                                            </svg>
                                        </div>
                                        <div className={css.personTextSection}>
                                            <span className={css.personName}><FormattedMessage id={'TopBlogsPage.conorOLoughlin'} /></span>
                                            <span className={css.personPost}><FormattedMessage id={'TopBlogsPage.CEO'} /></span>
                                        </div>
                                    </div>

                                    <div className={css.growthContainer}>
                                        <div className={css.growthWrapper}>
                                            <span className={css.growthNumber}>4k</span>
                                            <span className={css.growthType}><FormattedMessage id={'TopBlogsPage.businessesGrowing'} /></span>
                                        </div>
                                        <div className={css.growthWrapper}>
                                            <span className={css.growthNumber}>60+</span>
                                            <span className={css.growthType}><FormattedMessage id={'TopBlogsPage.countriesGrowing'} /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className={css.getQuoteFormContainer}>
                                    <GetQuoteForm onSubmit={() => { }} />
                                </div>
                            </div>
                        </div>

                    </LayoutWrapperMain>

                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page >
        )
    };
}


export default TopBlogsPageComponent;

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import moment from 'moment';
import { object, string, bool, number, func, shape } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import config from '../../config';
import { parse, stringify } from 'util/urlHelpers';
import {
  BookingDateRangeLengthFilter,
  SelectSingleFilter,
  PriceFilter,
  SortBy,
  ListingTypeFilter,
  UpcomingCalendar,
  Button,
  IconSearch,
} from '../../components';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './SearchFilters.css';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { LISTING_TYPES } from 'util/constants';
import NamedLink from 'components/NamedLink/NamedLink';

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = 4;
const RADIX = 10;

// resolve initial value for a single value filter
const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

// resolve initial values for a multi value filter
// const initialValues = (queryParams, paramName) => {
//   return !!queryParams[paramName] ? queryParams[paramName].split(',') : [];
// };

const initialPriceRangeValue = (queryParams, paramName) => {
  const price = queryParams[paramName];
  const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

  return !!price && valuesFromParams.length === 2
    ? {
      minPrice: valuesFromParams[0],
      maxPrice: valuesFromParams[1],
    }
    : null;
};

const capitalize = (input) => {
  var words = input.split(' ');
  var CapitalizedWords = [];
  words.forEach(element => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
  });
  return CapitalizedWords.join(' ');
}

const getSelectedDate = searchParams => {
  const dates = searchParams.dates.split(',');
  return dates[0] ? moment(dates[0]) : moment();
};

const initialDateRangeValue = (queryParams, paramName) => {
  const dates = queryParams[paramName];
  const rawValuesFromParams = !!dates ? dates.split(',') : [];
  const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
  const initialValues =
    !!dates && valuesFromParams.length === 2
      ? {
        dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
      }
      : { dates: null };

  return initialValues;
};

const RESULT_MESSAGE = {
  [LISTING_TYPES.LISTING]: 'SearchFilters.foundResultsPros',
  [LISTING_TYPES.CLASS]: 'SearchFilters.foundResultsClass',
  [LISTING_TYPES.ENQUIRY]: 'SearchFilters.foundResultsEnquiry',
  [LISTING_TYPES.COMPANY]: 'SearchFilters.foundResultsBusiness',
  [LISTING_TYPES.FACILITY]: 'SearchFilters.foundResultsFacility',
};

const NO_RESULT_MESSAGE = {
  [LISTING_TYPES.LISTING]: 'SearchFilters.noResults',
  [LISTING_TYPES.CLASS]: 'SearchFilters.noResultsClass',
  [LISTING_TYPES.ENQUIRY]: 'SearchFilters.noResultsEnquiry',
  [LISTING_TYPES.COMPANY]: 'SearchFilters.noResultsBusiness',
  [LISTING_TYPES.FACILITY]: 'SearchFilters.noResultsFacilities',
};

const SearchFiltersComponent = props => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    sort,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    typeFilter,
    categoryFilter,
    subcategoryFilter,
    languageFilter,
    levelFilter,
    priceFilter,
    dateRangeLengthFilter,
    keywordFilter,
    isSearchFiltersPanelOpen,
    toggleSearchFiltersPanel,
    searchFiltersPanelSelectedCount,
    history,
    isPromopage,
    isNeedAdditional,
    params,
    intl,
    isSearchPage,
  } = props;

  const urlParams = parse(history.location.search);
  const searchListingType = urlParams.pub_listing_type;
  const [selectedStartDate] = urlParams.dates ? urlParams.dates.split(',') : [];
  const [keyWordValue, setKeyWordValue] = useState(initialValue(urlQueryParams, keywordFilter.paramName) || '')
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    if (!searchListingType) {
      handleSelectOption(typeFilter.paramName, LISTING_TYPES.LISTING);
    }
     // eslint-disable-next-line
  },[]);

  useEffect(() => {
    // setSubCategories([])
    const categoryData = filterCategoriesByListing();
    setCategories(categoryData)
    /* eslint-disable-next-line */
  }, [history.location.search]);


  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);

  const categoryLabel = intl.formatMessage({
    id: 'SearchFilters.categoryLabel',
  });

  const subcategoryLabel = intl.formatMessage({
    id: 'SearchFilters.subCategoryLabel',
  });

  const languageLabel = intl.formatMessage({
    id: 'SearchFilters.languageLabel',
  });

  const levelLabel = intl.formatMessage({
    id: 'SearchFilters.levelLabel',
  });

  const initialtype = typeFilter ? initialValue(urlQueryParams, typeFilter.paramName) : null;

  const initialcategory = categoryFilter
    ? initialValue(urlQueryParams, categoryFilter.paramName) ? initialValue(urlQueryParams, categoryFilter.paramName)
      : null : null;



  const initialsubcategory = subcategoryFilter
    ? initialValue(urlQueryParams, subcategoryFilter.paramName)
    : null;

  const initialLanguage = languageFilter
    ? initialValue(urlQueryParams, languageFilter.paramName)
    : null;

  const initialLevel = levelFilter ? initialValue(urlQueryParams, levelFilter.paramName) : null;

  const initialPriceRange = priceFilter
    ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName)
    : null;

  const initialDates = dateRangeLengthFilter
    ? initialDateRangeValue(urlQueryParams, dateRangeLengthFilter.paramName)
    : null;

  const initialMinDuration = dateRangeLengthFilter
    ? initialValue(urlQueryParams, dateRangeLengthFilter.minDurationParamName)
    : null;

  const initialTimes = dateRangeLengthFilter
    ? initialValue(urlQueryParams, dateRangeLengthFilter.timesParamName)
    : null;

  const initialKeyword = keywordFilter
    ? initialValue(urlQueryParams, keywordFilter.paramName)
    : null;

  // const filterText = intl.formatMessage({ id: 'KeywordFilter.filterText' });

  const placeholder = intl.formatMessage({ id: 'SectionSearch.searchByKeyword' });

  // const applyButtonText = intl.formatMessage({ id: 'FilterForm.submit' })

  useEffect(() => {
    const subCategoryData = filterSubCategoriesByCategory();
    setSubCategories(subCategoryData)
    /* eslint-disable-next-line */
  }, [initialcategory]);

  const addRemoveDatesForClassTypeSearch = (urlParam, option, queryParams) => {
    if (urlParam === 'pub_listing_type' && option === 'class') {
      const date = moment().format('YYYY-MM-DD');

      return {
        ...queryParams,
        dates: `${date},${date}`,
        times: '00:00,23:59',
      };
    } else if (urlParam === 'pub_listing_type' && option !== 'class') {
      return omit(queryParams, ['dates', 'times']);
    }

    return queryParams;
  };

  const handleSelectOption = (urlParam, option, isFilter) => {
    if (isFilter) {
      let queryParams = option
        ? { ...urlQueryParams, [urlParam]: option, pub_category: null, pub_sub_category: null }
        : omit(urlQueryParams, 'pub_category');

      if (isPromopage) {
        if (option === typeFilter.options[1].key) {
          queryParams = { ...urlQueryParams, [urlParam]: option, pub_category: "trainers" }
        }
        else if (option === typeFilter.options[3].key) {
          queryParams = { ...urlQueryParams, [urlParam]: option, pub_category: "health_care" }
        }
        else {
          queryParams = { ...urlQueryParams, [urlParam]: option, pub_category: null }
        }
      }
      else {
        if (option === typeFilter.options[1].key) {
          queryParams = { ...urlQueryParams, [urlParam]: option, pub_category: "trainers", pub_sub_category: null }
        }
        else if (option === typeFilter.options[0].key) {
          queryParams = { ...urlQueryParams, [urlParam]: option, pub_category: "group_activities" }
        }
        else if (option === typeFilter.options[2].key) {
          queryParams = { ...urlQueryParams, [urlParam]: option, pub_category: "health_care" }
        }
        else if (option === typeFilter.options[3].key) {
          queryParams = { ...urlQueryParams, [urlParam]: option, pub_category: "health_care", pub_sub_category: null }
        }
      }

      queryParams = addRemoveDatesForClassTypeSearch(urlParam, option, queryParams);

      if (isPromopage) {
        history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: option, subCategory: params.subCategory }, queryParams));
      }
      else {
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
      }

    } else {
      // query parameters after selecting the option
      // if no option is passed, clear the selection for the filter
      if (urlParam === 'pub_listing_type') {
        let queryParams = option
          ? { ...urlQueryParams, [urlParam]: option, pub_sub_category: null }
          : omit(urlQueryParams, 'pub_category');

        if (isPromopage) {
          queryParams = { ...urlQueryParams, [urlParam]: option }
        }

        queryParams = addRemoveDatesForClassTypeSearch(urlParam, option, queryParams);
        if (isPromopage) {
          history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: option, subCategory: params.subCategory }, queryParams));
        }
        else {
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
        }

      } else if (urlParam === 'pub_category') {
        // let queryParams = option
        // ? { ...urlQueryParams, [urlParam]: option, pub_sub_category: null }
        // : omit(urlQueryParams, 'pub_category');
        let queryParams = option
          ? { ...urlQueryParams, [urlParam]: option, pub_sub_category: null }
          : { ...omit(urlQueryParams, 'pub_category'), pub_sub_category: null };

        if (isPromopage) {
          queryParams = { ...urlQueryParams, [urlParam]: option }
        }
        queryParams = addRemoveDatesForClassTypeSearch(urlParam, option, queryParams);

        if (isPromopage) {
          history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: params.listingType, subCategory: params.subCategory }, queryParams));
        }
        else {
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
        }
        // history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));

      }
      else {
        let queryParams = option
          ? { ...urlQueryParams, [urlParam]: option }
          : omit(urlQueryParams, urlParam);

        queryParams = addRemoveDatesForClassTypeSearch(urlParam, option, queryParams);

        if (isPromopage) {
          if (option) {
            history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: queryParams.pub_listing_type, subCategory: capitalize(queryParams.pub_sub_category) }, queryParams));
          }
          else {
            history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: queryParams.pub_listing_type, subCategory: params.subCategory }, queryParams));
          }
        }
        else {
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
        }

      }
    }

  };

  const handlePrice = (urlParam, range) => {
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);

    if (isPromopage) {
      history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: params.listingType, subCategory: params.subCategory }, queryParams));
    }
    else {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
    }
    //history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handleKeyword = (urlParam, values) => {
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    if (isPromopage) {
      history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: params.listingType, subCategory: params.subCategory }, queryParams));
    }
    else {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
    }
    // history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const isKeywordFilterActive = !!initialKeyword;

  const listingTypeFilterElement = typeFilter ? (
    !isSearchPage ? (<ListingTypeFilter
      options={typeFilter.options}
      urlParam={typeFilter.paramName}
      onSelect={handleSelectOption}
      isWithImage={isSearchPage}
      value={initialtype}
    />) : null
  ) : null;

  const filterCategoriesByListing = () => {
    // const { initialValue } = this.props;
    const searchListingType = urlParams.pub_listing_type;

    if (searchListingType === 'class') {
      const categories = categoryFilter.options.filter((category) => category.listing === 'Classes')
      return categories
    }
    if (searchListingType === 'listing') {
      const categories = categoryFilter.options.filter((category) => category.listing === 'Pros/Trainers')
      return categories
    }
    if (searchListingType === 'facility') {
      const categories = categoryFilter.options.filter((category) => category.listing === 'Facilities')
      return categories
    }
    if (searchListingType === 'enquiry') {
      const categories = categoryFilter.options.filter((category) => category.listing === 'Enquiry')
      return categories
    }
    if (searchListingType === 'company') {
      const categories = categoryFilter.options.filter((category) => category.listing === 'Businesses')
      return categories
    }
  }

  const categoryFilterElement = categoryFilter ? (
    <SelectSingleFilter
      urlParam={categoryFilter.paramName}
      label={categoryLabel}
      onSelect={handleSelectOption}
      showAsPopup
      options={orderBy(categories, "label", "asc")}
      initialValue={initialcategory}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const filterSubCategoriesByCategory = () => {
    const searchListingType = urlParams.pub_listing_type;
    if (initialcategory) {
      let childSubCategories = []
      if (searchListingType === 'class') {
        const categories = categoryFilter.options.find((category) => category.listing === 'Classes' && category.key === initialcategory)
        categories && categories.subCategories.length && categories.subCategories.map((sb) => {
          sb.options.map((childCategory) => {
            childSubCategories.push(childCategory);
          })
        })
        return childSubCategories
      }
      if (searchListingType === 'listing') {
        const categories = categoryFilter.options.find((category) => category.listing === 'Pros/Trainers' && category.key === initialcategory)
        categories && categories.subCategories.length && categories.subCategories.map((sb) => {
          sb.options.map((childCategory) => {
            childSubCategories.push(childCategory);
          })
        })
        return childSubCategories
      }
      if (searchListingType === 'enquiry') {
        const categories = categoryFilter.options.find((category) => category.listing === 'Enquiry' && category.key === initialcategory)
        categories && categories.subCategories.length && categories.subCategories.map((sb) => {
          sb.options.map((childCategory) => {
            childSubCategories.push(childCategory);
          })
        })
        return childSubCategories
      }
      if (searchListingType === 'facility') {
        const categories = categoryFilter.options.find((category) => category.listing === 'Facilities' && category.key === initialcategory)
        categories && categories.subCategories.length && categories.subCategories.map((sb) => {
          sb.options.map((childCategory) => {
            childSubCategories.push(childCategory);
          })
        })
        return childSubCategories
      }
      if (searchListingType === 'company') {
        const categories = categoryFilter.options.find((category) => category.listing === 'Businesses' && category.key === initialcategory)
        categories && categories.subCategories.length && categories.subCategories.map((sb) => {
          sb.options.map((childCategory) => {
            childSubCategories.push(childCategory);
          })
        })
        return childSubCategories
      }
    } else {
      return []
    }

  }
  const subcategoryFilterElement = subcategoryFilter ? (
    <SelectSingleFilter
      urlParam={subcategoryFilter.paramName}
      label={subcategoryLabel}
      onSelect={handleSelectOption}
      showAsPopup
      // options={subcategoryFilter.options}
      options={orderBy(subCategories, "label", "asc")}
      initialValue={initialsubcategory}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const popularSubCategoriesKey = ['gym', 'tennis', 'yoga',
    'boxing', 'pilates',
    'rock climbing', 'crossfit',
    'cycling', 'golf',
    'surfing', 'martial_arts'
  ];
  const onClickSportsBtnHandler = (value) => {
    const { pathname, search, state } = history.location;
    const parseObject = { ...parse(search) };
    let searchString = `?${stringify({ ...parse(search), pub_sub_category: value })}`;
    if (parseObject.pub_sub_category === value) {
      searchString = `?${stringify(omit(parseObject, 'pub_sub_category'))}`;
    }
    history.push(`${pathname}${searchString}`, state);
  }

  const popularSubCategories = subCategories ? subCategories.filter(sub => popularSubCategoriesKey.includes(sub.key)) : [];
  const unpopularSubCategories = subCategories ? subCategories.filter(sub => !popularSubCategoriesKey.includes(sub.key)) : [];
  const prioritizedSubCategories = [...popularSubCategories, ...unpopularSubCategories]

  const selectedSport = (urlQueryParams && urlQueryParams.pub_sub_category) || "";
  const sportsBtn = prioritizedSubCategories && prioritizedSubCategories.map((sub) => {
    return (
      <div className={classNames(css.sportsBtn, { [css.selectedSportsBtn]: selectedSport === sub.key })} onClick={() => onClickSportsBtnHandler(sub.key)} key={sub.key}>
        <span>{sub.label}</span>
      </div>
    );
  })

  const languageFilterElement = languageFilter ? (
    <SelectSingleFilter
      urlParam={languageFilter.paramName}
      label={languageLabel}
      className={css.languageFilter}
      onSelect={handleSelectOption}
      showAsPopup
      options={orderBy(languageFilter.options, "label", "asc")}
      initialValue={initialLanguage}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const levelFilterElement = levelFilter ? (
    <SelectSingleFilter
      urlParam={levelFilter.paramName}
      label={levelLabel}
      onSelect={handleSelectOption}
      showAsPopup
      options={levelFilter.options}
      initialValue={initialLevel}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const priceFilterElement = priceFilter ? (
    <PriceFilter
      id="SearchFilters.priceFilter"
      urlParam={priceFilter.paramName}
      onSubmit={handlePrice}
      showAsPopup
      {...priceFilter.config}
      initialValues={initialPriceRange}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const handleDateRangeLength = values => {
    const hasDates = values && values[dateRangeLengthFilter.paramName];
    const { startDate, endDate } = hasDates ? values[dateRangeLengthFilter.paramName] : {};
    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;
    const minDuration =
      hasDates && values && values[dateRangeLengthFilter.minDurationParamName]
        ? values[dateRangeLengthFilter.minDurationParamName]
        : null;

    const startTime =
      hasDates && values && values['startTime'] ? values && values['startTime'] : null;
    const endTime = hasDates && values && values['endTime'] ? values['endTime'] : null;

    const restParams = omit(
      urlQueryParams,
      dateRangeLengthFilter.paramName,
      dateRangeLengthFilter.minDurationParamName,
      dateRangeLengthFilter.minDurationParamName,
      dateRangeLengthFilter.timesParamName
    );

    const datesMaybe =
      start != null && end != null ? { [dateRangeLengthFilter.paramName]: `${start},${end}` } : {};
    const minDurationMaybe = minDuration
      ? { [dateRangeLengthFilter.minDurationParamName]: minDuration }
      : {};

    const timesMaybe =
      startTime && endTime
        ? { [dateRangeLengthFilter.timesParamName]: `${startTime},${endTime}` }
        : {};

    const queryParams = {
      ...datesMaybe,
      ...minDurationMaybe,
      ...timesMaybe,
      ...restParams,
    };

    if (isPromopage) {
      history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: params.listingType, subCategory: params.subCategory }, queryParams));
    }
    else {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
    }
    //history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handleClassDateChange = selectedDate => {
    const date = moment(selectedDate).format('YYYY-MM-DD');

    const queryParams = {
      ...urlParams,
      dates: `${date},${date}`,
      times: '00:00,23:59',
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const isOutsideRange = day => {
    const endOfRange = config.dayCountAvailableForBooking - 1;

    if (urlQueryParams.pub_listing_type === 'class') {
      return (
        !isInclusivelyAfterDay(day, moment()) ||
        !isInclusivelyBeforeDay(day, moment().add(6, 'days'))
      );
    }

    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  };

  const dateRangeLengthFilterElement =
    dateRangeLengthFilter && dateRangeLengthFilter.config.active ? (
      <BookingDateRangeLengthFilter
        id="SearchFilters.dateRangeLengthFilter"
        dateRangeLengthFilter={dateRangeLengthFilter}
        datesUrlParam={dateRangeLengthFilter.paramName}
        durationUrlParam={dateRangeLengthFilter.minDurationParamName}
        onSubmit={handleDateRangeLength}
        showAsPopup
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        initialDateValues={initialDates}
        initialDurationValue={initialMinDuration}
        initialTimeValues={initialTimes}
        isOutsideRange={isOutsideRange}
      />
    ) : null;


  const toggleSearchFiltersPanelButtonClasses =
    isSearchFiltersPanelOpen || searchFiltersPanelSelectedCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSearchFiltersPanelButton = toggleSearchFiltersPanel ? (
    <button
      className={toggleSearchFiltersPanelButtonClasses}
      onClick={() => {
        toggleSearchFiltersPanel(!isSearchFiltersPanelOpen);
      }}
    >
      <FormattedMessage
        id="SearchFilters.moreFiltersButton"
        values={{ count: searchFiltersPanelSelectedCount }}
      />
    </button>
  ) : null;

  const handleSortBy = (urlParam, values) => {
    if (isPromopage) {
      const queryParams = values
        ? { ...urlQueryParams, [urlParam]: values }
        : omit(urlQueryParams, urlParam);

      history.push(createResourceLocatorString('BookListingPromo', routeConfiguration(), { listingType: params.listingType, subCategory: params.subCategory }, queryParams));
    }
    else {
      const queryParams = values
        ? { ...urlQueryParams, [urlParam]: values }
        : omit(urlQueryParams, urlParam);

      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
    }
  };

  const sortBy = config.custom.sortConfig.active ? (
    <SortBy
      className={css.sortBy}
      sort={sort}
      showAsPopup
      isKeywordFilterActive={isKeywordFilterActive}
      onSelect={handleSortBy}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const filterKeywordContent = isSearchPage && (<div className={classNames(css.filterKeywordWrap,{[css.classLeftSpace]: searchListingType === LISTING_TYPES.CLASS})}>
    <div className={css.filterWrap}>
      <div className={css.buttonWrap}>
        <Button className={css.btnSearchIcon} onClick={() => handleKeyword(keywordFilter.paramName, keyWordValue)}><IconSearch /></Button>
      </div>
      <div className={css.inputWrap}>
        <input id={'SearchFilters.keywordFilter'} value={keyWordValue} placeholder={placeholder} onChange={(e) => setKeyWordValue(e.target.value)} />
      </div>
      {/* <Button className={css.btnapply} onClick={() => handleKeyword(keywordFilter.paramName, keyWordValue)}>{applyButtonText}</Button> */}
    </div>
  </div>);

  return (
    <div className={classes}>
      <div className={css.filters}>
        {listingTypeFilterElement}
        <div className={css.otherFiltersWrap}>
          <div className={css.otherFilters}>
            {categoryFilterElement}
            {subcategoryFilterElement}
            {languageFilterElement}
            {levelFilterElement}
            {priceFilterElement}
            {searchListingType !== LISTING_TYPES.CLASS ? dateRangeLengthFilterElement : null}
            {filterKeywordContent}
            {toggleSearchFiltersPanelButton}
          </div>
        </div>


        {/* {!isPromopage && <div className={css.filterKeywordWrap}>
          <label className={css.mobLabel}>{filterText}</label>
          <div className={css.filterWrap}>
            <div className={css.buttonWrap}>
              <label className={css.deviceLabel}>{filterText}</label>
              <input id={'SearchFilters.keywordFilter'} value={keyWordValue} placeholder={`${placeholder}...`} onChange={(e) => setKeyWordValue(e.target.value)} />
            </div>
            <div className={css.buttonWrap}>
              <label className={css.labelText} onClick={() => {
                if (keyWordValue.length > 0 && initialValue(urlQueryParams, keywordFilter.paramName)) {
                  handleKeyword(keywordFilter.paramName, '')
                }
                setKeyWordValue('')
              }}>Clear</label>
              <Button className={css.btnapply} onClick={() => handleKeyword(keywordFilter.paramName, keyWordValue)}>{applyButtonText}</Button>
            </div>
          </div>
        </div>} */}
      </div >


      {
        subCategories && isSearchPage && (<div className={css.sportsBtnWrap}>
          {sportsBtn}

        </div>)
      }

      {
        searchListingType === LISTING_TYPES.CLASS && !isSearchPage && (
          <div className={css.classCalendarContainer}>
            <UpcomingCalendar
              initialDate={getSelectedDate(urlParams)}
              onDateSelect={handleClassDateChange}
              numberOfDays={30}
            />
          </div>
        )
      }

      {
        (!isPromopage && isNeedAdditional) && <div className={css.additional}>
          {listingsAreLoaded && resultsCount > 0 ? (
            <div className={css.searchResultSummary}>
              <span className={css.resultsFound}>
                <FormattedMessage
                  id={RESULT_MESSAGE[searchListingType] || 'SearchFilters.foundResultsPros'}
                  values={{
                    count: resultsCount,
                    date: !!selectedStartDate
                      ? moment(selectedStartDate).format('dddd, MMMM DD')
                      : '',
                  }}
                />
              </span>

              {searchListingType === LISTING_TYPES.ENQUIRY && (
                <NamedLink name="NewInquirePage" className={css.addEnquiryBtn}>
                  Create an enquiry
                </NamedLink>
              )}

              {searchListingType !== LISTING_TYPES.ENQUIRY && (
                <NamedLink name="JobsBoardPage"
                  to={{
                    search: 'pub_listing_type=enquiry',
                  }}
                  className={css.addEnquiryBtn}>
                  Let us find your match
                </NamedLink>
              )}

            </div>
          ) : null}

          {hasNoResult ? (
            <div className={css.noSearchResults}>
              <FormattedMessage
                id={NO_RESULT_MESSAGE[searchListingType] || NO_RESULT_MESSAGE[LISTING_TYPES.LISTING]}
              />
            </div>
          ) : null}

          {searchInProgress ? (
            <div className={css.loadingResults}>
              <FormattedMessage id="SearchFilters.loadingResults" />
            </div>
          ) : null}

          {sortBy}
        </div>
      }
    </div >
  );
};

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  categoryFilter: null,
  subcategoryFilter: null,
  languageFilter: null,
  levelFilter: null,
  priceFilter: null,
  dateRangeLengthFilter: null,
  isSearchFiltersPanelOpen: false,
  isPromopage: false,
  isNeedAdditional: true,
  isSearchPage: false,
  params: {},
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
};

SearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  categoryFilter: propTypes.filterConfig,
  subcategoryFilter: propTypes.filterConfig,
  languageFilter: propTypes.filterConfig,
  levelFilter: propTypes.filterConfig,
  priceFilter: propTypes.filterConfig,
  dateRangeLengthFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  isPromopage: bool,
  isNeedAdditional: bool,
  isSearchPage: bool,
  params: object,
  toggleSearchFiltersPanel: func,
  searchFiltersPanelSelectedCount: number,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchFilters = compose(withRouter, injectIntl)(SearchFiltersComponent);

export default SearchFilters;

import { capitalize, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { TopbarSearchForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_TYPES } from '../../util/constants';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import NamedLink from '../NamedLink/NamedLink';
import DropdownButton from './DropdownButton';
import css from './JobsPageHeader.css';
const RADIX = 10;

export const ListingTypes = [
  {
    key: LISTING_TYPES.COMPANY,
    label: 'Company',
  },
  {
    key: LISTING_TYPES.CLASS,
    label: 'Classes',
  },
  {
    key: LISTING_TYPES.LISTING,
    label: 'Instructors',
  },
  {
    key: LISTING_TYPES.FACILITY,
    label: 'Rent Space',
  },
];

const Level_Of_Seller = [
  {
    key: 'descending',
    label: 'Descending',
    isSortable: false,
  },
  {
    key: 'ascending',
    label: 'Ascending',
    isSortable: true,
  },
];

const getInitialMainCategory = urlQuery => {
  const listing = urlQuery && urlQuery.pub_listing_type;
  const category = urlQuery && urlQuery.pub_category;
  const adventerCategories = [
    'cycling',
    'running',
    'biking',
    'rock climbing',
    'bootcamp',
    'obstacle sports',
    'surfing',
    'kite surfing',
    'skateboarding',
  ].join(',');
  const subCategory = urlQuery && urlQuery.pub_sub_category;

  if (category && category === 'wellness_and_beauty') {
    return 'wellness';
  } else if (!listing || subCategory === adventerCategories) {
    return 'adventure';
  } else {
    return listing;
  }
};

const JobsPageHeader = props => {
  const {
    urlQueryParams,
    isMobileLayout,
    categoryFilter,
    priceFilter,
    languageFilter,
    redirectPageName,
    history,
    listingModeElement,
    resultsCount,
  } = props;

  const [mainListingType, setMainListingType] = useState(LISTING_TYPES.COMPANY);
  const [selectedSellerLevels, setSelectedSellerLevels] = useState([]);
  const [isFilterPanelOpen, setFilterPanelOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('sports');
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [priceRange, setPriceRange] = useState(null);

  useEffect(() => {
    const categoryData = filterCategoriesByListing();
    setCategories(categoryData);
    /* eslint-disable-next-line */
  }, [mainListingType]);

  useEffect(() => {
    const subCategoryData = filterSubCategoriesByCategory();
    const uniqueSubCategory = uniqBy(subCategoryData, 'key');
    setSubCategories(uniqueSubCategory);
    /* eslint-disable-next-line */
  }, [selectedCategory, mainListingType]);

  const redirectToUrl = url => {
    if (isMobileLayout || redirectPageName === 'BookListingPromo') {
      return history.push(`${url}#mainpanel-results`);
    } else {
      return history.push(url);
    }
  };

  const initialStateData = () => {
    const urlParams = parse(history.location.search);
    const MainListingName = getInitialMainCategory(urlQueryParams);
    setMainListingType(MainListingName);
    if (urlParams && (urlParams.meta_level || urlParams.sort)) {
      const selectedLevels = urlParams.meta_level
        ? Array.isArray(urlParams.meta_level)
          ? urlParams.meta_level
          : urlParams.meta_level.split(',')
        : [];

      const selectedSorts = urlParams.sort
        ? Array.isArray(urlParams.sort)
          ? urlParams.sort
          : urlParams.sort.split(',')
        : [];

      setSelectedSellerLevels([...selectedLevels, ...selectedSorts]);
    }
    if (urlParams && urlParams.pub_category) {
      setSelectedCategory(urlParams && urlParams.pub_category);
    }
    if (urlParams && urlParams.pub_sub_category) {
      const subCategoriesData = Array.isArray(urlParams.pub_sub_category)
        ? urlParams.pub_sub_category
        : urlParams.pub_sub_category.split(',');
      setSelectedSubCategories(subCategoriesData);

      if (MainListingName === 'adventure' && subCategoriesData.length === 10) {
        setSelectedSubCategories([]);
      }
    }
    if (urlParams && urlParams.pub_hosted_in) {
      setSelectedLanguages(
        Array.isArray(urlParams.pub_hosted_in)
          ? urlParams.pub_hosted_in
          : urlParams.pub_hosted_in.split(',')
      );
    }
  };
  useEffect(() => {
    initialStateData();
    // eslint-disable-next-line
  }, []);

  const filterCategoriesByListing = () => {
    // const searchListingType = mainListingType;

    const categories = categoryFilter.options;
    return categories;
  };

  const getVideoUrl = value => {
    switch (value) {
      case 'adventure':
        return 'https://res.cloudinary.com/movementbuddy/video/upload/v1648126037/adventureVideo_ek8hj5.mp4';
      case 'wellness':
        return 'https://res.cloudinary.com/movementbuddy/video/upload/v1648125985/wellnessVideo_zwd011.mp4';
      default:
        return 'https://res.cloudinary.com/movementbuddy/video/upload/v1648126221/proSportsVideo_euehkb.mp4';
    }
  };
  const filterSubCategoriesByCategory = () => {
    const initialcategories = selectedCategory;
    if (initialcategories.length) {
      let childSubCategories = [];
      const categories = categoryFilter.options.filter(
        category => initialcategories === category.key
      );
      categories &&
        categories.forEach(category => {
          category &&
            category.subCategories.length &&
            category.subCategories.forEach(sb => {
              sb.options.forEach(childCategory => {
                childSubCategories.push(childCategory);
              });
            });
        });
      return childSubCategories;
    } else {
      return [];
    }
  };

  const onSelectSellerLevelHandler = value => {
    const updateValue = selectedSellerLevels;
    const urlParams = parse(history.location.search);
    const fetchLevel = Level_Of_Seller.find(level => level.key === value);

    let queryParams = { ...urlParams };
    if (fetchLevel.isSortable) {
      const sortData = urlParams.sort
        ? Array.isArray(urlParams.sort)
          ? urlParams.sort
          : urlParams.sort.split(',')
        : [];
      if (updateValue.includes(value)) {
        sortData.splice(sortData.indexOf(value), 1);
        queryParams = { ...urlParams, sort: sortData };
      } else {
        sortData.push(value);
        queryParams = { ...urlParams, sort: sortData };
      }
    } else {
      const metaData = urlParams.meta_level
        ? Array.isArray(urlParams.meta_level)
          ? urlParams.meta_level
          : urlParams.meta_level.split(',')
        : [];
      if (updateValue.includes(value)) {
        metaData.splice(metaData.indexOf(value), 1);
        queryParams = { ...urlParams, meta_level: metaData };
      } else {
        metaData.push(value);
        queryParams = { ...urlParams, meta_level: metaData };
      }
    }

    if (updateValue.includes(value)) {
      updateValue.splice(updateValue.indexOf(value), 1);
    } else {
      updateValue.push(value);
    }

    redirectToUrl(
      createResourceLocatorString(redirectPageName, routeConfiguration(), {}, queryParams)
    );
    setSelectedSellerLevels(updateValue);
  };

  const onChangeCategoryHandler = value => {
    const urlParams = parse(history.location.search);
    setSelectedCategory(value);
    let queryParams = { ...urlParams, pub_category: value };
    return redirectToUrl(
      createResourceLocatorString(redirectPageName, routeConfiguration(), {}, queryParams)
    );
  };

  const initialPriceRangeValue = (queryParams, paramName) => {
    const price = queryParams[paramName];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
      ? {
          minPrice: valuesFromParams[0],
          maxPrice: valuesFromParams[1],
        }
      : null;
  };

  const initialPriceRange = priceFilter
    ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName)
    : null;

  const handlePrice = (urlParam, range) => {
    const { minPrice, maxPrice } = range || {};

    const priceRangeValue = minPrice != null && maxPrice != null ? `${minPrice},${maxPrice}` : null;
    setPriceRange(priceRangeValue);
  };

  const handleLanguage = (urlParam, value) => {
    const updateValue = selectedLanguages;
    if (updateValue.includes(value)) {
      updateValue.splice(updateValue.indexOf(value), 1);
    } else {
      updateValue.push(value);
    }
    setSelectedLanguages(updateValue);
  };

  const videoUrl = getVideoUrl(selectedCategory);

  return (
    <>
      {redirectPageName === 'SearchPage' && (
        <section className={css.sectionHostedByPros}>
          <div className={css.hostedByProsWrap}>
            <div className={css.hostedProsHeadingWrap}>
              <h3 className={css.hostedByProsTitle}>
                {capitalize(selectedCategory)} Post Your Request And Let Our Pro’s Connect
                {/* <span className={css.blueText}>pros</span> */}
              </h3>
              <span className={css.hostedByProsSlogan}>
                Fasted way to find the right Services for you.
              </span>
            </div>

            <div className={css.videoWrapper}>
              <video src={videoUrl} loop autoPlay muted playsInline width="100%">
                <source src={videoUrl} type={`video/mp4`} />
              </video>
            </div>
          </div>
        </section>
      )}

      <div className={css.divider}></div>

      <section id="mainpanel-results">
        <div className={css.prosupportWrapper}>
          <div className={css.prosupportMain}>
            <div className={css.prosupportTitle}>
              pro<span>Sports</span>{' '}
            </div>
            {/* {categories &&
              categories.map(cat => {
                if (selectedCategory === cat.key) {
                  return (
                    <div className={css.prosupportTitle}>
                      pro<span>{cat.label}</span>{' '}
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => onChangeCategoryHandler(cat.key)}
                      className={css.prosupportHeading}
                    >
                      {cat.label}
                    </div>
                  );
                }
              })} */}

            {/* <div className={css.prosupportHeading}>Adventure</div>
                    <div className={css.prosupportHeading}>Wellness</div> */}
          </div>
        </div>

        <div className={css.advanceFilterContainer}>
          <div className={css.advanceFilterWrapper}>
            <NamedLink name="SubscriptionPage" className={css.subscriptionLink}>
              <FormattedMessage id="BecomeVendorPage.openAndPausedJobs" />
              <p className={css.buttonSubTitle} style={{ marginLeft: 5 }}>
                ({resultsCount})
              </p>
            </NamedLink>
            <NamedLink name="NewInquirePage" className={css.postNewJob}>
              <FormattedMessage id="BecomeVendorPage.postAJob" />
            </NamedLink>
          </div>
        </div>
        {/* <div className={css.sectionSearchContainer}>
          <TopbarSearchForm
            className={css.searchLink}
            desktopInputRoot={css.topbarSearchWithLeftPadding}
            onSubmit={() => {}}
            // initialValues={initialSearchFormValues}
          />
        </div> */}
      </section>
    </>
  );
};

export default JobsPageHeader;

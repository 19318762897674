import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import css from './AutocompleteSingleSelectFilter.module.css';
import { convertSelectQueryStringToValue } from '../../utils';
import { useTheme, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const AutocompleteSingleSelectFilter = props => {
  const {
    handleFiltersChange,
    currentQueryParams,
    id,
    searchKey,
    options,
    type,
    placeholder,
  } = props;

  const valueFromQueryString = currentQueryParams && convertSelectQueryStringToValue(currentQueryParams[searchKey]);

  const selectedValue = valueFromQueryString
    ? {
      key: valueFromQueryString,
      label: options.find(o => o.key === valueFromQueryString)?.label,
    }
    : null;

  const border = currentQueryParams[searchKey]
    ? '2px solid #00ab99'
    : '1px solid rgb(159, 159, 159, 0.5)';

  const StyledInput = styled(InputBase)(({ theme }) => ({
    width: '100%',

    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: border,
      fontSize: 14,
      borderRadius: '5px',

      padding: '12px 7px',
    },
  }));

  return (
    <div className={css.wrapper}>
      <Autocomplete
        sx={{
          display: 'inline-block',
          '& input': {
            width: 200,
            bgcolor: 'background.paper',
            color: theme => theme.palette.getContrastText(theme.palette.background.paper),
          },
        }}
        id={id}
        options={options.filter(o => selectedValue?.key != o.key)}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <StyledInput
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            {...params}
            placeholder={placeholder}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          const converted = newValue?.key ?? '';
          const searchValue = type + ':' + converted;
          const isSearchValueEmpty = searchValue === type + ':';
          handleFiltersChange({ [searchKey]: isSearchValueEmpty ? null : searchValue });
        }}
      />
    </div>
  );
};

export default AutocompleteSingleSelectFilter;

import React, { Component } from 'react';
import classNames from 'classnames';
import config from '../../config';
import { memoize } from 'lodash/function';
import { FormattedMessage } from 'util/reactIntl';
import { filterListingsByCategory } from 'util/data';
import { ListingsByCategory, Button, ListingTravelAgentService } from '../../components';
import css from './CompanyPage.css';
import SectionLatestReviews from 'components/SectionLatestReviews/SectionLatestReviews';
import Identity_Verified_Icon from '../../assets/Listing_Page/Identity_Verified_Icon.png';
import Triangle_Orange from '../../assets/Listing_Page/Triangle_Orange.png';
import SectionMapMaybe from './SectionMapMaybe';
import SectionHeadImagesSlider from 'components/SectionHeadImagesSlider/SectionHeadImagesSlider';
import ResponsiveImage from 'components/ResponsiveImage/ResponsiveImage';
import { isMobile } from 'util/userAgent';

const filterListingsByCategoryMemo = memoize(filterListingsByCategory);

const getRemovedWithOutContentReview = reviews => {
  return reviews.filter(review => review?.attributes.content !== config.dummyReviewContent);
};

class CompanyPageCommonSection extends Component {
  render() {
    const {
      currentListing,
      backgroundImage,
      profileImage,
      title,
      intl,
      listingVariant,
      reviews,
      authorsProductListings,
      otherAuthorsListings,
      allListingReviews,
    } = this.props;

    const { publicData, description, metadata } = currentListing.attributes;
    const { category, sub_category } = publicData;
    const totalRating = publicData.rating && !isNaN(publicData.rating) ? publicData.rating : 0;
    const totalListingHaveReviews = publicData.total_listing_has_reviews || 0;

    // const categoryData = config.custom.categories.find(cat => cat.key === category);
    // const listingsByCategory = filterListingsByCategoryMemo(otherAuthorsListings);
    const productByCategory = filterListingsByCategoryMemo(authorsProductListings);

    const profileList = [
      {
        id: 'travel_business',
        title: 'Travel Business',
        details: [currentListing?.attributes?.title],
      },
      {
        id: 'business_number',
        title: 'Business Number',
        details: [publicData?.Travel_business_busnumber],
      },
      {
        id: 'years_in_business',
        title: 'Years in Business',
        details: [publicData?.Travel_business_busyears],
      },
      {
        id: 'registered_address',
        title: 'Registered Address',
        details: [publicData?.Travel_business_address?.search],
      },
      {
        id: 'accreditation',
        title: 'Accreditations',
        details: publicData?.Travel_business_acreditation?.map(item => {
          const acronym = item.split('-')[0].slice(0, -1);
          return acronym;
        }),
      },
      {
        id: 'payments_accepted',
        title: 'Payments Accepted',
        details: publicData?.Travel_business_payment_method,
      },
    ];

    if (typeof window === 'undefined') {
      return null;
    }

    return (
      <>
        <div className={css.banner}>
          <ResponsiveImage
            image={backgroundImage}
            // variants={imgVariants}
            variants={[
              'scaled-medium',
              'scaled-large',
              'default',
              'landscape-background-big-crop',
              'landscape-background-crop',
            ]}
            alt={'title'}
          />
          <div className={css.titleContainer}>{title}</div>
          <div className={css.sliderContainer}>
            <SectionHeadImagesSlider rootClassName={css.imageSlider} images={[profileImage]}>
              {[profileImage] &&
                [profileImage].map(image => {
                  return (
                    <div className={css.singleImageContainer}>
                      <div style={{ border: '5px solid white', borderRadius: 15 }}>
                        <ResponsiveImage
                          className={css.image}
                          image={image}
                          variants={['scaled-medium', 'default']}
                          // variants={imgVariants}
                          alt={'title'}
                        />
                      </div>
                    </div>
                  );
                })}
              <div className={css.singleImageContainer}>
                <SectionMapMaybe
                  geolocation={publicData.geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                  className={css.mapImage}
                />
              </div>
            </SectionHeadImagesSlider>
          </div>
        </div>
        <div className={css.contentContainer}>
          <div className={css.mainContent}>
            <div className={css.specializationWrapper}>
              <h2>
                Travel BUSINESS{' '}
                <div className={css.profileIdentityWrapper}>
                  <img
                    src={Identity_Verified_Icon}
                    alt="Identity_Verified_Icon"
                    width="28px"
                    height="28px"
                  />
                  <span>IDENTITY VERIFIED</span>
                </div>
              </h2>
              {profileList &&
                profileList.map(list => {
                  let count = list.details.length;
                  return (
                    count > 0 && (
                      <li
                        className={
                          count > 1 || (list.id == 'registered_address' && isMobile())
                            ? css.columnDirection
                            : css.rowDirection
                        }
                        key={list.id}
                      >
                        <div className={css.verticalAlignCenter}>
                          <div className={css.verticalAlignCenter}>
                            <img src={Triangle_Orange} alt={list.id} />
                          </div>
                          <h5>{list.title} : &nbsp;</h5>
                        </div>
                        <div className={css.wrapper}>
                          {list.details.length > 1 ? (
                            list.details.map((c, index) => (
                              <div className={css.chip} key={index}>
                                {c}
                              </div>
                            ))
                          ) : (
                            <p>{list.details}</p>
                          )}
                        </div>
                      </li>
                    )
                  );
                })}
            </div>
            {authorsProductListings && !!authorsProductListings.length && (
              <div className={css.ourProSectionWrapper}>
                <div>
                  <h2 className={css.ourProsHeading}>
                    <span>
                      <FormattedMessage id="ListingPage.linkedTripExperts" />
                    </span>
                  </h2>
                </div>
                <ListingsByCategory
                  intl={intl}
                  listingsByCategory={productByCategory}
                  allListingReviews={allListingReviews}
                />
              </div>
            )}
          </div>
        </div>
        {!!reviews?.length && (
          <div className={classNames(css.sectionWrapper, css.greyReviewSection)}>
            <SectionLatestReviews
              data={getRemovedWithOutContentReview(reviews)}
              className={css.mainReviewContainer}
              title={`Reviews`}
              rating={metadata && metadata.rating ? metadata.rating : 0}
              totalRating={metadata && metadata.totalRating ? metadata.totalRating : 0}
            />
            <div className={css.btnWrap}>
              <Button className={css.seeMoreButton}>Show all {reviews.length} Reviews</Button>
            </div>
          </div>
        )}
        <ListingTravelAgentService
          rootClassName={css.container}
          currentListing={currentListing}
          intl={intl}
          listingVariant={listingVariant}
        />
      </>
    );
  }
}

export default CompanyPageCommonSection;

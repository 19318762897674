import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import css from './GetQuoteForm.css';

const TITLE_MAX_LENGTH = 60;

class GetQuoteFormComponent extends React.Component {
  // constructor(props) {
  //     super(props);
  // }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const { className, rootClassName, intl, values } = fieldRenderProps;

          const submitReady =
            values.email !== undefined &&
            values.name !== undefined &&
            values.phone_number !== undefined;
          const nameMessage = intl.formatMessage({
            id: 'GetQuoteForm.name',
          });
          const phoneMessage = intl.formatMessage({
            id: 'GetQuoteForm.phoneNumber',
          });
          const emailMessage = intl.formatMessage({
            id: 'GetQuoteForm.emailAddress',
          });
          const namePlaceholderMessage = intl.formatMessage({
            id: 'GetQuoteForm.namePlaceholder',
          });
          const phonePlaceholderMessage = intl.formatMessage({
            id: 'GetQuoteForm.phoneNumberPlaceholder',
          });
          const emailPlaceholderMessage = intl.formatMessage({
            id: 'GetQuoteForm.emailAddressPlaceholder',
          });

          const nameRequiredMessage = intl.formatMessage({
            id: 'GetQuoteForm.nameRequired',
          });

          const phoneRequiredMessage = intl.formatMessage({
            id: 'GetQuoteForm.phoneNumberRequired',
          });

          const emailRequiredMessage = intl.formatMessage({
            id: 'GetQuoteForm.emailAddressRequired',
          });
          const maxLengthMessage = intl.formatMessage(
            { id: 'GetQuoteForm.maxLength' },
            {
              maxLength: TITLE_MAX_LENGTH,
            }
          );

          const submitButtonText = intl.formatMessage({
            id: 'GetQuoteForm.getQuoteButton',
          });

          const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
          const classes = classNames(rootClassName || css.root, className);

          return (
            <div className={css.formContainer}>
              <Form
                className={classes}
                action="mailto:info@lyfshort.com?subject=Required a quote"
                encType="text/plain"
                method="post"
              >
                <h3 className={css.formHeading}>Fill the Details</h3>
                <FieldTextInput
                  id="name"
                  name="name"
                  className={css.title}
                  type="text"
                  label={nameMessage}
                  placeholder={namePlaceholderMessage}
                  maxLength={TITLE_MAX_LENGTH}
                  validate={composeValidators(required(nameRequiredMessage), maxLength60Message)}
                  autoFocus={false}
                />

                <FieldTextInput
                  id="phone_number"
                  name="phone_number"
                  className={css.title}
                  type="text"
                  label={phoneMessage}
                  placeholder={phonePlaceholderMessage}
                  maxLength={TITLE_MAX_LENGTH}
                  validate={composeValidators(required(phoneRequiredMessage), maxLength60Message)}
                  autoFocus={false}
                />

                <FieldTextInput
                  id="email"
                  name="email"
                  className={css.title}
                  type="text"
                  label={emailMessage}
                  placeholder={emailPlaceholderMessage}
                  maxLength={TITLE_MAX_LENGTH}
                  validate={composeValidators(required(emailRequiredMessage), maxLength60Message)}
                  autoFocus={false}
                />

                <div className={css.buttons}>
                  <PrimaryButton
                    className={css.getQuoteButton}
                    disabled={!submitReady}
                    type="submit"
                  >
                    {submitButtonText}
                  </PrimaryButton>
                </div>
              </Form>
            </div>
          );
        }}
      />
    );
  }
}

GetQuoteFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

GetQuoteFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

const GetQuoteForm = compose(injectIntl)(GetQuoteFormComponent);
GetQuoteForm.displayName = 'GetQuoteForm';

export default GetQuoteForm;

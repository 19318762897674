import React, { useState, useEffect } from 'react';
import css from './ListingPage.css';
import { ShareButton } from 'components';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const ShareBtnGroup = props => {
  const { title, shareUrl, viewport, currentListing, currentUser, className } = props;
  const listingId = currentListing.id.uuid;
  const [isFavourite, setIsFavourite] = useState(false);
  const [favListingsArray, setFavListingsArray] = useState([]);

  useEffect(() => {
    sdk.currentUser
      .show()
      .then(res => {
        if (
          currentUser &&
          currentUser?.attributes?.profile?.privateData &&
          currentUser?.attributes?.profile?.privateData?.favListingsArray
        ) {
          setFavListingsArray(currentUser.attributes.profile.privateData.favListingsArray);
          const listingsIdsArray = currentUser.attributes.profile.privateData.favListingsArray;
          const isFavourite = listingsIdsArray.find(id => {
            return id === listingId;
          });
          if (isFavourite) {
            setIsFavourite(true);
          }
        }
      })
      .catch(e => console.log(e));
  }, [currentUser]);

  const addToFavourites = async action => {
    if (className) {
      return null;
    }

    let updatedListingsArray = [...favListingsArray];

    if (action === 'add') {
      if (!updatedListingsArray.includes(listingId)) {
        updatedListingsArray.push(listingId);

        try {
          await sdk.currentUser.updateProfile({
            privateData: {
              favListingsArray: updatedListingsArray,
            },
          });

          setIsFavourite(true);
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      updatedListingsArray = updatedListingsArray.filter(id => id !== listingId);

      try {
        await sdk.currentUser.updateProfile({
          privateData: {
            favListingsArray: updatedListingsArray,
          },
        });

        setIsFavourite(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className={css.shareBtnWrapper}>
      <ShareButton
        shareData={{
          url: shareUrl,
          title: title,
        }}
        viewport={viewport}
        className={css.shareButton}
      />
      <div className={css.likeButton}>
        <div className={css.favButtonWrapper}>
          {!isFavourite ? (
            <FavoriteBorderIcon
              onClick={() => addToFavourites('add')}
              className={css.favButtonNotSelected}
            />
          ) : (
            <FavoriteIcon
              onClick={() => addToFavourites('remove')}
              className={css.favButtonSelected}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareBtnGroup;

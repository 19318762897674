import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  PromotedListing,
} from 'components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  IconText,
  IconCalendar,
  IconShop,
  IconSetting,
  IconSearch,
  IconPlus,
  IconRoundCheckGreen,
  IconRoundCheckWhite,
  IconInbox,
  IconMobileInbox,
} from '../../components/IconBecomeVendor/IconBecomeVendor';
import classNames from 'classnames';
import { TopbarContainer } from 'containers/index';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { loadData } from '../LandingPage/LandingPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import css from './BecomeVendorPage.css';

export class BecomeVendorPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReviewFetch: false,
    };
  }

  componentDidMount() {
    const el = document.createElement('script');
    el.onload = () => {
      if (typeof window !== 'undefined') {
        window.Chargebee.init({
          site: config.cbSite,
        });
        window.Chargebee.registerAgain();
      }
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);
  }

  // componentDidUpdate() {
  //     if (!this.state.isReviewFetch &&
  //         this.props.promotedListingRefs.length !== 0) {
  //         this.props.onQueryPromotedListingReviews([...this.props.promotedListingRefs])
  //         this.setState({ isReviewFetch: true })
  //     }
  // }

  render() {
    const { intl, promotedListings = [], listingAllReviews, scrollingDisabled } = this.props;

    const promotedTrainers = promotedListings.filter(
      pl => pl.attributes.publicData.category === 'trainers'
    );

    const topPromotedListings = promotedTrainers.splice(0, 5);

    return (
      <Page title={'Become vendor'} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <section className={css.topSection}>
              <div className={css.topHeadContainer}>
                <div className={css.topHeadSection}>
                  <span className={css.probuddyBookingTools}>
                    <FormattedMessage id="BecomeVendorPage.probuddyBookingTools" />
                  </span>
                  <h1>
                    <FormattedMessage id="BecomeVendorPage.madeForFreelancersAndStudios" />
                  </h1>
                  <p>
                    <FormattedMessage id="BecomeVendorPage.becomeVendorPageInfo" />
                  </p>

                  <NamedLink name="SubscriptionPage" className={css.subscriptionLink}>
                    <FormattedMessage id="BecomeVendorPage.subscibePerMonth" />
                  </NamedLink>
                </div>
              </div>
              <div className={css.imageContainer}>
                <IconInbox className={css.desktopSVG} />
                <IconMobileInbox className={css.mobileSVG} />
              </div>
            </section>

            <div className={css.sectionCommunity}>
              <h2>
                <FormattedMessage id="BecomeVendorPage.comunityInfo" />
              </h2>
              <img
                className={css.desktopImage}
                src={'/static/images/becomeVendorPage/Comunity.png'}
                alt="Comunity"
              />
              <img
                className={css.mobileImage}
                src={'/static/images/becomeVendorPage/ComunityMobile.png'}
                alt="ComunityMobile"
              />
            </div>
            <div className={css.uniqueSectionContainer}>
              <section className={css.reverseSection}>
                <div className={css.uniqueDesignSection}>
                  <div>
                    <h3>
                      <FormattedMessage id="BecomeVendorPage.simpleBookingsFlexiblePayments" />
                    </h3>
                    <span className={css.topSpan}>
                      <FormattedMessage id="BecomeVendorPage.ourOnlineBookingSystemEmpowers" />
                    </span>
                    <hr />
                    <span className={css.bottomSpan}>
                      <FormattedMessage id="BecomeVendorPage.payingByCreditCard" />
                    </span>
                  </div>
                </div>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={'/static/images/becomeVendorPage/sectionImage1.png'}
                    alt="sectionImage1"
                  />
                </div>
              </section>

              <section className={css.section}>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={'/static/images/becomeVendorPage/sectionImage2.png'}
                    alt="sectionImage2"
                  />
                </div>
                <div className={css.uniqueDesignSection}>
                  <h3>
                    <FormattedMessage id="BecomeVendorPage.CreateCompanysalesPages" />
                  </h3>
                  <span className={css.topSpan}>
                    <FormattedMessage id="BecomeVendorPage.attractClientsSocial" />
                  </span>
                  <hr />
                  <span className={css.bottomSpan}>
                    <FormattedMessage id="BecomeVendorPage.payingByCreditCard" />
                  </span>
                </div>
              </section>

              {topPromotedListings && topPromotedListings.length > 0 ? (
                <div className={css.ourTopExampleContainer}>
                  <h3 className={css.ourTopExampleTitle}>
                    <FormattedMessage id="BecomeVendorPage.topExample" />
                  </h3>
                  <div className={css.sectionPromotedListings}>
                    {topPromotedListings.map(pl => (
                      <PromotedListing
                        key={pl.id.uuid}
                        pl={pl}
                        intl={intl}
                        reviews={listingAllReviews ? listingAllReviews[pl.id.uuid] : []}
                      />
                    ))}
                  </div>
                </div>
              ) : null}

              <section className={css.reverseSection}>
                <div className={css.uniqueDesignSection}>
                  <h3>
                    <FormattedMessage id="BecomeVendorPage.getMoreReviewsBuildCredibility" />
                  </h3>
                  <span className={css.topSpan}>
                    <FormattedMessage id="BecomeVendorPage.sendAutomaticReviewRequestsAfterSessions" />
                  </span>
                  <hr />
                  <span className={css.bottomSpan}>
                    <FormattedMessage id="BecomeVendorPage.payingByCreditCard" />
                  </span>
                </div>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={'/static/images/becomeVendorPage/sectionImage3.png'}
                    alt="sectionImage3"
                  />
                </div>
              </section>
              <section className={css.section}>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={'/static/images/becomeVendorPage/sectionImage4.png'}
                    alt="sectionImage4"
                  />
                </div>
                <div className={css.uniqueDesignSection}>
                  <h3>
                    <FormattedMessage id="BecomeVendorPage.monitorProgresswithExpertInsights" />
                  </h3>
                  <span className={css.topSpan}>
                    <FormattedMessage id="BecomeVendorPage.trackMonitorPaymentsAndBookings" />
                  </span>
                  <hr />
                  <span className={css.bottomSpan}>
                    <FormattedMessage id="BecomeVendorPage.payingByCreditCard" />
                  </span>
                </div>
              </section>
            </div>

            <section className={css.planGreyWrap}>
              <div className={css.selectPricesection}>
                <div className={css.pricingHeading}>
                  <h2>
                    <FormattedMessage id="BecomeVendorPage.simplePricingForEveryTeam" />
                  </h2>
                  <span className={css.fromFreelancers}>
                    <FormattedMessage id="BecomeVendorPage.fromFreelancers" />
                  </span>
                </div>
                <div className={css.planContainer}>
                  <div className={css.planSection}>
                    <div className={css.planHeading}>
                      <div>
                        <h3>Pro Plan</h3>
                        <span>freelancer</span>
                      </div>
                      <div className={css.planPriceWrapper}>
                        <h4>$19.99</h4>
                        <span>/Month</span>
                        <FormattedMessage id="BecomeVendorPage.1MonthFreeTrial" />
                      </div>
                    </div>
                    <div className={css.planLists}>
                      <h3>Features</h3>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> One host page listing
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> Multiple Class listings
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> New lead matchmaking
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> direct User messaging & Booking Request inbox
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> Booking Management Tools
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> Freelancer profile setup assistance
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> Social media reach
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> Instagram feed embedded
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckGreen /> 0% commission
                      </span>
                    </div>
                    <div className={css.action}>
                      <div className={css.actionBtn}>
                        {/* eslint-disable-next-line */}
                        <a
                          href={void 0}
                          data-cb-type="checkout"
                          data-cb-item-0="Basic-Plan-Monthly-USD-Monthly"
                          data-cb-item-0-quantity="1"
                          data-cb-customer-cf-user-id="123"
                        >
                          Get Pro Plan
                        </a>
                      </div>
                      <span className={css.billedMonthly}>Billed monthly</span>
                    </div>
                  </div>
                  <div className={css.planSection2}>
                    <div className={classNames(css.planHeading, css.planHeadingWhite)}>
                      <div>
                        <h3>Team Plan</h3>
                        <span>Company Team</span>
                      </div>
                      <div className={css.planPriceWrapper}>
                        <h4>$29.99</h4>
                        <span>/Month</span>
                        <FormattedMessage id="BecomeVendorPage.1MonthFreeTrial" />
                      </div>
                    </div>
                    <div className={classNames(css.planLists, css.planListsWhite)}>
                      <h3>Features</h3>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> One Company page listing
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> Up to 10 host Listings
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> Multiple Class listings
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> direct User messaging & Booking Request inbox
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> Booking Management Tools
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> New Lead Matching
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> Social media reach
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> Instagram feed embedded
                      </span>
                      <span>
                        {' '}
                        <IconRoundCheckWhite /> 0% commission
                      </span>
                    </div>
                    <div className={css.action}>
                      <div className={css.primaryActionBtn}>
                        {/* eslint-disable-next-line */}
                        <a
                          href={void 0}
                          data-cb-type="checkout"
                          data-cb-item-0="Premium-Plan-USD-Monthly"
                          data-cb-item-0-quantity="1"
                        >
                          Get Team Plan
                        </a>
                      </div>
                      <span className={classNames(css.billedMonthly, css.whiteText)}>
                        Billed monthly
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className={css.bottomSection}>
              <div className={css.detailSeaction}>
                <h2>
                  {' '}
                  <FormattedMessage id="BecomeVendorPage.theFeaturesYouNeedToGrowYourBusiness" />
                </h2>
                <span className={css.listYourServices}>
                  <FormattedMessage id="BecomeVendorPage.listYourServicesOnProbuddyMarketplace" />
                </span>
              </div>
              <div>
                <div className={css.cardsContainer}>
                  <div className={css.cards}>
                    <IconText className={css.cardIcons} />

                    <div>
                      <FormattedMessage id="BecomeVendorPage.manageBookings" />{' '}
                      <span>
                        <IconPlus className={css.iconPlus} />
                      </span>
                    </div>
                  </div>
                  <div className={css.cards}>
                    <IconCalendar className={css.cardIcons} />

                    <div>
                      <FormattedMessage id="BecomeVendorPage.classAppointmentScheduling" />{' '}
                      <span>
                        <IconPlus className={css.iconPlus} />
                      </span>
                    </div>
                  </div>
                  <div className={css.cards}>
                    <IconShop className={css.cardIcons} />

                    <div>
                      <FormattedMessage id="BecomeVendorPage.createPromoteSalesPages" />{' '}
                      <span>
                        <IconPlus className={css.iconPlus} />
                      </span>
                    </div>
                  </div>
                  <div className={css.cards}>
                    <img
                      src={'/static/images/becomeVendorPage/IconTime.png'}
                      alt="IconTime"
                      height="44px"
                      width="44px"
                    />

                    <div>
                      <FormattedMessage id="BecomeVendorPage.flexiblePaymentOptions" />{' '}
                      <span>
                        <IconPlus className={css.iconPlus} />
                      </span>
                    </div>
                  </div>
                  <div className={css.cards}>
                    <IconSetting className={css.cardIcons} />

                    <div>
                      <FormattedMessage id="BecomeVendorPage.getMoreReviewsBuildCredibility" />{' '}
                      <span>
                        <IconPlus className={css.iconPlus} />
                      </span>
                    </div>
                  </div>
                  <div className={css.cards}>
                    <IconSearch className={css.cardIcons} />

                    <div>
                      <FormattedMessage id="BecomeVendorPage.increaseReachWithSocialMediaMarketing" />{' '}
                      <span>
                        <IconPlus className={css.iconPlus} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  const { promotedListingRefs, listingAllReviews } = state.LandingPage;
  const promotedListings = getMarketplaceEntities(state, promotedListingRefs);
  return {
    promotedListings,
    scrollingDisabled: isScrollingDisabled(state),
    promotedListingRefs,
    listingAllReviews,
  };
};

const mapDispatchToProps = dispatch => ({
  // onQueryPromotedListingReviews: listingRef => dispatch(queryPromotedListingReviews(listingRef))
});

const BecomeVendorPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BecomeVendorPageComponent);

BecomeVendorPage.loadData = loadData;

export default BecomeVendorPage;

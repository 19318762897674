import React, { Component } from 'react';
import { startCase, toLower } from 'lodash';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Draggable from 'react-draggable';
import { propTypes, REVIEW_TYPE_OF_PROVIDER } from 'util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { getRatingFromReviews } from 'containers/ListingPage/helper';
import css from './PromoPage.css';
import {
  Page,
  SearchMap,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  PrimaryButton,
  Footer,
  PromoPageFilters,
} from 'components';
import SectionLatestReviews from 'components/SectionLatestReviews/SectionLatestReviews';
import MainPanel from './MainPanel';
import { TopbarContainer } from 'containers/index';
import PromoPageForm from './PromoPageForm';
import { createResourceLocatorString, pathByRouteName } from 'util/routes';
import routeConfiguration from 'routeConfiguration';
import {
  searchListings,
  searchMapListings,
  setActiveListing,
  fetchAllListingReviews,
  queryUserReviews,
} from './PromoPage.duck';
import {
  validURLParamsForExtendedData,
  validFilterParams,
  pickSearchParamsOnly,
  createSearchResultSchema,
} from './PromoPage.helpers';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import debounce from 'lodash/debounce';
import { types as sdkTypes } from '../../util/sdkLoader';
import unionWith from 'lodash/unionWith';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import SectionHireBestCoach from './SectionHireBestCoach';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

const { UUID } = sdkTypes;

const RESULT_PAGE_SIZE = 24;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const DRAG_LAYOUT_BREAKPOINT = 1024;
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const LISTING_DRAG_MAP_SHOW_OFFSET = 550;
const LISTING_DRAG_LIMIT_OFFSET = 180;

class PromoImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt + 'sdfsd'} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(PromoImage);

export class PromoPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: false,
      isMobileModalOpen: false,
      listingDrawerPos: { x: 0, y: this.isMobileLayout() ? 250 : 0 },
      isMapIconVisible: false,
      isReviewLoaded: false,
      dataCurrentPageResultIds: [],
      keyWordValue: '',
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  scrollFunction = () => {
    if (typeof window !== 'undefined') {
      const { isMapIconVisible, listingDrawerPos } = this.state;
      const { y } = listingDrawerPos;
      const scrolled = window.scrollY;
      const totalHeight = window.innerHeight;
      const isScrolledEnough = scrolled > totalHeight;
      const dragLimit = totalHeight - LISTING_DRAG_MAP_SHOW_OFFSET;

      if (!isScrolledEnough && y > dragLimit && isMapIconVisible) {
        this.setState({ isMapIconVisible: false });
      } else if (isScrolledEnough && !isMapIconVisible) {
        this.setState({ isMapIconVisible: true });
      }
    }
  };

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('scroll', this.scrollFunction);
    const urlQueryParams = parse(this.props.location.search);
    if (urlQueryParams && urlQueryParams.keywords && this.state.keyWordValue === '') {
      this.setState({ keyWordValue: urlQueryParams.keywords });
    }

    // //Add Chatra
    // if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    //   (function(d, w, c) {
    //     w.ChatraID = '3jnywm2fKfLtHLfWp';
    //     var s = d.createElement('script');
    //     w[c] =
    //       w[c] ||
    //       function() {
    //         (w[c].q = w[c].q || []).push(arguments);
    //       };
    //     s.async = true;
    //     s.src = 'https://call.chatra.io/chatra.js';
    //     if (d.head) d.head.appendChild(s);
    //   })(document, window, 'Chatra');
    // }
  }

  componentDidUpdate() {
    if (!this.state.isReviewLoaded && this.props.currentUser) {
      const id = new UUID(this.props.currentUser && this.props.currentUser.id.uuid);
      this.props.onQueryUserReviews(id);
      this.setState({ isReviewLoaded: true });
    }

    if (
      JSON.stringify(this.state.dataCurrentPageResultIds) !==
        JSON.stringify(this.props.currentPageResultIds) &&
      this.props.currentPageResultIds.length !== 0
    ) {
      this.props.onFetchAllListingReviews(this.props.currentPageResultIds);
      this.setState({ dataCurrentPageResultIds: this.props.currentPageResultIds });
    }
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('scroll', this.scrollFunction);

    // if (typeof document !== 'undefined') {
    //   const chatra = document.getElementById('chatra');
    //   if (chatra !== null) document.body.removeChild(chatra);
    // }
  }

  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('BookListingPromo', routes);
    const currentPath = this.props.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...rest,
        ...validFilterParams(rest, this.filters()),
      };

      history.push(createResourceLocatorString('BookListingPromo', routes, {}, searchParams));
      var mapElement = document.getElementById('search-map');
      mapElement.scrollIntoView();
    }
  }

  filters() {
    const {
      categoryConfig,
      subcategoryConfig,
      languageConfig,
      levelConfig,
      priceFilterConfig,
      keywordFilterConfig,
      dateRangeLengthFilterConfig,
      listingTypeConfig,
    } = this.props;

    // Note: "category" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    return {
      typeFilter: {
        paramName: 'pub_listing_type',
        options: listingTypeConfig,
      },
      categoryFilter: {
        paramName: 'pub_category',
        options: categoryConfig.filter(c => !c.hideFromFilters),
      },
      subcategoryFilter: {
        paramName: 'pub_sub_category',
        options: subcategoryConfig.filter(c => !c.hideFromFilters),
      },
      languageFilter: {
        paramName: 'pub_hosted_in',
        options: languageConfig.map(i => ({ ...i, key: `has_any:${i.key}` })),
      },
      levelFilter: {
        paramName: 'meta_level',
        options: levelConfig,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      dateRangeLengthFilter: {
        paramName: 'dates',
        minDurationParamName: 'minDuration',
        timesParamName: 'times',
        config: dateRangeLengthFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
    };
  }

  isMobileLayout = () => {
    const isWindowDefined = typeof window !== 'undefined';
    return isWindowDefined && window.innerWidth < DRAG_LAYOUT_BREAKPOINT;
  };
  render() {
    const {
      intl,
      listings,
      location,
      history,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      reviews,
      listingAllReviews,
    } = this.props;

    const { listingDrawerPos } = this.state;

    const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const filters = this.filters();
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = this.isMobileLayout();
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);
    const subCategory = urlQueryParams.pub_sub_category || '';
    const subCategoryLabel = subCategory && subCategory.split(',')[0];
    const urlQueryString = stringify(urlQueryParams);
    const windowHeight = isWindowDefined && window.innerHeight;
    const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && true);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;
    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);
    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };
    const { address, bounds, origin } = searchInURL || {};
    const { title } = createSearchResultSchema(listings, address, intl);

    const submitHandler = values => {
      const type = location.search && parse(location.search).pub_category;
      if (
        !values.location &&
        JSON.parse(values.promoCategory).label === subCategoryLabel &&
        JSON.parse(values.promoCategory).type === type
      )
        return;
      let searchParams = {};
      if (values.location && values.location.search !== searchInURL.address) {
        const { search = '', selectedPlace } = values.location;
        const { origin, bounds } = selectedPlace;
        searchParams = {
          ...validQueryParams,
          address: search,
          origin,
          bounds,
          pub_sub_category: values.promoCategory
            ? JSON.parse(values.promoCategory).key
            : location.search && parse(location.search).pub_sub_category,
          pub_category: null,
        };
      } else {
        searchParams = {
          ...validQueryParams,
          pub_sub_category:
            JSON.parse(values.promoCategory).key ||
            (location.search && parse(location.search).pub_sub_category),
          pub_category: null,
        };
      }
      if (values.promoCategory && JSON.parse(values.promoCategory).type) {
        searchParams['pub_category'] = JSON.parse(values.promoCategory).type;
      }

      history.push(
        createResourceLocatorString('BookListingPromo', routeConfiguration(), {}, searchParams)
      );
    };

    // Set topbar class based on if a modal is open in
    // a child component

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    const reviewsOfProvider =
      reviews &&
      reviews.filter(
        r =>
          r.attributes.type === REVIEW_TYPE_OF_PROVIDER &&
          r.attributes.content !== config.dummyReviewContent
      );
    const totalRating = getRatingFromReviews(reviewsOfProvider);

    const subCategoryImageName = subCategoryLabel
      ? startCase(toLower(subCategoryLabel)).replace(' ', '_')
      : '';

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="BookListingPromo"
              currentSearchParams={urlQueryParams}
              keywordSearchTopbar={true}
            />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <section className={css.hero}>
              <div>
                <div className={css.heroContainer}>
                  <div className={css.formcontainer}>
                    {/* <h1 className={css.heroTitle}>{subCategoryLabel} Yoga Activities hosted by <span className={css.blueText}>pros</span></h1>
                    <p>Book and Explore all Yoga at your finger tips.</p> */}
                    <PromoPageForm
                      onSubmit={submitHandler}
                      location={location}
                      subCategoryLabel={subCategoryLabel}
                    />
                  </div>
                  <div className={css.imageContainer}>
                    <img
                      src={
                        validQueryParams.pub_category === 'kids'
                          ? `/static/promoPage/Kids/${subCategoryImageName}.jpg`
                          : `/static/promoPage/${subCategoryImageName}.jpg`
                      }
                      alt={'promoimage'}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = '/static/promoPage/Tennis.jpg';
                      }}
                      className={css.locationImage}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="636"
                      height="397"
                      viewBox="0 0 636 397"
                      fill="none"
                    >
                      <path
                        d="M300.947 44.3084C201.746 -54.2835 -73.8159 24.3142 18.9566 162.205C111.729 300.096 -116.068 400.757 186.13 396.619C488.328 392.482 653.665 331.122 634.376 232.529C615.086 133.937 400.149 142.9 300.947 44.3084Z"
                        fill="#46A3B6"
                        fill-opacity="0.14"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </section>

            {!isMobileLayout && (
              <section>
                <PromoPageFilters
                  urlQueryParams={urlQueryParams}
                  redirectPageName={'BookListingPromo'}
                  isMobileLayout={isMobileLayout}
                  categoryFilter={filters.categoryFilter}
                  subcategoryFilter={filters.subcategoryFilter}
                  priceFilter={filters.priceFilter}
                  languageFilter={filters.languageFilter}
                  history={history}
                />
              </section>
            )}

            <section className={css.sectionMapContainer}>
              <div className={css.subContainer}>
                <div className={css.draggableWrapper}>
                  <Draggable
                    axis="y"
                    handle={`.${css.draggableHandler}`}
                    bounds={{ top: 0, bottom: windowHeight - LISTING_DRAG_LIMIT_OFFSET }}
                    position={listingDrawerPos}
                    onDrag={this.onControlledDrag}
                    onStop={this.onControlledDragStop}
                    disabled={!isMobileLayout}
                  >
                    <MainPanel
                      urlQueryParams={validQueryParams}
                      sort={sort}
                      listings={listings}
                      searchInProgress={searchInProgress}
                      searchListingsError={searchListingsError}
                      searchParamsAreInSync={searchParamsAreInSync}
                      onActivateListing={onActivateListing}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onOpenModal={this.onOpenMobileModal}
                      onCloseModal={this.onCloseMobileModal}
                      onMapIconClick={onMapIconClick}
                      pagination={pagination}
                      searchParamsForPagination={parse(location.search)}
                      showAsModalMaxWidth={MODAL_BREAKPOINT}
                      primaryFilters={{
                        typeFilter: filters.typeFilter,
                        categoryFilter: filters.categoryFilter,
                        subcategoryFilter: filters.subcategoryFilter,
                        languageFilter: filters.languageFilter,
                        levelFilter: filters.levelFilter,
                        priceFilter: filters.priceFilter,
                        dateRangeLengthFilter: filters.dateRangeLengthFilter,
                        keywordFilter: filters.keywordFilter,
                      }}
                      allListingReview={listingAllReviews}
                      history={history}
                      isMobileLayout={isMobileLayout}
                    />
                  </Draggable>
                </div>
                <div className={css.mapPanel}>
                  {shouldShowSearchMap ? (
                    <SearchMap
                      reusableContainerClassName={css.map}
                      activeListingId={activeListingId}
                      bounds={bounds}
                      center={origin}
                      isSearchMapOpenOnMobile={true}
                      location={location}
                      listings={mapListings || []}
                      onMapMoveEnd={this.onMapMoveEnd}
                      onCloseAsModal={() => {
                        onManageDisableScrolling('SearchPage.map', false);
                      }}
                      messages={intl.messages}
                    />
                  ) : null}
                </div>
              </div>
            </section>

            <section>
              <div className={css.sectionCoaches}>
                <div className={css.sectionCoachescontainer}>
                  {validQueryParams && validQueryParams.address && (
                    <span className={css.headCategory}>
                      {validQueryParams.address} / <span>{subCategoryLabel}</span>
                    </span>
                  )}
                  <h1>{subCategoryLabel}</h1>
                  <PrimaryButton>42 Approve Listings</PrimaryButton>
                  <p>
                    Are you looking for the best {subCategoryLabel} lessons, {subCategoryLabel}{' '}
                    coaches and {subCategoryLabel} group classes? lyfshort can help you find book
                    and enjoy your first session in a few easy steps. Browse all our trainers or
                    simply submit your request to our jobs board. our pro’s pay to contact you with
                    a identity verified profile.
                  </p>
                </div>
              </div>
            </section>

            {!!reviewsOfProvider.length && (
              <section>
                <div className={css.sectionReviews}>
                  <div className={css.container}>
                    <div className={css.ReviewSection}>
                      <SectionLatestReviews
                        data={reviewsOfProvider}
                        className={css.mainReviewContainer}
                        title={`Reviews ${totalRating.toFixed(2)}`}
                        subCategoryLabel={subCategoryLabel}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section>
              <SectionHireBestCoach history={history} />
            </section>

            {subCategoryLabel === 'Tennis' && (
              <section>
                <div className={css.channelContainer}>
                  <h1>Learn on our Tennis Channel </h1>
                  <div className="elfsight-app-f179dbde-311e-4f1b-909c-1909ad741c95"></div>
                </div>
              </section>
            )}
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

PromoPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  categoryConfig: config.custom.categories,
  subcategoryConfig: config.custom.subCategoriesFilterOptions,
  languageConfig: config.custom.languageFilterConfig,
  levelConfig: config.custom.levelFilterConfig,
  priceFilterConfig: config.custom.priceFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  dateRangeLengthFilterConfig: config.custom.dateRangeLengthFilterConfig,
  listingTypeConfig: config.custom.listingTypeFilterConfig,
  activeListingId: null,
};

PromoPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  categoryConfig: array,
  subcategoryConfig: array,
  languageConfig: array,
  levelConfig: array,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeLengthFilterConfig: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    reviews,
    listingAllReviews,
  } = state.PromoPage;
  const { currentUser } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUser,
    reviews,
    currentPageResultIds,
    listingAllReviews,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onQueryUserReviews: userId => dispatch(queryUserReviews(userId)),
  onFetchAllListingReviews: listingRef => dispatch(fetchAllListingReviews(listingRef)),
});

const PromoPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PromoPageComponent);

PromoPage.loadData = (
  params,
  search = '?address=Singapore%20City%2C%20SG&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395&pub_listing_type=listing'
) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'description', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default PromoPage;

import React from 'react';
import css from './LandingPageHowItsWorksSection.css';
import { FormattedMessage } from '../../util/reactIntl';
import Browse_Image from '../../assets/Landing_Pages/HowItsWorks/Browse_Image.png';
import Hire_Pay_Image from '../../assets/Landing_Pages/HowItsWorks/Hire_Pay_Image.png';
import Recieve_Delivery_Image from '../../assets/Landing_Pages/HowItsWorks/Recieve_Delivery_Image.png';
import Optional_Image from '../../assets/Landing_Pages/HowItsWorks/Optional_Image.png';
import Book_Image from '../../assets/Landing_Pages/HowItsWorks/Book_Image.png';
import Pay_Image from '../../assets/Landing_Pages/HowItsWorks/Pay_Image.png';
import Support_Image from '../../assets/Landing_Pages/HowItsWorks/Support_Image.png';
import LeftTop_BottomRight_Arrow from '../../assets/Landing_Pages/HowItsWorks/LeftTop_BottomRight_Arrow.png';
import RightTop_BottomLeft_Arrow from '../../assets/Landing_Pages/HowItsWorks/RightTop_BottomLeft_Arrow.png';
import cns from 'classnames';

const LandingPageHowItsWorksSection = props => {
  const HowItsWorkList = [
    {
      id: 'Browse',
      src: Browse_Image,
      title: 'HowItsWorksSection.browseTitle',
      description: 'HowItsWorksSection.browseText',
      imgDirection: 'left',
    },
    {
      id: 'Hire Pay',
      src: Hire_Pay_Image,
      title: 'HowItsWorksSection.HirePayTitle',
      description: 'HowItsWorksSection.HirePayText',
      imgDirection: 'right',
      topImage: RightTop_BottomLeft_Arrow,
    },
    {
      id: 'Revive Delivery',
      src: Recieve_Delivery_Image,
      title: 'HowItsWorksSection.RecieveDeliveryTitle',
      description: 'HowItsWorksSection.RecieveDeliveryText',
      imgDirection: 'left',
      topImage: LeftTop_BottomRight_Arrow,
    },
    {
      id: 'Optional',
      src: Optional_Image,
      title: 'HowItsWorksSection.optionalTitle',
      description: 'HowItsWorksSection.optionalText',
      imgDirection: 'right',
      isOptional: true,
    },
    {
      id: 'Book',
      src: Book_Image,
      title: 'HowItsWorksSection.BookTitle',
      description: 'HowItsWorksSection.BookText',
      imgDirection: 'left',
    },
    {
      id: 'Pay',
      src: Pay_Image,
      title: 'HowItsWorksSection.PayTitle',
      description: 'HowItsWorksSection.PayText',
      imgDirection: 'right',
      topImage: RightTop_BottomLeft_Arrow,
    },
    {
      id: 'Support',
      src: Support_Image,
      title: 'HowItsWorksSection.SupportTitle',
      description: 'HowItsWorksSection.SupportText',
      imgDirection: 'left',
      topImage: LeftTop_BottomRight_Arrow,
    },
  ];

  return (
    <div className={css.HowItsWorksSectionContainer}>
      <div className={css.title}>
        <FormattedMessage id="HowItsWorksSection.title" />
      </div>
      <div className={css.description}>
        <FormattedMessage id="HowItsWorksSection.description" />
      </div>
      <div>
        {HowItsWorkList.map(data => (
          <div key={data.id}>
            {data.topImage && (
              <div className={css.imgOptionalDisplay}>
                <img src={data.topImage} alt={data.id} />
              </div>
            )}
            <div className={css.ListWrapper}>
              {data.imgDirection === 'left' ? (
                <div className={css.imgLeftContainer}>
                  <div className={css.imgWrapper}>
                    <img src={data.src} alt={data.id} />
                  </div>
                  <div className={css.DetailsContainer}>
                    <h6>
                      <FormattedMessage id={data.title} />
                    </h6>
                    <FormattedMessage id={data.description} />
                  </div>
                </div>
              ) : (
                <div className={css.imgRightContainer}>
                  <div className={cns({
                      [css.DetailsContainer]: true,
                      [css.DetailsOptionalContainer]: data.isOptional,
                    })}>
                    <h6>
                      <FormattedMessage id={data.title} />
                    </h6>
                    <FormattedMessage id={data.description} />
                  </div>
                  <div className={cns({
                      [css.imgWrapper]: true,
                      [css.imgOptionalWrapper]: data.isOptional,
                      [css.imgOptionalDisplay1]: data.isOptional,
                    })}>
                    <img className={css.imgOptionalDisplay} src={data.src} alt={data.id} />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

LandingPageHowItsWorksSection.defaultProps = {};

LandingPageHowItsWorksSection.propTypes = {};

export default LandingPageHowItsWorksSection;

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { entityRefs } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const QUERY_PROMOTED_LISTINGS_REQUEST = 'app/LandingPage/QUERY_PROMOTED_LISTINGS_REQUEST';
export const QUERY_PROMOTED_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_PROMOTED_LISTINGS_SUCCESS';
export const QUERY_PROMOTED_LISTINGS_ERROR = 'app/LandingPage/QUERY_PROMOTED_LISTINGS_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/LandingPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/LandingPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/LandingPage/SEARCH_LISTINGS_ERROR';

export const QUERY_PROMOTED_LISTING_REVIEWS_REQUEST =
  'app/LandingPage/QUERY_PROMOTED_LISTING_REVIEWS_REQUEST';
export const QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS =
  'app/LandingPage/QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS';
export const QUERY_PROMOTED_LISTING_REVIEWS_ERROR =
  'app/LandingPage/QUERY_PROMOTED_LISTING_REVIEWS_ERROR';

export const FETCH_COMPANIES_REQUEST = 'app/LandingPage/FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'app/LandingPage/FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'app/LandingPage/FETCH_COMPANIES_ERROR';

export const FETCH_ALL_LISTING_REVIEWS = 'app/LandingPage/FETCH_ALL_LISTING_REVIEWS';

// ================ Reducer ================ //

const initialState = {
  queryPromotedListingsError: null,
  promotedListingRefs: [],
  promotedListingReviews: [],
  suggestedListings: [],

  //companies
  companiesRefs: [],
  companiesError: null,
  companiesLoading: false,
  listingAllReviews: {},
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_PROMOTED_LISTINGS_REQUEST:
      return {
        ...state,
        promotedListingRefs: state.promotedListingRefs,
        queryPromotedListingsError: null,
      };
    case QUERY_PROMOTED_LISTINGS_SUCCESS:
      return { ...state, promotedListingRefs: payload.listingRefs };
    case QUERY_PROMOTED_LISTINGS_ERROR:
      return { ...state, promotedListingRefs: [], queryPromotedListingsError: payload };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        suggestedListings: state.suggestedListings,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return { ...state, suggestedListings: payload.listings };
    case SEARCH_LISTINGS_ERROR:
      return { ...state, suggestedListings: [], searchListingsError: payload };
    case QUERY_PROMOTED_LISTING_REVIEWS_REQUEST:
      return { ...state, queryPromotedListingReviewsError: null };
    case QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS:
      return { ...state, promotedListingReviews: { ...state.promotedListingReviews, payload } };
    case QUERY_PROMOTED_LISTING_REVIEWS_ERROR:
      return { ...state, reviews: [], queryPromotedListingReviewsError: payload };

    //Companies
    case FETCH_COMPANIES_REQUEST:
      return { ...state, companiesError: null, companiesLoading: true };
    case FETCH_COMPANIES_SUCCESS:
      return { ...state, companiesRefs: payload, companiesLoading: false };
    case FETCH_COMPANIES_ERROR:
      return { ...state, companiesError: payload, companiesLoading: false };

    // reviews
    case FETCH_ALL_LISTING_REVIEWS:
      return { ...state, listingAllReviews: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryPromotedListingsRequest = () => ({
  type: QUERY_PROMOTED_LISTINGS_REQUEST,
});

export const queryPromotedListingsSuccess = listingRefs => ({
  type: QUERY_PROMOTED_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryPromotedListingsError = e => ({
  type: QUERY_PROMOTED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = () => ({
  type: SEARCH_LISTINGS_REQUEST,
});

export const searchListingsSuccess = listings => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { listings },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryPromotedListingReviewsRequest = () => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_REQUEST,
});

export const allListingReviews = reviewData => ({
  type: FETCH_ALL_LISTING_REVIEWS,
  payload: reviewData,
});

export const queryPromotedListingReviewsSuccess = reviews => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryPromotedListingReviewsError = e => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const fetchCompaniesRequest = () => ({
  type: FETCH_COMPANIES_REQUEST,
});
export const fetchCompaniesSuccess = payload => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload,
});
export const fetchCompaniesError = payload => ({
  type: FETCH_COMPANIES_ERROR,
  payload,
});

// ================ Thunks ================ //

export const queryPromotedListings = categories => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  let listingQueryParams = { pub_listing_type: 'listing' };
  if (categories && categories.length) {
    listingQueryParams = {
      pub_listing_type: 'listing',
      pub_suggestion_category: categories,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 3,
      perPage: 15,
    };
  } else {
    listingQueryParams = {
      pub_listing_type: 'listing',
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 3,
      perPage: 15,
    };
  }

  return sdk.listings
    .query(listingQueryParams)
    .then(response => {
      const listingRefs = response?.data?.data?.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryPromotedListingsSuccess(listingRefs));

      // Fetch reviews
      // response.data.data.map(({id}) => { dispatch(queryPromotedListingReviews(id.uuid)) })

      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
};

export const queryPromotedListingReviews = listingRef => (dispatch, getState, sdk) => {
  let listingReview = {};
  listingRef.forEach(async (ref, index) => {
    try {
      const resp = await sdk.reviews.query({
        listing_id: ref.id,
        state: 'public',
      });
      if (resp?.data?.data?.length !== 0) {
        listingReview[ref.id.uuid] = resp?.data?.data;
      }
    } catch (error) {
      console.log(error);
    }
    if (index === listingRef.length - 1) {
      dispatch(allListingReviews(listingReview));
    }
  });
  // return sdk.reviews
  //   .query({
  //     listing_id: listingId,
  //     state: 'public',
  //     include: ['author', 'author.profileImage'],
  //     'fields.image': ['variants.square-small', 'variants.square-small2x'],
  //   }).then(response => {
  //     const reviews = denormalisedResponseEntities(response);
  //     dispatch(queryPromotedListingReviewsSuccess(reviews));
  //   })
  //   .catch(e => dispatch(queryPromotedListingReviewsError(e)));
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest());
  return sdk.listings
    .query(searchParams)
    .then(response => {
      const listings = response?.data?.data?.map(({ id, attributes }) => ({
        id,
        title: attributes.title,
      }));
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(listings));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(e));
      throw e;
    });
};

export const fetchCompanies = () => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.listings.query({
      pub_listing_type: 'company',
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    });

    const refs = entityRefs(response?.data?.data);
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchCompaniesSuccess(refs));
  } catch (e) {
    console.error(e);
    dispatch(fetchCompaniesError(storableError(e)));
  }
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([dispatch(queryPromotedListings())]);
  // return Promise.all([dispatch(queryPromotedListings()), dispatch(fetchCompanies())]);
};

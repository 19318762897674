import React, { PureComponent } from 'react';
import { array, bool, func, number, object, objectOf, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import moment from 'moment';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel,
  SearchFiltersPanel,
  UpcomingCalendar,
  ModifiedSearchFilters,
} from '../../components';
import { LISTING_TYPES } from '../../util/constants';
import css from './SearchPage.css';

class MainPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isSearchFiltersPanelOpen: false };
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      sort,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      pagination,
      searchParamsForPagination,
      primaryFilters,
      secondaryFilters,
      allListingReview,
      allAuthorNumberOfListings,
      history,
      isMobileLayout,
      otherListings,
    } = this.props;

    const listingIds = listings.map(listing => listing.id.uuid);
    const otherListingIds = otherListings.map(listing => listing.id.uuid);

    const notInListings = listing => {
      return !listingIds.includes(listing);
    };

    const extraIds = otherListingIds.filter(notInListings);
    const extraListings = otherListings.filter(listing => extraIds.includes(listing.id.uuid));

    const getListingTypeLabel = type => {
      switch (type) {
        case LISTING_TYPES.COMPANY:
          return 'Companies';
        case LISTING_TYPES.FACILITY:
          return 'Facilities';
        case LISTING_TYPES.CLASS:
          return 'Classes';
        case LISTING_TYPES.LISTING:
        default:
          return "Pro's";
      }
    };

    const isSearchFiltersPanelOpen = !!secondaryFilters && this.state.isSearchFiltersPanelOpen;
    const searchListingType = urlQueryParams.pub_listing_type;

    const handleClassDateChange = selectedDate => {
      const date = moment(selectedDate).format('YYYY-MM-DD');

      const queryParams = {
        ...urlQueryParams,
        dates: `${date},${date}`,
        times: '00:00,23:59',
      };

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
      );
    };

    const getSelectedDate = searchParams => {
      const dates = searchParams.dates ? searchParams.dates.split(',') : [];
      return dates[0] ? moment(dates[0]) : moment();
    };
    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering

    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    const secondaryFilterParamNames = secondaryFilters
      ? Object.values(secondaryFilters).map(f => f.paramName)
      : [];

    return (
      <div className={classes}>
        {/* <div>
          {isMobileLayout && (
            <ModifiedSearchFilters
              urlQueryParams={urlQueryParams}
              redirectPageName={'SearchPage'}
              isMobileLayout={isMobileLayout}
              categoryFilter={primaryFilters.categoryFilter}
              subcategoryFilter={primaryFilters.subcategoryFilter}
              priceFilter={primaryFilters.priceFilter}
              languageFilter={primaryFilters.languageFilter}
              history={history}
            />
          )}
        </div> */}

        {searchListingType === LISTING_TYPES.CLASS && (
          <>
            <div className={css.classCalendarContainer}>
              <UpcomingCalendar
                initialDate={getSelectedDate(urlQueryParams)}
                onDateSelect={handleClassDateChange}
                numberOfDays={30}
                totalItems={totalItems}
              />
              <div className={css.classMode}></div>
            </div>
            {/* <div className={css.mobileResultHead}>
            <span className={css.totalStays}>{totalItems} Listings found</span>
          </div> */}
          </>
        )}

        {searchListingType !== LISTING_TYPES.CLASS && (
          <div className={css.resultHead}>
            {searchListingType === LISTING_TYPES.COMPANY ? (
              <div className={css.modeHeadingWrap}>
                <h2 className={css.staysLocation}>
                  <span>Partners</span>
                </h2>
              </div>
            ) : null}
            <div className={css.modeHeadingWrap}>
              <h2 className={css.staysLocation}>
                <span>
                  {searchListingType === LISTING_TYPES.COMPANY
                    ? 'Travel Business Listings'
                    : 'Trip Experts'}
                </span>
              </h2>
            </div>
            {/* <span className={css.totalStays}>{totalItems} Listings found</span> */}
            <span className={css.totalStays}>{listingsLength} Listings found</span>
          </div>
        )}
        {/*<SearchFiltersMobile
          className={css.searchFiltersMobile}
          urlQueryParams={urlQueryParams}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          filterParamNames={filterParamNames}
          selectedFiltersCount={selectedFiltersCount}
          {...primaryFilters}
          {...secondaryFilters}
        />*/}
        {isSearchFiltersPanelOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersPanel
              urlQueryParams={urlQueryParams}
              sort={sort}
              listingsAreLoaded={listingsAreLoaded}
              onClosePanel={() => this.setState({ isSearchFiltersPanelOpen: false })}
              filterParamNames={secondaryFilterParamNames}
              {...secondaryFilters}
            />
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}
            <SearchResultsPanel
              className={css.searchListingsPanel}
              listings={listings}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              setActiveListing={onActivateListing}
              allListingReview={allListingReview}
              allAuthorNumberOfListings={allAuthorNumberOfListings}
            />
            {listings?.length < 6 &&
              extraListings.length > 0 &&
              searchListingType !== LISTING_TYPES.COMPANY && (
                <>
                  <h2>Other experts</h2>
                  <SearchResultsPanel
                    className={css.searchListingsPanel}
                    // listings={otherListings}
                    listings={extraListings}
                    pagination={listingsAreLoaded ? pagination : null}
                    search={searchParamsForPagination}
                    setActiveListing={onActivateListing}
                    allListingReview={allListingReview}
                    allAuthorNumberOfListings={allAuthorNumberOfListings}
                  />
                </>
              )}
          </div>
        )}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  primaryFilters: null,
  secondaryFilters: null,
  allListingReview: {},
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  primaryFilters: objectOf(propTypes.filterConfig),
  secondaryFilters: objectOf(propTypes.filterConfig),
  allListingReview: object,
};

export default MainPanel;

import React from 'react';
import classnames from 'classnames';
import { capitalize } from 'lodash/string';
import {
  Modal,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SecondaryButton,
  WhatsappButton
} from '../../components';

import css from './ListingPage.css';
import ActionBarMaybe from 'containers/ListingPage/ActionBarMaybe';
import SectionDescriptionMaybe from 'containers/ListingPage/SectionDescriptionMaybe';
import { EnquiryForm } from 'forms';
import { FormattedMessage } from 'util/reactIntl';
import UserCard from '../../components/UserCard/UserCard'
import config from '../../config';

function EnquiryPageContent(props) {
  const {
    authorDisplayName,
    topbar,
    isOwnListing,
    currentListing,
    listingId,
    listingSlug,
    listingType,
    listingTab,
    onManageDisableScrolling,
    isAuthenticated,
    onSubmitEnquiry,
    title,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpen,
    setEnquiryModalOpen,
    showContactUser,
    onContactUser,
    ensuredAuthor
  } = props;

  const { category, sub_category, location, times_of_day, times_per_week, week_day_available } = currentListing.attributes.publicData;
  const { description } = currentListing.attributes;
  const categoryData = config.custom.categories.find((cat) => cat.key === category);
  const currentAddress = location.address || "";
  const TimesOfDay = times_of_day ? times_of_day.join(', ') : null;
  const timesPerWeek = times_per_week ? `${times_per_week} hours / week` : null;
  const weekDayAvailable = week_day_available ? week_day_available.join(', ') : null;
  return (
    <LayoutSingleColumn className={css.pageRoot}>
      <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
      <LayoutWrapperMain>
        <div>
          <div className={css.enquiryActionBarWrapper}>
            <ActionBarMaybe
              isOwnListing={isOwnListing}
              listing={currentListing}
              routeName="EditInquirePage"
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
              }}
            />
          </div>

          <div className={css.contentContainer}>
            <div className={classnames(css.mainContent, css.inquireMainContent)}>
              <UserCard user={currentListing.author} onContactUser={onContactUser} />
              <div className={css.sectionWrapper}>
                <div className={css.title}>
                  <span>{title}</span>
                </div>
              </div>

              <div className={css.sectionWrapper}>
                <p className={css.category}>
                  <span>
                    <FormattedMessage id="ListingPage.category" />
                  </span>
                  {capitalize((categoryData && categoryData.label) || '')}
                </p>
                <p className={css.subCategory}>
                  <span>
                    <FormattedMessage id="ListingPage.subcategory" />
                  </span>
                  {capitalize(sub_category)}
                </p>
              </div>

              <div className={css.sectionWrapper}>
                <SectionDescriptionMaybe description={description} />
              </div>

              <div className={css.timeInfoWrapper}>
                <div className={css.svgWrapper}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                    <path d="M8.38353 18.3276C8.19975 18.3281 8.01827 18.2868 7.8528 18.2068C7.68732 18.1269 7.54219 18.0103 7.42836 17.866L4.98655 14.7875C3.48906 14.0426 2.27791 12.8261 1.53951 11.3254C0.801117 9.82469 0.576409 8.12288 0.899977 6.48193C1.22354 4.84098 2.07745 3.35185 3.33027 2.24376C4.58308 1.13566 6.16535 0.470026 7.83353 0.3493C8.88016 0.269926 9.93185 0.409208 10.9217 0.75829C11.9116 1.10737 12.818 1.65861 13.5833 2.37696C14.4851 3.21741 15.1698 4.26388 15.5788 5.42677C15.9877 6.58966 16.1088 7.83434 15.9316 9.05425C15.7544 10.2742 15.2842 11.4329 14.5613 12.4314C13.8384 13.4299 12.8842 14.2383 11.7806 14.7875L9.33879 17.866C9.22495 18.0103 9.07981 18.1269 8.91432 18.2068C8.74883 18.2868 8.56733 18.3281 8.38353 18.3276ZM8.38859 1.30733C8.22715 1.30733 8.06498 1.31303 7.90208 1.32442C6.43933 1.43074 5.05274 2.01758 3.95808 2.99363C2.86342 3.96967 2.12208 5.28018 1.84943 6.72123C1.57678 8.16228 1.78811 9.65304 2.45053 10.9615C3.11295 12.27 4.1893 13.3229 5.51211 13.9562C5.57894 13.9883 5.6376 14.0351 5.68367 14.0932L8.19422 17.2585C8.21684 17.287 8.24562 17.3101 8.27841 17.326C8.3112 17.3418 8.34716 17.35 8.38358 17.35C8.42 17.35 8.45595 17.3418 8.48874 17.326C8.52154 17.3101 8.55032 17.287 8.57294 17.2585L11.0835 14.0932C11.1295 14.0351 11.1882 13.9883 11.255 13.9562C12.232 13.4883 13.0799 12.7889 13.7251 11.9187C14.3702 11.0486 14.793 10.034 14.9568 8.96322C15.1206 7.89244 15.0203 6.79786 14.6648 5.77465C14.3092 4.75143 13.7091 3.83054 12.9167 3.09203C11.6906 1.94072 10.0705 1.30217 8.38859 1.30733Z" fill="#202020" />
                    <path d="M8.38418 11.9871C7.62794 11.9871 6.88868 11.7629 6.25989 11.3427C5.63109 10.9226 5.14101 10.3254 4.85161 9.62672C4.5622 8.92804 4.48648 8.15924 4.63402 7.41752C4.78155 6.67581 5.14572 5.99451 5.68046 5.45976C6.21521 4.92502 6.89651 4.56085 7.63822 4.41332C8.37994 4.26578 9.14874 4.3415 9.84742 4.6309C10.5461 4.9203 11.1433 5.41039 11.5634 6.03918C11.9836 6.66797 12.2078 7.40723 12.2078 8.16348C12.2067 9.17721 11.8034 10.1491 11.0866 10.8659C10.3698 11.5827 9.39792 11.9859 8.38418 11.9871ZM8.38418 5.31745C7.82129 5.31745 7.27104 5.48436 6.80301 5.79709C6.33499 6.10981 5.9702 6.5543 5.75479 7.07434C5.53938 7.59439 5.48302 8.16663 5.59284 8.7187C5.70265 9.27078 5.97371 9.77789 6.37173 10.1759C6.76975 10.5739 7.27687 10.845 7.82894 10.9548C8.38102 11.0646 8.95326 11.0083 9.4733 10.7929C9.99335 10.5775 10.4378 10.2127 10.7506 9.74464C11.0633 9.27662 11.2302 8.72637 11.2302 8.16348C11.2294 7.40892 10.9292 6.68552 10.3957 6.15197C9.86214 5.61842 9.13874 5.3183 8.38418 5.31745Z" fill="#202020" />
                  </svg>
                  <span className={css.currentListingAddress}>{currentAddress}</span>
                </div>
                {timesPerWeek && (<div className={css.svgWrapper}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path d="M9.7558 18.6343C7.97577 18.6343 6.23572 18.1064 4.75568 17.1175C3.27565 16.1285 2.1221 14.7229 1.44093 13.0784C0.759748 11.4338 0.581529 9.62424 0.928807 7.87841C1.27608 6.13259 2.13326 4.52894 3.39194 3.27028C4.65062 2.01162 6.25427 1.15446 8.0001 0.807203C9.74593 0.459947 11.5555 0.638188 13.2001 1.31939C14.8446 2.00059 16.2502 3.15415 17.2391 4.63419C18.228 6.11424 18.7559 7.8543 18.7559 9.63433C18.7531 12.0205 17.804 14.3081 16.1168 15.9953C14.4295 17.6825 12.1419 18.6316 9.7558 18.6343ZM9.7558 1.79869C8.20608 1.7987 6.69115 2.25826 5.40261 3.11925C4.11406 3.98024 3.10976 5.20399 2.51671 6.63575C1.92366 8.06752 1.76849 9.64299 2.07083 11.1629C2.37317 12.6829 3.11944 14.0791 4.21526 15.1749C5.31109 16.2707 6.70725 17.017 8.2272 17.3193C9.74715 17.6216 11.3226 17.4665 12.7544 16.8734C14.1861 16.2804 15.4099 15.2761 16.2709 13.9875C17.1319 12.699 17.5914 11.1841 17.5914 9.63433C17.5891 7.55691 16.7628 5.56525 15.2938 4.09629C13.8249 2.62733 11.8332 1.80104 9.7558 1.79869Z" fill="#202020" />
                    <path d="M13.039 13.4992C12.9625 13.4993 12.8868 13.4843 12.8162 13.455C12.7455 13.4257 12.6814 13.3828 12.6274 13.3286L9.17383 9.87533V4.653C9.17383 4.49859 9.23517 4.35051 9.34435 4.24132C9.45354 4.13214 9.60162 4.0708 9.75603 4.0708C9.91044 4.0708 10.0585 4.13214 10.1677 4.24132C10.2769 4.35051 10.3382 4.49859 10.3382 4.653V9.39296L13.4506 12.5054C13.532 12.5868 13.5875 12.6905 13.6099 12.8034C13.6324 12.9164 13.6209 13.0334 13.5768 13.1398C13.5327 13.2462 13.4581 13.3371 13.3624 13.4011C13.2667 13.465 13.1541 13.4992 13.039 13.4992Z" fill="#202020" />
                  </svg>
                  <span className={css.timesInfoTitle}>{timesPerWeek}</span>
                </div>)}
                {TimesOfDay && (<div className={css.svgWrapper}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path d="M10.7652 16.3697C9.62878 16.3697 8.51792 16.0327 7.57306 15.4014C6.6282 14.77 5.89177 13.8727 5.45689 12.8228C5.02202 11.7729 4.90824 10.6177 5.12993 9.50313C5.35163 8.38858 5.89885 7.36481 6.70239 6.56127C7.50593 5.75773 8.5297 5.21052 9.64424 4.98882C10.7588 4.76713 11.914 4.88091 12.9639 5.31578C14.0138 5.75065 14.9111 6.48708 15.5425 7.43195C16.1738 8.37681 16.5108 9.48767 16.5108 10.624C16.509 12.1473 15.9031 13.6078 14.826 14.6849C13.7489 15.762 12.2885 16.3679 10.7652 16.3697ZM10.7652 6.15409C9.88108 6.15409 9.01686 6.41625 8.28178 6.90741C7.5467 7.39858 6.97378 8.09669 6.63546 8.91347C6.29714 9.73024 6.20862 10.629 6.38109 11.4961C6.55357 12.3632 6.97929 13.1596 7.60442 13.7848C8.22956 14.4099 9.02603 14.8356 9.89311 15.0081C10.7602 15.1806 11.659 15.0921 12.4757 14.7537C13.2925 14.4154 13.9906 13.8425 14.4818 13.1074C14.9729 12.3723 15.2351 11.5081 15.2351 10.624C15.2338 9.43895 14.7624 8.30277 13.9244 7.46478C13.0864 6.62679 11.9503 6.15542 10.7652 6.15408V6.15409Z" fill="#202020" />
                    <path d="M10.7648 4.1415C10.681 4.14151 10.5981 4.12501 10.5207 4.09296C10.4433 4.06091 10.373 4.01393 10.3138 3.9547C10.2545 3.89547 10.2075 3.82515 10.1755 3.74776C10.1434 3.67037 10.1269 3.58743 10.127 3.50366V1.27211C10.127 1.10295 10.1942 0.940712 10.3138 0.821095C10.4334 0.701478 10.5956 0.634277 10.7648 0.634277C10.934 0.634277 11.0962 0.701478 11.2158 0.821095C11.3354 0.940712 11.4026 1.10295 11.4026 1.27211V3.50366C11.4026 3.58743 11.3861 3.67037 11.3541 3.74776C11.322 3.82515 11.2751 3.89547 11.2158 3.9547C11.1566 4.01393 11.0863 4.06091 11.0089 4.09296C10.9315 4.12501 10.8486 4.14151 10.7648 4.1415Z" fill="#202020" />
                    <path d="M3.62512 11.262H1.39369C1.30993 11.262 1.22699 11.2455 1.14961 11.2134C1.07222 11.1814 1.00191 11.1344 0.942677 11.0752C0.883449 11.016 0.836466 10.9456 0.804412 10.8683C0.772357 10.7909 0.755859 10.7079 0.755859 10.6242C0.755859 10.5404 0.772357 10.4575 0.804412 10.3801C0.836466 10.3027 0.883449 10.2324 0.942677 10.1731C1.00191 10.1139 1.07222 10.0669 1.14961 10.0349C1.22699 10.0028 1.30993 9.98633 1.39369 9.98633H3.62512C3.70888 9.98633 3.79182 10.0028 3.86921 10.0349C3.9466 10.0669 4.01691 10.1139 4.07614 10.1731C4.13537 10.2324 4.18235 10.3027 4.2144 10.3801C4.24646 10.4575 4.26296 10.5404 4.26296 10.6242C4.26296 10.7079 4.24646 10.7909 4.2144 10.8683C4.18235 10.9456 4.13537 11.016 4.07614 11.0752C4.01691 11.1344 3.9466 11.1814 3.86921 11.2134C3.79182 11.2455 3.70888 11.262 3.62512 11.262Z" fill="#202020" />
                    <path d="M5.76498 6.15428C5.68121 6.15439 5.59824 6.13794 5.52084 6.10587C5.44345 6.0738 5.37316 6.02674 5.31401 5.96741L3.73611 4.38952C3.67679 4.33032 3.62971 4.26001 3.59757 4.1826C3.56544 4.1052 3.54887 4.02222 3.54883 3.9384C3.54878 3.85459 3.56526 3.7716 3.59731 3.69416C3.62936 3.61672 3.67636 3.54635 3.73563 3.48709C3.79489 3.42783 3.86525 3.38083 3.94269 3.34877C4.02013 3.31672 4.10313 3.30025 4.18694 3.30029C4.27075 3.30034 4.35373 3.3169 4.43114 3.34904C4.50854 3.38117 4.57885 3.42825 4.63805 3.48758L6.21595 5.06547C6.30513 5.15468 6.36586 5.26832 6.39046 5.39203C6.41507 5.51575 6.40243 5.64398 6.35417 5.76052C6.3059 5.87705 6.22416 5.97666 6.11929 6.04675C6.01441 6.11684 5.89112 6.15426 5.76498 6.15428H5.76498Z" fill="#202020" />
                    <path d="M3.62512 11.262H1.39369C1.30993 11.262 1.22699 11.2455 1.14961 11.2134C1.07222 11.1814 1.00191 11.1344 0.942677 11.0752C0.883449 11.016 0.836466 10.9456 0.804412 10.8683C0.772357 10.7909 0.755859 10.7079 0.755859 10.6242C0.755859 10.5404 0.772357 10.4575 0.804412 10.3801C0.836466 10.3027 0.883449 10.2324 0.942677 10.1731C1.00191 10.1139 1.07222 10.0669 1.14961 10.0349C1.22699 10.0028 1.30993 9.98633 1.39369 9.98633H3.62512C3.70888 9.98633 3.79182 10.0028 3.86921 10.0349C3.9466 10.0669 4.01691 10.1139 4.07614 10.1731C4.13537 10.2324 4.18235 10.3027 4.2144 10.3801C4.24646 10.4575 4.26296 10.5404 4.26296 10.6242C4.26296 10.7079 4.24646 10.7909 4.2144 10.8683C4.18235 10.9456 4.13537 11.016 4.07614 11.0752C4.01691 11.1344 3.9466 11.1814 3.86921 11.2134C3.79182 11.2455 3.70888 11.262 3.62512 11.262Z" fill="#202020" />
                    <path d="M10.7453 20.6342C10.6615 20.6342 10.5785 20.6177 10.5012 20.5856C10.4238 20.5536 10.3535 20.5066 10.2942 20.4474C10.235 20.3881 10.188 20.3178 10.156 20.2404C10.1239 20.163 10.1074 20.0801 10.1074 19.9963V17.7648C10.1074 17.5956 10.1746 17.4334 10.2942 17.3138C10.4139 17.1942 10.5761 17.127 10.7453 17.127C10.9144 17.127 11.0767 17.1942 11.1963 17.3138C11.3159 17.4334 11.3831 17.5956 11.3831 17.7648V19.9963C11.3831 20.0801 11.3666 20.163 11.3346 20.2404C11.3025 20.3178 11.2555 20.3881 11.1963 20.4474C11.1371 20.5066 11.0667 20.5536 10.9894 20.5856C10.912 20.6177 10.829 20.6342 10.7453 20.6342Z" fill="#202020" />
                    <path d="M4.05962 17.8414C3.93348 17.8414 3.81018 17.804 3.7053 17.7339C3.60043 17.6638 3.51869 17.5642 3.47042 17.4477C3.42215 17.3311 3.40952 17.2029 3.43413 17.0792C3.45873 16.9555 3.51946 16.8418 3.60865 16.7526L5.18654 15.1747C5.30622 15.0555 5.46833 14.9886 5.63726 14.9888C5.8062 14.9889 5.96818 15.0561 6.08764 15.1756C6.2071 15.295 6.27428 15.457 6.27444 15.6259C6.27459 15.7949 6.20772 15.957 6.08848 16.0767L4.51059 17.6546C4.45144 17.7139 4.38115 17.7609 4.30376 17.793C4.22636 17.8251 4.14339 17.8415 4.05962 17.8414Z" fill="#202020" />
                    <path d="M20.0979 11.282H17.8664C17.6972 11.282 17.5349 11.2148 17.4153 11.0952C17.2957 10.9756 17.2285 10.8133 17.2285 10.6442C17.2285 10.475 17.2957 10.3128 17.4153 10.1932C17.5349 10.0735 17.6972 10.0063 17.8664 10.0063H20.0979C20.2671 10.0063 20.4293 10.0735 20.5489 10.1932C20.6685 10.3128 20.7357 10.475 20.7357 10.6442C20.7357 10.8133 20.6685 10.9756 20.5489 11.0952C20.4293 11.2148 20.2671 11.282 20.0979 11.282Z" fill="#202020" />
                    <path d="M10.7453 4.1415C10.6615 4.14151 10.5785 4.12501 10.5012 4.09296C10.4238 4.06091 10.3535 4.01393 10.2942 3.9547C10.235 3.89547 10.188 3.82515 10.156 3.74776C10.1239 3.67037 10.1074 3.58743 10.1074 3.50366V1.27211C10.1074 1.10295 10.1746 0.940712 10.2942 0.821095C10.4139 0.701478 10.5761 0.634277 10.7453 0.634277C10.9144 0.634277 11.0767 0.701478 11.1963 0.821095C11.3159 0.940712 11.3831 1.10295 11.3831 1.27211V3.50366C11.3831 3.58743 11.3666 3.67037 11.3346 3.74776C11.3025 3.82515 11.2555 3.89547 11.1963 3.9547C11.1371 4.01393 11.0667 4.06091 10.9894 4.09296C10.912 4.12501 10.829 4.14151 10.7453 4.1415Z" fill="#202020" />
                    <path d="M15.8527 6.28083C15.7265 6.28084 15.6032 6.24343 15.4983 6.17334C15.3934 6.10324 15.3117 6.00362 15.2634 5.88705C15.2151 5.77049 15.2025 5.64223 15.2271 5.51849C15.2517 5.39475 15.3125 5.2811 15.4017 5.1919L16.9798 3.61401C17.0994 3.4944 17.2616 3.42722 17.4308 3.42725C17.6 3.42727 17.7622 3.49449 17.8818 3.61413C18.0014 3.73377 18.0686 3.89602 18.0686 4.06519C18.0686 4.23436 18.0013 4.39659 17.8817 4.5162L16.3037 6.09409C16.2445 6.15338 16.1742 6.2004 16.0968 6.23245C16.0194 6.2645 15.9365 6.28094 15.8527 6.28083Z" fill="#202020" />
                    <path d="M20.1174 11.262H17.8859C17.7167 11.262 17.5545 11.1948 17.4349 11.0752C17.3152 10.9556 17.248 10.7933 17.248 10.6242C17.248 10.455 17.3152 10.2928 17.4349 10.1731C17.5545 10.0535 17.7167 9.98633 17.8859 9.98633H20.1174C20.2866 9.98633 20.4488 10.0535 20.5685 10.1731C20.6881 10.2928 20.7553 10.455 20.7553 10.6242C20.7553 10.7933 20.6881 10.9556 20.5685 11.0752C20.4488 11.1948 20.2866 11.262 20.1174 11.262Z" fill="#202020" />
                    <path d="M10.7648 20.6342C10.681 20.6342 10.5981 20.6177 10.5207 20.5856C10.4433 20.5536 10.373 20.5066 10.3138 20.4474C10.2545 20.3881 10.2075 20.3178 10.1755 20.2404C10.1434 20.163 10.1269 20.0801 10.127 19.9963V17.7648C10.127 17.5956 10.1942 17.4334 10.3138 17.3138C10.4334 17.1942 10.5956 17.127 10.7648 17.127C10.934 17.127 11.0962 17.1942 11.2158 17.3138C11.3354 17.4334 11.4026 17.5956 11.4026 17.7648V19.9963C11.4026 20.0801 11.3861 20.163 11.3541 20.2404C11.322 20.3178 11.2751 20.3881 11.2158 20.4474C11.1566 20.5066 11.0863 20.5536 11.0089 20.5856C10.9315 20.6177 10.8486 20.6342 10.7648 20.6342Z" fill="#202020" />
                    <path d="M17.4511 17.8414C17.3674 17.8415 17.2844 17.8251 17.207 17.793C17.1296 17.7609 17.0593 17.7139 17.0002 17.6546L15.4223 16.0767C15.303 15.957 15.2362 15.7949 15.2363 15.6259C15.2365 15.457 15.3037 15.295 15.4231 15.1756C15.5426 15.0561 15.7046 14.9889 15.8735 14.9888C16.0424 14.9886 16.2045 15.0555 16.3242 15.1747L17.9021 16.7526C17.9913 16.8418 18.052 16.9555 18.0766 17.0792C18.1012 17.2029 18.0886 17.3311 18.0403 17.4477C17.9921 17.5642 17.9103 17.6638 17.8055 17.7339C17.7006 17.804 17.5773 17.8414 17.4511 17.8414Z" fill="#202020" />
                  </svg>
                  <span className={css.timesInfoTitle}>{TimesOfDay}</span>
                </div>)}
                {weekDayAvailable && (<div className={css.svgWrapper}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M13.9864 18.6342H3.99828C3.13862 18.6332 2.31445 18.2913 1.70659 17.6834C1.09872 17.0755 0.756809 16.2513 0.755859 15.3917L0.755859 5.40367C0.756809 4.54401 1.09872 3.71982 1.70659 3.11194C2.31445 2.50405 3.13862 2.16211 3.99828 2.16113H13.9864C14.8461 2.16211 15.6702 2.50405 16.2781 3.11194C16.886 3.71982 17.2279 4.54401 17.2288 5.40367V15.3917C17.2279 16.2513 16.886 17.0755 16.2781 17.6834C15.6702 18.2913 14.8461 18.6332 13.9864 18.6342ZM3.99828 3.30846C3.44281 3.3091 2.91026 3.53005 2.51749 3.92285C2.12472 4.31564 1.90379 4.84819 1.90318 5.40367V15.3917C1.90379 15.9471 2.12472 16.4797 2.51749 16.8725C2.91026 17.2653 3.44281 17.4862 3.99828 17.4869H13.9864C14.5419 17.4862 15.0744 17.2653 15.4672 16.8725C15.86 16.4797 16.0809 15.9471 16.0815 15.3917V5.40367C16.0809 4.84819 15.86 4.31564 15.4672 3.92285C15.0744 3.53005 14.5419 3.3091 13.9864 3.30846H3.99828Z" fill="#202020" />
                    <path d="M16.6557 6.46191H1.33008V7.60924H16.6557V6.46191Z" fill="#202020" />
                    <path d="M13.9037 5.3401C13.8284 5.34011 13.7538 5.32527 13.6842 5.29645C13.6146 5.26762 13.5514 5.22536 13.4981 5.17209C13.4448 5.11882 13.4026 5.05558 13.3737 4.98598C13.3449 4.91638 13.3301 4.84178 13.3301 4.76644V1.20794C13.3301 1.0558 13.3905 0.909882 13.4981 0.802299C13.6057 0.694717 13.7516 0.634277 13.9037 0.634277C14.0559 0.634277 14.2018 0.694717 14.3094 0.802299C14.417 0.909882 14.4774 1.0558 14.4774 1.20794V4.76644C14.4774 4.84178 14.4626 4.91638 14.4338 4.98598C14.4049 5.05558 14.3627 5.11882 14.3094 5.17209C14.2561 5.22536 14.1929 5.26762 14.1233 5.29645C14.0537 5.32527 13.9791 5.34011 13.9037 5.3401Z" fill="#202020" />
                    <path d="M4.08148 5.3401C4.00614 5.34011 3.93154 5.32527 3.86194 5.29645C3.79233 5.26762 3.72909 5.22536 3.67582 5.17209C3.62255 5.11882 3.5803 5.05558 3.55147 4.98598C3.52264 4.91638 3.50781 4.84178 3.50781 4.76644V1.20794C3.50781 1.0558 3.56825 0.909882 3.67583 0.802299C3.78342 0.694717 3.92933 0.634277 4.08148 0.634277C4.23362 0.634277 4.37953 0.694717 4.48712 0.802299C4.5947 0.909882 4.65514 1.0558 4.65514 1.20794V4.76644C4.65514 4.84178 4.64031 4.91638 4.61148 4.98598C4.58265 5.05558 4.5404 5.11882 4.48713 5.17209C4.43386 5.22536 4.37062 5.26762 4.30101 5.29645C4.23141 5.32527 4.15681 5.34011 4.08148 5.3401Z" fill="#202020" />
                  </svg>
                  <span className={css.timesInfoTitle}>{weekDayAvailable}</span>
                </div>)}
              </div>

            </div>

            <div className={css.enquirySidePanel}>
              <div className={css.sectionWrapper}>
                {showContactUser ? (
                  <div className={css.contactWrapper}>
                    <SecondaryButton className={css.contactLink} onClick={onContactUser}>
                      <FormattedMessage id="ListingPage.enquiryResponse" />
                    </SecondaryButton>
                    <div>
                      <div className={css.whatsapp}>
                        <WhatsappButton user={ensuredAuthor} />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Modal
          id="ListingPage.enquiry"
          contentClassName={css.enquiryModalContent}
          isOpen={isAuthenticated && enquiryModalOpen}
          onClose={() => setEnquiryModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <EnquiryForm
            className={css.enquiryForm}
            submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
            listingTitle={
              <FormattedMessage id="EnquiryForm.heading" values={{ listingTitle: title }} />
            }
            authorDisplayName={authorDisplayName}
            sendEnquiryError={sendEnquiryError}
            onSubmit={onSubmitEnquiry}
            inProgress={sendEnquiryInProgress}
          />
        </Modal>
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </LayoutSingleColumn>
  );
}

export default EnquiryPageContent;

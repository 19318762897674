import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { CurrencyDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { ensureCurrentUser } from '../../util/data';
import css from './CurrencyDetailsPage.css';

export const CurrencyDetailsPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    onUpdateProfile,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const CurrencyDetailsFormContent =
    currentUser && currentUser.id ? (
      <CurrencyDetailsForm
        currentUser={currentUser}
        initialValues={{
          currency: user.attributes.profile?.protectedData?.currency,
          marketplaceCurrency: user.attributes.profile?.protectedData?.marketplaceCurrency
        }}
        onSubmit={(value) => {
          const updatedValues = { protectedData: { ...value } }
          onUpdateProfile(updatedValues);
        }}
      />
    ) : null;

  const title = intl.formatMessage({ id: 'CurrencyDetailsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="CurrencyDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          {/* <UserNav selectedPageName="CurrencyDetailsPage" /> */}
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="CurrencyDetailsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="CurrencyDetailsPage.heading" />
            </h1>
            {CurrencyDetailsFormContent}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

CurrencyDetailsPageComponent.defaultProps = {
  changePasswordError: null,
  currentUser: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, func } = PropTypes;

CurrencyDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const CurrencyDetailsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CurrencyDetailsPageComponent);

export default CurrencyDetailsPage;

import React from 'react';
import { withRouter } from 'react-router-dom';
import css from './SectionAddListingBanner.css';
import routeConfiguration from 'routeConfiguration';
import { createResourceLocatorString } from 'util/routes';

const SectionAddListingBanner = props => {
  const onClickHandler = () => {
    const routes = routeConfiguration();
    const to = createResourceLocatorString('NewListingPage', routes, {}, {});
    props.history.push(to);
  };
  return <div className={css.addListingBanner} onClick={onClickHandler} />;
};

export default withRouter(SectionAddListingBanner);

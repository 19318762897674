import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import { twitterPageURL } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './CommissionsWaitingList.css';
import { post } from 'util/api';
import TransactionsTable from './components/TransactionsTable/TransactionsTable';

const CommissionsWaitingList = () => {
  const [waitingList, setWaitingList] = useState([]);

  useEffect(() => {
    post('/api/query-waiting-commission-payments', {})
      .then(resp => {
        setWaitingList(resp);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  console.log(waitingList);
  return (
    <StaticPage
      title="CommissionsWaitingList"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'CommissionsWaitingList',
        description: 'CommissionsWaitingList',
        name: 'CommissionsWaitingList',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <center>
            <h2>Commissions waiting list</h2>
          </center>
          <TransactionsTable transactions={waitingList} />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default CommissionsWaitingList;

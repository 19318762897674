import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { GoogleLogin } from 'react-google-login';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  saveSettings,
} from './GoogleCalendarSettingsPage.duck';
import css from './GoogleCalendarSettingsPage.css';

export const GoogleCalendarSettingsPageComponent = props => {
  const {
    saveSettingsError,
    saveSettingsInProgress,
    currentUser,
    scrollingDisabled,
    onSaveSettings,
    intl,
    // updated,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const title = intl.formatMessage({ id: 'GoogleCalendarSettingsPage.title' });
  const authorizeCalendarText = intl.formatMessage({ id: 'GoogleCalendarSettingsPage.authorizeCalendar' });
  const googleClientId = config.googleClientId;

  const onLoginSuccess = response => {
    const { code } = response;
    onSaveSettings(code)
  }

  const onLoginFailure = response => {
    console.log(response)
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="HitpaySettingsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="HitpaySettingsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="GoogleCalendarSettingsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="GoogleCalendarSettingsPage.heading" />
            </h1>
            <GoogleLogin 
              clientId={googleClientId}
              buttonText={authorizeCalendarText}
              onSuccess={onLoginSuccess}
              onFailure={onLoginFailure}
              responseType='code'
              accessType='offline'
              scope='openid email profile https://www.googleapis.com/auth/calendar'
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

GoogleCalendarSettingsPageComponent.defaultProps = {
  saveSettingsError: null,
  saveSettingsInProgress: null,
  currentUser: null,
};

GoogleCalendarSettingsPageComponent.propTypes = {
  saveSettingsError: propTypes.error,
  saveSettingsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onSaveSettings: func.isRequired,
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
  } = state.user;
  const {
    saveSettingsError,
    saveSettingsInProgress,
    // updated,
  } = state.HitpaySettingsPage;
  
  const userMatches = currentUser && currentUser.id && getMarketplaceEntities(state, [{ type: 'user', id: currentUser.id }]);

  return {
    currentUser: userMatches && userMatches.length === 1 ? userMatches[0] : currentUser,
    saveSettingsError,
    saveSettingsInProgress,
    // updated,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveSettings: values => dispatch(saveSettings(values)),
});

const GoogleCalendarSettingsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(GoogleCalendarSettingsPageComponent);

GoogleCalendarSettingsPage.loadData = () => {
  return fetchCurrentUser();
};

export default GoogleCalendarSettingsPage;

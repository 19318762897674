import React from 'react';
import { convertMultipleSelectQueryStringToArray, convertQueryParamsToChips } from '../../utils';
import ClearIcon from '@mui/icons-material/Clear';
import css from './ChipsFilter.module.css';
import { convertArrayToSearchString } from 'util/urlHelpers';

const ChipsFilter = props => {
  const { currentQueryParams, handleFiltersChange, allOptions, handleResetFilters } = props;

  const chips = convertQueryParamsToChips(currentQueryParams, allOptions);

  const handleRemoveChip = chip => {
    const chipKey = chip.searchKey;
    const chipValue = chip.value;
    const targetedSearchQuery = currentQueryParams[chipKey];
    const targetedSearchQueryValues = convertMultipleSelectQueryStringToArray(targetedSearchQuery);
    const newSearchQueryValues = targetedSearchQueryValues.filter(v => v !== chipValue);
    //TODO add case for single select filter
    if (chipKey === 'price') {
      handleFiltersChange({
        [chipKey]: null,
      });
    } else if (chipKey === 'address') {
      handleFiltersChange({
        address: null,
        bounds: null,
      });
    } else {
      const type = 'has_any';
      const newSearchQuery = convertArrayToSearchString(newSearchQueryValues, type);
      const isSearchValueEmpty = newSearchQuery === type + ':';

      handleFiltersChange({
        [chipKey]: isSearchValueEmpty ? null : newSearchQuery,
      });
    }
  };

  const filteredChips = chips.filter(c => c?.label && c?.value && c?.searchKey);
  const uniqueChips = [...new Set(filteredChips)];

  const showResetChip = uniqueChips.length > 0;
  return (
    <div className={css.wrapper}>
      {uniqueChips.map(c => (
        <div className={css.chip}>
          {c.label} <ClearIcon onClick={() => handleRemoveChip(c)} className={css.clearIcon} />
        </div>
      ))}
      {/* {showResetChip && (
        <div className={css.resetAllButton} onClick={handleResetFilters}>
          {'Reset all'}
        </div>
      )} */}
    </div>
  );
};

export default ChipsFilter;

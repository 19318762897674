import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ShareButton, UserDisplayName } from 'components';
import css from './ListingPage.css';
import like_Icon from '../../assets/Listing_Page/like_Icon.svg';
import Identity_Verified_Icon from '../../assets/Listing_Page/Identity_Verified_Icon.png';
import Lyfshort_icon from '../../assets/Listing_Page/Lyfshort_icon.svg';
import map_Icon from '../../assets/Landing_Pages/Map_Icon_Red.svg';
import Average_Response_Icon from '../../assets/Listing_Page/Average_Response_Icon.svg';
import classNames from 'classnames';
import StarRatings from 'react-star-ratings';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { isMobile } from 'util/userAgent';
import spacetime from 'spacetime';
import { context2Location } from 'util/location';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const SectionHead = props => {
  const {
    intl,
    ensuredAuthor,
    rating,
    totalRating,
    companyName,
    address,
    timezone,
    context,
    averageResponse,
    viewport,
    reviewsLength,
    currentListing,
    currentUser,
    className,
  } = props;

  const [localTime, setLocalTime] = useState(spacetime.now(timezone).format('time'));

  useEffect(() => {
    const ineterval = setInterval(() => {
      setLocalTime(spacetime.now(timezone).format('time'));
    }, 1000);
    return () => {
      clearInterval(ineterval);
    };
  }, [timezone]);

  const utcOffset = spacetime.now(timezone).offset() / 60;
  const utcFormatted = utcOffset > 0 ? `+ ${utcOffset}` : utcOffset;

  const currentLocation = context2Location(context) || address;

  // const listingId = currentListing.id.uuid;
  // const [isFavourite, setIsFavourite] = useState(false);
  // const [favListingsArray, setFavListingsArray] = useState([]);

  // useEffect(() => {
  //   sdk.currentUser
  //     .show()
  //     .then(res => {
  //       if (
  //         currentUser &&
  //         currentUser?.attributes?.profile?.privateData &&
  //         currentUser?.attributes?.profile?.privateData?.favListingsArray
  //       ) {
  //         setFavListingsArray(currentUser.attributes.profile.privateData.favListingsArray);
  //         const listingsIdsArray = currentUser.attributes.profile.privateData.favListingsArray;
  //         const isFavourite = listingsIdsArray.find(id => {
  //           return id === listingId;
  //         });
  //         if (isFavourite) {
  //           setIsFavourite(true);
  //         }
  //       }
  //     })
  //     .catch(e => console.log(e));
  // }, [currentUser]);

  const authorUsername = ensuredAuthor?.attributes?.profile?.publicData?.userName;
  return (
    <div className={className || css.SectionHead}>
      <div className={css.headerDetailsContainer}>
        <div className={css.headerMainDetailsWrapper}>
          <div>
            <div className={css.nameWrapper}>
              <UserDisplayName user={ensuredAuthor} intl={intl} />
            </div>
            <div className={css.headerUserNameWrapper}>
              <div className={css.imgWrap}>
                {' '}
                <img src={Lyfshort_icon} alt="lyfshort" />
              </div>
              <span className={css.topSubHeading1}>{authorUsername || companyName}</span>
            </div>
            {/* {!isMobile() && (
              <div className={css.topXpertExtra}>
                <div className={css.IdentityWrapper}>
                  <img
                    src={Identity_Verified_Icon}
                    alt="Identity_Verified_Icon"
                    width="28px"
                    height="28px"
                  />
                  <span>IDENTITY VERIFIED</span>
                </div>
                {rating ? (
                  <div className={css.starContent}>
                    <span className={classNames(css.ratingWrapper, { [css.filledRating]: rating })}>
                      <StarRatings
                        rating={rating}
                        starRatedColor="#00bfe6"
                        starEmptyColor="#fff"
                        numberOfStars={1}
                        starDimension="24px"
                        starSpacing="0px"
                      />
                      <span className={css.reviewCount}>{rating}</span>
                      <span className={css.reviewCountWrapper}>({totalRating})</span>
                    </span>
                  </div>
                ) : null}
                {reviewsLength ? (
                  <div className={css.verticalAlignCenter}>
                    <a href="#" style={{ color: "#346bf6" }}>{reviewsLength} Reviews</a>
                  </div>
                ) : null}
              </div>)} */}
          </div>
          <div className={css.headerExpertDetailsWrapper}>
            <div className={css.headerAddressDetailsWrapper1}>
              {/* <div className={css.imgWrap}>
                {' '}
                <img src={map_Icon} alt="map_Icon" />
              </div> */}
              <span className={css.topHeading}>{currentLocation}</span>
            </div>
            <div
              className={css.topSubHeading3}
            >{`Local Time ${localTime} (UTC ${utcFormatted})`}</div>
            {/* {averageResponse && (
              <div className={css.headerAddressDetailsWrapper2}>
                <span className={css.topSubHeading2}>Average response {averageResponse}</span>
              </div>
            )} */}
          </div>
        </div>
        {/* {isMobile() && (
          <div className={css.topXpertExtra}>
            <div className={css.IdentityWrapper}>
              <img
                src={Identity_Verified_Icon}
                alt="Identity_Verified_Icon"
                width="28px"
                height="28px"
              />
              <span>IDENTITY VERIFIED</span>
            </div>
            {rating ? (
              <div className={css.starContent}>
                <span className={classNames(css.ratingWrapper, { [css.filledRating]: rating })}>
                  <StarRatings
                    rating={rating}
                    starRatedColor="#00bfe6"
                    starEmptyColor="#fff"
                    numberOfStars={1}
                    starDimension="24px"
                    starSpacing="0px"
                  />
                  <span className={css.reviewCount}>{rating}</span>
                  <span className={css.reviewCountWrapper}>({totalRating})</span>
                </span>
              </div>
            ) : null}
            {reviewsLength ? (
              <div className={css.verticalAlignCenter}>
                <a href="#" style={{ color: "#346bf6" }}>{reviewsLength} Reviews</a>
              </div>
            ) : null}
          </div>)} */}
      </div>
      {/* <div className={css.shareBtnWrapper}>
        <ShareButton
          shareData={{
            url: shareUrl,
            title: title,
          }}
          viewport={viewport}
          className={css.shareButton}
          />
          <div className={css.likeButton}>
          <div className={css.favButtonWrapper}>
          {!isFavourite ? (
            <FavoriteBorderIcon
            onClick={() => addToFavourites('add')}
            className={css.favButtonNotSelected}
            />
            ) : (
              <FavoriteIcon
              onClick={() => addToFavourites('remove')}
              className={css.favButtonSelected}
              />
              )}
              </div>
              </div>
            </div> */}
    </div>
  );
};

export default SectionHead;

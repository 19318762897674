/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import moment from 'moment';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { withViewport } from '../../util/contextHelpers';
import axios from 'axios';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensurePrice,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  // Avatar,
  // Button,
  // IconClose,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTimeSlots,
  fetchAllListingReviews,
} from './ListingPage.duck';
import css from './ListingPage.css';
import ActionBarMaybe from 'containers/ListingPage/ActionBarMaybe';
import EnquiryPageContent from 'containers/ListingPage/EnquiryPageContent';
import { LISTING_TYPES } from 'util/constants';
import { GenericMessage } from 'components';
import { getCurrentPageUrl } from 'util/urlHelpers';
import CompanyPageContent from 'containers/ListingPage/CompanyPageContent';
import {
  fetchTransactionLineItems,
  fetchAuthorClasses,
} from 'containers/ListingPage/ListingPage.duck';
import { getRatingFromReviews } from 'containers/ListingPage/helper';
import { pathByRouteName } from 'util/routes';
import Review_Profile from '../../assets/Listing_Page/Review_Profile.png';
import { get } from 'util/api';
import ListingPageCommonSection from './ListingPageCommonSection';
const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const getRemovedWithOutContentReview = reviews => {
  return reviews.filter(review => review?.attributes.content !== config.dummyReviewContent);
};
const getSeatsFromMonthlyTimeSlots = monthlyTimeSlots => {
  const key = moment().format('YYYY-MM');
  const keyNextMonth = moment()
    .add(1, 'month')
    .format('YYYY-MM');
  const res =
    (monthlyTimeSlots[key] &&
      monthlyTimeSlots[key].timeSlots &&
      monthlyTimeSlots[key].timeSlots.length &&
      monthlyTimeSlots[key].timeSlots[0]?.attributes.seats) ||
    (monthlyTimeSlots[keyNextMonth] &&
      monthlyTimeSlots[keyNextMonth].timeSlots &&
      monthlyTimeSlots[keyNextMonth].timeSlots.length &&
      monthlyTimeSlots[keyNextMonth].timeSlots[0]?.attributes.seats) ||
    '1';

  return res;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      rating: 3,
      whatwilldo: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      showPackages: false,
      withPayment: true,
      paymentProofSsUrls: [],
      paymentProofRequiredError: null,
      packageAttr: [],
      creditsRequestSuccessMessage: false,
      creditsRequestFailMessage: false,
      creditRequestInProgress: false,
      isReviewFetch: false,
      openChatBox: false,
      businessAccountInfo: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.handlePackagesModalClose = this.handlePackagesModalClose.bind(this);
    this.handlePackagesModalOpen = this.handlePackagesModalOpen.bind(this);
    this.handlePackageBuy = this.handlePackageBuy.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.onCloseCreditsRequestFailMessage = this.onCloseCreditsRequestFailMessage.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.isReviewFetch && this.props.authorsListingsRefs.length !== 0) {
      this.props.onFetchAllListingReviews(this.props.authorsListingsRefs);
      this.setState({ isReviewFetch: true });
    }
  }

  componentDidMount() {
    if (
      this.props.location.search === '?private' &&
      typeof window !== 'undefined' &&
      !!window.sessionStorage
    ) {
      window.sessionStorage.setItem('privateSiteUrl', this.props.location.pathname);
    }
    const { params, getListing } = this.props;
    const listingId = new UUID(params.id);
    const currentListing = ensureListing(getListing(listingId));
    const id = currentListing.attributes.metadata?.businessId;
    if (id) {
      get('/api/provider-business/get-public', { id })
        .then(resp => {
          const businessListingId = resp.providerBusiness.sharetribeListingId;
          const id = new UUID(businessListingId);
          return sdk.listings
            .show({
              id: id,
              include: ['images'],
              'fields.image': ['variants.scaled-small', 'variants.square-small2x'],
            })
            .then(res => {
              const businessAccountListing = res.data.data;
              const imageIds = businessAccountListing.relationships.images.data.map(
                item => item.id.uuid
              );
              const filteredImages = res.data.included.filter(image =>
                imageIds.includes(image.id.uuid)
              );

              const updatedBusinessAccountListing = {
                ...businessAccountListing,
                images: filteredImages,
                relationships: undefined,
              };

              this.setState(prevState => ({
                ...prevState,
                businessAccountInfo: updatedBusinessAccountListing,
              }));
            });
        })
        .catch(e => console.log(e));
    }
  }

  checkIfUserLoggedIn = () => {
    const { history } = this.props;
    history.push(pathByRouteName('LoginPage', routeConfiguration()));
  };

  onClosecreditsRequestSuccessMessage = () => {
    this.setState({ creditsRequestSuccessMessage: false });
  };

  onCloseCreditsRequestFailMessage = () => {
    this.setState({ creditsRequestFailMessage: false });
  };

  onDrop = files => {
    const url = 'https://api.cloudinary.com/v1_1/movementbuddy/auto/upload';
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('file', file);
      formData.append('upload_preset', 'movementbuddy');

      fetch(url, { method: 'POST', body: formData })
        .then(response => response.text())
        .then(data => {
          this.setState({
            paymentProofSsUrls: [...this.state.paymentProofSsUrls, JSON.parse(data)],
          });
        });
    }
  };
  removeFile = index => e => {
    e.stopPropagation();

    const dt = new DataTransfer();
    const file = this.state.paymentProofSsUrls[index];
    const el = document.getElementById('payment-dropzone');
    for (let i = 0; i < el.files.length; i++) {
      const rowFile = el.files[i];
      let name = rowFile.name.split('.');
      name.splice(name.length - 1, 1);
      name = name.join('');
      if (name !== file.original_filename) dt.items.add(rowFile);
    }
    el.files = dt.files;

    this.state.paymentProofSsUrls.splice(index, 1);
    this.setState({
      paymentProofSsUrls: [...this.state.paymentProofSsUrls],
    });
  };

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
    } = this.props;

    if (!currentUser) {
      this.checkIfUserLoggedIn();
      return;
    }

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, period, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      period: Number(period),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const pageName = values.withPayment === true ? 'CheckoutPage' : 'CheckoutPageWithoutPayment';
    const { setInitialValues } = findRouteByRouteName(pageName, routes);

    const checkoutParams = values.withPayment
      ? { id: listing.id.uuid, slug: createSlug(listing?.attributes?.title) }
      : {
          id: listing.id.uuid,
          slug: createSlug(listing?.attributes?.title),
          credits: values.withCredits.toString(),
        };
    callSetInitialValues(setInitialValues, initialValues);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();
    // Redirect to CheckoutPage

    // const queryParam =
    //   values.payment_method && values.payment_method.length !== 0 ?
    //     { payment_method: values.payment_method.join(",") }
    //     : {}

    values.withCredits
      ? history.push(
          createResourceLocatorString('CheckoutPageWithoutPayment', routes, checkoutParams)
        )
      : history.push(createResourceLocatorString('CheckoutPage', routes, checkoutParams));
  }

  handlePackageSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
    } = this.props;

    if (!currentUser) {
      this.checkIfUserLoggedIn();
      return;
    }

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const initialValues = {
      listing,
      orderData: {
        quantity: 1,
        packageData: values,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    const checkoutParams = { id: listing.id.uuid, slug: createSlug(listing?.attributes?.title) };
    callSetInitialValues(setInitialValues, initialValues);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();
    // Redirect to CheckoutPage

    // const queryParam =
    //   values.payment_method && values.payment_method.length !== 0 ?
    //     { payment_method: values.payment_method.join(",") }
    //     : {}

    history.push(createResourceLocatorString('CheckoutPage', routes, checkoutParams));
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  handlePackagesModalClose() {
    this.setState({
      showPackages: false,
    });
  }

  handlePackagesModalOpen() {
    const { currentUser } = this.props;
    if (!currentUser) {
      this.checkIfUserLoggedIn();
      return;
    }

    this.setState({
      showPackages: true,
    });
  }

  handlePaymentTypeSelect = withPayment => {
    this.setState({
      withPayment,
    });
  };

  handlePackageBuy(packageAttr, withPayment, callBack) {
    if (
      !withPayment &&
      (!packageAttr.student_name ||
        (this.state.paymentProofSsUrls.length <= 0 && !packageAttr.membership_card_number))
    ) {
      this.setState({
        paymentProofRequiredError: <FormattedMessage id="ListingPage.paymentProofRequiredError" />,
      });
      return;
    } else if (withPayment === true && packageAttr.student_name === undefined) {
      this.setState({
        paymentProofRequiredError: <FormattedMessage id="ListingPage.paymentProofRequiredError" />,
      });
      return;
    }

    this.setState({
      creditRequestInProgress: true,
    });

    this.stripe = typeof window !== 'undefined' && window.Stripe(config.stripe.publishableKey);
    const { currentUser, params, getListing } = this.props;
    const { paymentProofSsUrls } = this.state;

    const listingId = new UUID(params.id);
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant ? null : ensureListing(getListing(listingId));

    const authorAvailable = currentListing && currentListing.author;
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const url =
      withPayment === true
        ? config.serverBaseUrl + config.paymentIntentsEndPoint
        : config.serverBaseUrl + config.creditRequestEndPoint;
    const packagePaymentParams =
      withPayment === true
        ? {
            payment_params: {
              ...packageAttr,
              buyer: currentUser,
              seller: ensuredAuthor,
              currency: config.currency,
              listing_id: listingId.uuid,
              listing_title: currentListing?.attributes?.title,
              payment_type: 'online_payment',
            },
          }
        : {
            credit_request_params: {
              ...packageAttr,
              buyer_id: currentUser.id.uuid,
              seller_id: ensuredAuthor.id.uuid,
              currency: config.currency,
              listing_id: listingId.uuid,
              payment_proof_ss_urls: paymentProofSsUrls.map(a => a.secure_url),
              listing_title: currentListing?.attributes?.title,
              author_name: currentListing?.author?.attributes?.profile?.displayName,
              payment_type: 'bank_payment',
            },
          };
    const headers = {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
    };

    this.setState({
      packageAttr: packageAttr,
    });
    axios
      .post(url, packagePaymentParams, headers)
      .then(response => {
        if (withPayment === true) {
          this.stripe
            .redirectToCheckout({
              // Make the id field from the Checkout Session creation API response
              // available to this file, so you can provide it as parameter here
              // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
              sessionId: response.data,
            })
            .then(function(result) {
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
              if (!result.error.message) {
                callBack();
              }
            });
        } else {
          this.setState({
            creditsRequestSuccessMessage: true,
            creditsRequestFailMessage: false,
            // showPackages: false,
            creditRequestInProgress: false,
          });
          callBack();
          // closeBookModal(history, location);
        }
      })
      .catch(error => {
        this.setState({
          creditsRequestFailMessage: true,
          creditsRequestSuccessMessage: false,
          showPackages: false,
          creditRequestInProgress: false,
        });
        // closeBookModal(history, location);
      });
  }

  actionBarRouteName = listingType => {
    switch (listingType) {
      case LISTING_TYPES.LISTING:
        return 'EditListingPage';
      case LISTING_TYPES.CLASS:
        return 'EditClassPage';
      case LISTING_TYPES.FACILITY:
        return 'EditFacilityPage';
      case LISTING_TYPES.PRODUCT:
        return 'EditProductPage';
      default:
        return 'EditListingPage';
    }
  };

  bookingTitleId = listingType => {
    switch (listingType) {
      case LISTING_TYPES.PRODUCT:
        return 'ListingPage.productTitle';
      case LISTING_TYPES.FACILITY:
        return 'ListingPage.facilityTitle';
      case LISTING_TYPES.LISTING:
      default:
        return 'ListingPage.bookingTitle';
    }
  };

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      sportsConfig,
      activityLevelConfig,
      treatmentRoomOptionsConfig,
      viewport,
      getAllAuthorsListingsExceptCurrent,
      fetchAuthorClasses,
      authorClasses,
      authorClassesIsLoading,
      authorsProductListings,
      listingAllReviews,
      allClassListingReviews,
      authorsCompanyListingDetails,
    } = this.props;

    const businessAccountInfo = this.state.businessAccountInfo;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const otherAuthorsListings = getAllAuthorsListingsExceptCurrent(listingId);
    const listingVariant = currentListing?.attributes?.publicData.listing_type;
    const isProduct = listingVariant === LISTING_TYPES.PRODUCT;
    const shareUrl = getCurrentPageUrl();

    const listingSlug = rawParams.slug || createSlug(currentListing?.attributes?.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    // const listingTab = isDraftVariant ? 'photos' : 'overview';
    const listingTab = 'overview';

    const isApproved =
      currentListing.id && currentListing?.attributes?.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // const hasListingState = !!currentListing.attributes.state;
    // const isClosed = hasListingState && currentListing.attributes.state === LISTING_STATE_CLOSED;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }
    const {
      description = '',
      price = null,
      title = '',
      publicData,
      metadata,
    } = currentListing?.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id={this.bookingTitleId(listingVariant)} values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer mobileRootClassName={css.mobileTopbar} />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
    const authorDateOfJoin = moment(ensuredAuthor?.attributes?.createdAt).format('YYYY');
    const currentPrice = ensurePrice(price);
    const { formattedPrice, priceTitle } = priceData(currentPrice, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing?.attributes?.state === LISTING_STATE_CLOSED;
      if ((isOwnListing || isCurrentlyClosed) && typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const handlePackagePurchase = values => {
      const isCurrentlyClosed = currentListing?.attributes?.state === LISTING_STATE_CLOSED;
      if ((isOwnListing || isCurrentlyClosed) && typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      } else {
        this.handlePackageSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image?.attributes?.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image?.attributes?.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const sportsOptions = sportsConfig
      ? sportsConfig.filter(option => publicData.sports.includes(option.key)).map(s => s.label)
      : null;

    const activity_levels =
      activityLevelConfig && publicData.activity_levels && publicData.activity_levels[0]
        ? activityLevelConfig
            .filter(al => publicData.activity_levels.includes(al.key))
            .map(al => al.label)
            .join(', ')
        : '';
    const treatment_room =
      treatmentRoomOptionsConfig && publicData.treatment_room
        ? treatmentRoomOptionsConfig.find(al => publicData.treatment_room.includes(al.key)).label
        : '';
    // const delivery_options =
    //   deliveryOptionsConfig && publicData.delivery_options
    //     ? deliveryOptionsConfig.find(al => publicData.deliveryOptions.includes(al.key)).label
    //     : '';

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    const { packages } = currentListing?.attributes?.publicData;
    const locationSearch = new URLSearchParams(location.search);
    const sessions_bought = locationSearch.get('sessions_bought');
    const {
      creditsRequestSuccessMessage,
      creditsRequestFailMessage,
      creditRequestInProgress,
    } = this.state;
    const seats = getSeatsFromMonthlyTimeSlots(monthlyTimeSlots);

    const totalRating = getRatingFromReviews(reviews);
    const hostLanguageArray =
      publicData.hosted_in && Array.isArray(publicData.hosted_in)
        ? publicData.hosted_in.map(lang => {
            return lang.split('_')[1];
          })
        : publicData.hosted_in;

    let hostLanguages =
      hostLanguageArray && Array.isArray(hostLanguageArray) ? '' : hostLanguageArray;
    hostLanguageArray &&
      Array.isArray(hostLanguageArray) &&
      hostLanguageArray.forEach((lang, index) => {
        hostLanguages += lang;
        if (hostLanguageArray.length !== 1 && hostLanguageArray.length - 2 === index) {
          hostLanguages += ' and ';
        } else if (hostLanguageArray.length !== 1 && hostLanguageArray.length - 1 !== index) {
          hostLanguages += ', ';
        }
      });

    const URL = 'https://wa.me';
    const authorPublicData = ensuredAuthor?.attributes?.profile?.publicData || {};
    const contactNumber = authorPublicData.phoneNumber || '';
    const number = contactNumber && contactNumber.replace(/[^\w\s]/gi, '').replace(/ /g, '');
    const url = `${URL}/${number}`;

    const onClickChatIconHandler = () => {
      this.setState({ openChatBox: true });
    };

    const onCloseChatBoxHandler = () => {
      this.setState({ openChatBox: false });
    };

    const listingDisplayImages = currentListing.images;
    const listingBannerImage = listingDisplayImages.splice(0, 1);
    const featureImages = listingDisplayImages.splice(0, 2);
    const extraImages = listingDisplayImages;
    // const imgVariants = ['scaled-medium'];

    const listingVideos = currentListing.attributes.publicData.listingVideos;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        // isBottomMenuRequire={listingVariant === LISTING_TYPES.COMPANY ? true : false}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        {listingVariant === LISTING_TYPES.COMPANY ? (
          <CompanyPageContent
            intl={intl}
            topbar={topbar}
            isOwnListing={isOwnListing}
            currentListing={currentListing}
            currentUser={currentUser}
            listingId={listingId}
            listingSlug={listingSlug}
            listingType={listingType}
            // listingTab={listingTab}
            listingTab="description"
            richTitle={richTitle}
            authorDisplayName={authorDisplayName}
            onManageDisableScrolling={onManageDisableScrolling}
            isAuthenticated={isAuthenticated}
            title={title}
            sendEnquiryInProgress={sendEnquiryInProgress}
            sendEnquiryError={sendEnquiryError}
            onSubmitEnquiry={this.onSubmitEnquiry}
            enquiryModalOpen={this.state.enquiryModalOpen}
            setEnquiryModalOpen={value => this.setState({ enquiryModalOpen: value })}
            showContactUser={showContactUser}
            onContactUser={this.onContactUser}
            ensuredAuthor={ensuredAuthor}
            otherAuthorsListings={otherAuthorsListings}
            authorsProductListings={authorsProductListings}
            fetchAuthorClasses={fetchAuthorClasses}
            authorClasses={authorClasses}
            authorClassesIsLoading={authorClassesIsLoading}
            allListingReviews={listingAllReviews}
            allClassListingReviews={allClassListingReviews}
            reviews={reviews}
            viewport={viewport}
            authorDateOfJoin={authorDateOfJoin}
            listingVariant={listingVariant}
          />
        ) : listingVariant === LISTING_TYPES.ENQUIRY ? (
          <EnquiryPageContent
            topbar={topbar}
            isOwnListing={isOwnListing}
            currentListing={currentListing}
            listingId={listingId}
            listingSlug={listingSlug}
            listingType={listingType}
            listingTab={listingTab}
            richTitle={richTitle}
            onManageDisableScrolling={onManageDisableScrolling}
            isAuthenticated={isAuthenticated}
            onSubmitEnquiry={this.onSubmitEnquiry}
            title={title}
            sendEnquiryInProgress={sendEnquiryInProgress}
            sendEnquiryError={sendEnquiryError}
            enquiryModalOpen={this.state.enquiryModalOpen}
            setEnquiryModalOpen={value => this.setState({ enquiryModalOpen: value })}
            showContactUser={showContactUser}
            onContactUser={this.onContactUser}
            ensuredAuthor={ensuredAuthor}
            listingVariant={listingVariant}
          />
        ) : (
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <div className={css.actionBarWrapperContainer}>
                <div className={css.actionBarWrapper}>
                  <ActionBarMaybe
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    routeName={this.actionBarRouteName(listingVariant)}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingVariant === LISTING_TYPES.PRODUCT ? 'details' : listingTab,
                    }}
                  />
                </div>
                <div>
                  {sessions_bought === 'true' ? (
                    <GenericMessage
                      message={<FormattedMessage id="ListingPage.sessionsBoughtSuccessMessage" />}
                      show={true}
                    />
                  ) : sessions_bought === 'false' ? (
                    <GenericMessage
                      message={<FormattedMessage id="ListingPage.sessionsBoughtFailureMessage" />}
                      show={true}
                    />
                  ) : null}
                </div>

                <GenericMessage
                  message={<FormattedMessage id="ListingPage.creditsRequestSuccessMessage" />}
                  show={creditsRequestSuccessMessage}
                  onClose={this.onClosecreditsRequestSuccessMessage}
                />

                <GenericMessage
                  message={<FormattedMessage id="ListingPage.creditsRequestFailMessage" />}
                  show={creditsRequestFailMessage}
                  onClose={this.onCloseCreditsRequestFailMessage}
                />
                <ListingPageCommonSection
                  currentListing={currentListing}
                  listingBannerImage={listingBannerImage[0]}
                  featureImages={featureImages}
                  extraImages={extraImages}
                  title={title}
                  viewport={viewport}
                  currentUser={currentUser}
                  publicData={publicData}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  intl={intl}
                  handleBookingSubmit={handleBookingSubmit}
                  handlePackagePurchase={handlePackagePurchase}
                  bookingTitle={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  metadata={metadata}
                  reviews={reviews}
                  listingVariant={listingVariant}
                  otherAuthorsListings={otherAuthorsListings}
                  handlePackagesModalOpen={this.handlePackagesModalOpen}
                  getRemovedWithOutContentReview={getRemovedWithOutContentReview}
                  isProduct={isProduct}
                  businessAccountInfo={businessAccountInfo}
                  isDraftView={false}
                />
              </div>

              <Modal
                id="ListingPage.enquiry"
                contentClassName={css.enquiryModalContent}
                isOpen={isAuthenticated && this.state.enquiryModalOpen}
                onClose={() => this.setState({ enquiryModalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <EnquiryForm
                  className={css.enquiryForm}
                  submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                  listingTitle={
                    <FormattedMessage id="EnquiryForm.heading" values={{ listingTitle: title }} />
                  }
                  authorDisplayName={authorDisplayName}
                  sendEnquiryError={sendEnquiryError}
                  onSubmit={this.onSubmitEnquiry}
                  inProgress={sendEnquiryInProgress}
                />
              </Modal>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer className={css.listingFooter} />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        )}
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  sportsConfig: config.custom.sports,
  activityLevelConfig: config.custom.activityLevel,
  treatmentRoomOptionsConfig: config.custom.treatmentRoomOptions,
  deliveryOptionsConfig: config.custom.deliveryOptions,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  sportsConfig: array,
  activityLevelConfig: array,
  treatmentRoomOptionsConfig: array,
  deliveryOptionsConfig: array,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpenForListingId,
    authorsListingsRefs,
    amountOfBookings,
    authorClassesIsLoading,
    authorClassesRefs,
    authorsProductListingsRefs,
    listingAllReviews,
    allClassListingReviews,
    authorsCompanyListingDetails,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getAllAuthorsListingsExceptCurrent = id => {
    return getMarketplaceEntities(state, authorsListingsRefs).filter(
      listing => listing.id.uuid !== id.uuid
    );
  };

  const authorClasses = getMarketplaceEntities(state, authorClassesRefs);

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    getAllAuthorsListingsExceptCurrent,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    amountOfBookings,
    authorClasses,
    authorClassesIsLoading,
    authorsListingsRefs,
    authorsProductListings: getMarketplaceEntities(state, authorsProductListingsRefs),
    listingAllReviews,
    allClassListingReviews,
    authorsCompanyListingDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  fetchAuthorClasses: (authorId, dates) => dispatch(fetchAuthorClasses(authorId, dates)),
  onFetchAllListingReviews: listingRef => dispatch(fetchAllListingReviews(listingRef)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;

import React from 'react';
import PropTypes, { oneOf } from 'prop-types';
import { propTypes } from 'util/types';
import {
  InboxItem,
  InboxEnquireItem,
} from 'components';
import { intlShape } from 'util/reactIntl';

function InboxItemWithButtons(props) {
  return (
    <>
      {props.isEnquiryTab ? (
        <InboxEnquireItem
          type={props.type}
          tx={props.tx}
          intl={props.intl}
          onEnquireMessageModalOpen={props.onEnquireMessageModalOpen}
        />
      ) : (
        <InboxItem
          type={props.type}
          tx={props.tx}
          intl={props.intl}
          stateData={props.stateData}
          currentUser={props.currentUser}
        />
      )}
    </>
  );
}

InboxItemWithButtons.propTypes = {
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  stateData: PropTypes.object,
};

export default InboxItemWithButtons;

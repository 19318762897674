import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './MarketplaceDevelopmentPage.css';

const SectionGetDoneInWeeks = props => {

    return (
        <div className={css.eBookSection}>
            <div className={css.customerStoriesTop}>
                <h3 className={css.customerStoriesTitle}><FormattedMessage id={'MarketplaceDevelopmentPage.getDoneIn4To12Weeks'} /></h3>
            </div>
            <div className={css.customerStoriesContainer}>
                <div className={css.customerStoriesItem}>
                    <div className={css.eBookImageContainer}>
                        <img src={'/static/images/MarketplaceDevelopmentPage/getDone1.png'} alt="Get done in 4-12 weeks" />
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.friendlyService'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.ILoveBuilding'} /></p>
                </div>
                <div className={css.customerStoriesItem}>
                    <div className={css.eBookImageContainer}>
                        <img src={'/static/images/MarketplaceDevelopmentPage/getDone2.png'} alt="Get done in 4-12 weeks" />
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.withCustomersHeart'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.weHaveOver1300UsersCurrently'} /></p>
                </div>
                <div className={css.customerStoriesItem}>
                    <div className={css.eBookImageContainer}>
                        <img src={'/static/images/MarketplaceDevelopmentPage/getDone3.png'} alt="Get done in 4-12 weeks" />
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.getReadyWorkFromAnywhere'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.wantToBuildMarketplace'} /></p>
                </div>
            </div>
        </div>
    );
}

export default SectionGetDoneInWeeks;
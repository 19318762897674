import unionWith from 'lodash/unionWith';
import { uniqBy } from 'lodash';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToTz, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import config from '../../config';
import { LISTING_TYPES } from 'util/constants';
import axios from 'axios';
import omit from 'lodash/omit';
import qs from 'qs';
import { ensureCURLListings } from 'util/data';
import { deepLocationSearchMaybe } from './utils';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_OTHER_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_OTHER_LISTINGS_SUCCESS';

export const SEARCH_CLASS_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_CLASS_LISTINGS_REQUEST';
export const SEARCH_CLASS_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_CLASS_LISTINGS_SUCCESS';
export const SEARCH_CLASS_LISTINGS_ERROR = 'app/SearchPage/SEARCH_CLASS_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FETCH_ALL_LISTING_REVIEWS = 'app/SearchPage/FETCH_ALL_LISTING_REVIEWS';

export const All_AUTHOR_NO_OF_LISTING_TYPE = 'app/SearchPage/All_AUTHOR_NO_OF_LISTING_TYPE';

export const UPDATE_COMPANY_SATUS_COMPLETED = 'app/SearchPage/UPDATE_COMPANY_SATUS_COMPLETED';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  otherCurrentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  listingAllReviews: {},
  allAuthorNumberOfListings: {},
  companyStatusCompleted: false,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        currentPageResultIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_OTHER_LISTINGS_SUCCESS:
      return {
        ...state,
        otherCurrentPageResultIds: resultIds(payload.data),
        // pagination: payload.data.meta,
        searchInProgress: false,
      };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    // reviews
    case FETCH_ALL_LISTING_REVIEWS:
      return { ...state, listingAllReviews: payload };

    case All_AUTHOR_NO_OF_LISTING_TYPE:
      return { ...state, allAuthorNumberOfListings: payload };

    case UPDATE_COMPANY_SATUS_COMPLETED:
      return { ...state, companyStatusCompleted: true };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const updateCompanyStatusCompleted = () => ({
  type: UPDATE_COMPANY_SATUS_COMPLETED,
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchOtherListingsSuccess = response => ({
  type: SEARCH_OTHER_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const getBounds = listingParams => {
  if (!listingParams.bounds) return undefined;

  const { ne, sw } = listingParams.bounds;
  const { lat: nelat, lng: nelng } = ne;
  const { lat: swlat, lng: swlng } = sw;

  return `${nelat},${nelng},${swlat},${swlng}`;
};

export const allListingReviews = reviewData => ({
  type: FETCH_ALL_LISTING_REVIEWS,
  payload: reviewData,
});

export const actionAllAuthorNumberOfListings = e => ({
  type: All_AUTHOR_NO_OF_LISTING_TYPE,
  payload: e,
});

const searchClassListings = listingParams => async (dispatch, getState, sdk) => {
  try {
    const boundsString = getBounds(listingParams);

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
      params: {
        ...omit(listingParams, [
          'fields.listing',
          'fields.user',
          'fields.image',
          'include',
          'per_page',
        ]),
        bounds: boundsString,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    const ensureResponse = ensureCURLListings(response);
    dispatch(addMarketplaceEntities(ensureResponse));
    dispatch(searchListingsSuccess(ensureResponse));
    return ensureResponse;
  } catch (e) {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  }
};

export const fetchAllAuthorNumberOfListType = response => (dispatch, getState, sdk) => {
  if (!response.length > 0) {
    return;
  }
  const authorIds = response.map(listing => {
    return listing.relationships.author.data.id;
  });

  const uniqueAuthorIds = uniqBy(authorIds, function(authorId) {
    return authorId.uuid;
  });

  let allAuthorNumberOfListings = {};

  uniqueAuthorIds.forEach((authorId, index) => {
    sdk.listings
      .query({
        author_id: authorId,
        include: ['author'],
      })
      .then(response => {
        const authorListings = response.data.data;
        let pro = 0;
        let rental = 0;
        let classes = 0;
        authorListings.forEach(listing => {
          const listingType = listing.attributes.publicData.listing_type;
          if (listingType === LISTING_TYPES.LISTING) {
            pro += 1;
          } else if (listingType === LISTING_TYPES.FACILITY) {
            rental += 1;
          } else if (listingType === LISTING_TYPES.CLASS) {
            classes += 1;
          }
        });
        if (pro !== 0 || classes !== 0 || rental !== 0) {
          allAuthorNumberOfListings[authorId.uuid] = { pro, rental, classes };
        }

        if (index === uniqueAuthorIds.length - 1) {
          dispatch(actionAllAuthorNumberOfListings(allAuthorNumberOfListings));
        }
      })
      .catch(e => console.error(e));
  });
};

export const updateLatestCompanyStatus = () => async (dispatch, getState, sdk) => {
  const state = getState();
  const currentUserId = state.user.currentUser ? state.user.currentUser.id.uuid : '';
  const statusCompleted = state.SearchPage.companyStatusCompleted;
  if (currentUserId === '' || statusCompleted === true) return;

  dispatch(updateCompanyStatusCompleted());
  try {
    const responseCompanyListings = await sdk.listings.query({
      authorId: currentUserId,
      pub_listing_type: 'company',
      include: ['author'],
    });
    responseCompanyListings.data.data.forEach(listing => {
      dispatch(updateCompanyStatus(listing.id.uuid, currentUserId));
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateCompanyStatus = (listingId, currentUserId) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const responseListings = await sdk.listings.query({
      authorId: currentUserId,
      pub_listing_type: ['listing', 'class', 'facility'],
      include: ['author'],
    });
    const owningListing = responseListings.data.data;
    let total_rating = 0;
    let total_booking = 0;
    let countTotalReviewers = 0;
    owningListing.forEach(listing => {
      const publicData = listing.attributes.publicData;
      countTotalReviewers +=
        publicData && publicData.total_reviewers ? publicData.total_reviewers : 0;
      total_rating += publicData && publicData.total_ratings ? publicData.total_ratings : 0;
      total_booking += publicData && publicData.total_bookings ? publicData.total_bookings : 0;
    });
    const rating = total_rating / countTotalReviewers;
    const totalRating = total_rating;
    const params = {
      id: listingId,
      publicData: {
        total_bookings: total_booking,
        rating: isNaN(rating) ? 0 : rating,
        total_reviewers: countTotalReviewers,
        total_ratings: totalRating,
      },
    };
    await sdk.ownListings.update(params);
  } catch (e) {
    console.error(e);
  }
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  dispatch(updateLatestCompanyStatus());

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const availabilityParams = (datesParam, minDurationParam, timesParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const timeValues = timesParam ? timesParam.split(',') : [];
    const hasTimeValues = timeValues && timeValues.length === 2;
    const startDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[0]} ${timeValues[0]}`
        : hasDateValues
        ? dateValues[0]
        : null; //"2020-06-12 12:00"//;
    const endDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[1]} ${timeValues[1]}`
        : hasDateValues
        ? dateValues[1]
        : null; //"2020-06-12 13:00"//;

    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};

    const defaultTimeZone = () =>
      typeof window !== 'undefined'
        ? getDefaultTimeZoneOnBrowser()
        : config.custom.dateRangeLengthFilterConfig.searchTimeZone;

    const timeZone = defaultTimeZone();
    return hasDateValues
      ? {
          start: formatDateStringToTz(startDate, timeZone),
          end: formatDateStringToTz(endDate, timeZone),

          // When we have `time-partial` value in the availability, the
          // API returns listings that don't necessarily have the full
          // start->end range available, but enough that the minDuration
          // (in minutes) can be fulfilled.
          //
          // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering

          availability: 'time-partial',
          ...minDurationMaybe,
        }
      : {};
  };

  const { perPage, price, dates, minDuration, times, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const availabilityMaybe = availabilityParams(dates, minDuration, times);

  const params = {
    ...rest,
    ...priceMaybe,
    ...availabilityMaybe,
    per_page: perPage,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.square-small2x',
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  if (params.pub_listing_type === LISTING_TYPES.CLASS) {
    return dispatch(searchClassListings(params));
  }

  // deep location search maybe

  return deepLocationSearchMaybe(sdk, params)
    .then(deepLocationSearchResults => {
      const deepLocationSearchData = deepLocationSearchResults?.data;
      const deepLocationSearchIncluded = deepLocationSearchResults?.included;

      return sdk.listings
        .query(params)
        .then(response => {
          //add combined results if there's any
          if (!!deepLocationSearchData && deepLocationSearchData?.length > 0) {
            const uniqueResults = deepLocationSearchData.filter(
              l => !response?.data?.data?.find(x => x?.id?.uuid === l?.id?.uuid)
            );

            const finalResponseData = [...response?.data?.data, ...uniqueResults];
            const defaultResponseIncluded = response?.data?.included || [];
            const finalResponse = {
              ...response,
              data: {
                data: finalResponseData,
                included: [...defaultResponseIncluded, ...deepLocationSearchIncluded],
                meta: {
                  ...response?.data?.data?.meta,
                  totalItems: finalResponseData?.length,
                },
              },
            };

            const listingsLimitInResponse = finalResponse.data.data.length < 6;

            dispatch(addMarketplaceEntities(finalResponse));
            dispatch(searchListingsSuccess(finalResponse));
            if (params.pub_listing_type === LISTING_TYPES.COMPANY) {
              dispatch(fetchAllAuthorNumberOfListType(finalResponse.data.data));
            }

            if (listingsLimitInResponse) {
              const otherExpertsParams = {
                pub_listing_type: 'listing',
                per_page: perPage,
                page: 1,
                include: ['author', 'author.profileImage', 'images'],
                'fields.image': [
                  'variants.scaled-small',
                  'variants.square-small2x',
                  // Avatars
                  'variants.square-small',
                  'variants.square-small2x',
                ],
              };
              return sdk.listings
                .query(otherExpertsParams)
                .then(res => {
                  dispatch(addMarketplaceEntities(res));
                  dispatch(searchOtherListingsSuccess(res));
                })
                .catch(e => {
                  // dispatch(searchListingsError(storableError(e)));
                  throw e;
                });
            }

            return finalResponse;
          }

          const listingsLimitInResponse = response.data.data.length < 6;
          dispatch(addMarketplaceEntities(response));
          dispatch(searchListingsSuccess(response));
          if (params.pub_listing_type === LISTING_TYPES.COMPANY) {
            dispatch(fetchAllAuthorNumberOfListType(response.data.data));
          }

          if (listingsLimitInResponse) {
            const otherExpertsParams = {
              pub_listing_type: 'listing',
              per_page: perPage,
              page: 1,
              include: ['author', 'author.profileImage', 'images'],
              'fields.image': [
                'variants.scaled-small',
                'variants.square-small2x',
                // Avatars
                'variants.square-small',
                'variants.square-small2x',
              ],
            };
            return sdk.listings
              .query(otherExpertsParams)
              .then(res => {
                dispatch(addMarketplaceEntities(res));
                dispatch(searchOtherListingsSuccess(res));
              })
              .catch(e => {
                // dispatch(searchListingsError(storableError(e)));
                throw e;
              });
          }

          return response;
        })
        .catch(e => {
          dispatch(searchListingsError(storableError(e)));
          throw e;
        });
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const fetchAllListingReviews = listingRef => (dispatch, getState, sdk) => {
  let listingReview = {};
  listingRef.forEach(async (ref, index) => {
    try {
      const resp = await sdk.reviews.query({
        listing_id: ref,
        type: 'ofProvider',
        state: 'public',
      });
      if (resp.data.data.length !== 0) {
        listingReview[ref.uuid] = resp.data.data;
      }
    } catch (error) {
      console.log(error);
    }
    if (index === listingRef.length - 1) {
      dispatch(allListingReviews(listingReview));
    }
  });
};

import React from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import { AvatarLarge, Button } from 'components';
import Basic_List_Icon1 from '../../../assets/Listing_Page/edit.svg';
import Basic_List_Icon2 from '../../../assets/Listing_Page/users.svg';
import Basic_List_Icon3 from '../../../assets/Listing_Page/rotate-cw.svg';
import Basic_List_Icon4 from '../../../assets/Listing_Page/message-square.svg';
// import Basic_Avtar from '../../../assets/Listing_Page/Basic_Avtar.png';
import { convertMoneyToNumber } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
// import Triangle from '../../../assets/Listing_Page/Triangle.png';

const { Money } = sdkTypes;

const SubTabSectionDetailsContent = (props) => {

  const { avatarDetails, currentListing, intl, tabName, tabIndex, handlePackagePurchase, isDraftView, ...rest } = props;
  const { publicData } = currentListing.attributes;
  const totalItineraryItems = (publicData?.packages[tabIndex]?.items) || 3;
  const totalParticipants = (publicData?.packages[tabIndex]?.participants) || 2;
  const deliveryHours = (publicData?.packages[tabIndex]?.deliveryTime) || 42;
  const collaborationHours = (publicData?.packages[tabIndex]?.collaborationTime) || 24;
  const listingCurrency = publicData.base_currency || 'USD';
  const packagePrice = publicData?.packages[tabIndex]?.price;
  const price = packagePrice ? new Money(packagePrice.amount, packagePrice.currency) : null;
  const priceInNumber = price ? convertMoneyToNumber(price) : '15';


  const BasicItemList = [
    {
      id: 'list1',
      title: `Up to ${totalItineraryItems} itinerary items`,
      src: Basic_List_Icon1,
    },
    {
      id: 'list2',
      title: `Up to ${totalParticipants} participants`,
      src: Basic_List_Icon2,
    },
    {
      id: 'list3',
      title: `${deliveryHours}hr delivery`,
      src: Basic_List_Icon3,
    },
    {
      id: 'list4',
      title: `${collaborationHours}hr collaboration`,
      src: Basic_List_Icon4,
    },
  ];

  return (
    <div className={css.subTabSectionContainer}>
      <div>
        <h2>PRICING</h2>
        <p>
          The Trip Expert request will gather general ideas such as where, when, who with, budget,
          preferences and what you would like included. I will then research and craft a
          personalised itinerary, delivered online through the Lyfshort App within the quoted
          delivery time. Following delivery you have collaboration time to request adjustments and
          modifications using messaging. The service will finish when you aknowledge completion or
          the collaboration window expires.
        </p>

        <div className={css.userInformation}>
          <div className={css.userInformationWrap}>
            <div className={css.userListItem}>
              {BasicItemList.map(list => (
                <li key={list.id}>
                  <img src={list.src} alt={list.id} />
                  <h5>{list.title}</h5>
                </li>
              ))}
            </div>
            <div className={css.authorDetailsImageWrapper}>
              <div className={css.authorDetailsImageRelative}>
                <AvatarLarge
                  className={css.authorDetailsImageLarge}
                  user={avatarDetails}
                  disableProfileLink
                />
              </div>
              <div className={css.supContent}>
                {listingCurrency}
                <span>{`$${priceInNumber}*`}</span>
              </div>
            </div>
          </div>
          <span className={css.bottomText}>
            *Pricing is displayed without taxes which may be applicable at checkout depending on
            purchase region.
          </span>
        </div>

        <div className={css.subTabBtnWrap}>
          <Button
            rootClassName={css.sendRequestButton}
            onClick={(e) => !isDraftView ? handlePackagePurchase({ ...publicData?.packages[tabIndex], price, packageLable: tabName }) : e.preventDefault()}
          > Send Request </Button>
        </div>
      </div>
    </div>
  );
};

export default SubTabSectionDetailsContent;

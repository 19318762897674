import React, { Component } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { compose } from 'redux';
import { connect } from 'react-redux';
import unionWith from 'lodash/unionWith';
import { TopbarContainer } from 'containers/index';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { withRouter } from 'react-router-dom';
import { searchListings, searchMapListings, setActiveListing } from './JobsBoardPage.duck';
import { propTypes } from 'util/types';
// import IconMap from '../../components/IconMap/IconMap';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Page,
  SearchMap,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSearch,
  Button,
  NamedLink,
  GenericMessage,
  JobsPageHeader,
  JobsCard,
} from 'components';
import { parse, stringify } from '../../util/urlHelpers';
import routeConfiguration from 'routeConfiguration';
import config from '../../config';
import { createResourceLocatorString, pathByRouteName } from 'util/routes';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  validURLParamsForExtendedData,
  validFilterParams,
  pickSearchParamsOnly,
} from '../PromoPage/PromoPage.helpers';
import MainPanel from './MainPanel';
import css from './JobsBoardPage.css';

const RESULT_PAGE_SIZE = 24;
const MODAL_BREAKPOINT = 768;
const DRAG_LAYOUT_BREAKPOINT = 1024;
const SEARCH_WITH_MAP_DEBOUNCE = 300;

const LISTING_DRAG_MAP_SHOW_OFFSET = 550;
const LISTING_DRAG_LIMIT_OFFSET = 80;

export const Filter_Type = [
  {
    key: 'type_of_listing',
    label: 'Type of Listing',
  },
];

export class JobsBoardPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listingDrawerPos: { x: 0, y: this.isMobileLayout() ? 250 : 0 },
      isMapIconVisible: false,
      isMapOpen: false,
      selectedFiltersType: 'type_of_listing',
      keyWordValue: '',
      showBecomeHostMessage: false,
    };

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
  }

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('scroll', this.scrollFunction);
    const urlQueryParams = parse(this.props.location.search);
    if (urlQueryParams && urlQueryParams.keywords && this.state.keyWordValue === '') {
      this.setState({ keyWordValue: urlQueryParams.keywords });
    }
  }

  componentDidUpdate() {}

  filters() {
    const {
      categoryConfig,
      subcategoryConfig,
      languageConfig,
      levelConfig,
      priceFilterConfig,
      keywordFilterConfig,
      dateRangeLengthFilterConfig,
      listingTypeConfig,
    } = this.props;

    // Note: "category" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    return {
      typeFilter: {
        paramName: 'pub_listing_type',
        options: listingTypeConfig,
      },
      requestTypeFilter: {
        paramName: 'pub_request_type',
        options: [
          { key: 'part-time', label: 'Part Time' },
          { key: 'full-time', label: 'Full Time' },
        ],
      },
      categoryFilter: {
        paramName: 'pub_category',
        options: categoryConfig.filter(c => !c.hideFromFilters),
      },
      subcategoryFilter: {
        paramName: 'pub_sub_category',
        options: subcategoryConfig.filter(c => !c.hideFromFilters),
      },
      languageFilter: {
        paramName: 'pub_hosted_in',
        options: languageConfig.map(i => ({ ...i, key: `has_any:${i.key}` })),
      },
      levelFilter: {
        paramName: 'meta_level',
        options: levelConfig,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      dateRangeLengthFilter: {
        paramName: 'dates',
        minDurationParamName: 'minDuration',
        timesParamName: 'times',
        config: dateRangeLengthFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
    };
  }

  onClickDraggableHandler = () => {
    if (this.state.isMapOpen === true) {
      this.setState({ listingDrawerPos: { x: 0, y: 250 }, isMapOpen: false });
    }
  };

  onClickShowBecomeHostMessage = () => {
    this.setState({ showBecomeHostMessage: true });
  };

  renderDraggableHandler = isMobileLayout =>
    isMobileLayout ? (
      <div className={css.draggableHandler}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
        >
          <path
            d="M1.61133 7.36719H14.387"
            stroke="#233045"
            strokeWidth="1.68101"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.61133 1.65137H14.387"
            stroke="#233045"
            strokeWidth="1.68101"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ) : null;

  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;
    const { history, location } = this.props;
    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('JobsBoardPage', routes);
    const currentPath = location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...rest,
        ...validFilterParams(rest, this.filters()),
      };

      history.push(createResourceLocatorString('JobsBoardPage', routes, {}, searchParams));
      var mapElement = document.getElementById('search-map');
      mapElement.scrollIntoView();
    }
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onControlledDragStop = (e, position) => {
    const { y } = position;
    this.setState({ listingDrawerPos: { x: 0, y } });
  };
  onControlledDrag = (e, position) => {
    if (typeof window !== 'undefined') {
      const { isMapIconVisible } = this.state;
      const { y } = position;
      const dragLimit = window.innerHeight - LISTING_DRAG_MAP_SHOW_OFFSET;

      if (y > dragLimit && isMapIconVisible) {
        this.setState({ isMapIconVisible: false });
      } else if (y <= dragLimit && !isMapIconVisible) {
        this.setState({ isMapIconVisible: true });
      }
    }
  };

  onMapButtonClick = () => {
    if (typeof window !== 'undefined') {
      const yPos = window.innerHeight - LISTING_DRAG_LIMIT_OFFSET;
      window.scrollTo(0, 760);
      this.setState({
        listingDrawerPos: { x: 0, y: yPos },
        isMapOpen: true,
        isMapIconVisible: false,
      });
    }
  };

  onMapClick = () => {
    if (this.state.isMapOpen === false && typeof window !== 'undefined') {
      const yPos = window.innerHeight - LISTING_DRAG_LIMIT_OFFSET;
      window.scrollTo(0, 780);
      this.setState({ listingDrawerPos: { x: 0, y: yPos }, isMapOpen: true });
    }
  };

  isMobileLayout = () => {
    const isWindowDefined = typeof window !== 'undefined';
    return isWindowDefined && window.innerWidth < DRAG_LAYOUT_BREAKPOINT;
  };

  scrollFunction = () => {
    if (typeof window !== 'undefined') {
      const scrolled = window.scrollY;
      if (scrolled > 780) {
        this.setState({ showConnetPro: true });
      } else {
        this.setState({ showConnetPro: false });
      }
    }
  };

  render() {
    const {
      intl,
      listings,
      location,
      history,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      currentUserHasListings,
      currentUser,
      params,
    } = this.props;

    const filters = this.filters();
    const { listingDrawerPos, showConnetPro } = this.state;
    const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);
    const urlQueryString = stringify(urlQueryParams);
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = this.isMobileLayout();
    const windowHeight = isWindowDefined && window.innerHeight;
    const isFullTime = urlQueryParams && urlQueryParams.pub_request_type === 'full-time';
    const partTimeListing =
      listings &&
      listings.filter(lst => {
        return (
          lst.attributes.publicData.job_request_type === 'part-time' ||
          lst.attributes.publicData.job_request_type === undefined
        );
      });
    const fullTimeListing =
      listings &&
      listings.filter(lst => {
        return lst.attributes.publicData.job_request_type === 'full-time';
      });

    const currentRequestedListing =
      urlQueryParams && urlQueryParams.pub_request_type === 'full-time'
        ? fullTimeListing
        : partTimeListing;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);
    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };
    const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && true);

    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;
    const { bounds, origin } = searchInURL || {};

    const primaryFilters = {
      typeFilter: filters.typeFilter,
      categoryFilter: filters.categoryFilter,
      subcategoryFilter: filters.subcategoryFilter,
      languageFilter: filters.languageFilter,
      levelFilter: filters.levelFilter,
      priceFilter: filters.priceFilter,
      dateRangeLengthFilter: filters.dateRangeLengthFilter,
      keywordFilter: filters.keywordFilter,
    };

    const resultsCount = listings.length;

    // const secondaryFilters = null
    // const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    // const listingsLength = currentRequestedListing ? currentRequestedListing.length : 0;
    // const totalItems =
    //     searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const onClickFilterTypeHandler = value => {
      this.setState({ selectedFiltersType: value });
    };

    const applyButtonText = intl.formatMessage({ id: 'FilterForm.submit' });
    const placeholder = intl.formatMessage({ id: 'SectionSearch.searchByKeyword' });
    const handleKeyword = (urlParam, values) => {
      const queryParams = values
        ? { ...urlQueryParams, [urlParam]: values }
        : omit(urlQueryParams, urlParam);

      history.push(
        createResourceLocatorString('JobsBoardPage', routeConfiguration(), {}, queryParams)
      );
    };
    const filterKeywordContent = (
      <div className={css.filterKeywordWrap}>
        <div className={css.filterWrap}>
          <div className={css.buttonWrap}>
            <IconSearch />
          </div>
          <div className={css.inputWrap}>
            <input
              id={'SearchFilters.keywordFilter'}
              value={this.state.keyWordValue}
              placeholder={placeholder}
              onChange={e => this.setState({ keyWordValue: e.target.value })}
            />
          </div>
          <Button
            className={css.btnapply}
            onClick={() => handleKeyword(filters.keywordFilter.paramName, this.state.keyWordValue)}
          >
            {applyButtonText}
          </Button>
        </div>
      </div>
    );

    const FilterTypeElement = (
      <div className={css.filterTypeButtonWrapper}>
        {Filter_Type.map(ftype => {
          return (
            <div
              key={ftype.key}
              onClick={() => onClickFilterTypeHandler(ftype.key)}
              className={classNames(css.filterTypeButton, {
                [css.selectedFilterTypeButton]: this.state.selectedFiltersType === ftype.key,
              })}
            >
              {ftype.label}
            </div>
          );
        })}
        <div>{filterKeywordContent}</div>
      </div>
    );
    // eslint-disable-next-line
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    return (
      <Page
        title={'Jobs Board'}
        scrollingDisabled={scrollingDisabled}
        // isBottomMenuRequire={false}
        className={css.jobsBoardTopbar}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="JobsBoardPage"
              currentSearchParams={urlQueryParams}
              keywordSearchTopbar={true}
            />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <GenericMessage
              show={this.state.showBecomeHostMessage}
              onClose={() => this.setState({ showBecomeHostMessage: false })}
              message={'Please sign up as a Lyfshort host'}
            />
            {/* <section className={css.topSection}> */}
            <JobsPageHeader
              urlQueryParams={urlQueryParams}
              redirectPageName={'SearchPage'}
              isMobileLayout={isMobileLayout}
              categoryFilter={filters.categoryFilter}
              subcategoryFilter={filters.subcategoryFilter}
              priceFilter={filters.priceFilter}
              languageFilter={filters.languageFilter}
              history={history}
              resultsCount={resultsCount}
            />

            <div className={css.sectionMapContainer}>
              <Draggable
                axis="y"
                handle={`.${css.draggableHandler}`}
                bounds={{ top: 0, bottom: windowHeight - LISTING_DRAG_LIMIT_OFFSET }}
                position={listingDrawerPos}
                onDrag={this.onControlledDrag}
                onStop={this.onControlledDragStop}
                onStart={this.onClickDraggableHandler}
                disabled={!isMobileLayout}
              >
                <div className={css.draggableWrapper}>
                  <div>{this.renderDraggableHandler(isMobileLayout)}</div>
                  <MainPanel
                    urlQueryParams={validQueryParams}
                    sort={sort}
                    listings={currentRequestedListing}
                    params={params}
                    currentUser={currentUser}
                    searchInProgress={searchInProgress}
                    searchListingsError={searchListingsError}
                    // searchParamsAreInSync={searchParamsAreInSync}
                    searchParamsAreInSync={true}
                    onActivateListing={onActivateListing}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onMapIconClick={onMapIconClick}
                    pagination={pagination}
                    searchParamsForPagination={parse(location.search)}
                    showAsModalMaxWidth={MODAL_BREAKPOINT}
                    primaryFilters={primaryFilters}
                    history={history}
                    selectedFiltersType={this.state.selectedFiltersType}
                    FilterTypeElement={FilterTypeElement}
                    onClickFilterTypeHandler={onClickFilterTypeHandler}
                    isMobileLayout={isMobileLayout}
                    currentUserHasListings={currentUserHasListings}
                    onClickShowBecomeHostMessage={this.onClickShowBecomeHostMessage}
                  />
                </div>
              </Draggable>
            </div>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>{!isMobileLayout && <Footer />}</LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

JobsBoardPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'enquiry',
  categoryConfig: config.custom.categories,
  subcategoryConfig: config.custom.subCategoriesFilterOptions,
  languageConfig: config.custom.languageFilterConfig,
  levelConfig: config.custom.levelFilterConfig,
  priceFilterConfig: config.custom.priceFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  dateRangeLengthFilterConfig: config.custom.dateRangeLengthFilterConfig,
  listingTypeConfig: config.custom.listingTypeFilterConfig,
  activeListingId: null,
};

JobsBoardPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'enquiry', 'map']).isRequired,
  categoryConfig: array,
  subcategoryConfig: array,
  languageConfig: array,
  levelConfig: array,
  params: shape({ listingType: string, category: string, subCategory: string }),
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeLengthFilterConfig: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.PromoPage;
  const { currentUser, currentUserHasListings } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUser,
    currentPageResultIds,
    currentUserHasListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

const JobsBoardPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(JobsBoardPageComponent);

JobsBoardPage.loadData = (
  params,
  search = '?address=Singapore%20City%2C%20SG&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395'
) => {
  const queryParamsObj = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const queryParams = {
    ...queryParamsObj,
    pub_listing_type: 'enquiry',
  };
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': [
      'title',
      'createdAt',
      'description',
      'geolocation',
      'price',
      'publicData',
      'metadata',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default JobsBoardPage;

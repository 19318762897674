import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import css from './Topbar.css';

const SearchIconComponent = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);
  if (typeof window === 'undefined') {
    return null;
  }
  return <SearchIcon className={className} />;
};

const { string } = PropTypes;

SearchIconComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchIconComponent.propTypes = {
  className: string,
  rootClassName: string,
};

export default SearchIconComponent;

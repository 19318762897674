import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { array, bool, func, number, object, objectOf, string } from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
// import omit from 'lodash/omit';
import { propTypes } from '../../util/types';
import { SearchFiltersPanel, PaginationLinks } from '../../components';
import JobsBoardResults from './JobsBoardResults/JobsBoardResults';
import ModifiedSearchFilters from './ModifiedSearchFilters/ModifiedSearchFilters';
import css from './JobsBoardPage.css';

class MainPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSearchFiltersPanelOpen: false,
    };
  }

  render() {
    const {
      urlQueryParams,
      sort,
      listings,
      currentUser,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      pagination,
      searchParamsForPagination,
      primaryFilters,
      secondaryFilters,
      selectedFiltersType,
      onClickFilterTypeHandler,
      FilterTypeElement,
      isMobileLayout,
      currentUserHasListings,
      onClickShowBecomeHostMessage,
      history,
    } = this.props;

    const isSearchFiltersPanelOpen = !!secondaryFilters && this.state.isSearchFiltersPanelOpen;
    // const filters = merge(primaryFilters, secondaryFilters);
    // const selectedFilters = validFilterParams(urlQueryParams, filters);

    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering

    // const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    // const listingsLength = listings ? listings.length : 0;
    // const totalItems =
    //   searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const secondaryFilterParamNames = secondaryFilters
      ? Object.values(secondaryFilters).map(f => f.paramName)
      : [];

    const resultsCount = listings.length;

    // const addRemoveDatesForClassTypeSearch = (urlParam, option, queryParams) => {
    //   if (urlParam === 'pub_listing_type' && option === 'class') {
    //     const date = moment().format('YYYY-MM-DD');

    //     return {
    //       ...queryParams,
    //       dates: `${date},${date}`,
    //       times: '00:00,23:59',
    //     };
    //   } else if (urlParam === 'pub_listing_type' && option !== 'class') {
    //     return omit(queryParams, ['dates', 'times']);
    //   }

    //   return queryParams;
    // };

    const paginationLinks =
      pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName={'JobsBoardPage'}
          pageSearchParams={searchParamsForPagination}
          pagination={pagination}
        />
      ) : null;

    return (
      <div id="mainpanel-results" className={css.mainPanelSection}>
        {/* <div>
          <div className={css.mobileFilterTypeElement}>{FilterTypeElement}</div>
          <div className={css.searchFiltersWrapper}>
            <ModifiedSearchFilters
              urlQueryParams={urlQueryParams}
              selectedFiltersType={selectedFiltersType}
              redirectPageName={'JobsBoardPage'}
              onClickFilterTypeHandler={onClickFilterTypeHandler}
              isMobileLayout={isMobileLayout}
              categoryFilter={primaryFilters.categoryFilter}
              subcategoryFilter={primaryFilters.subcategoryFilter}
              priceFilter={primaryFilters.priceFilter}
              languageFilter={primaryFilters.languageFilter}
              history={history}
            />
          </div>
        </div>

        <div className={css.resultHead}>
          <h1 className={css.coachesInfo}>Recent Client Request</h1>
          {listingsAreLoaded && resultsCount > 0 ? (
            <div className={css.searchResultSummary}>
              <span className={css.resultsFound}>{resultsCount} New projects posted today.</span>
            </div>
          ) : null}
        </div> */}

        {isSearchFiltersPanelOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersPanel
              urlQueryParams={urlQueryParams}
              sort={sort}
              listingsAreLoaded={listingsAreLoaded}
              onClosePanel={() => this.setState({ isSearchFiltersPanelOpen: false })}
              filterParamNames={secondaryFilterParamNames}
              {...secondaryFilters}
            />
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="PromoPage.searchError" />
              </h2>
            ) : null}
            <div className={css.searchListingsPanel}>
              <div>
                <JobsBoardResults
                  listings={listings}
                  currentUser={currentUser}
                  currentUserHasListings={currentUserHasListings}
                  onClickShowBecomeHostMessage={onClickShowBecomeHostMessage}
                  setActiveListing={onActivateListing}
                />
              </div>
              {paginationLinks}
            </div>
          </div>
        )}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  primaryFilters: null,
  secondaryFilters: null,
  allListingReview: {},
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  primaryFilters: objectOf(propTypes.filterConfig),
  secondaryFilters: objectOf(propTypes.filterConfig),
  allListingReview: object,
};

export default compose(withRouter, injectIntl)(MainPanel);

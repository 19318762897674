import React from 'react';
import cns from 'classnames';
import {
    SecondaryButton,
} from 'components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './TopBlogsPage.css';

const SectionBlogs = props => {

    return (
        <div className={css.blogSection}>
            <div className={css.blogTop}>
                <h3 className={css.customerStoriesTitle}><FormattedMessage id={'SectionBlogs.fromTheBlogs'} /></h3>
                <SecondaryButton className={cns(css.allCustomerStoriesBtn, css.desktopButton )}>
                    <FormattedMessage id={'SectionBlogs.allArticles'} />
                </SecondaryButton>
            </div>
            <div className={css.customerStoriesContainer}>
                <div className={css.customerStoriesItem}>
                    <div className={css.customerStoriesImageContainer}>

                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionBlogs.blogTitle'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionBlogs.blogInfo'} /></p>
                </div>
                <div className={css.customerStoriesItem}>
                    <div className={css.customerStoriesImageContainer}>

                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionBlogs.blogTitle'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionBlogs.blogInfo'} /></p>
                </div>
                <div className={css.customerStoriesItem}>
                    <div className={css.customerStoriesImageContainer}>

                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionBlogs.blogTitle'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionBlogs.blogInfo'} /></p>
                </div>
            </div>
            <SecondaryButton className={cns(css.allCustomerStoriesBtn, css.mobileButton )}>
                    <FormattedMessage id={'SectionBlogs.allArticles'} />
                </SecondaryButton>
        </div>
    );
}

export default SectionBlogs;
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './ListingTravelAgentService.css';
import SectionPromotedListings from 'components/SectionPromotedListings/SectionPromotedListings';
import NamedLink from 'components/NamedLink/NamedLink';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

function ListingTravelAgentService(props) {
  const { listingVariant, intl, currentListing } = props;
  const [tripExperts, setTripExperts] = useState([]);

  useEffect(() => {
    if (currentListing?.attributes) {
      const { businessId } = currentListing.attributes.metadata;
      if (businessId) {
        const listingQueryParams = {
          meta_businessId: businessId,
          pub_listing_type: 'listing',
          include: ['author', 'author.profileImage', 'images'],
          'fields.image': [
            'variants.scaled-small',
            'variants.square-small2x',
  
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
          ],
          'limit.images': 3,
          perPage: 100,
        };
        sdk.listings.query(listingQueryParams).then(res => {
          const listings = res.data.data;
          const images =
            res.data.included &&
            res.data.included.filter(i => {
              return i.type === 'image';
            });
  
          const users =
            res.data.included &&
            res.data.included.filter(i => {
              return i.type === 'user';
            });
  
          if (listings && listings.length > 0) {
            listings.forEach(l => {
              const authorId = l.relationships.author.data?.id.uuid;
  
              const relationshipImages = l.relationships.images.data;
              const fullImages = relationshipImages.map(img => {
                const luckyImage = images.find(i => {
                  return i?.id.uuid === img.id.uuid;
                });
                return luckyImage;
              });
  
              const author = users.find(u => {
                return u?.id.uuid === authorId;
              });
  
              l.author = author;
              l.images = fullImages.filter(i => i);
            });
          }
          setTripExperts(listings);
        });
      }
    }
  }, [currentListing]);

  return (
    <div className={css.ListingDetailsMainTabConatiner}>
      <div className={css.listingTravelAgentServiceInfo}>
        {tripExperts.length > 0 && (
          <>
            <h2>Trip Experts</h2>
            <SectionPromotedListings
              intl={intl}
              promotedListings={tripExperts}
              showAll={'SectionPromotedTrainers.showAll'}
              allRelatedQuery={'trainers'}
              label={'Top Trainers'}
            />
          </>
        )}

        <div className={css.listingTravelAgentServiceWrapper}>
          <h2>
            <FormattedMessage id="ListingTravelAgentService.title" />
          </h2>
          <p>
            {listingVariant !== 'company' ? (
              <FormattedMessage
                id="ListingTravelAgentService.description"
                className={css.listingText}
              />
            ) : (
              <FormattedMessage
                id="ListingTravelAgentService.description4Business"
                values={{
                  here: (
                    <NamedLink name="TravelAgentTnCPage" target="_blank">
                      here
                    </NamedLink>
                  ),
                }}
                className={css.listingText}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
export default ListingTravelAgentService;

import React, { Component } from 'react';
import ResponsiveImage from 'components/ResponsiveImage/ResponsiveImage';
import ShareBtnGroup from './ShareBtnGroup';
import SectionHeadImagesSlider from 'components/SectionHeadImagesSlider/SectionHeadImagesSlider';
import SectionHead from './SectionHead';
import { ListingDetailsSectionMainTab } from '..';
import { SecondaryButton, ListingTravelAgentService, CompanyListingCard } from '../../components';
import SectionLatestReviews from 'components/SectionLatestReviews/SectionLatestReviews';
import SectionPhotos from './SectionPhotos';
import SectionVideos from './SectionVideos';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.css';
import cns from 'classnames';
import { getCurrentPageUrl } from 'util/urlHelpers';
import { ensureUser } from 'util/data';
import Identity_Verified_Icon from '../../assets/Listing_Page/Identity_Verified_Icon.png';
import { setActiveListing } from 'containers/SearchPage/SearchPage.duck';
import { getRatingFromReviews } from './helper';

class ListingPageCommonSection extends Component {
  render() {
    const {
      currentListing,
      listingBannerImage,
      featureImages,
      extraImages,
      title,
      viewport,
      currentUser,
      publicData,
      isOwnListing,
      unitType,
      intl,
      handleBookingSubmit,
      handlePackagePurchase,
      bookingTitle,
      authorDisplayName,
      onManageDisableScrolling,
      monthlyTimeSlots,
      onFetchTimeSlots,
      metadata,
      reviews,
      listingVariant,
      otherAuthorsListings,
      handlePackagesModalOpen,
      getRemovedWithOutContentReview,
      isProduct,
      businessAccountInfo,
      isDraftView,
    } = this.props;

    const shareUrl = getCurrentPageUrl();
    const rating = getRatingFromReviews(reviews);
    // const listingDisplayImages = currentListing.images;
    // const extraImages = listingDisplayImages;
    // const featureImages = listingDisplayImages.splice(0, 2);
    const listingVideos = currentListing.attributes.publicData.listingVideos;
    const businessId = businessAccountInfo?.id.uuid;

    const authorAvailable = currentListing && currentListing.author;
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    return (
      <div>
        <div className={css.banner}>
          <ResponsiveImage
            image={listingBannerImage}
            // variants={imgVariants}
            variants={['scaled-medium', 'scaled-large', 'default']}
            alt={'title'}
          />
          <ShareBtnGroup
            shareUrl={shareUrl}
            title={title}
            viewport={viewport}
            currentListing={currentListing}
            currentUser={currentUser}
          />
          <div className={css.centerWrapper}>
            <div className={css.sliderContainer}>
              <SectionHeadImagesSlider rootClassName={css.imageSlider} images={featureImages}>
                {featureImages &&
                  featureImages.map(image => {
                    return (
                      <div key={image.id.uuid} className={css.singleImageContainer}>
                        <div style={{ border: '5px solid white', borderRadius: 15 }}>
                          <ResponsiveImage
                            className={css.image}
                            image={image}
                            variants={['scaled-medium', 'default']}
                            alt={'title'}
                          />
                        </div>
                      </div>
                    );
                  })}
                <div className={css.singleImageContainer}>
                  <SectionMapMaybe
                    geolocation={currentListing.attributes.geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                    className={css.mapImage}
                  />
                </div>
              </SectionHeadImagesSlider>
            </div>
          </div>
        </div>
        <div className={css.headCenterWrapper}>
          <SectionHead
            ensuredAuthor={ensuredAuthor}
            rating={rating}
            totalRating={metadata && metadata.totalRating ? metadata.totalRating : 0}
            companyName={currentListing?.author?.attributes?.profile?.publicData?.companyName}
            address={publicData.location.address}
            timezone={publicData.location.timezone}
            context={publicData.location.context}
            averageResponse={'2- 4hrs'}
            viewport={viewport}
            intl={intl}
            reviewsLength={reviews ? reviews.length || 0 : 0}
            currentListing={currentListing}
            currentUser={currentUser}
          />
        </div>
        <ListingDetailsSectionMainTab
          authorDetails={currentListing?.author}
          currentListing={currentListing}
          currentUser={currentUser}
          isOwnListing={isOwnListing}
          unitType={unitType}
          intl={intl}
          handleBookingSubmit={handleBookingSubmit}
          handlePackagePurchase={handlePackagePurchase}
          bookingTitle={bookingTitle}
          authorDisplayName={authorDisplayName}
          onManageDisableScrolling={onManageDisableScrolling}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          viewport={viewport}
          handlePackagesModalOpen={handlePackagesModalOpen}
          isProduct={isProduct}
          images={extraImages}
          videos={listingVideos}
          rating={rating}
          isDraftView={isDraftView}
          photosSection={
            extraImages?.length ? (
              <SectionPhotos images={extraImages} isDraftView={isDraftView} />
            ) : null
          }
          videosSection={listingVideos?.length ? <SectionVideos videos={listingVideos} /> : null}
        />
        {rating ? (
          <div className={cns(css.sectionWrapper, css.greyReviewSection)}>
            <SectionLatestReviews
              data={getRemovedWithOutContentReview(reviews)}
              className={css.mainReviewContainer}
              title={`Reviews`}
              // rating={metadata && metadata.rating ? metadata.rating : 0}
              rating={rating}
              // totalRating={metadata && metadata.totalRating ? metadata.totalRating : 0}
              totalRating={reviews.length}
            />
            {/* <div className={css.btnWrap}>
              <SecondaryButton className={css.seeMoreButton}>
                Show all {reviews.length} Reviews
              </SecondaryButton>
            </div> */}
          </div>
        ) : null}

        <ListingTravelAgentService
          listingVariant={listingVariant}
          otherAuthorsListings={otherAuthorsListings.length ? otherAuthorsListings[0] : null}
        />
        {businessAccountInfo && (
          <div className={css.travelBusinessInfoContainer}>
            <div className={css.travelBusinessInfo}>
              <div className={css.businessIdentityWrapper}>
                <h2>Travel BUSINESS</h2>
                <div className={css.profileIdentityWrapper}>
                  <img
                    src={Identity_Verified_Icon}
                    alt="Identity_Verified_Icon"
                    width="28px"
                    height="28px"
                  />
                  <span>IDENTITY VERIFIED</span>
                </div>
              </div>
              <div style={{ maxWidth: '230px' }}>
                <CompanyListingCard
                  key={businessId}
                  listing={businessAccountInfo}
                  setActiveListing={setActiveListing}
                  isListing={true}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ListingPageCommonSection;

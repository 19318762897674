import moment from 'moment';

import joinSession1 from '../assets/landingPage/joinSession1.jpg';
import joinSession2 from '../assets/landingPage/joinSession2.jpg';
import joinSession3 from '../assets/landingPage/joinSession3.jpg';
import joinSession4 from '../assets/landingPage/joinSession4.jpg';
import joinSession5 from '../assets/landingPage/joinSession5.jpg';
import joinSession6 from '../assets/landingPage/joinSession6.jpg';

const date = moment().format('YYYY-MM-DD');
export const CategoriesListOne = [
  {
    key: 'Outdoor and Adventure',
    title: 'Outdoor and Adventure',
    image: joinSession6,
    name: 'SearchPage',
    searchPageLink: `pub_category=online_session&pub_listing_type=listing`,
    links: [
      { linkTitle: 'Golf', link: `pub_sub_category=golf&pub_listing_type=listing` },
      { linkTitle: 'Surfing', link: `pub_sub_category=surfing&pub_listing_type=listing` },
      {
        linkTitle: 'Obstacle Sports',
        link: `pub_sub_category=obstacle sports&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Rock CLimbing',
        link: `pub_sub_category=rock climbing&pub_listing_type=listing`,
      },
      { linkTitle: 'Running', link: `pub_sub_category=running&pub_listing_type=listing` },
      { linkTitle: 'Cycling', link: `pub_sub_category=cycling&pub_listing_type=listing` },
    ],
  },
  {
    key: 'Wellness',
    title: 'Wellness',
    image: joinSession3,
    name: 'SearchPage',
    searchPageLink: `pub_category=wellness_and_beauty&pub_listing_type=listing`,
    links: [
      { linkTitle: 'Massage', link: `pub_sub_category=massage&pub_listing_type=listing` },
      { linkTitle: 'Chiropractor', link: `pub_sub_category=chiropractor&pub_listing_type=listing` },
      {
        linkTitle: 'Meditation',
        link: `pub_sub_category=meditation&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Prenatal',
        link: `pub_sub_category=prenatal&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Physical Therapy',
        link: `pub_sub_category=physical Therapy&pub_listing_type=listing`,
      },
      { linkTitle: 'Acupuncture', link: `pub_sub_category=acupuncture&pub_listing_type=listing` },
    ],
  },
  {
    key: 'Kids Specialist',
    title: 'Kids Specialist',
    image: joinSession4,
    name: 'SearchPage',
    searchPageLink: `pub_category=kids&pub_listing_type=listing`,
    links: [
      {
        linkTitle: 'Golf',
        link: `pub_category=kids&pub_sub_category=golf&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Tennis',
        link: `pub_category=kids&pub_sub_category=tennis&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Cricket',
        link: `pub_category=kids&pub_sub_category=cricket&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Football',
        link: `pub_category=kids&pub_sub_category=football&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Martial Arts',
        link: `pub_category=kids&pub_sub_category=martial arts&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Baseball',
        link: `pub_category=kids&pub_sub_category=baseball&pub_listing_type=listing`,
      },
    ],
  },
];

export const CategoriesListTwo = [
  {
    key: 'Fitness Classes',
    title: 'Fitness Classes',
    image: joinSession1,
    name: 'SearchPage',
    searchPageLink: ``,
    links: [
      {
        linkTitle: 'Cross Fit',
        link: `address=Singapore&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395&dates=${date},${date}&times=00:00,23:59&pub_sub_category=crossfit&pub_listing_type=class`,
      },
      {
        linkTitle: 'Yoga',
        link: `address=Singapore&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395&dates=${date},${date}&times=00:00,23:59&pub_sub_category=yoga&pub_listing_type=class`,
      },
      {
        linkTitle: 'Pilates',
        link: `address=Singapore&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395&dates=${date},${date}&times=00:00,23:59&pub_sub_category=pilates&pub_listing_type=class`,
      },
      {
        linkTitle: 'Personal Training',
        link: `address=Singapore&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395&dates=${date},${date}&times=00:00,23:59&pub_sub_category=personal training&pub_listing_type=class`,
      },
      {
        linkTitle: 'Swiming',
        link: `address=Singapore&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395&dates=${date},${date}&times=00:00,23:59&pub_sub_category=swimming&pub_listing_type=class`,
      },
      {
        linkTitle: 'Bootcamp',
        link: `address=Singapore&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395&dates=${date},${date}&times=00:00,23:59&pub_sub_category=bootcamp&pub_listing_type=class`,
      },
    ],
  },
  {
    key: 'Racket Sports',
    title: 'Racket Sports',
    image: joinSession2,
    name: 'SearchPage',
    searchPageLink: `pub_category=trainers&pub_listing_type=listing`,
    links: [
      { linkTitle: 'Badminton', link: `pub_sub_category=badminton&pub_listing_type=listing` },
      {
        linkTitle: 'Padel Tennis',
        link: `pub_sub_category=paddle tennis&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Pickle Ball',
        link: `pub_sub_category=pickle ball&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Squash',
        link: `pub_sub_category=squash&pub_listing_type=listing`,
      },
      { linkTitle: 'Tennis', link: `pub_sub_category=tennis&pub_listing_type=listing` },
      { linkTitle: 'Tabel Tennis', link: `pub_sub_category=table tennis&pub_listing_type=listing` },
    ],
  },
  {
    key: 'Combat Sports',
    title: 'Combat Sports',
    image: joinSession5,
    name: 'SearchPage',
    searchPageLink: ``,
    links: [
      { linkTitle: 'Boxing', link: `pub_sub_category=boxing&pub_listing_type=listing` },
      { linkTitle: 'Jiu-jitsu', link: `pub_sub_category=jiu-jitsu&pub_listing_type=listing` },
      {
        linkTitle: 'Karate',
        link: `pub_sub_category=karate&pub_listing_type=listing`,
      },
      {
        linkTitle: 'Kick Boxing',
        link: `pub_sub_category=kick boxing&pub_listing_type=listing`,
      },
      { linkTitle: 'Martial Arts', link: `pub_sub_category=martial arts&pub_listing_type=listing` },
      { linkTitle: 'Wrestling', link: `pub_sub_category=wrestling&pub_listing_type=listing` },
    ],
  },
];

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './MarketplaceDevelopmentPage.css';

const SectionWhatMakesUsDifferent = props => {

    return (
        <div className={css.blogSection}>
            <div className={css.blogTop}>
                <h3 className={css.customerStoriesTitle}><FormattedMessage id={'MarketplaceDevelopmentPage.whatMakesUsDifferent'} /></h3>
            </div>
            <div className={css.customerStoriesContainer}>
                <div className={css.customerStoriesItem}>
                    <div className={css.customerStoriesImageContainer}>
                    <img src={'/static/images/MarketplaceDevelopmentPage/whatMakesUsDifferent1.png'} alt="What makes us different" />
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.figma'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.weBuildFigmaWithOurDesigner'} /></p>
                </div>
                <div className={css.customerStoriesItem}>
                    <div className={css.customerStoriesImageContainer}>
                    <img src={'/static/images/MarketplaceDevelopmentPage/whatMakesUsDifferent2.png'} alt="What makes us different" />
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.flexDashboard'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.weSetupAllFlexTransactions'} /></p>
                </div>
                <div className={css.customerStoriesItem}>
                    <div className={css.customerStoriesImageContainer}>
                    <img src={'/static/images/MarketplaceDevelopmentPage/whatMakesUsDifferent3.png'} alt="What makes us different" />
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'MarketplaceDevelopmentPage.paymentGateways'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'MarketplaceDevelopmentPage.withExperienceWeHelpYouSetup'} /></p>
                </div>
            </div>
        </div>
    );
}

export default SectionWhatMakesUsDifferent;
import React, { Component } from 'react';
import { bool, func, object, shape, string, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { ProfileSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { updateProfile, uploadImage } from './ProfileSettingsPage.duck';
import { resetPassword, requestSaveEmail } from '../ContactDetailsPage/ContactDetailsPage.duck';
import css from './ProfileSettingsPage.css';
import { getMarketplaceEntities } from 'ducks/marketplaceData.duck';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export class ProfileSettingsPageComponent extends Component {
  render() {
    const {
      currentUser,
      currentUserListing,
      image,
      onImageUpload,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      intl,
      createdCompanies,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      saveEmailError,
      resetPasswordInProgress,
      resetPasswordError,
      onResetPassword,
      onRequestSaveEmail,
    } = this.props;

    const handleSubmit = values => {
      const user = ensureCurrentUser(currentUser);
      const {
        email,
        currentPassword,
        firstName,
        lastName,
        userName,
        countryCode,
        phoneNumber,
        profileImage,
        ...rest
      } = values;

      const trimmedFirstName = firstName.trim();
      const trimmedLastName = lastName.trim();

      const profile = {
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
        publicData: { userName, countryCode, phoneNumber },
        protectedData: { ...rest },
      };
      const uploadedImage = this.props.image;

      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      if (user.attributes.email !== email) {
        onRequestSaveEmail({ email, currentPassword });
      }

      onUpdateProfile(updatedValues);
    };

    const user = ensureCurrentUser(currentUser);
    const {
      firstName,
      lastName,
      publicData: { userName, countryCode, phoneNumber },
      protectedData,
    } = user.attributes.profile;
    const ensureProtectedData = protectedData ? protectedData : {};
    const { email } = user.attributes;

    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };
    const company = createdCompanies[0];

    const profileSettingsForm = user.id ? (
      <ProfileSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{
          profileImage: user.profileImage,
          userName,
          firstName,
          lastName,
          email,
          countryCode,
          phoneNumber,
          ...ensureProtectedData,
        }}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        saveEmailError={saveEmailError}
        onResetPassword={onResetPassword}
        resetPasswordInProgress={resetPasswordInProgress}
        resetPasswordError={resetPasswordError}
        createdCompany={company}
      />
    ) : null;

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfileSettingsPage" />
            {/* <UserNav selectedPageName="ProfileSettingsPage" listing={currentUserListing} /> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="ProfileSettingsPage.heading" />
                </h1>
                {/* {user.id ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                  >
                    <FormattedMessage id="ProfileSettingsPage.reviewsLink" />
                  </NamedLink>
                ) : null} */}
              </div>

              {profileSettingsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  currentUserListing: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.listing,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  createdCompany: arrayOf(propTypes.listing),
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    createdCompanyRef,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;

  const { saveEmailError, resetPasswordInProgress, resetPasswordError } = state.ContactDetailsPage;
  return {
    currentUser,
    currentUserListing,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
    createdCompanies: getMarketplaceEntities(state, createdCompanyRef),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    saveEmailError,
    resetPasswordInProgress,
    resetPasswordError,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onResetPassword: values => dispatch(resetPassword(values)),
  onRequestSaveEmail: values => dispatch(requestSaveEmail(values)),
});

const ProfileSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes, { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  UserDisplayName,
} from '../../components';
import User_Icon from '../../assets/Landing_Pages/User_Icon.png';
import Bottom_Arrow from '../../assets/Landing_Pages/Bottom_Arrow.png';
import CSVDownloadModal from 'containers/InboxPage/components/CSVDownloadModal/CSVDownloadModal';
import css from './TopbarDesktop.css';
import { NavLink } from 'react-router-dom';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    notificationCount,
    saleNotificationCount,
    orderNotificationCount,
    intl,
    isAuthenticated,
    onLogout,
    isDarkMode,
    currentUserHasListings,
    currentUserHasCompanyListings,
    downloadDeliveredTransactions,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [isCSVDownloadOpen, setIsCSVDownloadOpen] = useState(false);
  const [ownListings, setOwnListings] = useState([]);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }

  useEffect(() => {
    setMounted(true);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setMounted(true);
    if (mounted) {
      sdk.ownListings
        .query({})
        .then(res => {
          setOwnListings(res.data.data);
        })
        .catch(e => {
          //ignore error
        });
    }
  }, [mounted]);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  let isPrivateSite = false;
  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
  }
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = useMemo(
    () =>
      classNames(
        rootClassName || css.root,
        {
          [css.transparentRoot]: !isScroll && currentPage === 'LandingPage',
          [css.darkRoot]: isDarkMode && !(!isScroll && currentPage === 'LandingPage'),
        },
        className
      ),
    [isScroll, currentPage]
  );

  const saleNotificationDot = saleNotificationCount > 0 ? <div className={css.menuItemDot} /> : null;
  const orderNotificationDot = orderNotificationCount > 0 ? <div className={css.menuItemDot} /> : null;
  const avatarhasDot = saleNotificationCount + orderNotificationCount > 0;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const deliveredSortByClientName = intl.formatMessage({
    id: 'InboxPage.deliveredSortByClientName',
  });
  const deliveredSortByClientNameTitle = intl.formatMessage({
    id: 'InboxPage.deliveredSortByClientNameTitle',
  });
  const deliveredSortByBookingDate = intl.formatMessage({
    id: 'InboxPage.deliveredSortByBookingDate',
  });
  const deliveredSortByBookingDateTitle = intl.formatMessage({
    id: 'InboxPage.deliveredSortByBookingDateTitle',
  });

  const packageSortByListingTitle = intl.formatMessage({
    id: 'InboxPage.packageSortByListingTitle',
  });
  const packageSortByListingTitleTitle = intl.formatMessage({
    id: 'InboxPage.packageSortByListingTitleTitle',
  });
  const packageSortByPackageBoughtDate = intl.formatMessage({
    id: 'InboxPage.packageSortByPackageBoughtDate',
  });
  const packageSortByPackageBoughtDateTitle = intl.formatMessage({
    id: 'InboxPage.packageSortByPackageBoughtDateTitle',
  });

  const isExpert = currentUserHasListings && !currentUserHasCompanyListings;
  const isPartner = currentUserHasCompanyListings;

  const expertListing = ownListings?.find(
    l => l?.attributes?.publicData?.listing_type === 'listing'
  );
  const partnerListing = ownListings?.find(
    l => l?.attributes?.publicData?.listing_type === 'company'
  );
  const isPartnerListingDraft = partnerListing && partnerListing?.attributes?.state === 'draft';
  const isExpertListingDraft = expertListing && expertListing?.attributes?.state === 'draft';
  const hasAdminId = currentUser?.attributes?.profile?.publicData?.adminId;
  const profileMenu = authenticatedOnClientSide ? (
    <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
      <MenuLabel
        className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <span>{currentUser && currentUser.attributes.profile.firstName} &nbsp;</span>
        <div className={css.activeUser}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          <div className={avatarhasDot ? css.redDot : css.active} />
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="UserProfile">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('UserProfile'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MySettings">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('MySettings'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyOrdersPage">
          <div className={css.notificationContainer}>
            {orderNotificationDot}
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: 'orders', state: 'all' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.myOrdersPage" />
            </NamedLink>
          </div>
        </MenuItem>

        <MenuItem key="FavListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavListingsPage'))}
            name="FavListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.FavListingsPage" />
          </NamedLink>
        </MenuItem>
        {currentUser ? (
          <MenuItem key="MyReviewsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ProfilePage1'))}
              name="ProfilePage"
              params={{ id: currentUser.id.uuid }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.myReviews" />
            </NamedLink>
          </MenuItem>) : null}

        {hasAdminId ? ( //expertListing
          <>
            <div className={css.divider} />

            <MenuItem key="tripExpertAdminSectionMark">
              <span
                className={classNames(css.tripExpertAdmin, currentPageClass('TripExpertAdmin'))}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.tripExpertAdmin" />
              </span>
            </MenuItem>

            <MenuItem key="tripExpertAdmin_1">
              {expertListing ? (
                <NamedLink
                  className={classNames(css.yourListingsLink, currentPageClass('TripExpertAdmin'))}
                  name={isExpertListingDraft ? 'EditListingPage' : 'ListingPage'}
                  params={
                    isExpertListingDraft
                      ? {
                        id: expertListing.id.uuid,
                        slug: expertListing.attributes.title.replaceAll(' ', '-'),
                        type: 'draft',
                        tab: 'description',
                      }
                      : {
                        id: expertListing.id.uuid,
                        slug: expertListing.attributes.title.replaceAll(' ', '-'),
                      }
                  }
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.editSalesPage" />
                </NamedLink>
              ) : (
                <NamedLink
                  className={classNames(css.yourListingsLink, currentPageClass('BecomeTripExpert'))}
                  name="NewListingPage"
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.becomeTripExpert" />
                </NamedLink>
              )}
            </MenuItem>

            <MenuItem key="OrderManagement">
              <div className={css.notificationContainer}>
                {saleNotificationDot}
                <NamedLink
                  className={classNames(
                    // css.orderManagement,
                    css.yourListingsLink,
                    currentPageClass('OrderManagement'),
                    saleNotificationDot > 0 && css.boldText
                  )}
                  name="InboxPage"
                  params={{ tab: 'sales', state: 'all' }}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.orderManagement" />
                </NamedLink>
              </div>
            </MenuItem>

            <MenuItem key="CustomerReporting">
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('Reporting'))}
                name="InboxPage"
                params={{ tab: 'myClients', state: 'all' }}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.reporting" />
              </NamedLink>
            </MenuItem>
          </>
        ) : null}

        {partnerListing ? (
          <>
            <div className={css.divider} />
            <MenuItem key="travelBusinessAdminLabel">
              <span
                className={classNames(css.tripExpertAdmin, currentPageClass('travelBusinessAdmin'))}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.travelBusinessAdmin" />
              </span>
            </MenuItem>

            <MenuItem key="travelBusinessAdmin">
              <NamedLink
                className={classNames(
                  css.yourListingsLink,
                  currentPageClass('travelBusinessAdmin')
                )}
                name={isPartnerListingDraft ? 'EditCompanyPage' : 'ListingPage'}
                params={
                  isPartnerListingDraft
                    ? {
                      id: partnerListing.id.uuid,
                      slug: partnerListing.attributes.title.replaceAll(' ', '-'),
                      type: 'draft',
                      tab: 'description',
                    }
                    : {
                      id: partnerListing.id.uuid,
                      slug: partnerListing.attributes.title.replaceAll(' ', '-'),
                    }
                }
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.editBusinessPage" />
              </NamedLink>
            </MenuItem>

            <MenuItem key="AssociatedAccountsPage">
              <NamedLink
                className={classNames(
                  css.yourListingsLink,
                  currentPageClass('AssociatedAccountsPage')
                )}
                name="AssociatedAccountsPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.associatedAccountsPage" />
              </NamedLink>
            </MenuItem>

            {/* <MenuItem key="BecomeTripExpert">
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('BecomeTripExpert'))}
                name="NewListingPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.createTripExperts" />
              </NamedLink>
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('BecomeTripExpert'))}
                name="ManageListingsPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.editTripExpertPages" />
              </NamedLink>
            </MenuItem> */}
            {/* <MenuItem key="AddBusinessPage">
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('AddBusinessPage'))}
                name="NewCompanyPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.addTravelBusinessPage" />
              </NamedLink>
            </MenuItem> */}
            <MenuItem key="OrderView">
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('OrderView'))}
                name="PartnerOrdersPage"
              // params={{ tab: 'sales' }}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.orderView" />
              </NamedLink>
            </MenuItem>

            <MenuItem key="ReportingBusiness">
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('Reporting'))}
                name="InboxPage"
                params={{ tab: 'enquiry-client', state: 'all' }}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.reportingBusiness" />
              </NamedLink>
            </MenuItem>

          </>
        ) : null}
        <div className={css.divider} />
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const marketPlaceLink = (
    <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
      <MenuLabel
        className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
        isOpenClassName={css.profileMenuIsOpen}
      >
        {/* <NamedLink name="NewListingPage" className={css.menuMarketPlaceLink}> */}
        <span className={classNames(css.menuMarketPlace, { [css.darkTopbarMenuLink]: isDarkMode })}>
          <FormattedMessage id="TopbarDesktop.marketPlace" />
        </span>
        {/* </NamedLink> */}
      </MenuLabel>

      <MenuContent
        className={css.menuContentClass}
        arrowTopClass={css.arrowTopClass}
        arrowBelowClass={css.arrowBelowClass}
      >
        <MenuItem key="SearchForATripExpert">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('SearchForATripExpert')
            )}
            name="SearchPage"
            to={{
              search: `pub_listing_type=listing`,
            }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.searchForATripExpert" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="Overview">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('Overview'))}
            name="OverviewPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.overview" />
          </NamedLink>
        </MenuItem>
        {/* {!currentUserHasListings && (
          <MenuItem key="BecomeTripExpert">
            <NavLink
              to="/trip_expert#trip-expert-partner"
              className={classNames(css.yourListingsLink, currentPageClass('BecomeTripExpert'))}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.becomeTripExpert" />
            </NavLink>
          </MenuItem>
        )} */}

        {/* <MenuItem key="FaqMarketplace">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FaqMarketplace'))}
            name="ContactPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.faqMarketplace" />
          </NamedLink>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  );

  const organiserAppLink = (
    <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
      <MenuLabel
        className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <NamedLink name="LandingPage" className={css.organiserAppLink}>
          <span className={classNames(css.organiserApp, { [css.darkTopbarMenuLink]: isDarkMode })}>
            <FormattedMessage id="TopbarDesktop.organiserApp" />
          </span>
        </NamedLink>
      </MenuLabel>

      <MenuContent
        className={css.menuContentClass}
        arrowTopClass={css.arrowTopClass}
        arrowBelowClass={css.arrowBelowClass}
      >
        <MenuItem key="DownloadFreeOrganiser">
          <a
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('DownloadFreeOrganiser')
            )}
            href="https://www.lyfshort.com"
            target="_blank"
          // name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.downloadFreeOrganiser" />
          </a>
        </MenuItem>
        {!currentUserHasListings && (
          <MenuItem key="CreateALyfshortProfile">
            <NavLink
              to="/signup"
              className={classNames(
                css.yourListingsLink,
                currentPageClass('CreateALyfshortProfile')
              )}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.createALyfshortProfile" />
            </NavLink>
          </MenuItem>
        )}

        {/* <MenuItem key="FaqLyfshortTripApp">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FaqLyfshortTripApp'))}
            name="ContactPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.faqLyfshortTripApp" />
          </NamedLink>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  );

  const tripExpertLink = (
    <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
      <MenuLabel
        className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <NamedLink name="LandingPage" className={css.tripExpertLink}>
          <span className={classNames(css.tripExpert, { [css.darkTopbarMenuLink]: isDarkMode })}>
            <FormattedMessage id="TopbarDesktop.tripExpert" />
          </span>
        </NamedLink>
      </MenuLabel>

      <MenuContent
        className={css.menuContentClass}
        arrowTopClass={css.arrowTopClass}
        arrowBelowClass={css.arrowBelowClass}
      >
        <MenuItem key="Benefits">
          <NavLink
            to="/trip_expert#benefits"
            className={classNames(css.yourListingsLink, currentPageClass('Benefits'))}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.benefits" />
          </NavLink>
        </MenuItem>
        <MenuItem key="Process">
          <NavLink
            to="/trip_expert#process"
            className={classNames(css.yourListingsLink, currentPageClass('Process'))}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.process" />
          </NavLink>
        </MenuItem>
        <MenuItem key="Pricing">
          <NavLink
            to="/trip_expert#pricing"
            className={classNames(css.yourListingsLink, currentPageClass('Pricing'))}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.pricing" />
          </NavLink>
        </MenuItem>

        <MenuItem key="BecomeTripExpert">
          <NavLink
            to="/trip_expert#trip-expert-partner"
            className={classNames(css.yourListingsLink, currentPageClass('trip-expert-partner'))}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.becomeTripExpert" />
          </NavLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const partnersLink = (
    <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
      <MenuLabel
        className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <NamedLink
          name="SearchPage"
          className={css.partnersLink}
          to={{
            search: `pub_listing_type=company`,
          }}
        >
          <span className={classNames(css.partners, { [css.darkTopbarMenuLink]: isDarkMode })}>
            <FormattedMessage id="TopbarDesktop.partners" />
          </span>
        </NamedLink>
      </MenuLabel>

      <MenuContent
        className={css.menuContentClass}
        arrowTopClass={css.arrowTopClass}
        arrowBelowClass={css.arrowBelowClass}
      >
        <MenuItem key="TravelAgencies">
          <NamedLink
            name="SearchPage"
            className={classNames(css.yourListingsLink, currentPageClass('TravelAgencies'))}
            to={{
              search: `pub_listing_type=company`,
            }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.travelAgencies" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="BecomeAPartner">
          <NavLink
            to="/become-expert-or-partner"
            className={classNames(css.yourListingsLink, currentPageClass('BecomeAPartner'))}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.becomeAPartner" />
          </NavLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const USDLink = (
    <NamedLink name="LandingPage" className={css.USDLink}>
      <span className={classNames(css.USD, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.USD" />
        <img src={Bottom_Arrow} alt="BottomArrow" />
      </span>
    </NamedLink>
  );

  const englishLink = (
    <NamedLink name="LandingPage" className={css.englishLink}>
      <span className={classNames(css.english, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.english" />
        <img src={Bottom_Arrow} alt="BottomArrow" />
      </span>
    </NamedLink>
  );

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={classNames(css.signup, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
      <MenuLabel
        className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <NamedLink
          name="LoginPage"
          className={classNames(css.loginLink, { [css.darkTopbarMenuLink]: isDarkMode })}
        >
          <span className={css.login}>
            <FormattedMessage id="TopbarDesktop.login" />
            <img src={User_Icon} />
          </span>
        </NamedLink>
      </MenuLabel>

      {/*<MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyOrdersPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('MyOrdersPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myOrdersPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyMessages">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('MyMessages'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myMessages" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyFavorites">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('MyFavorites'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myFavorites" />
          </NamedLink>
        </MenuItem>

        <div className={css.divider}/>
        <MenuItem key="tripExpertAdmin">
          <NamedLink
            className={classNames(css.tripExpertAdmin, currentPageClass('TripExpertAdmin'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.tripExpertAdmin" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="EditSalesPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('EditSalesPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.editSalesPage" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="OrderManagement">
          <div class={css.notificationContainer}>
            <div className={css.menuItemDot} />
            <NamedLink
              className={classNames(css.orderManagement, currentPageClass('OrderManagement'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.orderManagement" />
            </NamedLink>
          </div>
        </MenuItem>

        <MenuItem key="Reporting">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('Reporting'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.reporting" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="Messages">
          <div class={css.notificationContainer}>
            <div className={css.menuItemDot} />
            <NamedLink
              className={classNames(css.messages, currentPageClass('Messages'))}
              name="AccountSettingsPage"
            >
              <FormattedMessage id="TopbarDesktop.messages" />
            </NamedLink>
          </div>
        </MenuItem>

        <div className={css.divider}></div>
        <MenuItem key="travelBusinessAdmin">
          <NamedLink
            className={classNames(css.tripExpertAdmin, currentPageClass('travelBusinessAdmin'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.travelBusinessAdmin" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="EditBusinessPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('edeitBusinessPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.edeitBusinessPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="OrderView">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('OrderView'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.orderView" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="Reporting">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('Reporting'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.reporting" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="Messages">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('Messages'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.messages" />
          </NamedLink>
        </MenuItem>

         {!isPrivateSite && (
          <>
            <MenuItem key="MarketplaceDevelopmentPage">
              <NamedLink className={css.yourListingsLink} name="MarketplaceDevelopmentPage">
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.marketplaceDevelopment" />
              </NamedLink>
            </MenuItem>
            <MenuItem key="BlogLink">
              <a className={css.yourListingsLink} href="https://probuddyblog.com/">
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.blog" />
              </a>
            </MenuItem>
          </>
        )}

        <div className={css.divider}></div>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>*/}
    </Menu>
  );

  return (
    <nav className={classes}>
      {isPrivateSite ? (
        <div className={css.privateNavWrap}>
          <a href={window.sessionStorage.getItem('privateSiteUrl')}>
            <Logo
              format="desktop"
              className={css.logo}
              isPrivateSite={isPrivateSite}
              isDarkMode={isDarkMode}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </a>
          <div className={css.privateMenu}>
            {signupLink}
            {loginLink}
            {profileMenu}
          </div>
        </div>
      ) : (
        <div id={'topbarNavWrap'} className={css.navWrap}>
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
              isDarkMode={isDarkMode}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </NamedLink>
          <div className={css.navLinkContainer}>
            {marketPlaceLink}
            {organiserAppLink}
            {tripExpertLink}
            {partnersLink}
            {/* {USDLink} */}
            {/* {englishLink} */}
            {/*{signupLink}*/}
            {loginLink}
            {profileMenu}
          </div>

          <CSVDownloadModal
            id={'csv-download'}
            isOpen={isCSVDownloadOpen}
            onConfirm={downloadDeliveredTransactions}
            toggle={value => setIsCSVDownloadOpen(value)}
            onManageDisableScrolling={() => { }}
            sortBy1={deliveredSortByClientName}
            sortBy1Title={deliveredSortByClientNameTitle}
            sortBy2={deliveredSortByBookingDate}
            sortBy2Title={deliveredSortByBookingDateTitle}
          />
        </div>
      )}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  saleNotificationCount: 0,
  orderNotificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
  isDarkMode: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: PropTypes.oneOfType([propTypes.ownListing, propTypes.listing]),
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  saleNotificationCount: number,
  orderNotificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  isDarkMode: bool,
};

export default TopbarDesktop;

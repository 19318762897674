import React, { Component, useState, useRef } from 'react';
import { string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import Slider from 'react-slick';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage, UserDisplayName, SectionLevel } from '../../components';
// import IconWhatsapp from '../../components/IconWhatsapp/IconWhatsapp';
import { priceData, convertMoneyToNumber } from 'util/currency';
// import StarRatings from 'react-star-ratings';
import { getRatingFromReviews } from 'containers/ListingPage/helper';
import { limitTextLength, sortImagesFunction } from './utils';
import css from './ListingCard.css';
import StarRatings from 'react-star-ratings';
import { validateStringLength } from './utils';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    intl,
    listing,
    renderSizes,
    setActiveListing,
    reviews = [],
    viewButtonClass,
    authorNameClass,
    startingAtClass,
    cardContainerClass,
    imageWrapperClass,
    addressWrapperClass,
    reviewsWrapperClass,
    descriptionClass,
    buttonWrapperClass,
    favListings,
  } = props;
  const viewButton = classNames(viewButtonClass, css.viewButton);
  const authorName = classNames(authorNameClass, css.authorName);
  const startingAt = classNames(startingAtClass, css.startingAt);
  const cardContainer = classNames(cardContainerClass, css.popular);
  const imageWrapper = classNames(imageWrapperClass, css.imageWrapper);
  const addressWrapper = classNames(addressWrapperClass, css.addressWrapper);
  const reviewsWrapper = classNames(reviewsWrapperClass, css.reviewsWrapper);
  const descriptionStyle = classNames(descriptionClass, css.description);
  const buttonWrapper = classNames(buttonWrapperClass, css.buttonWrapper);
  const currentListing = ensureListing(listing);
  const [isZoomIn, setZoomIn] = useState(false);
  const sliderRef = useRef(null);
  const sliderSettings = {
    fade: true,
    infinite: false,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2100,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToScroll: 0,
          arrows: false,
        },
      },
    ],
  };

  const id = currentListing.id.uuid;
  const { title = '', publicData, price, metadata } = currentListing.attributes;
  const slug = createSlug(title);
  const images = currentListing.images ? currentListing.images : [];
  const featureImages = images.slice(1, 3);
  const description = title;
  // const { formattedPrice } = priceData(price, intl);
  const formattedPrice = price ? `$${convertMoneyToNumber(price)} USD` : `0 USD`;
  const author = currentListing.author;
  const rating = getRatingFromReviews(reviews);
  const reviewcount = reviews ? reviews.length : 0;

  const authorPublic = author ? author.attributes.profile.publicData : {};

  const { address, context } = publicData.location ?? {};
  const formattedCountry = context?.country ? `, ${context?.country}` : '';
  const formattedPlace = context?.place ? `${validateStringLength(context?.place, 30)}, ` : '';
  const extraFormattedSlice =
    (context?.distract && `${validateStringLength(context?.distract, 30)}`) ||
    (context?.region && `${validateStringLength(context?.region, 30)}`) ||
    (context?.locality && `${validateStringLength(context?.locality, 30)}`) ||
    '';
  const currentLocation = formattedPlace + extraFormattedSlice + formattedCountry;
  const authorListingAddress = context ? currentLocation : address;

  const contactNumber = authorPublic.phoneNumber || '';

  return (
    <div className={css.listingLink}>
      <div
        className={cardContainer}
        onMouseOver={() => {
          sliderRef.current.slickNext();
          sliderRef.current.slickPlay();
          setZoomIn(true);
        }}
        onMouseOut={() => {
          sliderRef.current.slickPause();
          sliderRef.current.slickPrev();
          setZoomIn(false);
        }}
      >
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          {featureImages ? (
            <Slider
                ref={sliderRef}
                {...sliderSettings}
                autoplay={isZoomIn}
                className={css.slider}
              >
              {featureImages.map(image => (
                <div key={image.id.uuid} className={imageWrapper}>
                  <div className={css.aspectWrapper}>
                    <LazyImage
                      rootClassName={css.rootForImage}
                      alt={title}
                      className={classNames(css.popularImage, {
                        [css.popularImageHover]: isZoomIn,
                      })}
                      image={image}
                      variants={['scaled-small', 'square-small2x', 'landscape-crop']}
                      sizes={renderSizes}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          ) : null}
        </div>
        <div style={{ position: 'relative' }}>
          <div className={startingAt}>
            from <span>{formattedPrice}</span>
          </div>

          <div className={css.cardDetailsWrapper}>
            <UserDisplayName className={authorName} user={author} intl={intl} />
            <div className={addressWrapper}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <g clipPath="url(#clip0_173_866)">
                    <path
                      d="M10.5 5.21875C10.5 8.71875 6 11.7188 6 11.7188C6 11.7188 1.5 8.71875 1.5 5.21875C1.5 4.02528 1.97411 2.88068 2.81802 2.03677C3.66193 1.19286 4.80653 0.71875 6 0.71875C7.19347 0.71875 8.33807 1.19286 9.18198 2.03677C10.0259 2.88068 10.5 4.02528 10.5 5.21875Z"
                      stroke="#C13750"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6.71875C6.82843 6.71875 7.5 6.04718 7.5 5.21875C7.5 4.39032 6.82843 3.71875 6 3.71875C5.17157 3.71875 4.5 4.39032 4.5 5.21875C4.5 6.04718 5.17157 6.71875 6 6.71875Z"
                      stroke="#C13750"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_173_866">
                      <rect width="12" height="12" fill="white" transform="translate(0 0.21875)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>
                <span className={css.address}>{authorListingAddress}</span>
              </div>
            </div>

            <div className={reviewsWrapper} style={{ opacity: reviewcount > 0 ? 1 : 0 }}>
              <div className={css.reviersCountRating}>
                <StarRatings
                  rating={rating}
                  starRatedColor="#346bf6"
                  starEmptyColor="#fff"
                  numberOfStars={1}
                  starDimension="16px"
                  starSpacing="0px"
                />
                <span className={css.ratingWrapper}>{rating}</span>
              </div>
              <div className={css.reviersCount}>{`(${reviewcount})`}</div>
            </div>

            <p className={descriptionStyle}>{limitTextLength(description, 100)}</p>

            <div className={buttonWrapper}>
              <NamedLink name="ListingPage" params={{ id, slug }} className={viewButton}>
                View
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);

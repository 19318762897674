import React from 'react';
// import config from '../../config';
// import { twitterPageURL } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import CheckIcon from '../../assets/TripExpertPage/Check_Icon.svg';
import TripExpertSectionImage2 from '../../assets/TripExpertPage/TripExpertSectionImage2.svg';
import css from './BecomeExpertOrPartnerPage.css';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import * as validators from '../../util/validators';
import arrayMutators from 'final-form-arrays';
import { Button, FieldCheckboxGroup, NamedLink } from '../../components';
import { pushToPath } from 'util/urlHelpers';

const checklist = [
  { id: '1', description: 'Get Paid for Creating Itineraries' },
  { id: '2', description: 'Recieve qualified Travel Agent leads' },
  { id: '3', description: 'Set your own service pricing' },
  { id: '4', description: 'Setup a profile quickly' },
  { id: '5', description: 'Focus on specialised areas you love' },
  { id: '6', description: 'Reach New International markets ' },
  { id: '7', description: 'Use your existing IT Systems', italic: '(GDS, Booking tools)' },
  {
    id: '8',
    description: 'Use purpose built Travel App to communicate',
    italic: '(instead of email & phone calls)',
  },
  { id: '9', description: 'No up front costs' },
  { id: '10', description: 'No comissions on Travel bookings' },
  {
    id: '11',
    description: 'Small Fee on qualified leads',
    italic: '(Great Conversion Vs GAds, FB etc.) ',
  },
];

const BecomeExpertOrPartnerPage = () => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const onSubmit = values => {
    pushToPath('/c/new');
  };

  const expertOptions = [
    {
      key: '1',
      label:
        'I am a Travel Agent and work for or represent a Partner Travel Business already on the Marketplace.',
    },
    {
      key: '2',
      label:
        'I am a Travel Agent and the business I work for is not a Partner Travel Business yet.',
    },
    {
      key: '3',
      label:
        'I am not a Travel Agent but would like to investigate becoming one to offer Trip Expert Services.',
    },
  ];

  const partnerOptions = [
    {
      key: '1',
      label:
        'I own or represent a Travel Agency registered in one of these <a>supported countries</a> and would like to become a Partner to add Trip Experts to the Marketplace.',
    },
    {
      key: '2',
      label:
        'I can provide detalied commercial and financial information to verify the business as part of the application.',
    },
  ];

  return (
    <StaticPage
      title="BecomeExpertOrPartnerPage"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BecomeExpertOrPartnerPage',
        description: 'BecomeExpertOrPartnerPage',
        name: 'BecomeExpertOrPartnerPage',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.pageWrapper}>
            <div className={css.staticPageWrapper}>
              <div className={css.sectionInfo}>
                <h2>
                  Become A Trip Expert Or
                  <br />
                  Partner Travel Business
                </h2>

                <p>
                  Do you work as a Travel Agent and love helping customers from all around the world
                  create amazing Trips? Would you like to :{' '}
                </p>

                <ul>
                  {checklist.map(c => {
                    return (
                      <li className={css.topSpan}>
                        <img src={CheckIcon} className={css.checkIcon} />
                        <span>{c.description}</span>
                        {c.italic && (
                          <>
                            <br />
                            <i>{c.italic}</i>
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>

                {/* <img src={TripExpertSectionImage2} className={css.laptopImage} /> */}
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={TripExpertSectionImage2}
                    alt="sectionImage3"
                  />
                </div>
              </div>

              <div className={css.sectionForm}>
                <FinalForm
                  onSubmit={onSubmit}
                  mutators={{ ...arrayMutators }}
                  render={fieldRenderProps => {
                    const {
                      handleSubmit,
                      invalid,
                      pristine,
                      submitting,
                      formName,
                      emailSend,
                      values,
                      form,
                    } = fieldRenderProps;

                    const required = validators.required('This field is required');
                    const submitDisabled =
                      !values.partnerOptions ||
                      (values.partnerOptions && values.partnerOptions.length < 2);

                    let nextActionMessage = (
                      <i style={{ fontSize: '20px', lineHeight: '32px' }}>Select checkbox above</i>
                    );

                    if (values.expertOptions === '1') {
                      nextActionMessage = (
                        <p>
                          <span>
                            Contact your Businesses Admin and ask them for an invitation to create
                            your profile page on the marketplace.
                          </span>
                        </p>
                      );
                    }

                    if (values.expertOptions === '2') {
                      nextActionMessage = (
                        <p>
                          <span>
                            Contact the owner or administrator of your Travel Agency and suggest
                            they complete a Partnership Request (below), get approved, then add you
                            as a Trip Expert.{' '}
                          </span>
                        </p>
                      );
                    }

                    if (values.expertOptions === '3') {
                      nextActionMessage = (
                        <p>
                          <span>
                            Please email support@lyfshort.com with your name and contact details
                            (including phone number) to be connected with a partner business looking
                            for representatives.
                          </span>
                        </p>
                      );
                    }

                    const onChange = formValues => {
                      // if (formValues.values.partnerOptions && formValues.values.expertOptions) {
                      //   form.change('expertOptions', []);
                      // }
                    };

                    return (
                      <form
                        className={css.form}
                        onSubmit={e => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                      >
                        <center className={css.tripExpertTitle}>
                          <h2>Trip Expert</h2>
                        </center>

                        <FormSpy onChange={onChange} />

                        <FieldCheckboxGroup
                          id="expertOptions"
                          name="expertOptions"
                          options={expertOptions}
                          twoColumns={false}
                          radio={true}
                        />

                        <div className={!!values.expertOptions && css.nextActionWrapper}>
                          <h2>Next action:</h2>
                          {nextActionMessage}
                        </div>

                        <center className={css.travelBusinessTitle}>
                          <h2>Travel Business</h2>
                        </center>

                        <FieldCheckboxGroup
                          id="partnerOptions"
                          name="partnerOptions"
                          options={partnerOptions}
                          twoColumns={false}
                        />

                        <Button className={css.submit} type="submit" disabled={submitDisabled}>
                          Start Partnership Request
                        </Button>
                      </form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default BecomeExpertOrPartnerPage;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash/object';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import cns from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  fetchEnquiryMessages,
  sendMessage as sendEnquiryMessage,
} from 'containers/TransactionPage/TransactionPage.duck';
import SortByPopup from '../../components/SortBy/SortByPopup';
import { getExtensionFromUrl } from 'util/urlHelpers';
import { debounce, orderBy } from 'lodash';
import { ensureTransaction } from '../../util/data';
import {
  acceptPackageRequest,
  cancelPackageRequest,
  sellerPackageRequests,
  buyerPackageRequests,
  removePendingProgressReports,
  rescheduleBooking,
} from './InboxPage.duck';
import {
  fetchTimeSlots,
  fetchMonthlyTimeSlots,
} from '../../containers/ListingPage/ListingPage.duck';
import { getMarketplaceEntitiesMemo } from 'containers/BookingPlanPage/helpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createSlug, stringify } from '../../util/urlHelpers';
import Collapsible from 'react-collapsible';
import {
  NotificationBadge,
  Page,
  PaginationLinks,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  InboxItemWithButtons,
  SecondaryButton,
  PrimaryButton,
  NamedLink,
  Avatar,
  IconArrowDown,
  IncomeContent,
  TransactionStateButton,
  WalletListingCard,
  Portal,
  Modal,
  GenericMessage,
} from '../../components';
import { txState } from 'components/InboxItem/helpers';
import { TopbarContainer, NotFoundPage } from '../../containers';
import config from '../../config';
import {
  loadData,
  transitionTransaction,
  downloadDeliveredTransactions,
  downloadPackages,
  sendMessage,
} from './InboxPage.duck';
import { SendMessageForm } from '../../forms';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_READY_TO_COMPLETE,
  TRANSITION_COMPLETE_MANUAL,
  TRANSITION_DECLINE,
  txIsDelivered,
} from 'util/transaction';
import IconDownload from 'components/IconDownload/IconDownload';
import css from './InboxPage.css';
import { withViewport } from 'util/contextHelpers';
import { getNoResultsMessageId, isResults } from 'containers/InboxPage/helpers';
import moment from 'moment';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from 'routeConfiguration';
import Schedule from 'containers/InboxPage/Schedule';
import CSVDownloadModal from 'containers/InboxPage/components/CSVDownloadModal/CSVDownloadModal';
import GroupRow from 'containers/InboxPage/components/GroupableRow/GroupRow';
import TabNav from 'containers/InboxPage/components/TabNav/TabNav';
import Enquiries from 'containers/InboxPage/components/Enquiries/Enquiries';
import {
  IconOrdersTab,
  IconSalesTab,
  IconPackagesTab,
  IconScheduleTab,
  IconEnquiryTab,
  IconMyClientTab,
  IconProgressReportsTab,
  IconTransactionsTab,
} from './TabsIcons';
import { post } from 'util/api';

import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const VisibleSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#00ab99' : '#00ab99',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#00ab99',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? 'grey' : 'grey',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const { UUID } = sdkTypes;

const STATUS_COLOR_CLASS = {
  pending: '#ffaa00',
  accepted: '#2ecc71',
  cancelled: '#b2b2b2',
};

export const TABS = {
  order: 'orders',
  sale: 'sales',
  sellerPackages: 'sellerPackages',
  buyerPackages: 'buyerPackages',
  progressReports: 'progressReports',
  myClients: 'myClients',
  progressReportsClient: 'progressReportsClient',
  transactions: 'transactions',
  enquiry: 'enquiry',
  enquiryClient: 'enquiry-client',
  schedule: 'schedule',
  scheduleClient: 'schedule-client',
  proConnectRequests: 'pro_connect_requests',
};

const STATE_BTNS = [
  {
    state: 'all',
    label: <FormattedMessage id={'InboxPage.stateLabelAll'} />,
    tabs: ['sales', 'orders'],
  },

  {
    state: 'pending',
    label: <FormattedMessage id={'InboxPage.stateLabelRequested'} />,
    tabs: ['orders'],
  },

  {
    state: 'accepted',
    label: <FormattedMessage id={'InboxPage.stateLabelAccepted'} />,
    tabs: ['orders'],
  },
  {
    state: 'delivered',
    label: <FormattedMessage id={'InboxPage.stateLabelCollaborating'} />,
    tabs: ['orders'],
  },

  {
    state: 'completed_customer',
    label: <FormattedMessage id={'InboxPage.stateLabelCompleted'} />,
    tabs: ['orders'],
  },
  {
    state: 'pending',
    label: <FormattedMessage id={'InboxPage.stateLabelRequested'} />,
    tabs: ['sales'],
  },
  {
    state: 'accepted',
    label: <FormattedMessage id={'InboxPage.stateLabelAccepted'} />,
    tabs: ['sales'],
  },
  {
    state: 'delivered',
    label: <FormattedMessage id={'InboxPage.stateLabelCollaborating'} />,
    tabs: ['sales'],
  },
  {
    state: 'completed',
    label: <FormattedMessage id={'InboxPage.stateLabelCompleted'} />,
    tabs: ['sales'],
  },
  {
    state: 'canceled',
    label: <FormattedMessage id={'InboxPage.stateLabelCanceled'} />,
    tabs: ['sales', 'orders'],
  },
];

const STATES_WITH_MULTI_SELECT = ['ready-to-complete', 'accepted', 'pending', 'all'];

const actionInvisibleState = ['pending', 'accepted', 'canceled'];

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
  const listingId = listing.id && listing.id.uuid;
  const label = listing.attributes.title;
  const listingDeleted = listing.attributes.deleted;

  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <Avatar user={otherUser} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const StateButtons = ({ params, onClick, buttons }) => {
  const { tab, state = 'all' } = params;

  const stateBtns = buttons.filter(btn => btn.tabs.includes(tab));
  return (
    <div className={css.stateBtnsWrap}>
      {stateBtns.map(btn => (
        <TransactionStateButton
          key={btn.state}
          onClick={() => onClick(btn.state)}
          isActive={btn.state === state}
          {...btn}
        />
      ))}
    </div>
  );
};

export class InboxPageComponent extends React.Component {
  constructor(props) {
    super(props);

    const { params } = props;

    this.state = {
      selectedBookings: [],
      selectedEnquireTx: {},
      isMultiActionInProgress: false,
      isCSVDownloadOpen: false,
      isCSVPackagesDownloadOpen: false,
      searchPackagesQuery: '',
      isSendMessageModalOpen: false,
      showSuccessMessage: false,
      actionRequired: params.action === 'action-required',
    };

    this.acceptPackageRequest = this.acceptPackageRequest.bind(this);
    this.rejectPackageRequest = this.rejectPackageRequest.bind(this);
    // this.handleTriggerClick = this.handleTriggerClick.bind(this);
    this.proceedToProgressReportHandler = this.proceedToProgressReportHandler.bind(this);
    this.handlePackageRequestQueryChange = this.handlePackageRequestQueryChange.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.onEnquiryMessageSubmit = this.onEnquiryMessageSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.actionRequired !== this.state.actionRequired) {
      const action = this.state.actionRequired ? 'action-required' : 'all';
      this.actionRequiredToggleHandler(action);
    }

    if (prevProps.params.tab !== this.props.params.tab) {
      this.setState(prevState => ({
        ...prevState,
        actionRequired: false,
      }));
    }
  }

  toggleCSVDownload = value => {
    this.setState({
      isCSVDownloadOpen: value,
    });
  };

  toggleCSVPackagesDownload = value => {
    this.setState({
      isCSVPackagesDownloadOpen: value,
    });
  };

  onCloseSuccessMessage = () => {
    this.setState({ showSuccessMessage: false });
  };

  acceptPackageRequest(e) {
    e.preventDefault();
    e.stopPropagation();
    const packageReqId = e.target.closest('button').dataset.id;
    this.props.onAcceptPackageRequest(packageReqId);
  }

  rejectPackageRequest(e) {
    e.preventDefault();
    e.stopPropagation();
    const packageReqId = e.target.closest('button').dataset.id;
    this.props.onRejectPackageRequest(packageReqId, this.props.currentUser.id.uuid);
  }

  searchPackages = debounce(value => {
    const { tab } = this.props.params;
    const isSellerPackages = tab === 'sellerPackages';
    const isBuyerPackages = tab === 'buyerPackages';
    if (isBuyerPackages) this.props.onBuyerPackageRequests(this.props.currentUser.id.uuid, value);
    else if (isSellerPackages)
      this.props.onSellerPackageRequests(this.props.currentUser.id.uuid, value);
  }, 900);

  handlePackageRequestQueryChange(e) {
    e.preventDefault();
    this.setState({
      searchPackagesQuery: e.target.value,
    });
    if (this.props.currentUser) this.searchPackages(e.target.value);
  }

  cancelDeclineHandler = transition => tx => {
    const txId = tx.id.uuid;
    const customerId = tx.customer.id.uuid;
    const sellerId = tx.provider.id.uuid;
    const listing = tx.listing.id.uuid;
    const withCredits = tx.attributes.protectedData.withCredits;

    const chargeId = tx?.attributes?.protectedData?.chargeId;

    return post('/api/refund-amount-to-expert', { chargeId })
      .then(resp => {
        return this.props.transitionTransaction({
          transactionId: txId,
          transition: transition,
          withCredits: withCredits,
          customerId: customerId,
          sellerId: sellerId,
          listing: listing,
        });
      })
      .catch(e => console.log(e));
  };

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;

    const { onSendMessage } = this.props;
    const { selectedBookings } = this.state;
    if (!message) {
      return;
    }
    selectedBookings.forEach(tx => {
      const ensuredTransaction = ensureTransaction(tx);

      onSendMessage(ensuredTransaction.id, message)
        .then(messageId => {})
        .catch(e => {
          console.error(e);
        });
    });
    this.setState({
      isSendMessageModalOpen: false,
      isMultiActionInProgress: false,
      showSuccessMessage: true,
      selectedBookings: [],
    });
  }

  onEnquiryMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;

    const { onSendMessage } = this.props;
    const { selectedEnquireTx } = this.state;
    if (!message) {
      return;
    }
    const ensuredTransaction = ensureTransaction(selectedEnquireTx);

    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        this.setState({
          isSendMessageModalOpen: false,
          showSuccessMessage: true,
          selectedEnquireTx: {},
        });
      })
      .catch(e => {
        this.setState({ isSendMessageModalOpen: false, selectedEnquireTx: {} });
        console.error(e);
      });
  }

  completeAcceptHandler = transition => tx => {
    const uuid = tx.id.uuid;
    const listing = tx.listing.id.uuid;
    const sellerId = tx.provider.id.uuid;

    const chargeId = tx?.attributes?.protectedData?.chargeId;
    const partnerId = this.props?.currentUser?.attributes?.profile?.publicData?.adminId;
    const payoutAmount = tx?.attributes?.payoutTotal?.amount;
    const payinAmount = tx?.attributes?.payinTotal?.amount;

    if (!chargeId || !partnerId || !payoutAmount || !payinAmount) {
      return null;
    } else {
      this.props.transitionTransaction({
        transactionId: uuid,
        transition: transition,
        sellerId: sellerId,
        listing: listing,
      });
      // return post('/api/charge-commission-from-partner', {
      //   chargeId,
      //   partnerId,
      //   payinAmount,
      // })
      //   .then(resp => {
      //     this.props.transitionTransaction({
      //       transactionId: uuid,
      //       transition: transition,
      //       sellerId: sellerId,
      //       listing: listing,
      //     });
      //   })
      //   .catch(e => {
      //     this.props.transitionTransaction({
      //       transactionId: uuid,
      //       transition: transition,
      //       sellerId: sellerId,
      //       listing: listing,
      //     });
      //     console.log(e);
      //   });
    }
  };

  rescheduleHandler = transition => (tx, rescheduleParams) => {
    const uuid = tx.id.uuid;
    this.props.transitionTransaction({
      transactionId: uuid,
      transition: transition,
      rescheduleParams: rescheduleParams,
    });
  };

  allBookingsSelectHandler = () => {
    const { selectedBookings } = this.state;
    const { transactions } = this.props;

    const areAllSelected = selectedBookings.length === transactions.length;
    this.setState({ selectedBookings: areAllSelected ? [] : transactions });
  };

  allScheduleSelectedHandler = allSelectedData => {
    this.setState({ selectedBookings: allSelectedData });
  };

  bookingsSelectHandler = newTx => {
    this.setState(state => {
      if (state.selectedBookings.some(tx => tx.id.uuid === newTx.id.uuid)) {
        return {
          selectedBookings: state.selectedBookings.filter(tx => tx.id.uuid !== newTx.id.uuid),
        };
      }

      return {
        selectedBookings: [...state.selectedBookings, newTx],
      };
    });
  };

  bookAgainHandler = tx => {
    const { history } = this.props;
    if (tx.listing.attributes.title && tx.listing.id.uuid) {
      const listingLink = createResourceLocatorString(
        'ListingPage',
        routeConfiguration(),
        {
          slug: createSlug(tx.listing.attributes.title),
          id: tx.listing.id.uuid,
        },
        {}
      );
      history.push(listingLink);
    }
  };

  onRenderSortByPopup = () => {
    const { history, params } = this.props;
    const urlQueryParams = parse(history.location.search) || {};
    const handleSortBy = (urlParam, values) => {
      const queryParams = { ...urlQueryParams, [urlParam]: values };
      return history.push(
        createResourceLocatorString('InboxPage', routeConfiguration(), { ...params }, queryParams)
      );
    };
    const sortProps = {
      rootClassName: css.sortStyle,
      urlParam: 'sort',
      label: <FormattedMessage id={'SchedulePageContent.sortByHeading'} />,
      options: [
        { key: 'default', label: 'Default' },
        { key: 'asc', label: 'Ascending' },
        { key: 'desc', label: 'Descending' },
      ],
      initialValue: urlQueryParams && urlQueryParams.sort ? urlQueryParams.sort : 'default',
      onSelect: handleSortBy,
    };
    return <SortByPopup {...sortProps} />;
  };

  onRenderGroupByPopup = () => {
    const { history, params } = this.props;
    const urlQueryParams = parse(history.location.search) || {};
    const handleGroupBy = (urlParam, values) => {
      const queryParams = { ...urlQueryParams, [urlParam]: values };
      return history.push(
        createResourceLocatorString('InboxPage', routeConfiguration(), { ...params }, queryParams)
      );
    };
    const sortProps = {
      urlParam: 'group',
      label: <FormattedMessage id={'SchedulePageContent.GroupByHeading'} />,
      options: [
        { key: 'listing-name', label: 'Listing Name' },
        { key: 'booking-date', label: 'Booking Date' },
      ],
      initialValue: urlQueryParams && urlQueryParams.group ? urlQueryParams.group : 'listing-name',
      onSelect: handleGroupBy,
    };
    return <SortByPopup {...sortProps} />;
  };

  onSendMessageModalOpen = () => {
    if (this.state.selectedBookings.length === 0) {
      this.setState({ isMultiActionInProgress: false });
      return;
    }
    this.setState({ isSendMessageModalOpen: true });
  };

  onEnquireMessageModalOpen = tx => {
    this.setState({ isSendMessageModalOpen: true, selectedEnquireTx: tx });
  };

  proceedToProgressReportHandler(e) {
    const { history, isAnyPendingProgressReport } = this.props;
    if (isAnyPendingProgressReport) {
      const progressReportLink = createResourceLocatorString(
        'ProgressReportPage',
        routeConfiguration(),
        {},
        {}
      );
      history.push(progressReportLink);
    }
  }

  statusButtonClickHandler = state => {
    const { history, params } = this.props;
    const actionUpdated = actionInvisibleState.includes(state) ? 'all' : params.action;

    actionInvisibleState.includes(state) &&
      this.setState(prevState => ({
        ...prevState,
        actionRequired: false,
      }));

    history.push(
      createResourceLocatorString('InboxPage', routeConfiguration(), {
        ...params,
        state,
        action: actionUpdated,
      })
    );
  };

  actionRequiredToggleHandler = action => {
    const { history, params } = this.props;

    // when action required toggle on, automatically update the state to 'all'
    const stateUpdated = action === 'action-required' ? 'all' : params.state;

    history.push(
      createResourceLocatorString('InboxPage', routeConfiguration(), {
        ...params,
        state: stateUpdated,
        action,
      })
    );
  };

  render() {
    const {
      unitType,
      currentUser,
      fetchInProgress,
      fetchOrdersOrSalesError,
      intl,
      pagination,
      params,
      providerNotificationCount,
      saleEnquiriesNotificationCount,
      orderEnquiriesNotificationCount,
      providerPackagesNotificationCount,
      scrollingDisabled,
      transactions,
      manageDisableScrolling,
      buyerPackageRequests,
      fetchBuyerPackageRequestError,
      fetchBuyerPackageRequestInProgress,
      sellerPackageRequests,
      fetchSellerPackageRequestInProgress,
      fetchSellerPackageRequestError,
      acceptPackageRequestInProgress,
      cancelPackageRequestInProgress,
      fetchCSVDataInProgress,
      fetchCSVPackagesDataInProgress,
      packagesListings,
      packagesBuyers,
      incomeTransactions,
      incomeTransactionsLoading,
      viewport,
      enquiries,
      fetchEnquiryError,
      ownListings,
      fetchSellerProgressReportsRequestError,
      fetchSellerProgressReportsRequestInProgress,
      sellerProgressReports,
      fetchBuyerProgressReportsRequestError,
      fetchBuyerProgressReportsRequestInProgress,
      buyerProgressReports,
      progressReportsListings,
      progressReportsBuyers,
      downloadDeliveredTransactions,
      onDownloadPackages,
      onRescheduleBooking,
      rescheduleRequestInProgress,
      rescheduleError,
      rescheduledsuccessfully,
      onFetchTimeSlots,
      onFetchMonthlyTimeSlots,
      monthlyTimeSlots,
      walletListings,
      walletRequestInProgress,
      history,
      ScheduleTransactions,
      recentTransactions,
      isLoading,
      sellerPackagesPagination,
      buyerPackagesPagination,
    } = this.props;

    const { selectedBookings, actionRequired } = this.state;

    const { tab, state } = params;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const validTab = Object.entries(TABS).some(([key, t]) => t === tab);
    if (!validTab) {
      return <NotFoundPage />;
    }

    const isOrders = tab === 'orders';
    const isSaleOrOrder = tab === 'sales' || tab === 'orders';
    const isSellerPackages = tab === 'sellerPackages';
    const isBuyerPackages = tab === 'buyerPackages';

    const isSellerProgressReports = tab === 'progressReports';
    const isBuyerProgressReports = tab === 'progressReportsClient';

    const isTransactions = tab === 'transactions';
    const isEnquiry = tab === 'enquiry' || tab === 'enquiry-client';
    const isProConnectRequests = tab === 'pro_connect_requests';
    const isSchedule = tab === 'schedule';
    const isScheduleClient = tab === 'schedule-client';
    const isMyClients = tab === 'myClients';

    const showTopNav = isMyClients || isTransactions || isEnquiry;

    const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
    const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
    const title = isOrders ? ordersTitle : salesTitle;
    const searchPackageRequestPlaceholder = intl.formatMessage({
      id: 'InboxPage.searchPackageRequestPlaceholder',
    });

    const toGroupableTxItem = () => {
      const type = isOrders ? 'order' : 'sale';
      let entries = [];
      const { history } = this.props;
      const urlQueryParams = parse(history.location.search) || {};
      const sortBy = urlQueryParams.sort ? urlQueryParams.sort : 'default';
      const groupBy = urlQueryParams.group ? urlQueryParams.group : 'listing-name';

      if (state === 'ready-to-complete') {
        let orderedTransactions = transactions;
        if (groupBy === 'booking-date') {
          orderedTransactions = orderBy(transactions, 'booking.attributes.displayStart', 'desc');
        } else {
          if (sortBy !== 'default') {
            orderedTransactions = orderBy(transactions, 'listing.attributes.title', sortBy);
          }
        }

        entries = orderedTransactions.reduce((acc, item) => {
          const index = acc.findIndex(txArr => {
            return txArr.some(txItem => {
              if (groupBy === 'booking-date')
                return (
                  moment(
                    txItem && txItem.booking ? txItem.booking.attributes.displayStart : null
                  ).format('dddd MMMM Do') ===
                  moment(item && item.booking ? item.booking.attributes.displayStart : null).format(
                    'dddd MMMM Do'
                  )
                );
              else {
                const txItemListingTitle =
                  txItem && txItem.listing ? txItem.listing.attributes.title : null;
                const itemListingTitle =
                  item && item.listing ? item.listing.attributes.title : null;
                return txItemListingTitle === itemListingTitle;
              }
            });
          });
          index >= 0 ? acc[index].push(item) : acc.push([item]);
          return acc;
        }, []);
      } else {
        entries = orderBy(transactions, 'booking.attributes.displayStart', 'desc').reduce(
          (acc, item) => {
            const index = acc.findIndex(txArr => {
              return txArr.some(txItem => {
                return (
                  moment(
                    txItem && txItem.booking ? txItem.booking.attributes.displayStart : null
                  ).format('dddd MMMM Do') ===
                  moment(item && item.booking ? item.booking.attributes.displayStart : null).format(
                    'dddd MMMM Do'
                  )
                );
              });
            });
            index >= 0 ? acc[index].push(item) : acc.push([item]);
            return acc;
          },
          []
        );
      }

      return (
        entries &&
        entries.map((entry, index) => (
          <GroupRow
            key={`M${index}`}
            unitType={unitType}
            state={state}
            type={type}
            entries={entry}
            intl={intl}
            index={index}
            onComplete={this.completeAcceptHandler(TRANSITION_COMPLETE_MANUAL)}
            onCancel={this.cancelDeclineHandler}
            onDecline={this.cancelDeclineHandler(TRANSITION_DECLINE)}
            onAccept={this.completeAcceptHandler(TRANSITION_ACCEPT)}
            onManageDisableScrolling={manageDisableScrolling}
            onSelect={
              STATES_WITH_MULTI_SELECT.includes(state) || (type === 'sale' && state === undefined)
                ? this.bookingsSelectHandler
                : null
            }
            selectedBookings={selectedBookings}
            onBookAgain={this.bookAgainHandler}
            rescheduleBooking={onRescheduleBooking}
            rescheduleRequestInProgress={rescheduleRequestInProgress}
            rescheduleError={rescheduleError}
            rescheduledsuccessfully={rescheduledsuccessfully}
            currentUser={ensuredCurrentUser}
            onFetchTimeSlots={onFetchTimeSlots}
            onFetchMonthlyTimeSlots={onFetchMonthlyTimeSlots}
            monthlyTimeSlots={monthlyTimeSlots}
            sortBy={sortBy}
            groupBy={groupBy}
          />
        ))
      );
    };

    const toTxItem = tx => {
      let type = isOrders ? 'order' : 'sale';
      if (tab === 'enquiry-client') {
        type = 'order';
      }
      const stateData = txState(intl, tx, type);
      // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
      //tx.provider && currentUser && tx.provider.id.uuid === currentUser.id.uuid
      return stateData ? (
        <li key={tx.id.uuid} className={cns(css.listItem, isEnquiry && css.enquirylistItem)}>
          <InboxItemWithButtons
            unitType={unitType}
            type={type}
            tx={tx}
            intl={intl}
            stateData={stateData}
            onComplete={this.completeAcceptHandler(TRANSITION_COMPLETE_MANUAL)}
            onCancel={
              txIsDelivered(tx)
                ? this.cancelDeclineHandler(TRANSITION_CANCEL_READY_TO_COMPLETE)
                : this.cancelDeclineHandler(TRANSITION_CANCEL)
            }
            onDecline={this.cancelDeclineHandler(TRANSITION_DECLINE)}
            onAccept={this.completeAcceptHandler(TRANSITION_ACCEPT)}
            onManageDisableScrolling={manageDisableScrolling}
            onSelect={STATES_WITH_MULTI_SELECT.includes(state) ? this.bookingsSelectHandler : null}
            isSelected={selectedBookings.some(selectedTx => selectedTx.id.uuid === tx.id.uuid)}
            onBookAgain={this.bookAgainHandler}
            rescheduleBooking={onRescheduleBooking}
            rescheduleRequestInProgress={rescheduleRequestInProgress}
            rescheduleError={rescheduleError}
            rescheduledsuccessfully={rescheduledsuccessfully}
            currentUser={ensuredCurrentUser}
            onFetchTimeSlots={onFetchTimeSlots}
            onFetchMonthlyTimeSlots={onFetchMonthlyTimeSlots}
            monthlyTimeSlots={monthlyTimeSlots}
            onEnquireMessageModalOpen={this.onEnquireMessageModalOpen}
            isEnquiryTab={isEnquiry}
          />
        </li>
      ) : null;
    };

    const toPackageRequestItem = pr => {
      const slug = createSlug(pr.listing_title);
      const listing = packagesListings.filter(listing => listing.id.uuid === pr.listing_id)[0];
      const otherUser = isSellerPackages
        ? packagesBuyers.filter(buyer => buyer.id.uuid === pr.buyer_id)[0]
        : isBuyerPackages && listing
        ? listing.author
        : null;

      const listingLink = listing && otherUser ? createListingLink(listing, otherUser) : null;
      const color = STATUS_COLOR_CLASS[pr.status];
      const paymentStatusColor = pr.payment_status === 'paid' ? '#2ecc71' : '#b2b2b2';
      const totalPrAmount = (pr.amount / 100.0).toFixed(2) + ` ${pr.currency}`;
      const usedPrAmount =
        ((pr.amount / 100.0 / pr.credits) * pr.used_credits).toFixed(2) + ` ${pr.currency}`;
      const remainingPrAmount =
        ((pr.amount / 100.0 / pr.credits) * (pr.credits - pr.used_credits)).toFixed(2) +
        ` ${pr.currency}`;
      const trigger = (
        <div className={css.trigger}>
          <div className={css.triggerSectionLeft}>
            <div>{listingLink}</div>
            <div className={css.userAndListingName}>
              {otherUser ? (
                <div className={css.userName}>
                  <NamedLink name={'ProfilePage'} params={{ id: otherUser.id.uuid }}>
                    {otherUser.attributes.profile.displayName}
                  </NamedLink>
                </div>
              ) : null}
              <div className={css.listingTitle}>
                <NamedLink name={'ListingPage'} params={{ slug: slug, id: pr.listing_id }}>
                  {pr.listing_title}
                </NamedLink>
              </div>
              <div className={css.createdAt}>
                {moment(pr.created_at).format('MMM Do YY, h:mm:ss a')}
              </div>
            </div>
            <div className={css.sessions}>
              {pr.credits} <FormattedMessage id="InboxPage.sessions" />
              <span className={css.prAmount}>{totalPrAmount}</span>
            </div>
            <div className={css.usedSessions}>
              {pr.credits - pr.used_credits} <FormattedMessage id="InboxPage.sessions" />
              <span className={css.prAmount}>{remainingPrAmount}</span>
            </div>
          </div>
          <div className={css.triggerSectionRight}>
            <div style={{ color: color }} className={css.status}>
              {pr.status.toUpperCase()}
            </div>
            {pr.payment_type === 'online_payment' ? (
              <div className={css.paymentInfoContainer}>
                <div className={css.payment_type}>
                  <FormattedMessage id="InboxPage.onlinePayment" />
                </div>
                <div style={{ color: paymentStatusColor }} className={css.payment_status}>
                  {pr.payment_status && pr.payment_status.toUpperCase()}
                </div>
              </div>
            ) : null}
            {pr.payment_type !== 'online_payment' &&
            pr.status === 'pending' &&
            ensuredCurrentUser.id.uuid === pr.seller_id ? (
              <div className={css.packageRequestActions}>
                <PrimaryButton
                  data-id={pr.id}
                  className={`${css.acceptPackageRequest} ${'avoidTrigger'}`}
                  spinnerClassName={css.spinner}
                  disabled={acceptPackageRequestInProgress}
                  onClick={this.acceptPackageRequest}
                >
                  <FormattedMessage id="InboxPage.acceptPackageRequest" />
                </PrimaryButton>

                <SecondaryButton
                  data-id={pr.id}
                  className={`${css.rejectPackageRequest} ${'avoidTrigger'}`}
                  spinnerClassName={css.spinner}
                  disabled={cancelPackageRequestInProgress}
                  onClick={this.rejectPackageRequest}
                >
                  <FormattedMessage id="InboxPage.rejectPackageRequest" />
                </SecondaryButton>
              </div>
            ) : null}
            <div className={css.iconArrowDropDown}>
              <IconArrowDown selected={false} />
            </div>
          </div>
        </div>
      );
      return pr ? (
        <div key={pr.id}>
          <li className={css.listItem}>
            <Collapsible
              trigger={trigger}
              className={css.collapsible}
              openedClassName={css.collapsible}
            >
              <div>
                <h3>Total amount of package</h3>
                <div>{totalPrAmount}</div>

                <h3>Total amount of package sold</h3>
                <div>{usedPrAmount}</div>

                <h3>Remaining amount of package</h3>
                <div>{remainingPrAmount}</div>
              </div>

              <div className={css.paymentProofsContainer}>
                {pr.payment_proof_ss_urls.length > 0 ? (
                  <div>
                    <h3>
                      <FormattedMessage id="InboxPage.paymentScreenshots" />
                    </h3>
                    <div className={css.paymentProofSsContainer}>
                      {pr.payment_proof_ss_urls.map((ss, index) => {
                        return (
                          <a
                            key={`proof${index}`}
                            className={css.paymentProofSs}
                            // eslint-disable-next-line
                            target="_blank"
                            href={ss}
                          >
                            <img src={ss} alt="paymentProofSs" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {pr.membership_card_number ? (
                  <div>
                    <h3>
                      <FormattedMessage id="InboxPage.membershipCardNumber" />
                    </h3>
                    {pr.membership_card_number}
                  </div>
                ) : null}
                {pr.student_name ? (
                  <div>
                    <h3>
                      <FormattedMessage id="InboxPage.studentName" />
                    </h3>
                    {pr.student_name}
                  </div>
                ) : null}
                {pr.comments ? (
                  <div>
                    <h3>
                      <FormattedMessage id="InboxPage.comments" />
                    </h3>
                    {pr.comments}
                  </div>
                ) : null}
              </div>
              <div className={css.transactions}>
                {pr.transactions && pr.transactions.length > 0 ? (
                  <h3>Booking creation date</h3>
                ) : null}
                {pr.transactions &&
                  pr.transactions.length > 0 &&
                  pr.transactions.map(function(transaction, i) {
                    const refunds =
                      pr.refunds &&
                      pr.refunds.length > 0 &&
                      pr.refunds.map(refund => refund.transaction_id);
                    const refunded =
                      refunds && refunds.length > 0 && refunds.includes(transaction.transaction_id);
                    const user =
                      ensuredCurrentUser.id.uuid === pr.seller_id
                        ? otherUser
                          ? otherUser.attributes.profile.displayName
                          : 'Buyer'
                        : 'You';
                    return refunded ? null : (
                      <p key={`t${i}`}>
                        {user} booked {transaction.credits_used} hour(s) on{' '}
                        {moment(transaction.created_at).format('MMM Do YY, h:mm:ss a')} for amount{' '}
                        {(transaction.amountPaid / 100.0).toFixed(2)} {pr.currency}
                      </p>
                    );
                  })}
              </div>
            </Collapsible>
          </li>
        </div>
      ) : null;
    };

    const toProgressReportItem = pr => {
      const listing = progressReportsListings.filter(
        listing => listing.id.uuid === pr.listing_id
      )[0];
      const otherUser = isSellerProgressReports
        ? progressReportsBuyers.filter(buyer => buyer.id.uuid === pr.buyer_id)[0]
        : isBuyerProgressReports && listing
        ? listing.author
        : null;

      if (!listing || !otherUser) return;
      const slug = createSlug(listing.attributes.title);
      const listingLink = listing && otherUser ? createListingLink(listing, otherUser) : null;

      const trigger = (
        <div className={css.trigger}>
          <div className={css.triggerSectionLeft}>
            <div>{listingLink}</div>
            <div className={css.userAndListingName}>
              {otherUser ? (
                <div className={css.userName}>
                  <NamedLink name={'ProfilePage'} params={{ id: otherUser.id.uuid }}>
                    {otherUser.attributes.profile.displayName}
                  </NamedLink>
                </div>
              ) : null}
              <div className={css.listingTitle}>
                <NamedLink name={'ListingPage'} params={{ slug: slug, id: listing.id.uuid }}>
                  {listing.attributes.title}
                </NamedLink>
              </div>
              <div className={css.transactionLink}>
                <NamedLink
                  name={isBuyerProgressReports ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: pr.transaction_id }}
                >
                  {isBuyerProgressReports ? (
                    <FormattedMessage id="InboxPage.orderDetails" />
                  ) : (
                    <FormattedMessage id="InboxPage.saleDetails" />
                  )}
                </NamedLink>
              </div>
              <div className={css.createdAt}>
                {moment(pr.created_at).format('MMM Do YY, h:mm:ss a')}
              </div>
            </div>
            <div className={css.notes}>{pr.notes}</div>
          </div>
          <div className={css.triggerSectionRight}>
            <div className={css.iconArrowDown}>
              <IconArrowDown selected={false} />
            </div>
          </div>
        </div>
      );
      return pr ? (
        <div>
          <li className={css.listItem}>
            <Collapsible
              trigger={trigger}
              className={css.collapsible}
              openedClassName={css.collapsible}
            >
              <div>
                <h2>Notes</h2>
                <p>{pr.notes}</p>
              </div>

              <div className={css.attachmentContainer}>
                {pr.attachment && pr.attachment !== '' ? (
                  <div className={css.attachment}>
                    {pr.attachment_type === 'image' ? (
                      <a
                        // eslint-disable-next-line
                        target="_blank"
                        href={pr.attachment}
                      >
                        <img src={pr.attachment} alt="attachment" />
                      </a>
                    ) : pr.attachment_type === 'video' ? (
                      <video src={pr.attachment} controls width="100%">
                        <source
                          src={pr.attachment}
                          type={`video/${getExtensionFromUrl(pr.attachment)}`}
                        />
                      </video>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Collapsible>
          </li>
        </div>
      ) : null;
    };

    const error =
      fetchOrdersOrSalesError && fetchEnquiryError ? (
        <p className={css.error}>
          <FormattedMessage id="InboxPage.fetchFailed" />
        </p>
      ) : null;

    const noResults =
      !fetchInProgress &&
      !isResults(tab, transactions, enquiries) &&
      !fetchOrdersOrSalesError &&
      !fetchEnquiryError ? (
        <li key="noResults" className={css.noResults}>
          <FormattedMessage id={getNoResultsMessageId(tab, this.state.actionRequired)} />
        </li>
      ) : null;

    const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
      return isOrdersTab
        ? user.id && tx && tx.length > 0 && get(tx[0], 'customer.id.uuid') === user.id.uuid
        : user.id && tx && tx.length > 0 && get(tx[0], 'provider.id.uuid') === user.id.uuid;
    };
    const hasTransactions =
      !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
    const hasEnquiries = ensuredCurrentUser.id && enquiries && enquiries.length;
    const noPagination = isSellerProgressReports || isBuyerProgressReports ? true : false;
    const pagingLinks =
      !noPagination &&
      (hasTransactions || hasEnquiries) &&
      pagination &&
      pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="InboxPage"
          pagePathParams={params}
          pagination={pagination}
        />
      ) : isSellerPackages &&
        sellerPackagesPagination &&
        sellerPackagesPagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="InboxPage"
          pagePathParams={params}
          pageSearchParams={{ query: this.state.searchPackagesQuery }}
          pagination={sellerPackagesPagination}
        />
      ) : isBuyerPackages && buyerPackagesPagination && buyerPackagesPagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="InboxPage"
          pagePathParams={params}
          pageSearchParams={{ query: this.state.searchPackagesQuery }}
          pagination={buyerPackagesPagination}
        />
      ) : null;

    const providerNotificationBadge =
      providerNotificationCount > 0 ? (
        <NotificationBadge count={providerNotificationCount} />
      ) : null;

    const saleEnquiriesNotificationBadge =
      saleEnquiriesNotificationCount > 0 ? (
        <NotificationBadge count={saleEnquiriesNotificationCount} />
      ) : null;

    const orderEnquiriesNotificationBadge =
      orderEnquiriesNotificationCount > 0 ? (
        <NotificationBadge count={orderEnquiriesNotificationCount} />
      ) : null;

    const providerPackagesNotificationBadge =
      providerPackagesNotificationCount > 0 ? (
        <NotificationBadge count={providerPackagesNotificationCount} />
      ) : null;

    const tabs = [
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.ordersTabTitle" />
          </span>
        ),
        svgIcon: <IconOrdersTab />,
        selected: tab === 'orders',
        fillSvg: true,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders' },
        },
        showWith: [
          'orders',
          'buyerPackages',
          'progressReportsClient',
          'pro_connect_requests',
          'enquiry-client',
          'schedule-client',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.salesTabTitle" />
            {providerNotificationBadge}
          </span>
        ),
        svgIcon: <IconSalesTab />,
        selected: tab === 'sales',
        fillSvg: true,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          // 'progressReports',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.schedule" />
          </span>
        ),
        svgIcon: <IconScheduleTab />,
        fillSvg: true,
        selected: isSchedule,
        linkProps: {
          // name: 'BookingPlan',
          // params: { period: 'active' },
          name: 'InboxPage',
          params: { tab: 'schedule' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          // 'progressReports',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.schedule" />
          </span>
        ),
        svgIcon: <IconScheduleTab />,
        fillSvg: true,
        selected: isScheduleClient,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'schedule-client' },
        },
        showWith: [
          'orders',
          'buyerPackages',
          'progressReportsClient',
          'pro_connect_requests',
          'enquiry-client',
          'schedule-client',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.enquiryTabTitle" />
            {saleEnquiriesNotificationBadge}
          </span>
        ),
        svgIcon: <IconEnquiryTab />,
        selected: tab === 'enquiry',
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'enquiry' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          // 'progressReports',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.enquiryTabTitle" />
            {orderEnquiriesNotificationBadge}
          </span>
        ),
        svgIcon: <IconEnquiryTab />,
        selected: tab === 'enquiry-client',
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'enquiry-client' },
        },
        showWith: [
          'orders',
          'buyerPackages',
          'progressReportsClient',
          'pro_connect_requests',
          'enquiry-client',
          'schedule-client',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.myClient" />
          </span>
        ),
        svgIcon: <IconMyClientTab />,
        selected: isMyClients,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'myClients' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          // 'progressReports',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.proConnectRequests" />
          </span>
        ),
        svgIcon: <IconProgressReportsTab />,
        selected: isProConnectRequests,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'pro_connect_requests' },
        },
        showWith: [
          'orders',
          'buyerPackages',
          'progressReportsClient',
          'pro_connect_requests',
          'enquiry-client',
          'schedule-client',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.transactions" />
          </span>
        ),
        svgIcon: <IconTransactionsTab />,
        selected: isTransactions,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'transactions' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          // 'progressReports',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.deliverdBookings" />
            <br />
            <FormattedMessage id="InboxPage.clickToDownload" />
          </span>
        ),
        onClick: () => this.toggleCSVDownload(true),
        showWith: [
          'sales',
          'sellerPackages',
          // 'progressReports',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
        ],
      },
    ];
    const tabsToRender = tabs.filter(i => i.showWith.includes(tab));
    const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabsToRender} />;
    const getTabName = tab => {
      switch (tab) {
        case 'sales':
        case 'orders':
          return 'Bookings';
        case 'sellerPackages':
          return 'Your Packages';
        case 'enquiry':
        case 'enquiry-client':
          return 'Enquiries';
        case 'pro_connect_requests':
          return 'Pro Connect Requests';
        case 'progressReports':
          return 'Progress Reports';
        case 'myClients':
          return 'My Clients';
        case 'progressReportsClient':
          return 'Progress Reports Client';
        case 'schedule':
        case 'schedule-client':
          return 'Schedule';
        case 'buyerPackages':
          return 'Buyer Packages';
        default:
          return tab;
      }
    };

    const walletPanelWidth = 62.5;
    const walletRenderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${walletPanelWidth / 2}vw`,
      `${walletPanelWidth / 3}vw`,
    ].join(', ');

    const wallet =
      currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.sessions
        ? currentUser.attributes.profile.privateData.sessions
        : {};

    const deliveredSortByClientName = intl.formatMessage({
      id: 'InboxPage.deliveredSortByClientName',
    });
    const deliveredSortByClientNameTitle = intl.formatMessage({
      id: 'InboxPage.deliveredSortByClientNameTitle',
    });
    const deliveredSortByBookingDate = intl.formatMessage({
      id: 'InboxPage.deliveredSortByBookingDate',
    });
    const deliveredSortByBookingDateTitle = intl.formatMessage({
      id: 'InboxPage.deliveredSortByBookingDateTitle',
    });

    const packageSortByListingTitle = intl.formatMessage({
      id: 'InboxPage.packageSortByListingTitle',
    });
    const packageSortByListingTitleTitle = intl.formatMessage({
      id: 'InboxPage.packageSortByListingTitleTitle',
    });
    const packageSortByPackageBoughtDate = intl.formatMessage({
      id: 'InboxPage.packageSortByPackageBoughtDate',
    });
    const packageSortByPackageBoughtDateTitle = intl.formatMessage({
      id: 'InboxPage.packageSortByPackageBoughtDateTitle',
    });

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: 'selected users' }
    );

    const sendMessageModal = (
      <Portal portalRoot={document.getElementById('portal-root')}>
        <Modal
          id={'sendMessage'}
          contentClassName={css.modalContent}
          isOpen={this.state.isSendMessageModalOpen}
          onClose={() =>
            this.setState({ isSendMessageModalOpen: false, isMultiActionInProgress: false })
          }
          onManageDisableScrolling={manageDisableScrolling}
        >
          {this.props.sendMessageInProgress ? (
            <IconSpinner />
          ) : (
            <div className={css.contentWrap}>
              <SendMessageForm
                formId={'SendMessageForm'}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                onSubmit={isEnquiry ? this.onEnquiryMessageSubmit : this.onMessageSubmit}
              />
            </div>
          )}
        </Modal>
      </Portal>
    );

    // Action Required toggle button visibility according to selected state
    const actionInvisible = actionInvisibleState.includes(state);

    const headContent = (
      <>
        <div className={css.weekdayRow}>
          <div className={css.weekdayName}>
            <span className={css.bookingNumber}>
              bookings <span>-</span> {moment({}).format('dddd DD MMM')}{' '}
            </span>
          </div>
          {!actionInvisible && (
            <FormControlLabel
              control={
                <VisibleSwitch
                  sx={{ mx: 2 }}
                  defaultChecked={actionRequired}
                  onChange={e => {
                    const checkStatus = e.target.checked;
                    this.setState(prevState => ({
                      ...prevState,
                      actionRequired: checkStatus,
                    }));
                  }}
                />
              }
              className={css.formControlClass}
              label="Action Required"
              labelPlacement="start"
              style={{ width: '300px', color: 'black' }}
            />
          )}
        </div>
      </>
    );

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation containerClassName={css.LayoutSideContainer}>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              currentPage={`InboxPage/${tab}`}
            />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={isEnquiry ? css.enquiryRightPanel : css.rightPanel}>
            {error}
            {sendMessageModal}
            {showTopNav && (
              <div className={css.topNavnar}>
                <NamedLink
                  className={isMyClients ? css.navLinkSelected : css.navLink}
                  name="InboxPage"
                  params={{ tab: 'myClients', state: 'all' }}
                >
                  Customers
                </NamedLink>

                <NamedLink
                  className={isTransactions ? css.navLinkSelected : css.navLink}
                  name="InboxPage"
                  params={{ tab: 'transactions', state: 'all' }}
                >
                  Transactions
                </NamedLink>
              </div>
            )}
            <GenericMessage
              show={this.state.showSuccessMessage}
              onClose={this.onCloseSuccessMessage}
              message={'Messages sent'}
            />

            {(tab === 'sales' || tab === 'orders') && (
              <div className={css.salesHeader}>
                <StateButtons
                  params={params}
                  onClick={this.statusButtonClickHandler}
                  buttons={STATE_BTNS}
                />
              </div>
            )}

            <ul className={cns(css.itemList, isEnquiry && css.enquiryItemList)}>
              <div className={css.weekday}>
                {isSaleOrOrder && headContent}
                {isSellerPackages ? (
                  <div>
                    {fetchSellerPackageRequestError ? (
                      <FormattedMessage id="InboxPage.genericErrorMessage" />
                    ) : null}
                    {!fetchSellerPackageRequestInProgress ? (
                      fetchSellerPackageRequestError ? null : (
                        <div>
                          <div className={css.salesHeader}>
                            <input
                              type="text"
                              placeholder={searchPackageRequestPlaceholder}
                              value={this.state.searchPackagesQuery}
                              className={css.searchPackage}
                              onChange={this.handlePackageRequestQueryChange}
                            />
                            {sellerPackageRequests && sellerPackageRequests.length > 0 ? (
                              <SecondaryButton
                                inProgress={fetchCSVPackagesDataInProgress}
                                className={css.downloadBtn}
                                onClick={() => this.toggleCSVPackagesDownload(true)}
                              >
                                <IconDownload className={css.downloadBtnIcon} />
                                <FormattedMessage id={'InboxPage.downloadPackagesButton'} />
                              </SecondaryButton>
                            ) : null}
                          </div>
                          {sellerPackageRequests && sellerPackageRequests.length > 0 ? (
                            sellerPackageRequests.map(toPackageRequestItem)
                          ) : (
                            <FormattedMessage id="InboxPage.noPackageRequestsAsSeller" />
                          )}
                        </div>
                      )
                    ) : (
                      <li className={css.listItemsLoading}>
                        <IconSpinner />
                      </li>
                    )}
                  </div>
                ) : isBuyerPackages ? (
                  <div>
                    {fetchBuyerPackageRequestError ? (
                      <FormattedMessage id="InboxPage.genericErrorMessage" />
                    ) : null}
                    {!fetchBuyerPackageRequestInProgress ? (
                      fetchBuyerPackageRequestError ? null : (
                        <>
                          <LayoutWrapperMain className={css.staticPageWrapper}>
                            <div className={css.walletListingPanel}>
                              <h1 className={css.title}>
                                <FormattedMessage id="InboxPage.walletHeading" />
                              </h1>
                              <div className={css.walletListingCards}>
                                {walletRequestInProgress ? (
                                  <div className={css.listingsLoading}>
                                    <IconSpinner />
                                  </div>
                                ) : walletListings && walletListings.length > 0 ? (
                                  walletListings.map(l =>
                                    wallet[l.id.uuid] ? (
                                      <WalletListingCard
                                        className={css.walletListingCards}
                                        key={l.id.uuid}
                                        listing={l}
                                        renderSizes={walletRenderSizes}
                                        sessionsRemaining={wallet[l.id.uuid]}
                                      />
                                    ) : null
                                  )
                                ) : (
                                  <FormattedMessage id="InboxPage.noWalletCredits" />
                                )}
                              </div>
                            </div>
                          </LayoutWrapperMain>
                          <div>
                            <input
                              type="text"
                              placeholder={searchPackageRequestPlaceholder}
                              value={this.state.searchPackagesQuery}
                              className={css.searchPackage}
                              onChange={this.handlePackageRequestQueryChange}
                            />
                            {buyerPackageRequests && buyerPackageRequests.length > 0 ? (
                              buyerPackageRequests.map(toPackageRequestItem)
                            ) : (
                              <FormattedMessage id="InboxPage.noPackageRequestsAsBuyer" />
                            )}
                          </div>
                        </>
                      )
                    ) : (
                      <li className={css.listItemsLoading}>
                        <IconSpinner />
                      </li>
                    )}
                  </div>
                ) : isSellerProgressReports ? (
                  <div>
                    {fetchSellerProgressReportsRequestError ? (
                      <FormattedMessage id="InboxPage.genericErrorMessage" />
                    ) : null}
                    {!fetchSellerProgressReportsRequestInProgress ? (
                      fetchSellerProgressReportsRequestError ? null : sellerProgressReports &&
                        sellerProgressReports.length > 0 ? (
                        sellerProgressReports.map(toProgressReportItem)
                      ) : (
                        <FormattedMessage id="InboxPage.noProgressReportAsSeller" />
                      )
                    ) : (
                      <li className={css.listItemsLoading}>
                        <IconSpinner />
                      </li>
                    )}
                  </div>
                ) : isBuyerProgressReports ? (
                  <div>
                    {fetchBuyerProgressReportsRequestError ? (
                      <FormattedMessage id="InboxPage.genericErrorMessage" />
                    ) : null}
                    {!fetchBuyerProgressReportsRequestInProgress ? (
                      fetchBuyerProgressReportsRequestError ? null : buyerProgressReports &&
                        buyerProgressReports.length > 0 ? (
                        buyerProgressReports.map(toProgressReportItem)
                      ) : (
                        <FormattedMessage id="InboxPage.noProgressReportAsBuyer" />
                      )
                    ) : (
                      <li className={css.listItemsLoading}>
                        <IconSpinner />
                      </li>
                    )}
                  </div>
                ) : isTransactions ? (
                  <IncomeContent
                    intl={intl}
                    transactions={incomeTransactions}
                    isLoading={incomeTransactionsLoading}
                    currentUser={ensuredCurrentUser}
                    viewport={viewport}
                  />
                ) : isSchedule ? (
                  !!ownListings.length && (
                    <Schedule
                      transactions={ScheduleTransactions}
                      openModalSendMessage={this.onSendMessageModalOpen}
                      selectedBookings={selectedBookings}
                      onAllSelectedSchedule={this.allScheduleSelectedHandler}
                      onSelect={this.bookingsSelectHandler}
                      isLoading={isLoading}
                      params={params}
                      history={this.props.history}
                    />
                  )
                ) : isMyClients ? (
                  <Schedule
                    recentTransactions={recentTransactions}
                    selectedBookings={selectedBookings}
                    openModalSendMessage={this.onSendMessageModalOpen}
                    onAllSelectedSchedule={this.allScheduleSelectedHandler}
                    onSelect={this.bookingsSelectHandler}
                    isLoading={isLoading}
                    history={this.props.history}
                    pagination={pagination}
                    params={params}
                  />
                ) : isScheduleClient ? (
                  <Schedule
                    transactions={ScheduleTransactions}
                    openModalSendMessage={this.onSendMessageModalOpen}
                    selectedBookings={selectedBookings}
                    onAllSelectedSchedule={this.allScheduleSelectedHandler}
                    onSelect={this.bookingsSelectHandler}
                    isLoading={isLoading}
                    params={params}
                    history={this.props.history}
                  />
                ) : isEnquiry ? (
                  fetchInProgress ? (
                    <li className={css.listItemsLoading}>
                      <IconSpinner />
                    </li>
                  ) : (
                    // <Enquiries
                    //   enquiries={enquiries}
                    //   currentUser={currentUser}
                    //   type={tab === 'enquiry-client' ? 'order' : 'sale'}
                    //   onFetchMessage={this.props.onfetchEnquiryMessages}
                    //   enquiryMessages={this.props.enquiryMessages}
                    //   onEnquirySendMessage={this.props.onEnquirySendMessage}
                    //   enquirySendMessageInProgress={this.props.enquirySendMessageInProgress}
                    //   fetchMessagesInProgress={this.props.fetchMessagesInProgress}
                    //   fetchMessagesError={this.props.fetchMessagesError}
                    //   totalMessagePages={this.props.totalMessagePages}
                    //   oldestMessagePageFetched={this.props.oldestMessagePageFetched}
                    //   viewport={viewport}
                    //   openNav={this.openNav}
                    //   pagingLinks={pagingLinks}
                    //   intl={intl}
                    // />
                    <Schedule
                      recentTransactions={recentTransactions}
                      selectedBookings={selectedBookings}
                      openModalSendMessage={this.onSendMessageModalOpen}
                      onAllSelectedSchedule={this.allScheduleSelectedHandler}
                      onSelect={this.bookingsSelectHandler}
                      isLoading={isLoading}
                      history={this.props.history}
                      pagination={pagination}
                      params={params}
                    />
                  )
                ) : isProConnectRequests ? (
                  fetchInProgress ? (
                    <li className={css.listItemsLoading}>
                      <IconSpinner />
                    </li>
                  ) : (
                    enquiries.map(toTxItem)
                  )
                ) : !fetchInProgress ? (
                  toGroupableTxItem()
                ) : (
                  <li className={css.listItemsLoading}>
                    <IconSpinner />
                  </li>
                )}
                {isTransactions ||
                isSellerPackages ||
                isBuyerPackages ||
                isTransactions ||
                isSchedule ||
                isBuyerProgressReports ||
                isSellerProgressReports ||
                isSellerProgressReports ||
                isMyClients ||
                isEnquiry ||
                isScheduleClient
                  ? null
                  : noResults}
              </div>
            </ul>

            {isTransactions || isSchedule || isMyClients || isEnquiry ? null : pagingLinks}

            <CSVDownloadModal
              id={'csv-download'}
              isOpen={this.state.isCSVDownloadOpen}
              onConfirm={downloadDeliveredTransactions}
              toggle={this.toggleCSVDownload}
              onManageDisableScrolling={manageDisableScrolling}
              sortBy1={deliveredSortByClientName}
              sortBy1Title={deliveredSortByClientNameTitle}
              sortBy2={deliveredSortByBookingDate}
              sortBy2Title={deliveredSortByBookingDateTitle}
            />

            <CSVDownloadModal
              id={'csv-download-packages'}
              isOpen={this.state.isCSVPackagesDownloadOpen}
              onConfirm={onDownloadPackages}
              toggle={this.toggleCSVPackagesDownload}
              onManageDisableScrolling={manageDisableScrolling}
              sortBy1={packageSortByListingTitle}
              sortBy1Title={packageSortByListingTitleTitle}
              sortBy2={packageSortByPackageBoughtDate}
              sortBy2Title={packageSortByPackageBoughtDateTitle}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>{!isEnquiry && <Footer />}</LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserListing: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.listing,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    scheduleTransactionRefs,
    packageRequests,
    fetchSellerPackageRequestInProgress,
    fetchSellerPackageRequestError,
    sellerPackageRequests,
    sellerPackagesPagination,
    fetchBuyerPackageRequestInProgress,
    fetchBuyerPackageRequestError,
    acceptPackageRequestInProgress,
    acceptPackageRequestError,
    cancelPackageRequestInProgress,
    cancelPackageRequestError,
    buyerPackageRequests,
    buyerPackagesPagination,
    fetchSellerProgressReportsRequestError,
    fetchSellerProgressReportsRequestInProgress,
    sellerProgressReports,
    fetchBuyerProgressReportsRequestError,
    fetchBuyerProgressReportsRequestInProgress,
    buyerProgressReports,
    fetchCSVDataInProgress,
    fetchCSVPackagesDataInProgress,
    incomeTransactions,
    incomeTransactionsLoading,
    enquiryRefs,
    fetchEnquiryError,
    isAnyPendingProgressReport,
    rescheduleRequestInProgress,
    rescheduleError,
    rescheduledsuccessfully,
    walletRequestError,
    walletRequestInProgress,
    walletListings,
    isLoading,
    sendMessageInProgress,
    recentTransactions,
  } = state.InboxPage;

  const {
    currentUser,
    currentUserListing,
    currentUserNotificationCount: providerNotificationCount,
    currentUserPackagesNotificationCount: providerPackagesNotificationCount,
    currentUserSaleEnquiriesNotificationCount: saleEnquiriesNotificationCount,
    currentUserOrderEnquiriesNotificationCount: orderEnquiriesNotificationCount,
  } = state.user;

  const {
    fetchMessagesInProgress,
    sendMessageInProgress: enquirySendMessageInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages: enquiryMessages,
  } = state.TransactionPage;

  const { monthlyTimeSlots } = state.ListingPage;

  const { ownListingsRefs, companyListingsRefs } = state.Schedule;
  const ownListings = getMarketplaceEntities(state, ownListingsRefs);
  const companyListings = getMarketplaceEntities(state, companyListingsRefs);
  // const transactionsByDay = sortTransactionsByDay(transactions);

  const packagesBuyers = () => {
    if (sellerPackageRequests && sellerPackageRequests.length > 0) {
      const buyerRefs = sellerPackageRequests.map(pr => {
        return {
          id: new UUID(pr.buyer_id),
          type: 'user',
        };
      });
      return getMarketplaceEntities(state, buyerRefs);
    } else {
      return [];
    }
  };

  const packagesListings = () => {
    const buyerListingRefs = buyerPackageRequests.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });

    const sellerListingRefs = sellerPackageRequests.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });
    const listingRefs = [...buyerListingRefs, ...sellerListingRefs];

    return getMarketplaceEntities(state, listingRefs);
  };

  const progressReportsBuyers = () => {
    if (sellerProgressReports && sellerProgressReports.length > 0) {
      const buyerRefs = sellerProgressReports.map(pr => {
        return {
          id: new UUID(pr.buyer_id),
          type: 'user',
        };
      });
      return getMarketplaceEntities(state, buyerRefs);
    } else {
      return [];
    }
  };

  const progressReportsListings = () => {
    const sellerListingRefs = sellerProgressReports.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });

    const buyerListingRefs = buyerProgressReports.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });

    const listingRefs = [...buyerListingRefs, ...sellerListingRefs];
    return getMarketplaceEntities(state, listingRefs);
  };

  const enquiries = getMarketplaceEntities(state, enquiryRefs);

  return {
    currentUser,
    currentUserListing,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    providerPackagesNotificationCount,
    saleEnquiriesNotificationCount,
    orderEnquiriesNotificationCount,
    fetchCSVDataInProgress,
    fetchCSVPackagesDataInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    ScheduleTransactions: getMarketplaceEntitiesMemo(state, scheduleTransactionRefs),
    packageRequests,
    fetchSellerPackageRequestInProgress,
    fetchBuyerPackageRequestInProgress,
    sellerPackageRequests,
    sellerPackagesPagination,
    buyerPackageRequests,
    buyerPackagesPagination,
    recentTransactions,
    fetchBuyerPackageRequestError,
    acceptPackageRequestInProgress,
    acceptPackageRequestError,
    cancelPackageRequestInProgress,
    cancelPackageRequestError,
    fetchSellerPackageRequestError,
    packagesListings: packagesListings(),
    packagesBuyers: packagesBuyers(),
    progressReportsListings: progressReportsListings(),
    progressReportsBuyers: progressReportsBuyers(),
    fetchSellerProgressReportsRequestError,
    fetchSellerProgressReportsRequestInProgress,
    sellerProgressReports,
    fetchBuyerProgressReportsRequestError,
    fetchBuyerProgressReportsRequestInProgress,
    buyerProgressReports,
    incomeTransactions,
    incomeTransactionsLoading,
    enquiries,
    fetchEnquiryError,
    ownListings,
    companyListings,
    // scheduleTransactions: transactionsByDay,
    isAnyPendingProgressReport,
    rescheduleRequestInProgress,
    rescheduleError,
    rescheduledsuccessfully,
    monthlyTimeSlots,
    walletRequestError,
    walletRequestInProgress,
    walletListings,
    isLoading,
    sendMessageInProgress,
    enquirySendMessageInProgress,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    enquiryMessages,
  };
};

const mapDispatchToProps = dispatch => ({
  transitionTransaction: params => dispatch(transitionTransaction(params)),
  manageDisableScrolling,
  downloadDeliveredTransactions: sortBy => dispatch(downloadDeliveredTransactions(sortBy)),
  onDownloadPackages: sortBy => dispatch(downloadPackages(sortBy)),
  onAcceptPackageRequest: id => dispatch(acceptPackageRequest(id)),
  onRejectPackageRequest: id => dispatch(cancelPackageRequest(id)),
  onSellerPackageRequests: (id, query) => dispatch(sellerPackageRequests(id, query)),
  onBuyerPackageRequests: (id, query) => dispatch(buyerPackageRequests(id, query)),
  removePendingProgressReports: () => dispatch(removePendingProgressReports()),
  onRescheduleBooking: params => dispatch(rescheduleBooking(params)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchMonthlyTimeSlots: listing => dispatch(fetchMonthlyTimeSlots(listing)),
  onfetchEnquiryMessages: (txId, page) => dispatch(fetchEnquiryMessages(txId, page)),
  onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
  onEnquirySendMessage: (txId, message) => dispatch(sendEnquiryMessage(txId, message)),
});

const InboxPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;

import React from "react";
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from 'components';
import SectionCustomerStories from '../TopBlogsPage/SectionCustomerStories';
import css from "./PromoPage.css";

const SectionHireBestCoach = props => {
    return (<>
        <div className={css.topHireBestCoachContainer}>
            <div className={css.hireBestCoachContainer}>

                <div className={css.hireBestCoachDetail}>
                    <h2 className={css.hireBestCoachTitle}><FormattedMessage id="PromoPage.hireTheBestPro" /></h2>
                </div>
                <div>

                    <div>
                        <div className={css.hireBestStep1}>

                            <h1><span className={css.topHireBestCoachCount} >1</span><FormattedMessage id="PromoPage.submitRequest" /></h1>
                            <span className={css.coachCountInfo}>
                                <FormattedMessage id="PromoPage.whenLookingSportsSession" /> 
                            </span>
                        </div>
                    </div>
                    <div className={css.svgFormContainer}>

                        <div className={css.postRequestGifContainer}>
                            <img className={css.postRequestGif} src={"/static/images/landingpage/landingpage_postRequestGif.gif"} alt="landingpage_postRequestGif" />
                            <img className={css.imagePostRequestGifBackground} src={"/static/images/landingpage/landingpage_postRequestGifBackground.png"} alt="landingpage_postRequestGifBackground" />
                        </div>
                        <div className={css.searchEnquiryFormContainer}>
                            <section className={css.connectPros}>
                                <h4 className={css.connectProsTitle}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                                        <path d="M4.5 10H0L8.21429 0L6.5 7.5H10.5L1.78571 18L4.5 10Z" fill="url(#paint0_linear_4633_167)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_4633_167" x1="5" y1="2.42782e-09" x2="-3.48706" y2="17.1585" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#10D6EB" />
                                                <stop offset="1" stopColor="#0095B3" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <FormattedMessage id="LandingPage.getMatchesFaster" />
                                </h4>
                                <span className={css.startRequestFormDescription}>
                                    <FormattedMessage id="LandingPage.PostRequest" />
                                    <FormattedMessage id="LandingPage.connectPros" />
                                </span>
                                <NamedLink name="NewInquirePage"
                                    className={css.startRequestFormButton}>
                                    <FormattedMessage id="LandingPage.tryProConnect" />
                                </NamedLink>
                            </section>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <div className={css.probuddyInfo}>
            <span className={css.experienceHead}><FormattedMessage id="PromoPage.experience" /> </span>
            <img src={'/static/promoPage/SectionImage/experienceBanner.png'} className={css.probuddyInfoImage} alt={'banner'} />

            <div className={css.experienceTime}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                    <g clip-path="url(#clipClock0_5372_422)">
                        <path d="M14 0.929688C6.28027 0.929688 0 7.20996 0 14.9297C0 22.6495 6.28027 28.9297 14 28.9297C21.7198 28.9297 28 22.6494 28 14.9297C28 7.20996 21.7198 0.929688 14 0.929688ZM14 25.8696C7.96758 25.8696 3.06011 20.9621 3.06011 14.9297C3.06011 8.89757 7.96758 3.9898 14 3.9898C20.0324 3.9898 24.9399 8.89757 24.9399 14.9297C24.9399 20.9621 20.0324 25.8696 14 25.8696Z" fill="black" />
                        <path d="M15.2118 14.9464V9.07867C15.2118 8.4235 14.6809 7.89258 14.0259 7.89258C13.3708 7.89258 12.8398 8.4235 12.8398 9.07867V15.3253C12.8398 15.3439 12.8444 15.3614 12.8453 15.3801C12.8297 15.7026 12.9408 16.0301 13.1872 16.2764L17.6045 20.6934C18.0678 21.1567 18.8187 21.1567 19.2817 20.6934C19.7447 20.2301 19.745 19.4791 19.2817 19.0161L15.2118 14.9464Z" fill="black" />
                    </g>
                    <defs>
                        <clipPath id="clipClock0_5372_422">
                            <rect width="28" height="28" fill="white" transform="translate(0 0.929688)" />
                        </clipPath>
                    </defs>
                </svg>
                <span className={css.experienceTimeText}><FormattedMessage id="PromoPage.articleTime" /></span>
            </div>

            <div className={css.experienceDesc}>
                <h3 className={css.experienceDescHead}><FormattedMessage id="PromoPage.useProbuddyBookYourSports" /> </h3>
                <span className={css.experienceDescText}><FormattedMessage id="PromoPage.bookMoreWithProbuddy" /></span>
                <span className={css.experienceDescText}><FormattedMessage id="PromoPage.probuddyMakesEasy" /></span>
                <span className={css.experienceDescText}><FormattedMessage id="PromoPage.discoverNewExperiencesToday" /></span>
            </div>

        </div>

        <div className={css.probuddyServices}>
            <div className={css.probuddyServicesWrapper}>

                <div className={css.probuddyServicesHeadWrapper}>
                    <h3 className={css.probuddyServicesHead}>Why use Probuddy to book your Sports, Fitness, or Adventure services?</h3>
                    <span className={css.probuddyServicesText}>Searching for a new workout partner? Can’t find a tennis instructor? Want to book adventure sports? Probuddy can help you find the best place to train and connect you to the right Pro within hours.</span>
                    <span className={css.probuddyServicesText}>Here’s why Probuddy can help you book in-person and online training sessions near you.</span>
                </div>
                <div className={css.servicesPoints}>
                    <div className={css.servicesPointsWrap}>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}> 1. Browse and Filter Results </h3>
                            <p className={css.pointsItemsText}>Find a Pro near you or while traveling with easy-to-use filters. Tailor your results to match your style, ability, time, and location. Work out with a personal trainer, sports coach, or fitness instructor at a time that suits you.</p>
                        </div>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}> 2. Reputable Service Providers</h3>
                            <p className={css.pointsItemsText}>Probuddy partners with a host of Sports, Fitness, and Adventure service providers. All providers are verified and reputable. You can feel confident in booking the right Pro for your training session. </p>
                        </div>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}> 3. Transparent Pricing</h3>
                            <p className={css.pointsItemsText}>Find a Pro near you or while traveling with easy-to-use filters. Tailor your results to match your style, ability, time, and location. Work out with a personal trainer, sports coach, or fitness instructor at a time that suits you.</p>
                        </div>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}>  4. Instant Bookings </h3>
                            <p className={css.pointsItemsText}>Need a sports instructor during your lunch hour? Or adventurous vacation activities? With instant bookings and same-day replies, take the hassle out of finding Sports, Adventure, and Fitness services near you. </p>
                        </div>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}>  5. Honest Reviews</h3>
                            <p className={css.pointsItemsText}>Read honest reviews about your Pros from real clients. Learn what to expect from their coaching style and figure out if they are a good match for you. Have a clear understanding of your training session and feel ready to go. </p>
                        </div>
                    </div>

                    <div className={css.servicesPointsWrap}>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}>  6. Customer Support </h3>
                            <p className={css.pointsItemsText}>Probuddy is always on hand to help you find the right service provider for you. We know that finding fitness classes and training sessions can be overwhelming. Contact our customer support to help you find the best fit. </p>
                        </div>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}>  7. Flexible Online Payments </h3>
                            <p className={css.pointsItemsText}>Paying for fitness classes or sports training should be quick and simple. With flexible online payments, you can book and pay for a tennis lesson or exercise class through the Probuddy platform. </p>
                        </div>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}>  8. Automatically Update Your Diary </h3>
                            <p className={css.pointsItemsText}>Always forgetting about your fitness class or swim lesson? Probuddy automatically updates your Google calendar so you will never miss a training session again. Sync up your diary and stay on track, so you can focus on your workout. </p>
                        </div>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}>  9. Certifications and Accolades </h3>
                            <p className={css.pointsItemsText}>Found the right tennis coach or group fitness instructor? View their certifications and accolades on Probuddy with verified reviews. Book your lessons with reputable providers around your schedule.</p>
                        </div>
                        <div className={css.pointsItems}>
                            <h3 className={css.pointsItemsHead}>  10. Stripe Identity</h3>
                            <p className={css.pointsItemsText}>Probuddy uses Stripe Identity to increase trust and safety and collect identity information to make payments as safe as possible. Stripe Identity is a fast and easy way for you to verify your identification</p>
                        </div>
                    </div>
                </div>

                <div className={css.servicesPointsDesc}>
                    <p className={css.PointsDescText}>Try the best fitness classes, sports coaching, and outdoor adventure services with Probuddy.</p>
                    <p className={css.PointsDescText}>Discover virtual and in-person training services that fit your schedule.</p>
                </div>
            </div>
        </div>



        <div className={css.hireBestCoachSectionContainer}>
            <div className={css.hireBestCoachSection}>
                <div className={css.hireBestCoachContainerReverse}>
                    <div className={css.hireBestCoachDetail}>
                        <div className={css.reverseCoachHeading}>
                            <div className={css.HireBestCoachCount}>2</div>
                            <div>
                                <h1>Get Free Quotes</h1>
                                <span className={css.bestCoachInfo}>
                                    View all details from our verified listings including price,
                                    reviews, expectations and so much more.Feel comfortable
                                    hiring a Instructor or joining a new class.
                                </span>
                            </div>
                        </div>

                    </div>
                    <div>
                        <img src={'/static/promoPage/SectionImage/hereTheBestCoach2.png'} alt="hereTheBestCoach" />
                    </div>
                </div>
                <div className={css.hireBestCoachContainerStep3}>
                    <div className={css.hireBestCoachDetail}>
                        <div className={css.HireBestCoachCount}>3</div>
                        <h1>Hire Pro</h1>
                        <span className={css.bestCoachInfo}>
                            With Easy online booking and a range of convenient
                            payment options we make sure you have a great
                            experience with full transparency. Buy and manage
                            packages and upcoming classes from your private
                            acount.
                        </span>
                    </div>
                    <div>
                        <img src={'/static/promoPage/SectionImage/hereTheBestCoach3.png'} alt="hereTheBestCoach" />
                    </div>
                </div >
            </div>
        </div >

        <div className={css.fitnessArticleTitle}>Check Out more Articles on Booking Fitness classes?</div>
        <div>
            <div className={css.articalContainer}>
                <NamedLink name="ArticlePage" params={{ id: '101' }} className={css.articalWrapper}>
                    <div className={css.articleImageContainer}>
                        <img src={'/static/images/topBlogsPage/fitnessFreelancer.png'} alt="Fitness Freelancer" />
                    </div>
                    <span className={css.articalHeading}><FormattedMessage id={'TopBlogsPage.article'} /></span>
                    <p className={css.articalInfo} ><FormattedMessage id={'TopBlogsPage.howMuchEarnFitnessFreelancer'} /></p>
                    <span className={css.articalTime}><FormattedMessage id={'TopBlogsPage.articleTime'} /></span>
                </NamedLink>
                <NamedLink name="ArticlePage" params={{ id: '102' }} className={css.articalWrapper}>
                    <div className={css.articleImageContainer}>
                        <img src={'/static/images/topBlogsPage/coachingClassesCost.png'} alt="Coaching classes cost" />
                    </div>
                    <span className={css.articalHeading}><FormattedMessage id={'TopBlogsPage.article'} /></span>
                    <p className={css.articalInfo} ><FormattedMessage id={'TopBlogsPage.howMuchFitnessCoachingClassesCost'} /></p>
                    <span className={css.articalTime}><FormattedMessage id={'TopBlogsPage.articleTime'} /></span>
                </NamedLink>
            </div>
        </div>


        <div>
        <SectionCustomerStories/>
        </div>
    </>)
}

export default SectionHireBestCoach
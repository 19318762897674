import ascottLogo from 'assets/landingPage/ascottLogo.png';
import googleLogo from 'assets/landingPage/googleLogo.png';
import shangriLogo from 'assets/landingPage/shangriLogo.png';
import standartCharteredLogo from 'assets/landingPage/standartCharteredLogo.png';
import cns from 'classnames';
import { routeConfiguration } from 'index';
import { bool, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createResourceLocatorString } from 'util/routes';
import { createSlug } from 'util/urlHelpers';
import IconPostRequestMobile from '../../assets/landingPage/icons/postRequestIcon.png';
import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LazyLoadingImages,
  NamedLink,
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionRentSpace,
  SectionTopIconDesktop,
  SectionWhyUs,
  LandingPageCategoryListSection,
  LandingPageExtraordinarySection,
  LandingPageHowItsWorksSection,
  LandingPageExpertMarketPlaceSection,
  LandingPageTripExpertSection,
} from '../../components';
import PhoneNumberModal from '../../components/PhoneNumberModal/PhoneNumberModal';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './LandingPage.css';
import { loadData, searchListings, queryPromotedListings, queryPromotedListingReviews } from './LandingPage.duck';

const PROMO_LOGOS = [
  {
    id: 'shangri',
    src: shangriLogo,
  },
  {
    id: 'google',
    src: googleLogo,
  },
  {
    id: 'standardChartered',
    src: standartCharteredLogo,
  },
  {
    id: 'ascott',
    src: ascottLogo,
  },
];

class LandingPageComponent extends React.Component {
  constructor(props) {
    super(props);
    // const { attributes: { profile: { publicData: { phoneNumber = "" } = {} } = {} } = {} } = props.currentUser

    this.state = {
      isOpen: false,
      isReviewFetch: false,
      selectedIcon: 'Private Session',
      selectedCategories: [],
    };
    this.postRequestGifRef = React.createRef();
    this.handleProviderQueryChange = this.handleProviderQueryChange.bind(this);
    this.onSelectCategoriesHandler = this.onSelectCategoriesHandler.bind(this);
  }
  componentDidMount() {
    if (this.props.currentUser && this.props.saveContactDetailsInProgress === false) {
      const {
        currentUser: {
          attributes: { profile: { publicData: { phoneNumber = '' } = {} } = {} } = {},
        } = {},
      } = this.props;
      if (this.props.currentUser && !phoneNumber) {
        this.setState({
          isOpen: true,
        });
      }
    }
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      window.sessionStorage.removeItem('privateSiteUrl');
    }
    if (localStorage.getItem('LandingFormData')) {
      this.props.history.push('/i/draft/00000000-0000-0000-0000-000000000000/new/description');
    }
    if (this.postRequestGifRef.current) {
      this.postRequestGifRef.current.src =
        '/static/images/landingpage/landingpage_postRequestGif.gif';
    }
  }

  componentDidUpdate(prevProps) {
    const { promotedListingRefs, onQueryPromotedListingReviews } = this.props;

    if (JSON.stringify(prevProps.promotedListingRefs) !==
      JSON.stringify(promotedListingRefs)) {
      onQueryPromotedListingReviews(promotedListingRefs);
    }
  }

  toggleModal = () => {
    this.setState({
      isOpen: false,
    });
  };
  onOkay = () => {
    this.setState({
      isOpen: false,
    });
    this.props.history.push('/account/contact-details');
  };
  handleProviderQueryChange(providerQueryValue) {
    this.props.searchListings({
      keywords: providerQueryValue,
    });
  }

  onSelectCategoriesHandler(value) {
    const { onFetchPromotedListing } = this.props;
    let updateValue = [...this.state.selectedCategories];

    if (updateValue.includes(value)) {
      updateValue.splice(updateValue.indexOf(value), 1);
    } else {
      updateValue.push(value);
    }

    onFetchPromotedListing(updateValue);
    this.setState({ selectedCategories: updateValue });
  }

  handleRebookClick = tx => e => {
    const { history } = this.props;
    const title = tx.listing.attributes.title;
    const listingId = tx.listing.id.uuid;
    const url = createResourceLocatorString(
      'ListingPage',
      routeConfiguration(),
      { slug: createSlug(title), id: listingId },
      {}
    );
    history.push(url);
  };

  onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    console.log('Toggling ModalInMobile - currently:', componentId, scrollingDisabled);
  };

  render() {
    const {
      history,
      intl,
      location,
      scrollingDisabled,
      suggestedListings,
      promotedListings,
      listingAllReviews,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        isBottomMenuRequire={true}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer isDarkMode={true} currentPage="LandingPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.landingPageMainWrapper}>
            <div className={css.heroContainer}>
              <div className={css.lpImageWrapper}>
                <SectionHero
                  className={css.hero}
                  intl={intl}
                  history={history}
                  location={location}
                  suggestedListings={suggestedListings.slice(0, 5)}
                  handleProviderQueryChange={this.handleProviderQueryChange}
                />
              </div>
            </div>

            <div className={css.postRequestsection}>
              <div className={css.lpContentWrapper}>
                <LandingPageCategoryListSection
                  intl={intl}
                  listingData={promotedListings}
                  selectedCategories={this.state.selectedCategories}
                  onSelect={this.onSelectCategoriesHandler}
                  listingAllReviews={listingAllReviews}
                />
              </div>
            </div>

            {/* <div className={css.postRequestsection}>
              <div className={css.lpContentWrapper}>
                <LandingPageExtraordinarySection />
              </div>
            </div> */}

            <div className={css.howItWorksection}>
              <div className={css.lpworksContentWrapper}>
                <LandingPageHowItsWorksSection />
                {/* <iframe
                    width="100%"
                    height="515"
                    style={{ marginBottom: '40px', borderRadius: '8px' }}
                    src={'https://www.youtube.com/embed/pbtfWSCGWbw'}
                    title="video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe> */}
                <LandingPageExpertMarketPlaceSection />
              </div>
            </div>

            <div className={css.postRequestsection}>
              <div className={css.lpContentWrapper}>
                <LandingPageTripExpertSection title={true} />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
        {/* <PhoneNumberModal
          id="ReviewOrderModal"
          isOpen={this.state.isOpen}
          onOkay={this.onOkay}
          onCloseModal={this.toggleModal}
          onManageDisableScrolling={this.onManageDisableScrolling}
          rootClassName={''}
        /> */}
      </Page>
    );
  }
}

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
  promotedListingRefs: [],
  promotedListingReviews: [],
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.listing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    currentUserWasFetched,
    currentUserNotificationCount,
    currentUserPackagesNotificationCount,
    recentTransactions,
    recentTransactionsLoading,
  } = state.user;
  const {
    promotedListingRefs,
    promotedListingReviews,
    suggestedListings,
    companiesRefs,
    companiesLoading,
    listingAllReviews,
  } = state.LandingPage;
  const { saveContactDetailsInProgress } = state.ContactDetailsPage;
  const promotedListings = getMarketplaceEntities(state, promotedListingRefs);
  const companies = getMarketplaceEntities(state, companiesRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    promotedListings,
    suggestedListings,
    recentTransactions,
    recentTransactionsLoading,
    currentUser,
    currentUserWasFetched,
    companies,
    companiesLoading,
    currentUserNotificationCount,
    currentUserPackagesNotificationCount,
    promotedListingReviews,
    promotedListingRefs,
    companiesRefs,
    listingAllReviews,
    saveContactDetailsInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  onFetchPromotedListing: categories => dispatch(queryPromotedListings(categories)),
  onQueryPromotedListingReviews: listingRef => dispatch(queryPromotedListingReviews(listingRef))
});

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = loadData;

export default LandingPage;

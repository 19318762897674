import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
// import { ensureCurrentUser } from '../../util/data';
import config from '../../config';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './SubscriptionSettingsPage.css';

export const SubscriptionSettingsPageComponent = props => {
  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {
      if (typeof window !== 'undefined') {
        window.Chargebee.init({
          site: config.cbSite,
        });
        window.Chargebee.registerAgain();
      }
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);
  }, []);

  const { scrollingDisabled, intl } = props;

  // const user = ensureCurrentUser(currentUser);
  const title = intl.formatMessage({ id: 'SubscriptionSettingsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="SubscriptionSettingsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="SubscriptionSettingsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="SubscriptionSettingsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="SubscriptionSettingsPage.heading" />
            </h1>
            <p>
              You can easily manage, change, or cancel your subscription,
              {/* eslint-disable-next-line */}
              <a href={void 0} data-cb-type="portal">
                here
              </a>
              . If you need help with your subscription at any time, you can also email us directly
              at info@lyfshort.com
            </p>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

SubscriptionSettingsPageComponent.defaultProps = {};

SubscriptionSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser: currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({});

const SubscriptionSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SubscriptionSettingsPageComponent);

export default SubscriptionSettingsPage;

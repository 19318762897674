import React, { useState } from 'react';
import css from './LandingPageCategoryListSection.css';
import { SectionPromotedListings } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import cns from 'classnames';
import DropdownButton from '../ModifiedSearchFilters/Components/DropdownButton/DropdownButton';
import Profile_1 from '../../assets/Landing_Pages/CategoryList/Profile_1.png';
import Profile_2 from '../../assets/Landing_Pages/CategoryList/Profile_2.png';
import Profile_3 from '../../assets/Landing_Pages/CategoryList/Profile_3.png';
import Profile_4 from '../../assets/Landing_Pages/CategoryList/Profile_4.png';
import config from 'config';

const LandingPageCategoryListSection = props => {
  const { intl, onSelect, listingData, selectedCategories, listingAllReviews } = props;
  const categoryList = config.custom.suggestionCategoryOptions;

  return (
    <>
      <div className={css.title}>
        <FormattedMessage id="CategoryListSection.title" />
      </div>
      {/* <div className={css.description}>
        <FormattedMessage id="CategoryListSection.description" />
      </div> */}
      {/* <div className={css.tabsContainer}>
        {categoryList.slice(0, 8).map(sub => {
          return (
            <div
              key={sub.key}
              onClick={() => onSelect(sub.key)}
              className={cns(css.subCategoryItem, {
                [css.selectedSubCategoryItem]: selectedCategories.includes(sub.key),
              })}
            >
              {sub.label}
            </div>
          );
        })}

        <DropdownButton
          labelClassName={css.subCategoryMoreItems}
          label={<FormattedMessage id={'ModifiedSearchFilters.more'} />}
          inProgress={false}
          selectedOption={selectedCategories}
          menuContentClass={css.menuContentClass}
          actions={categoryList.slice(8, categoryList.length).map(sub => {
            return {
              label: sub.label,
              key: sub.key,
              handler: () => onSelect(sub.key),
            };
          })}
        />
      </div> */}

      {listingData && listingData.length > 0 ? (
        <SectionPromotedListings
          intl={intl}
          promotedListings={listingData}
          showAll={'SectionPromotedTrainers.showAll'}
          allRelatedQuery={'trainers'}
          label={'Top Trainers'}
          allListingReview={listingAllReviews}
        />
      ) : null}
    </>
  );
};
export default LandingPageCategoryListSection;

/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect, useState } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';

import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;
const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// const scrollToTab = currentTab => {
//   const el = document.querySelector(`#${currentTab}Tab`);

//   if (el) {
//     el.scrollIntoView({
//       block: 'end',
//       inline: 'end',
//       behavior: 'smooth',
//     });
//   }
// };

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const { currentTab, viewport } = props;
  const [isPartner, setIsPartner] = useState(false);

  useEffect(() => {
    sdk.ownListings
      .query({})
      .then(res => {
        const listings = res.data.data;
        console.log(listings);
        const hasAnyCompanyListings = listings.find(
          l => l.attributes?.publicData?.listing_type === 'company'
        );
        setIsPartner(!!hasAnyCompanyListings);
      })
      .catch(e => console.log(e));
  }, []);

  let hasScrolledToTab = false;

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    // Need to check if disabling this prevent tabs hiding
    //scrollToTab(currentTab);
    hasScrolledToTab = true;
  }

  const payoutTabMaybe = isPartner
    ? [
        {
          text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
          selected: currentTab === 'StripePayoutPage',
          id: 'StripePayoutPageTab',
          linkProps: {
            name: 'StripePayoutPage',
          },
        },
      ]
    : [];
  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />,
      selected: currentTab === 'ContactDetailsPage',
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.currencyDetailsTabTitle" />,
      selected: currentTab === 'CurrencyDetailsPage',
      id: 'CurrencyDetailsPageTab',
      linkProps: {
        name: 'CurrencyDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      selected: currentTab === 'PasswordChangePage',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },

    ...payoutTabMaybe,

    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
      selected: currentTab === 'PaymentMethodsPage',
      id: 'PaymentMethodsPageTab',
      linkProps: {
        name: 'PaymentMethodsPage',
      },
    },
    // {
    //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.hitpaySettingsTabTitle" />,
    //   selected: currentTab === 'HitpaySettingsPage',
    //   id: 'HitpaySettingsPageTab',
    //   linkProps: {
    //     name: 'HitpaySettingsPage',
    //   },
    // },
    // {
    //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.googleCalendarSettingsTabTitle" />,
    //   selected: currentTab === 'GoogleCalendarSettingsPage',
    //   id: 'GoogleCalendarSettingsPageTab',
    //   linkProps: {
    //     name: 'GoogleCalendarSettingsPage',
    //   },
    // },
    // {
    //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.subscription" />,
    //   selected: currentTab === 'SubscriptionSettingsPage',
    //   id: 'SubscriptionSettingsPageTab',
    //   linkProps: {
    //     name: 'SubscriptionSettingsPage',
    //   },
    // },
    // {
    //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.wallet" />,
    //   selected: currentTab === 'WalletPage',
    //   id: 'WalletTab',
    //   linkProps: {
    //     name: 'WalletPage',
    //   },
    // },
  ];

  return <LayoutWrapperSideNav tabs={tabs} />;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperAccountSettingsSideNav = compose(withViewport)(
  LayoutWrapperAccountSettingsSideNavComponent
);

export default LayoutWrapperAccountSettingsSideNav;

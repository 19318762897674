import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import { ensureListing } from 'util/data';
import { FormattedMessage } from 'util/reactIntl';
import { createSlug } from 'util/urlHelpers';
import { AvatarMedium, NamedLink, Button, JobsCard } from 'components/index';
import moment from 'moment';
import css from './JobsBoardResults.css';

function JobsBoardCard({
  listing,
  setActiveListing,
  currentUser,
  onClickShowBecomeHostMessage,
  currentUserHasListings,
}) {
  const currentListing = ensureListing(listing);
  const isOwnListing = currentUser ? currentListing.author.id.uuid === currentUser.id.uuid : false;
  const id = currentListing.id.uuid;
  const { title = '', publicData = {}, description } = currentListing.attributes;
  const slug = createSlug(title);
  const { location, times_of_day, times_per_week, week_day_available } = publicData;
  const authorDisplayName = listing.author.attributes.profile.displayName;
  const currentAddress = location.address || '';

  const formattedPostedTime = listing
    ? moment(listing.attributes.createdAt).format('d/YY H:mm A')
    : '';
  const TimesOfDay = times_of_day ? times_of_day.join(', ') : null;
  const timesPerWeek = times_per_week ? `${times_per_week} hours / week` : null;
  const weekDayAvailable = week_day_available ? week_day_available.join(', ') : null;

  return (
    <div
      onMouseEnter={() => setActiveListing(currentListing.id)}
      onMouseLeave={() => setActiveListing(null)}
      className={css.jobBoardResultCardRoot}
    >
      <JobsCard
        title={title}
        description={description}
        authorDisplayName={authorDisplayName}
        currentAddress={currentAddress}
        weekDayAvailable={weekDayAvailable}
        onClick={onClickShowBecomeHostMessage}
      />
    </div>
  );
}

JobsBoardCard.propTypes = {
  listing: propTypes.listing,
  setActiveListing: PropTypes.func,
};

function JobsBoardResults({
  listings,
  currentUserHasListings,
  currentUser,
  onClickShowBecomeHostMessage,
  setActiveListing,
  children,
  resultsCount,
}) {
  return (
    <>
      {/* <div className={css.listingCards}> */}
      {listings.map(l => (
        <JobsBoardCard
          key={l.id.uuid}
          listing={l}
          currentUser={currentUser}
          onClickShowBecomeHostMessage={onClickShowBecomeHostMessage}
          currentUserHasListings={currentUserHasListings}
          setActiveListing={setActiveListing}
          resultsCount={resultsCount}
        />
      ))}
      {children}
      {/* </div> */}
      <section className={css.connectPros}>
        <h4 className={css.connectProsTitle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
          >
            <path
              d="M4.5 10H0L8.21429 0L6.5 7.5H10.5L1.78571 18L4.5 10Z"
              fill="url(#paintJobsBoardResults_linear_4633_167)"
            />
            <defs>
              <linearGradient
                id="paintJobsBoardResults_linear_4633_167"
                x1="5"
                y1="2.42782e-09"
                x2="-3.48706"
                y2="17.1585"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#10D6EB" />
                <stop offset="1" stopColor="#0095B3" />
              </linearGradient>
            </defs>
          </svg>
          <FormattedMessage id="LandingPage.getMatchesFaster" />
        </h4>
        <NamedLink name="NewInquirePage" className={css.startRequestFormButton}>
          <FormattedMessage id="LandingPage.tryProConnect" />
        </NamedLink>
      </section>
    </>
  );
}

JobsBoardResults.propTypes = {
  listings: PropTypes.arrayOf(propTypes.listing),
  setActiveListing: PropTypes.func,
};

export default JobsBoardResults;

import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
    formatDateStringToTz,
    getDefaultTimeZoneOnBrowser,
} from '../../util/dates';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import config from '../../config';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/PromoPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/PromoPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/PromoPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/PromoPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/PromoPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/PromoPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/PromoPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
    pagination: null,
    searchParams: null,
    searchInProgress: false,
    currentPageResultIds: [],
    searchListingsError: null,
    searchMapListingIds: [],
    searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const JobsBoardPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_LISTINGS_REQUEST:
            return {
                ...state,
                searchParams: payload.searchParams,
                searchInProgress: true,
                searchMapListingIds: [],
                currentPageResultIds: [],
                searchListingsError: null,
            };
        case SEARCH_LISTINGS_SUCCESS:
            return {
                ...state,
                currentPageResultIds: resultIds(payload.data),
                pagination: payload.data.meta,
                searchInProgress: false,
            };
        case SEARCH_LISTINGS_ERROR:
            console.error(payload);
            return { ...state, searchInProgress: false, searchListingsError: payload };

        case SEARCH_MAP_LISTINGS_REQUEST:
            return {
                ...state,
                searchMapListingsError: null,
            };
        case SEARCH_MAP_LISTINGS_SUCCESS: {
            const searchMapListingIds = unionWith(
                state.searchMapListingIds,
                resultIds(payload.data),
                (id1, id2) => id1.uuid === id2.uuid
            );
            return {
                ...state,
                searchMapListingIds,
            };
        }
        case SEARCH_MAP_LISTINGS_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return { ...state, searchMapListingsError: payload };

        case SEARCH_MAP_SET_ACTIVE_LISTING:
            return {
                ...state,
                activeListingId: payload,
            };
        default:
            return state;
    }
};

export default JobsBoardPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
    type: SEARCH_LISTINGS_REQUEST,
    payload: { searchParams },
});

export const searchListingsSuccess = response => ({
    type: SEARCH_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const searchListingsError = e => ({
    type: SEARCH_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
    type: SEARCH_MAP_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const searchMapListingsError = e => ({
    type: SEARCH_MAP_LISTINGS_ERROR,
    error: true,
    payload: e,
});


// const getBounds = listingParams => {
//     if (!listingParams.bounds) return undefined;

//     const { ne, sw } = listingParams.bounds;
//     const { lat: nelat, lng: nelng } = ne;
//     const { lat: swlat, lng: swlng } = sw;

//     return `${nelat},${nelng},${swlat},${swlng}`;
// };

export const searchListings = searchParams => (dispatch, getState, sdk) => {
    dispatch(searchListingsRequest(searchParams));

    const priceSearchParams = priceParam => {
        const inSubunits = value =>
            convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
        const values = priceParam ? priceParam.split(',') : [];
        return priceParam && values.length === 2
            ? {
                price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
            }
            : {};
    };

    const availabilityParams = (datesParam, minDurationParam, timesParam) => {
        const dateValues = datesParam ? datesParam.split(',') : [];
        const hasDateValues = datesParam && dateValues.length === 2;
        const timeValues = timesParam ? timesParam.split(',') : [];
        const hasTimeValues = timeValues && timeValues.length === 2;
        const startDate =
            hasDateValues && hasTimeValues
                ? `${dateValues[0]} ${timeValues[0]}`
                : hasDateValues
                    ? dateValues[0]
                    : null; //"2020-06-12 12:00"//;
        const endDate =
            hasDateValues && hasTimeValues
                ? `${dateValues[1]} ${timeValues[1]}`
                : hasDateValues
                    ? dateValues[1]
                    : null; //"2020-06-12 13:00"//;

        const minDurationMaybe =
            minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
                ? { minDuration: minDurationParam }
                : {};

        const defaultTimeZone = () =>
            typeof window !== 'undefined'
                ? getDefaultTimeZoneOnBrowser()
                : config.custom.dateRangeLengthFilterConfig.searchTimeZone;

        const timeZone = defaultTimeZone();
        return hasDateValues
            ? {
                start: formatDateStringToTz(startDate, timeZone),
                end: formatDateStringToTz(endDate, timeZone),

                // When we have `time-partial` value in the availability, the
                // API returns listings that don't necessarily have the full
                // start->end range available, but enough that the minDuration
                // (in minutes) can be fulfilled.
                //
                // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering

                availability: 'time-partial',
                ...minDurationMaybe,
            }
            : {};
    };

    const { perPage, price, dates, minDuration, times, ...rest } = searchParams;
    const priceMaybe = priceSearchParams(price);
    const availabilityMaybe = availabilityParams(dates, minDuration, times);

    const params = {
        ...rest,
        ...priceMaybe,
        ...availabilityMaybe,
        per_page: perPage,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
            'variants.scaled-small',
            'variants.square-small2x',
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ],
    };

    return sdk.listings
        .query(params)
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(searchListingsSuccess(response));
            return response;
        })
        .catch(e => {
            dispatch(searchListingsError(storableError(e)));
            throw e;
        });
};

export const setActiveListing = listingId => ({
    type: SEARCH_MAP_SET_ACTIVE_LISTING,
    payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
    dispatch(searchMapListingsRequest(searchParams));

    const { perPage, ...rest } = searchParams;
    const params = {
        ...rest,
        per_page: perPage,
    };

    return sdk.listings
        .query(params)
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(searchMapListingsSuccess(response));
            return response;
        })
        .catch(e => {
            dispatch(searchMapListingsError(storableError(e)));
            throw e;
        });
};

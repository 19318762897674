import React from 'react';
import css from './ListingDetailsSectionMainTab.css';
import MainTabSectionDetailsContent from './components/MainTabSectionDetailsContent';
import { NavLink } from 'react-router-dom';

const ListingDetailsSectionMainTab = props => {
  const {
    authorDetails,
    images,
    videos,
    rating,
    photosSection,
    isDraftView,
    videosSection,
    ...rest
  } = props;

  const data = [
    {
      id: 'overview',
      label: 'OVERVIEW',
      content: (
        <MainTabSectionDetailsContent
          id="overview"
          authorDetails={authorDetails}
          isDraftView={isDraftView}
          {...rest}
        />
      ),
    },
    {
      id: 'locations',
      label: 'LOCATIONS',
      content: (
        <MainTabSectionDetailsContent id="locations" authorDetails={authorDetails} {...rest} />
      ),
    },
    {
      id: 'pricing',
      label: 'PRICING',
      content: (
        <MainTabSectionDetailsContent
          id="pricing"
          authorDetails={authorDetails}
          isDraftView={isDraftView}
          {...rest}
        />
      ),
    },
  ];

  if (images?.length > 0 || videos?.length > 0) {
    data.push({
      id: 'gallery',
      label: 'GALLERY',
      content: (
        <MainTabSectionDetailsContent
          photosSection={photosSection}
          videosSection={videosSection}
          id="gallery"
          authorDetails={authorDetails}
          {...rest}
        />
      ),
    });
  }

  if (rating) {
    data.push({
      id: 'reviews',
      label: 'REVIEWS',
      content: (
        <MainTabSectionDetailsContent id="reviews" authorDetails={authorDetails} {...rest} />
      ),
    });
  }
  const content = data.slice(0, 3).map(i => ({ id: i.id, content: i.content }));
  const optionalContent = data.slice(3).map(i => ({ id: i.id, content: i.content }));
  const menu = data.map(i => ({ label: i.label, id: i.id }));

  const pagePath = typeof window !== 'undefined' && window.location.pathname;
  return (
    <>
      {/* Navigation Tabs */}
      <div className={css.tabsWrapperContainer}>
        <div className={css.tabsWrapper}>
          {menu.map(item => (
            <NavLink
              to={`${pagePath}#${item.id}Scroll`}
              className={css.tabLabelItem2}
              key={item.label}
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
      {/* Content section */}
      <div className={css.ListingDetailsMainTabConatiner}>
        <div className={!isDraftView ? css.infoWrapper : css.draftInfoWrapper}>
          <div className={css.commonInfoContainer}>
            {content.map(item => (
              <div className={css.infoSectionWrapper} key={item.id} style={{gridArea: item.id}}>
                <div className={css.scrollAnchor} id={`${item.id}Scroll`} />
                {item.content}
              </div>
            ))}
          </div>
          {optionalContent.map(item => (
            <div className={css.infoSectionWrapper} key={item.id}>
              <div className={css.scrollAnchor} id={`${item.id}Scroll`}/>
              {item.content}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default ListingDetailsSectionMainTab;

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes, { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  // Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
// import User_Icon from '../../assets/Landing_Pages/User_Icon.png';
import Bottom_Arrow from '../../assets/Landing_Pages/bottom_arrow_black.png';
import logo from './logo.png';

import css from './SearchPageTopbar.css';

const SearchPageTopbar = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isDarkMode,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [isScroll, setIsScroll] = useState(true);

  useEffect(() => {
    setMounted(true);
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setIsScroll(false);
      } else {
        setIsScroll(true);
      }
    });
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  let isPrivateSite = false;
  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
  }
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const svgArrowIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
      <path
        d="M1.95782 0.818359L0.542969 2.23321L5.13873 6.83901L0.542969 11.4448L1.95782 12.8597L7.97847 6.83901L1.95782 0.818359Z"
        fill="#0095B3"
      />
    </svg>
  );

  const classes = useMemo(
    () =>
      classNames(
        rootClassName || css.root,
        {
          [css.transparentRoot]:
            isScroll && typeof window !== 'undefined' && window.location.pathname === '/',
          [css.darkRoot]:
            isDarkMode &&
            !(isScroll && typeof window !== 'undefined' && window.location.pathname === '/'),
        },
        className
      ),
    [isScroll]
  );

  console.log(
    'isScroll::',
    isScroll,
    'window pathname::',
    typeof window !== 'undefined' ? window.location.pathname : '---'
  );
  console.log(
    'Condition::',
    isScroll && typeof window !== 'undefined' && window.location.pathname === '/'
  );
  console.log('classes::', classes);

  const notificationDot = notificationCount > 0 ? <div className={css.menuItemDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
      <MenuLabel
        className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <span>{currentUser && currentUser.attributes.profile.firstName} &nbsp;</span>
        <div className={css.activeUser}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          <div className={css.active}></div>
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyOrdersPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage" params={{ tab: 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myOrdersPage" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="MyMessages">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('MyMessages'))}
            name="InboxPage" params={{ tab: 'enquiry-client' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myMessages" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="MyFavorites">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('MyFavorites'))}
            name="InboxPage" params={{ tab: 'orders', state: 'completed' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myFavorites" />
          </NamedLink>
        </MenuItem>

        <div className={css.divider}></div>
        <MenuItem key="tripExpertAdmin">
          <NamedLink
            className={classNames(css.tripExpertAdmin, currentPageClass('TripExpertAdmin'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.tripExpertAdmin" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="EditSalesPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.editSalesPage" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="CustomerOrders">
          <div class={css.notificationContainer}>
            {/* <div className={css.menuItemDot} /> */}
            {notificationDot}
            <NamedLink
              className={classNames(css.orderManagement, currentPageClass('InboxPage'))}
              name="InboxPage" params={{ tab: 'sales' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.orderManagement" />
            </NamedLink>
          </div>
        </MenuItem>

        <MenuItem key="CustomerReporting">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('Reporting'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.reporting" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="Messages">
          <div class={css.notificationContainer}>
            <div className={css.menuItemDot} />
            <NamedLink
              className={classNames(css.messages, currentPageClass('Messages'))}
              name="InboxPage" params={{ tab: 'enquiry' }}
            >
              <FormattedMessage id="TopbarDesktop.messages" />
            </NamedLink>
          </div>
        </MenuItem>

        <div className={css.divider}></div>
        <MenuItem key="travelBusinessAdmin">
          <NamedLink
            className={classNames(css.tripExpertAdmin, currentPageClass('travelBusinessAdmin'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.travelBusinessAdmin" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="EditBusinessPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.edeitBusinessPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="OrderView">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('OrderView'))}
            name="InboxPage" params={{ tab: 'sales' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.orderView" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ReportingBusiness">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('Reporting'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.reportingBusiness" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="Messages">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('Messages'))}
            name="InboxPage" params={{ tab: 'enquiry-client' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.messages" />
          </NamedLink>
        </MenuItem> */}

        <div className={css.divider}></div>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const marketPlaceLink = (
    <NamedLink name="NewListingPage" className={css.menuMarketPlaceLink}>
      <span className={classNames(css.menuMarketPlace, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.marketPlace" />
      </span>
    </NamedLink>
  );

  const organiserAppLink = (
    <NamedLink name="LandingPage" className={css.organiserAppLink}>
      <span className={classNames(css.organiserApp, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.organiserApp" />
      </span>
    </NamedLink>
  );

  const tripExpertLink = (
    <NamedLink name="LandingPage" className={css.tripExpertLink}>
      <span className={classNames(css.tripExpert, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.tripExpert" />
      </span>
    </NamedLink>
  );

  const partnersLink = (
    <NamedLink name="LandingPage" className={css.partnersLink}>
      <span className={classNames(css.partners, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.partners" />
      </span>
    </NamedLink>
  );

  const USDLink = (
    <NamedLink name="LandingPage" className={css.USDLink}>
      <span className={classNames(css.USD, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.USD" />
        <img src={Bottom_Arrow} alt="BottomArrow" />
      </span>
    </NamedLink>
  );

  const englishLink = (
    <NamedLink name="LandingPage" className={css.englishLink}>
      <span className={classNames(css.english, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.english" />
        <img src={Bottom_Arrow} alt="BottomArrow" />
      </span>
    </NamedLink>
  );

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={classNames(css.signup, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage">
      <span className={css.login}>
        <span className={css.loginName}>
          <FormattedMessage id="TopbarDesktop.login" />
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="29"
          viewBox="0 0 28 29"
          fill="none"
        >
          <g clip-path="url(#clip0_18_1588)">
            <path
              d="M14 0.219727C6.26882 0.219727 0 6.48732 0 14.2191C0 21.9509 6.26821 28.2185 14 28.2185C21.7324 28.2185 28 21.9509 28 14.2191C28 6.48732 21.7324 0.219727 14 0.219727ZM14 4.40571C16.5582 4.40571 18.6312 6.47932 18.6312 9.0363C18.6312 11.5939 16.5582 13.6669 14 13.6669C11.443 13.6669 9.37003 11.5939 9.37003 9.0363C9.37003 6.47932 11.443 4.40571 14 4.40571ZM13.9969 24.5583C11.4455 24.5583 9.10867 23.6291 7.30625 22.0911C6.86717 21.7166 6.61381 21.1675 6.61381 20.5912C6.61381 17.998 8.71264 15.9225 11.3065 15.9225H16.6947C19.2892 15.9225 21.38 17.998 21.38 20.5912C21.38 21.1681 21.1279 21.716 20.6882 22.0905C18.8864 23.6291 16.549 24.5583 13.9969 24.5583Z"
              fill="#00AB99"
            />
          </g>
          <defs>
            <clipPath id="clip0_18_1588">
              <rect width="28" height="28" fill="white" transform="translate(0 0.21875)" />
            </clipPath>
          </defs>
        </svg>
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div id={'topbarNavWrap'} className={css.navWrap}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <img src={logo} alt="" />
        </NamedLink>
        <div className={css.navLinkContainer}>
          {marketPlaceLink}
          {organiserAppLink}
          {tripExpertLink}
          {partnersLink}
          {USDLink}
          {englishLink}
          {/*{signupLink}*/}
          {loginLink}
          {profileMenu}
        </div>
      </div>
    </nav>
  );
};

SearchPageTopbar.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
  isDarkMode: false,
};

SearchPageTopbar.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: PropTypes.oneOfType([propTypes.ownListing, propTypes.listing]),
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  isDarkMode: bool,
};

export default SearchPageTopbar;

import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import {
  Form,
  Button,
  GenericMessage,
} from '../../components';
import config from '../../config';
import css from './EditBusinessViewDraftForm.css';
import { LISTING_TYPES } from 'util/constants';
import CompanyPageCommonSection from 'containers/ListingPage/CompanyPageCommonSection';

class EditBusinessViewDraftFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
      showMessage: false,
    };
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  render() {
    const { listingType, currentListing, backgroundImage, profileImage, actionImage } = this.props;
    const { publicData, description, metadata, title } = currentListing.attributes;

    // potential parameter to disable the publish button
    const approved = currentListing?.author?.attributes?.profile?.protectedData?.approved;

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            subCategory,
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            submittedButtonText,
            publishActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
          } = formRenderProps;

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;
          const isBusiness = currentListing?.attributes?.publicData?.listing_type === LISTING_TYPES.COMPANY;

          const reviews = [];

          const onSubmit = values => {
            this.setState({ showMessage: true });
            setTimeout(() => {
              this.setState({showMessage: false});
            }, 5000);
            handleSubmit(values);
          }

          return (
            <Form className={classes} onSubmit={onSubmit}>
              <div className={css.publishListingWrapper}>
                <div className={css.optionalTitle}>
                  <h1 className={css.headingTitle}>View Draft</h1>
                </div>
                <div className={css.btnGroupWrapper}>
                  <GenericMessage show={this.state.showMessage} message={'Your Business listing has been submitted for approval. If approved it will be automatically displayed on the marketplace.'} />
                  <Button
                    rootClassName={css.submitButton}
                    type="submit"
                    inProgress={false}
                    disabled={submitDisabled}
                    // ready={submitReady}
                  >
                    {!submitReady ? publishActionMsg : submittedButtonText}
                  </Button>
                  {isBusiness && (
                    <>
                      <div className={css.infoIcon}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_906_753)">
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.08984 8.99959C9.32495 8.33126 9.789 7.7677 10.3998 7.40873C11.0106 7.04975 11.7287 6.91853 12.427 7.0383C13.1253 7.15808 13.7587 7.52112 14.2149 8.06312C14.6712 8.60512 14.9209 9.29112 14.9198 9.99959C14.9198 11.9996 11.9198 12.9996 11.9198 12.9996"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <circle cx="12" cy="17" r="1" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0_906_753">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className={css.extraInfoWrapper}>
                        Your listing will be published after approved
                      </div>
                    </>
                  )}
                </div>
              </div>
              <CompanyPageCommonSection
                currentListing={currentListing}
                backgroundImage={backgroundImage}
                profileImage={profileImage}
                intl={intl}
                listingVariant={LISTING_TYPES.COMPANY}
                title={title}
                review={reviews}
                authorsProductListings={[]}
                // otherAuthorsListings={otherAuthorsListings}
                // allListingReviews={allListingReviews}
              />
            </Form>
          );
        }}
      />
    );
  }
}

EditBusinessViewDraftFormComponent.defaultProps = { className: null, fetchErrors: null };

EditBusinessViewDraftFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  // saveActionMsg: string.isRequired,
  publishActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditBusinessViewDraftFormComponent);

import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import { Logo, ExternalLink, NamedLink } from '../../components';
import Facebook_Icon from '../../assets/Landing_Pages/Footer/Facebook_Icon.png';
import Instagram_Icon from '../../assets/Landing_Pages/Footer/Instagram_Icon.png';
import Youtube_Icon from '../../assets/Landing_Pages/Footer/Youtube_Icon.png';
//import Linkedin_Icon from '../../assets/Landing_Pages/Footer/Linkedin_Icon.png';
import badge_apple from '../../assets/Landing_Pages/Footer/badge-apple.svg';
import badge_google from '../../assets/Landing_Pages/Footer/badge-google.svg';
import badge_web from '../../assets/Landing_Pages/Footer/badge-web.svg';
import MapPoint from '../../assets/Landing_Pages/Footer/MapPoint.png';
import Line from '../../assets/Landing_Pages/Footer/Line.png';

import css from './Footer.css';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteYoutubePage, siteLinkedInPage } = config;
  //const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInstagram = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });
  // const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  // const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });
  // const goToLinkedin = intl.formatMessage({ id: 'Footer.goToLinkedIn' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <img src={Facebook_Icon} alt="Facebook" />
    </ExternalLink>
  ) : null;
  const instagramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInstagram}
    >
      <img src={Instagram_Icon} alt="Instagram" />
    </ExternalLink>
  ) : null;
  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.icon}
      title={goToYoutube}
    >
      <img src={Youtube_Icon} alt="Youtube" />
    </ExternalLink>
  ) : null;

  /*const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <img src={Twitter_Icon} alt="Twitter" />
    </ExternalLink>
  ) : null;*/

  /*const PinterestLink = sitePinterestPage ? (
    <ExternalLink
      key="linkToPinterest"
      href={sitePinterestPage}
      className={css.icon}
      title={goToPinterest}
    >
      <img src={Pinterest_Icon} alt="Pinterest" />
    </ExternalLink>
  ) : null;*/

  /*const LinkedinLink = siteLinkedInPage ? (
    <ExternalLink
      key="linkToLinkedin"
      href={siteLinkedInPage}
      className={css.icon}
      title={goToLinkedin}
    >
      <img src={Linkedin_Icon} alt="Linkedin" />
    </ExternalLink>
  ) : null;*/

  return [fbLink, instagramLink, youtubeLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl, viewport } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  let isPrivateSite = false;
  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
  }

  // const isMobileLayout = viewport && viewport.width && viewport.width <= 550;
  const content = (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <div className={css.infoLinks}>
                  <ul className={classNames(css.list, css.firstSectionList)}>
                    <li className={css.listItem}>
                      <a
                        href="https://lyfshort.com/our-story/"
                        className={css.link}
                        target="_blank"
                      >
                        <FormattedMessage id="Footer.ourStoryPage" />
                      </a>
                    </li>
                    {/* <li className={css.listItem}>
                      <NamedLink name="AboutPage" className={css.link}>
                        <FormattedMessage id="Footer.toAboutPage" />
                      </NamedLink>
                    </li> */}
                    <li className={css.listItem}>
                      <a href="https://lyfshort.com/support/" className={css.link} target="_blank">
                        <FormattedMessage id="Footer.support" />
                      </a>
                    </li>
                  </ul>
                  <ul className={css.list}>
                    <li>
                      <img src={MapPoint} alt="location" />
                      <NamedLink name="LandingPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.location" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                {/* <li className={css.listItem}>
                  <div className={css.firstLink}>
                    <NamedLink name="NewListingPage" className={css.marketPlaceLink}>
                      <FormattedMessage id="Footer.marketPlacePage" />
                    </NamedLink>
                  </div>
                </li> */}
                <li className={css.listItem}>
                  <NavLink to="/trip_expert#process" className={css.link}>
                    <FormattedMessage id="Footer.howDoesItWorkPage" />
                  </NavLink>
                </li>
                {/* <li className={css.listItem}>
                  <NavLink to="/trip_expert#benefits" className={css.link}>
                    <FormattedMessage id="Footer.hireATripExpertPage" />
                  </NavLink>
                </li> */}
                <li className={css.listItem}>
                  <NavLink to="/trip_expert#trip-expert-partner" className={css.link}>
                    <FormattedMessage id="Footer.becomeATripExpertPage" />
                  </NavLink>
                </li>
                <li className={css.listItem}>
                  <NavLink to="/become-expert-or-partner" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NavLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ProcessesPage" className={css.link}>
                    <FormattedMessage id="Footer.processes" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CommercialModelPage" className={css.link}>
                    <FormattedMessage id="Footer.commercialModel" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                {/* <li className={css.listItem}>
                  <div className={css.firstLink}>
                  <FormattedMessage id="Footer.organiserApp" />
                  </div>
                </li> */}
                <li className={css.listItem}>
                  <NamedLink name="DefinitionsPage" className={css.link}>
                    <FormattedMessage id="Footer.definitions" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TermsOfServicePage" className={css.link}>
                    <FormattedMessage id="Footer.termsService" />
                  </NamedLink>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                  <FormattedMessage id="Footer.communityGuidelines" />
                  </NamedLink>
                </li> */}
                <li className={css.listItem}>
                  <NamedLink name="PrivacyPolicyPage" className={css.link}>
                    <FormattedMessage id="Footer.privacyPolicyPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TravelAgentTnCPage" className={css.link}>
                    <FormattedMessage id="Footer.travelAgentTnCPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.legalMatters}>
                <div className={css.someSocialMediaLinks}>{socialMediaLinks}</div>
                <ul className={classNames(css.list, css.footerLink)}>
                  <li className={css.listItem}>
                    <Collapsible
                      className={css.collapseItem}
                      openedClassName={css.collapseItem}
                      contentInnerClassName={css.subLink}
                      trigger={
                        <FormattedMessage id="Footer.marketPlacePage" />
                      }
                    >
                      <div>
                        <NavLink to="/trip_expert#process">
                          <FormattedMessage id="Footer.howDoesItWorkPage" />
                        </NavLink>
                      </div>
                      <div>
                        <NavLink to="/trip_expert#trip-expert-partner">
                          <FormattedMessage id="Footer.becomeATripExpertPage" />
                        </NavLink>
                      </div>
                      <div>
                        <NamedLink name="BecomeExpertOrPartnerPage">
                          <FormattedMessage id="TopbarDesktop.becomeAPartner" />
                        </NamedLink>
                      </div>
                      <div>
                        <NamedLink name="TravelAgentTnCPage">
                          <FormattedMessage id="Footer.travelAgentTnCPage" />
                        </NamedLink>
                      </div>
                      <div>
                        <NamedLink name="ProcessesPage">
                          <FormattedMessage id="Footer.processes" />
                        </NamedLink>
                      </div>
                      <div>
                        <NamedLink name="CommercialModelPage">
                          <FormattedMessage id="Footer.commercialModel" />
                        </NamedLink>
                      </div>
                      <div>
                        <NamedLink name="DefinitionsPage">
                          <FormattedMessage id="Footer.definitions" />
                        </NamedLink>
                      </div>
                    </Collapsible>
                  </li>
                  <li className={css.listItem}>
                    <a href="https://lyfshort.com/" className={css.link} target="_blank">
                      <FormattedMessage id="Footer.organiserApp" />
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <Collapsible
                      className={css.collapseItem}
                      openedClassName={css.collapseItem}
                      contentInnerClassName={css.subLink}
                      trigger={
                        <FormattedMessage id="Footer.support" />
                      }
                    >
                      <div>
                        <NamedLink name="TermsOfServicePage">
                          <FormattedMessage id="Footer.termsService" />
                        </NamedLink>
                      </div>
                      <div>
                        <NamedLink name="PrivacyPolicyPage">
                          <FormattedMessage id="Footer.privacyPolicyPage" />
                        </NamedLink>
                      </div>
                      <div>
                        <a href="https://lyfshort.com/support/" className={css.link} target="_blank">
                          <FormattedMessage id="Footer.contact" />
                        </a>
                      </div>
                    </Collapsible>
                  </li>
                </ul>
                <div className={css.tosAndPrivacy}>
                  <a href="https://l.lyfshort.com/website2" target="_blank">
                    <img src={badge_apple} alt="Apple" />
                  </a>
                  <a href="https://l.lyfshort.com/website1" target="_blank">
                    <img src={badge_google} alt="Google" />
                  </a>
                  <a href="https://l.lyfshort.com/webappbutton" target="_blank">
                    <img src={badge_web} alt="Web" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className={css.middleBorderWrapper} />
      <div className={css.footerLocation}>
        <img src={MapPoint} alt="location" />
        <NamedLink name="LandingPage" className={css.legalLink}>
          <FormattedMessage id="Footer.location" />
        </NamedLink>
      </div>
      <div className={css.bottomBorderWrapper}>
        <div className={css.copyrightLink}>
          <FormattedMessage id="Footer.copyright" />
        </div>
      </div>
    </div>
  );

  // const dropDownContent = (
  //   <div className={css.dropdownContent}>
  //     <div>
  //       <input type="checkbox" id="question1" name="q" className={css.questions} />
  //       <div className={css.plus}>+</div>
  //       <label htmlFor="question1" className={css.question}>
  //         Show More
  //       </label>
  //       <div className={css.answers}>{content}</div>
  //     </div>
  //   </div>
  // );

  // return !isPrivateSite ? (isMobileLayout ? dropDownContent : content) : null;
  return !isPrivateSite ? content : null;
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(Footer);

import React, { useEffect } from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import { CustomBookingForm } from '../../../forms'

const ListingBookingSection = props => {
  const {
    avatarDetails,
    currentListing,
    currentUser,
    isOwnListing,
    unitType,
    handleBookingSubmit,
    handlePackagePurchase,
    bookingTitle,
    authorDisplayName,
    onManageDisableScrolling,
    monthlyTimeSlots,
    onFetchTimeSlots,
    viewport,
    handlePackagesModalOpen,
    isProduct,
    intl,
    tabName,
    tabIndex,
  } = props;

  return (
    <div className={css.listingBookingSection}>
      <h2 className={css.pricingText}>PRICING</h2>
      <div className={css.bookingPanelContainer}>
        <CustomBookingForm
          className={css.bookingPanel}
          listing={currentListing}
          currentUser={currentUser}
          isOwnListing={isOwnListing}
          unitType={unitType}
          onSubmit={handlePackagePurchase}
          title={bookingTitle}
          authorDisplayName={authorDisplayName}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          viewport={viewport}
          isProduct={isProduct}
          intl={intl}
          tabName={tabName}
        />
      </div>
    </div>
  );
};

export default ListingBookingSection;

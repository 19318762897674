import React, { Component } from 'react';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from 'components';
import { getExtensionFromUrl } from 'util/urlHelpers';
import { TopbarContainer } from 'containers/index';
import { FormattedMessage } from '../../util/reactIntl';

import SectionMainFeatures from './SectionMainFeatures';
import SectionWhatMakesUsDifferent from './SectionWhatMakesUsDifferent';
import SectionSuperAddOns from './SectionSuperAddOns';
import SectionGetDoneInWeeks from './SectionGetDoneInWeeks';
import GetQuoteForm from '../../forms/GetQuoteForm/GetQuoteForm';
import css from './MarketplaceDevelopmentPage.css';

export class MarketplaceDevelopmentPageComponent extends Component {
  componentDidMount() {}

  render() {
    const probuddyTutorialVideoUrl =
      'https://res.cloudinary.com/movementbuddy/video/upload/v1646834165/probuddyTutorial_cdtvir.mp4';
    return (
      <Page title={'Marketplace Development'}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer isDarkMode={true} />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.topBlogPageWrapper}>
            <div className={css.topContainer}>
              <div className={css.topContainerWrapper}>
                <h2>
                  <FormattedMessage id={'MarketplaceDevelopmentPage.topTitle'} />
                </h2>
                <div className={css.stateButtonContainer}>
                  <a href="mailto:chris@lyfshort.com" className={css.enquireNowLink}>
                    <FormattedMessage id={'MarketplaceDevelopmentPage.enquireNow'} />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className={css.articalContainer}>
                <a className={css.articalWrapper} href={'https://www.lyfshort.com/'}>
                  <div className={css.articleImageContainer}>
                    <img
                      src={'/static/images/MarketplaceDevelopmentPage/probuddySiteImage.png'}
                      alt="Probuddy"
                    />
                  </div>
                  <span className={css.articalHeading}>
                    <FormattedMessage id={'MarketplaceDevelopmentPage.probuddy'} />
                  </span>
                  <p className={css.articalInfo}>
                    <FormattedMessage id={'MarketplaceDevelopmentPage.weBuildDetailed'} />
                  </p>
                </a>
                <a
                  href={'https://car-rental-marketplace.herokuapp.com/'}
                  className={css.articalWrapper}
                >
                  <div className={css.articleImageContainer}>
                    <img
                      src={'/static/images/MarketplaceDevelopmentPage/autobuddySiteImage.png'}
                      alt="Autobuddy"
                    />
                  </div>
                  <span className={css.articalHeading}>
                    <FormattedMessage id={'MarketplaceDevelopmentPage.autobuddy'} />
                  </span>
                  <p className={css.articalInfo}>
                    <FormattedMessage id={'MarketplaceDevelopmentPage.demoSiteForDailyBookings'} />
                  </p>
                </a>
              </div>
            </div>

            <div className={css.probuddyTutorial}>
              <h3>
                <FormattedMessage
                  id={
                    'MarketplaceDevelopmentPage.yourOwnMarketplaceWithSchedulingAndPaymentIntegrated'
                  }
                />
              </h3>
              <div className={css.videoWrapper}>
                <video
                  src={probuddyTutorialVideoUrl}
                  controls
                  loop
                  autoPlay
                  muted
                  playsInline
                  width="100%"
                >
                  <source
                    src={probuddyTutorialVideoUrl}
                    type={`video/${getExtensionFromUrl(probuddyTutorialVideoUrl)}`}
                  />
                </video>
              </div>
            </div>

            <SectionMainFeatures />

            <div className={css.recommendedEbookSection}>
              <div className={css.recommendedEbookDetailContainer}>
                <div className={css.recommendedEbookImageWrapper}>
                  <img
                    src={'/static/images/MarketplaceDevelopmentPage/getMarketplaceImage.png'}
                    alt="getMarketplaceImage"
                  />
                </div>
                <div className={css.recommendedEbookTextWrapper}>
                  <span className={css.recommendedEbookText}>
                    <FormattedMessage id={'MarketplaceDevelopmentPage.wantYourOwnMarketplace'} />
                  </span>
                  <span className={css.recommendedEbookInfo}>
                    <FormattedMessage
                      id={'MarketplaceDevelopmentPage.with2YearsBuildingAndRunning'}
                    />
                  </span>
                </div>
              </div>
              <a href="mailto:chris@lyfshort.com" className={css.GetEBookButton}>
                <FormattedMessage id={'MarketplaceDevelopmentPage.enquireNow'} />
              </a>
            </div>

            <SectionWhatMakesUsDifferent />

            <SectionSuperAddOns />

            <SectionGetDoneInWeeks />

            <div className={css.getQuoteSection}>
              <div className={css.getQuoteSectionWrapper}>
                <div className={css.getQuoteTextContainer}>
                  <h3 className={css.getQuoteHeading}>
                    <FormattedMessage id={'MarketplaceDevelopmentPage.GetQuote'} />
                  </h3>
                  <span className={css.getQuoteSubHeading}>
                    <FormattedMessage
                      id={'MarketplaceDevelopmentPage.takeAdvantageOurDifferentPriceModels'}
                    />
                  </span>
                  <span className={css.getQuoteTextInfo}>
                    <FormattedMessage
                      id={'MarketplaceDevelopmentPage.weBelieveYouShouldWorkWithSomeone'}
                    />
                  </span>

                  <div className={css.personDetailContainer}>
                    <div>
                      <img
                        src={'/static/images/MarketplaceDevelopmentPage/CEOImage.png'}
                        alt="CEO"
                      />
                    </div>
                    <div className={css.personTextSection}>
                      <span className={css.personName}>
                        <FormattedMessage id={'MarketplaceDevelopmentPage.christoWiese'} />
                      </span>
                      <span className={css.personPost}>
                        <FormattedMessage id={'MarketplaceDevelopmentPage.CEOMovementbuddy'} />
                      </span>
                    </div>
                  </div>

                  <div className={css.growthContainer}>
                    <div className={css.growthWrapper}>
                      <span className={css.growthNumber}>600k</span>
                      <span className={css.growthType}>
                        <FormattedMessage
                          id={
                            'MarketplaceDevelopmentPage.inTransactionVolumeOnSingleFlexMarketplace'
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className={css.getQuoteFormContainer}>
                  <GetQuoteForm onSubmit={() => {}} />
                </div>
              </div>
            </div>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

export default MarketplaceDevelopmentPageComponent;

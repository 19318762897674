import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { currencyConfiguration } from '../../currency-config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldTextInput,
  FieldMultipleSelection,
  HeadingFormFieldWrapper,
} from '../../components';
import css from './EditListingPricingForm.css';
import { PRICING } from 'util/constants';
import Collapsible from 'react-collapsible';

const { Money } = sdkTypes;
const MAX_FIELDS = 5;

const textRequired = value => (value ? undefined : 'Required');

const DESCRIPTION_MAX_LENGTH = 250;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
      } = formRenderProps;

      const {
        mutators: { push, pop },
      } = form;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      // const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      // const minPriceRequired = validators.moneySubUnitAmountAtLeast(
      //   intl.formatMessage(
      //     {
      //       id: 'EditListingPricingForm.priceTooLow',
      //     },
      //     {
      //       minPrice: formatMoney(intl, minPrice),
      //     }
      //   ),
      //   config.listingMinimumPriceSubUnits
      // );

      const minPrice = new Money(
        config.listingMinimumPriceSubUnits,
        values.base_currency || config.currency
      );
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const packagePriceValidators = config.listingMinimumPriceSubUnits ? minPriceRequired : null;

      const sessionsLabel = intl.formatMessage({
        id: 'EditListingPricingForm.sessionsLabel',
      });
      const sessionsPlaceholder = intl.formatMessage({
        id: 'EditListingPricingForm.sessionsPlaceholder',
      });
      const packagePricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.packagePricePlaceholder',
      });

      const packagePriceLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.packagePriceLabelMessage',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingPricingForm.packageDescriptionLabel',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.packageDescriptionPlaceholder',
      });

      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingPricingForm.descriptionMaxLength' },
        {
          maxLength: DESCRIPTION_MAX_LENGTH,
        }
      );

      const maxLength250Message = validators.maxLength(maxLengthMessage, DESCRIPTION_MAX_LENGTH);

      //Introduction at Start of Section
      const introductionStartLabel = intl.formatMessage({
        id: 'EditListingPricingForm.introductionStartLabel',
      });
      const introductionStartSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.introductionStartSupport',
      });

      //baseCurrency
      const baseCurrencyLabel = intl.formatMessage({
        id: 'EditListingPricingForm.baseCurrencyLabel',
      });
      const baseCurrencySupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.baseCurrencySupport',
      });

      //Introduction - Basic
      const introductionBasicLabel = intl.formatMessage({
        id: 'EditListingPricingForm.introductionBasicLabel',
      });
      const introductionBasicSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.introductionBasicSupport',
      });

      //Introduction - Detailed
      const introductionDetailedLabel = intl.formatMessage({
        id: 'EditListingPricingForm.introductionDetailedLabel',
      });
      const introductionDetailedSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.introductionDetailedSupport',
      });

      //Introduction - Custom
      const introductionCustomLabel = intl.formatMessage({
        id: 'EditListingPricingForm.introductionCustomLabel',
      });
      const introductionCustomSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.introductionCustomSupport',
      });

      //Items
      const itemsSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.itemsSupport',
      });
      const itemsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.itemsPlaceholder',
      });
      const itemsPriceSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.itemsPriceSupport',
      });

      //Participants
      const participantsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.participantsPlaceholder',
      });
      const participantsSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.participantsSupport',
      });
      const participantsPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.participantsPricePlaceholder',
      });
      const participantsPriceSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.participantsPriceSupport',
      });

      //Delivery time
      const deliveryTimePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.deliveryTimePlaceholder',
      });
      const deliveryTimeSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.deliveryTimeSupport',
      });
      const deliveryTimePricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.deliveryTimePricePlaceholder',
      });
      const deliveryTimePriceSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.deliveryTimePriceSupport',
      });

      //standardDeliveryTime
      const standardTimeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.standardTimeLable',
      });
      const standardTimeSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.standardTimeSupport',
      });

      //standardCollaborationTime
      const standardCollaborationTimeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.standardCollaborationTimeLable',
      });
      const standardCollaborationTimePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.standardCollaborationTimePlaceholder',
      });

      //Collaboration time
      const collaborationTimePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.collaborationTimePlaceholder',
      });
      const collaborationTimeSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.collaborationTimeSupport',
      });
      const collaborationTimePricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.collaborationTimePricePlaceholder',
      });
      const collaborationTimePriceSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.collaborationTimePriceSupport',
      });

      //Price
      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.pricePlaceholder',
      });
      const priceSupportMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceSupport',
      });

      //Basic Package
      const package1ItemLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package1ItemLabel',
      });
      const package1ParticipantsLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package1ParticipantsLabel',
      });
      const package1DeliveryTimeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package1DeliveryTimeLabel',
      });
      const package1CollaborationTimeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package1CollaborationTimeLabel',
      });
      const package1PriceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package1PriceLabel',
      });

      //Detailed Package
      const package2ItemLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package2ItemLabel',
      });
      const package2ParticipantsLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package2ParticipantsLabel',
      });
      const package2DeliveryTimeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package2DeliveryTimeLabel',
      });
      const package2CollaborationTimeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package2CollaborationTimeLabel',
      });
      const package2PriceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package2PriceLabel',
      });

      //Custom Package
      const package3ItemLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package3ItemLabel',
      });
      const package3ParticipantsLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package3ParticipantsLabel',
      });
      const package3DeliveryTimeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package3DeliveryTimeLabel',
      });
      const package3CollaborationTimeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.package3CollaborationTimeLabel',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || pristine;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const standardTimes = [
        { key: '24', label: '24hrs' },
        { key: '48', label: '48hrs' },
        { key: '72', label: '72hrs' },
        { key: '96', label: '96hrs' },
        { key: '120', label: '120hrs' },
        { key: '144', label: '144hrs' },
        { key: '168', label: '168hrs' },
      ];
      return (
        <Form onSubmit={handleSubmit} className={classes}>
          <div className={css.btnWrapper}>
            <div>
              <h1 className={css.headingTitle}>{PRICING}</h1>
            </div>
            <Button
              rootClassName={css.topSaveButton}
              type="submit"
              inProgress={false}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
          <div className={css.formGroup}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <p className={css.pricingInfo}>
              <FormattedMessage
                id="EditListingPricingForm.setup3"
                values={{
                  bold: header => <span className={css.boldText}>{header}</span>,
                }}
              />
            </p>
            <p className={css.pricingInfo}>
              <FormattedMessage
                id="EditListingPricingForm.basicRepresents"
                values={{
                  bold: header => <span className={css.boldText}>{header}</span>,
                }}
              />
            </p>
            <p className={css.pricingInfo}>
              <FormattedMessage
                id="EditListingPricingForm.detailedRepresents"
                values={{
                  bold: header => <span className={css.boldText}>{header}</span>,
                }}
              />
            </p>

            <p className={css.pricingInfo}>
              <FormattedMessage
                id="EditListingPricingForm.customRepresents"
                values={{
                  bold: header => <span className={css.boldText}>{header}</span>,
                }}
              />
              <p className={css.pricingInfo}>{introductionCustomSupportMessage}</p>
            </p>
            <Collapsible
              trigger={
                <div className={css.expandableBar}>
                  <span className={css.sectionTitle}>{introductionBasicLabel}</span>
                  <span className={css.accordionArrow}></span>
                </div>
              }
              triggerWhenOpen={
                <div className={css.expandableBar}>
                  <span className={css.sectionTitle}>{introductionBasicLabel}</span>
                  <span className={css.accordionArrowOpen}></span>
                </div>
              }
            >
              <FieldTextInput
                id="package_1_items"
                name="packages[0][items]"
                className={css.packageSessionsField}
                type="number"
                label={package1ItemLabel}
                supportText={itemsSupportMessage}
                placeholder={itemsPlaceholderMessage}
                validate={textRequired}
              />

              <FieldTextInput
                id="package_1_participants"
                name="packages[0][participants]"
                className={css.packageSessionsField}
                type="number"
                label={package1ParticipantsLabel}
                supportText={participantsSupportMessage}
                placeholder={participantsPlaceholderMessage}
                validate={textRequired}
              />

              <FieldMultipleSelection
                name="packages[0][deliveryTime]"
                id="package_1_delivery_time"
                className={css.packageSessionsField}
                label={package1DeliveryTimeLabel}
                supportText={deliveryTimePlaceholderMessage}
                options={standardTimes}
                validate={textRequired}
                limit={1}
              />

              <FieldMultipleSelection
                name="packages[0][collaborationTime]"
                id="package_1_collaboration_time"
                className={css.packageSessionsField}
                label={package1CollaborationTimeLabel}
                supportText={collaborationTimeSupportMessage}
                options={standardTimes}
                validate={textRequired}
                limit={1}
              />

              <HeadingFormFieldWrapper label={package1PriceLabel} supportText={priceSupportMessage}>
                <FieldCurrencyInput
                  id="Package_1_price"
                  name="packages[0][price]"
                  className={css.priceInput}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={
                    currencyConfiguration(values.base_currency || 'USD') || config.currencyConfig
                  }
                  validate={priceValidators}
                />
              </HeadingFormFieldWrapper>
            </Collapsible>
            <Collapsible
              trigger={
                <div className={css.expandableBar}>
                  <span className={css.sectionTitle}>{introductionDetailedLabel}</span>
                  <span className={css.accordionArrow}></span>
                </div>
              }
              triggerWhenOpen={
                <div className={css.expandableBar}>
                  <span className={css.sectionTitle}>{introductionDetailedLabel}</span>
                  <span className={css.accordionArrowOpen}></span>
                </div>
              }
            >
              <FieldTextInput
                id="package_2_items"
                name="packages[1][items]"
                className={css.packageSessionsField}
                type="number"
                label={package2ItemLabel}
                supportText={itemsSupportMessage}
                placeholder={itemsPlaceholderMessage}
                validate={textRequired}
              />

              <FieldTextInput
                id="package_2_participants"
                name="packages[1][participants]"
                className={css.packageSessionsField}
                type="number"
                label={package2ParticipantsLabel}
                supportText={participantsSupportMessage}
                placeholder={participantsPlaceholderMessage}
                validate={textRequired}
              />

              <FieldMultipleSelection
                name="packages[1][deliveryTime]"
                id="package_2_delivery_time"
                className={css.packageSessionsField}
                label={package2DeliveryTimeLabel}
                supportText={deliveryTimeSupportMessage}
                options={standardTimes}
                validate={textRequired}
                limit={1}
              />

              <FieldMultipleSelection
                name="packages[1][collaborationTime]"
                id="package_2_collaboration_time"
                className={css.packageSessionsField}
                label={package2CollaborationTimeLabel}
                supportText={collaborationTimeSupportMessage}
                options={standardTimes}
                validate={textRequired}
                limit={1}
              />

              <HeadingFormFieldWrapper label={package2PriceLabel} supportText={priceSupportMessage}>
                <FieldCurrencyInput
                  id="Package_2_price"
                  name="packages[1][price]"
                  className={css.priceInput}
                  placeholder={pricePlaceholderMessage}
                  validate={priceValidators}
                  currencyConfig={
                    currencyConfiguration(values.base_currency || 'USD') || config.currencyConfig
                  }
                />
              </HeadingFormFieldWrapper>
            </Collapsible>
            <Collapsible
              trigger={
                <div className={css.expandableBar}>
                  <span className={css.sectionTitle}>{introductionCustomLabel}</span>
                  <span className={css.accordionArrow}></span>
                </div>
              }
              triggerWhenOpen={
                <div className={css.expandableBar}>
                  <span className={css.sectionTitle}>{introductionCustomLabel}</span>
                  <span className={css.accordionArrowOpen}></span>
                </div>
              }
            >
              <HeadingFormFieldWrapper
                label={package3ItemLabel}
                supportText={itemsPriceSupportMessage}
              >
                <FieldCurrencyInput
                  id="Package_3_items"
                  name="packages[2][items]"
                  className={css.priceInput}
                  placeholder={'Itinerary item unit price'}
                  currencyConfig={
                    currencyConfiguration(values.base_currency || 'USD') || config.currencyConfig
                  }
                  validate={priceRequired}
                />
              </HeadingFormFieldWrapper>

              <HeadingFormFieldWrapper
                label={package3ParticipantsLabel}
                supportText={participantsPriceSupportMessage}
              >
                <FieldCurrencyInput
                  id="Package_3_participants"
                  name="packages[2][participants]"
                  className={css.priceInput}
                  placeholder={participantsPricePlaceholderMessage}
                  currencyConfig={
                    currencyConfiguration(values.base_currency || 'USD') || config.currencyConfig
                  }
                  validate={priceRequired}
                />
              </HeadingFormFieldWrapper>

              <HeadingFormFieldWrapper
                label={standardTimeLabel}
                supportText={standardTimeSupportMessage}
              >
                <div className={css.timeCalculation}>
                  {/* <img
                  src={timeCalculationTableImage}
                  alt={'Standard Delivery & Collaboration Time Calculation table'}
                /> */}
                  <table>
                    <tr>
                      <td>Number of items entered by visitor</td>
                      <td>&lt;8</td>
                      <td>9-20</td>
                      <td>21-30</td>
                      <td>31+</td>
                    </tr>
                    <tr>
                      <td>Number of participants entered by visitor</td>
                      <td>&lt;6</td>
                      <td>7-15</td>
                      <td>16-30</td>
                      <td>31+</td>
                    </tr>
                    <tr>
                      <td>Standard time displayed to visitor</td>
                      <td>&lt;48hrs</td>
                      <td>&lt;72hrs</td>
                      <td>&lt;96hrs</td>
                      <td>&lt;120hrs</td>
                    </tr>
                  </table>
                </div>
              </HeadingFormFieldWrapper>

              <HeadingFormFieldWrapper
                label={package3DeliveryTimeLabel}
                supportText={deliveryTimePriceSupportMessage}
              ></HeadingFormFieldWrapper>

              <div className={css.deliveryTimeFields}>
                <div>
                  <div className={css.timeFieldsWrapper}>
                    <FormattedMessage id="EditListingPricingForm.24hrsPremium" />
                    <FieldTextInput
                      id="package_delivery24quicker"
                      name="packages[2][delivery24quicker]"
                      className={css.timeFields}
                      type="number"
                      // validate={textRequired}
                    />
                    <span>%</span>
                  </div>

                  <div className={css.timeFieldsWrapper}>
                    <FormattedMessage id="EditListingPricingForm.48hrsPremium" />
                    <FieldTextInput
                      id="package_delivery48quicker"
                      name="packages[2][delivery48quicker]"
                      className={css.timeFields}
                      type="number"
                      // validate={textRequired}
                    />
                    <span>%</span>
                  </div>
                </div>

                <div>
                  <div className={css.timeFieldsWrapper}>
                    <FormattedMessage id="EditListingPricingForm.24hrsDiscount" />
                    <FieldTextInput
                      id="package_delivery24Slower"
                      name="packages[2][delivery24Slower]"
                      className={css.timeFields}
                      type="number"
                      // validate={textRequired}
                    />
                    <span>%</span>
                  </div>

                  <div className={css.timeFieldsWrapper}>
                    <FormattedMessage id="EditListingPricingForm.48hrsDiscount" />
                    <FieldTextInput
                      id="package_delivery48Slower"
                      name="packages[2][delivery48Slower]"
                      className={css.timeFields}
                      type="number"
                      // validate={textRequired}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>

              <HeadingFormFieldWrapper
                label={package3CollaborationTimeLabel}
                supportText={collaborationTimePriceSupportMessage}
              ></HeadingFormFieldWrapper>
              <div className={css.deliveryTimeFields}>
                <div>
                  <div className={css.timeFieldsWrapper}>
                    <FormattedMessage id="EditListingPricingForm.24hrsLongerPremium" />
                    <FieldTextInput
                      id="package_collaboration24Longer"
                      name="packages[2][collaboration24Longer]"
                      className={css.timeFields}
                      type="number"
                      // validate={textRequired}
                    />
                    <span>%</span>
                  </div>

                  <div className={css.timeFieldsWrapper}>
                    <FormattedMessage id="EditListingPricingForm.48hrsLongerPremium" />
                    <FieldTextInput
                      id="package_collaboration48Longer"
                      name="packages[2][collaboration48Longer]"
                      className={css.timeFields}
                      type="number"
                      // validate={textRequired}
                    />
                    <span>%</span>
                  </div>
                </div>

                <div>
                  <div className={css.timeFieldsWrapper}>
                    <FormattedMessage id="EditListingPricingForm.24hrsShorterDiscount" />
                    <FieldTextInput
                      id="package_collaboration24Shorter"
                      name="packages[2][collaboration24Shorter]"
                      className={css.timeFields}
                      type="number"
                      // validate={textRequired}
                    />
                    <span>%</span>
                  </div>

                  <div className={css.timeFieldsWrapper}>
                    <FormattedMessage id="EditListingPricingForm.48hrsShorterDiscount" />
                    <FieldTextInput
                      id="package_collaboration48Shorter"
                      name="packages[2][collaboration48Shorter]"
                      className={css.timeFields}
                      type="number"
                      // validate={textRequired}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
            </Collapsible>

            <p className={css.pricingInfo}>
              <p className={css.pricingInfo}>TIPS :</p>
              {introductionBasicSupportMessage}
              <br />
              {introductionDetailedSupportMessage}
            </p>
            <br />

            <Button
              rootClassName={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);

import React, { useEffect } from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  AvatarLarge,
  IconStar,
  ShareButton,
  SecondaryButton,
  WhatsappButton,
  Button,
  Map,
} from '../../components';
import { EnquiryForm } from '../../forms';
import css from './CompanyPage.css';
import ActionBarMaybe from 'containers/ListingPage/ActionBarMaybe';
import { getCurrentPageUrl } from 'util/urlHelpers';
import { FormattedMessage } from 'util/reactIntl';
import CompanyPageCommonSection from './CompanyPageCommonSection';

function CompanyPageContent(props) {
  const {
    intl,
    topbar,
    isOwnListing,
    currentListing,
    currentUser,
    listingId,
    listingSlug,
    listingType,
    listingTab,
    onSubmitEnquiry,
    title,
    isAuthenticated,
    otherAuthorsListings,
    authorDisplayName,
    authorsProductListings,
    fetchAuthorClasses,
    authorClasses,
    authorClassesIsLoading,
    allListingReviews,
    allClassListingReviews,
    onManageDisableScrolling,
    sendEnquiryError,
    sendEnquiryInProgress,
    showContactUser,
    onContactUser,
    ensuredAuthor,
    enquiryModalOpen,
    setEnquiryModalOpen,
    viewport,
    authorDateOfJoin,
    listingVariant,
  } = props;

  const shareUrl = getCurrentPageUrl();
  const { publicData, description, metadata } = currentListing.attributes;
  const { category, sub_category } = publicData;
  // const categoryData = config.custom.categories.find(cat => cat.key === category);
  const authorAvailable = currentListing && currentListing.author;
  const currentAuthor = authorAvailable ? currentListing.author : null;

  const reviews = []; //TODO get the reviews of the associated users

  //TODO this is a temporary fix, the safe solution is rewrite the .duck file to follow the original ST structure ( big job )
  if (currentListing?.images?.length === 0) {
    currentListing.images = currentListing?.attributes?.images || [];
  }
  // --------------------------

  //TODO this is a temporary fix, the safe solution is rewrite the
  //EditListingDescriptionPanel file to follow the original ST listing structure ( big job )
  const noLocation = !currentListing?.attributes?.geolocation;
  const noAddress = !publicData?.location;
  const publicDataGeolocation = publicData?.geolocation;
  const publicDataAddress = publicData?.Travel_business_address?.selectedPlace;
  if (
    currentListing?.attributes &&
    noLocation &&
    noAddress &&
    publicDataGeolocation &&
    publicDataAddress
  ) {
    currentListing.attributes.geolocation = publicDataGeolocation;
    currentListing.attributes.publicData.location = publicDataAddress;
  }

  const backgroundImage = currentListing.images[0];
  const profileImage = currentListing.images[1];

  return (
    <LayoutSingleColumn className={css.pageRoot}>
      <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
      <LayoutWrapperMain>
        <div>
          <div className={css.actionBarWrapper}>
            <ActionBarMaybe
              isOwnListing={isOwnListing}
              listing={currentListing}
              routeName="EditCompanyPage"
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
              }}
            />
          </div>
          <CompanyPageCommonSection
            currentListing={currentListing}
            backgroundImage={backgroundImage}
            profileImage={profileImage}
            intl={intl}
            listingVariant={listingVariant}
            title={title}
            review={reviews}
            authorsProductListings={authorsProductListings}
            otherAuthorsListings={otherAuthorsListings}
            allListingReviews={allListingReviews}
          />
        </div>
        <Modal
          id="CompanyPage.enquiry"
          contentClassName={css.enquiryModalContent}
          isOpen={isAuthenticated && enquiryModalOpen}
          onClose={() => setEnquiryModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <EnquiryForm
            className={css.enquiryForm}
            submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
            listingTitle={
              <FormattedMessage id="EnquiryForm.heading" values={{ listingTitle: title }} />
            }
            authorDisplayName={authorDisplayName}
            sendEnquiryError={sendEnquiryError}
            onSubmit={onSubmitEnquiry}
            inProgress={sendEnquiryInProgress}
          />
        </Modal>
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </LayoutSingleColumn>
  );
}

export default CompanyPageContent;

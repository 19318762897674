import React from 'react';
import cns from 'classnames'
import {
    SecondaryButton,
} from 'components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './TopBlogsPage.css';

const SectionEBook = props => {

    return (
        <div className={css.eBookSection}>
            <div className={css.customerStoriesTop}>
                <h3 className={css.customerStoriesTitle}><FormattedMessage id={'SectionEBook.EBooks'} /></h3>
                <SecondaryButton className={cns(css.allCustomerStoriesBtn, css.desktopButton)}>
                    <FormattedMessage id={'SectionEBook.allEBooks'} />
                </SecondaryButton>
            </div>
            <div className={css.customerStoriesContainer}>
                <div className={css.customerStoriesItem}>
                    <div className={css.eBookImageContainer}>
                        <div className={css.eBookImageDetailWrapper}>
                        <span className={css.imageHeading}><FormattedMessage id={'SectionEBook.eBooksImageTitle'} /></span>
                            <span className={css.imageInfo}><FormattedMessage id={'SectionEBook.eBooksImageInfo'} /></span>
                        </div>
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionEBook.eBooksTitle'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionEBook.eBooksInfo'} /></p>
                </div>
                <div className={css.customerStoriesItem}>
                    <div className={css.eBookImageContainer}>
                        <div className={css.eBookImageDetailWrapper}>
                            <span className={css.imageHeading}><FormattedMessage id={'SectionEBook.eBooksImageTitle'} /></span>
                            <span className={css.imageInfo}><FormattedMessage id={'SectionEBook.eBooksImageInfo'} /></span>
                        </div>
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionEBook.eBooksTitle'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionEBook.eBooksInfo'} /></p>
                </div>
                <div className={css.customerStoriesItem}>
                    <div className={css.eBookImageContainer}>
                        <div className={css.eBookImageDetailWrapper}>
                        <span className={css.imageHeading}><FormattedMessage id={'SectionEBook.eBooksImageTitle'} /></span>
                            <span className={css.imageInfo}><FormattedMessage id={'SectionEBook.eBooksImageInfo'} /></span>
                        </div>
                    </div>
                    <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionEBook.eBooksTitle'} /></span>
                    <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionEBook.eBooksInfo'} /></p>
                </div>
            </div>
            <SecondaryButton className={cns(css.allCustomerStoriesBtn, css.mobileButton)}>
                    <FormattedMessage id={'SectionEBook.allEBooks'} />
                </SecondaryButton>
        </div>
    );
}

export default SectionEBook;
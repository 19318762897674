export const deepLocationSearchMaybe = async (sdk, params = {}) => {
  try {
    const skipSearch =
      params.pub_listing_type !== 'listing' || !params?.address || params?.address === 'Everywhere';
    if (skipSearch) {
      return [];
    }
    const combinedSearchParams = {
      'fields.image': params['fields.image'],
      'fields.listing': params['fields.listing'],
      'fields.user': params['fields.user'],
      include: params.include,
      page: 1,
      per_page: 50,
      pub_listing_type: params.pub_listing_type,
      keywords: params?.address,
    };
    const results = await sdk.listings.query(combinedSearchParams);
    return {
      data: results?.data?.data || [],
      included: results?.data?.included || [],
    };
  } catch (e) {
    console.log(e);
    return [];
  }
};

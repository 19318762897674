import React, { useState, useEffect } from 'react';
import { Button } from '../../../components';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Box from '@mui/material/Box';

import css from './ChargeCustomerForm.module.css';
import CardSection from '../CardSection/CardSection';
import { post } from 'util/api';

const ChargeCustomerForm = props => {
  const { onSubmit, listing, pageData, speculatedTransaction } = props;
  console.log(speculatedTransaction);
  const [stripe, setStripe] = useState(false);
  const [card, setCard] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const authorId = listing?.author?.id?.uuid;
  const amountToPay = speculatedTransaction?.attributes?.payinTotal?.amount;
  const handleStripeToken = (event, stripe, elements) => {
    const card = elements.getElement(CardElement);

    if (event.complete) {
      setSubmitReady(true);
    }
    setCard(card);
    setStripe(stripe);
  };

  const handleErrMsg = (code) => {
    switch (code) {
      case 'expired_card':
        return "Card Expired";
      case 'incorrect_cvc':
        return "Incorrect CVC";
      case 'incorrect_number':
        return "Incorrect Number";
      case 'processing_error':
        return "Processing Error";
      default:
        return "Processing Error";
    }
  }

  const handleDeclineErrMsg = (declineCode) => {
    switch (declineCode) {
      case 'generic_decline':
        return 'Card Declined';
      case 'insufficient_funds':
        return 'Insufficient Funds'
      case 'lost_card':
      case 'stolen_card':
        return 'Card Invalid';
      default:
        return 'Declined';
    }
  }

  const speculatedTxId = speculatedTransaction?.id?.uuid;
  const handleSubmit = () => {
    setLoading(true);
    setErrorMessage("");

    return stripe
      .createToken(card)
      .then(resp => {
        const token = resp.token;
        const body = {
          token,
          amount: amountToPay,
          description: `
          EXPERT: ${listing?.author?.attributes?.profile?.displayName}
          LISTID: ${listing?.id?.uuid}
          TRANSID: ${speculatedTxId}
          `,
          authorId,
          txId: speculatedTxId,
        };

        return post('/api/charge-amount-from-customer', body)
          .then(resp => {
            const chargeId = resp?.id;
            if (chargeId) {
              return onSubmit({ chargeId, speculatedTxId });
            } else {
              const errCode = resp.code;
              const declineCode = resp?.decline_code;
              const errMsg = declineCode ? handleDeclineErrMsg(declineCode) : handleErrMsg(errCode);
              setError(true);
              setErrorMessage(errMsg);
              throw new Error('missing chargeId')
            }
          })
          .catch(e => {
            throw e;
          });
      })
      .catch(e => {
        setLoading(false);
        setError(true);
        console.log(e);
      });
  };

  //   setLoading(false);
  //   setSubmitted(true);
  //   setSuccess(true);
  //   setError(false);
  // })
  // .catch(e => {
  //   setLoading(false);
  //   setError(true);
  //   console.log(e);
  // });

  const successMessage = 'Congratulations! Your payment was successful!';
  const submitDisabled = !submitReady;

  if (!amountToPay) {
    return null;
  }

  return (
    <div className={css.wrapper}>
      <h2 className={css.title}>Payment details</h2>
      <div className={css.cardSectionWrapper}>
        <CardSection handleStripeToken={handleStripeToken} setError={setError} setErrorMessage={setErrorMessage} />
      </div>
      <Button
        type="button"
        onClick={handleSubmit}
        disabled={submitDisabled || submitted || loading}
      >
        {submitted ? (
          <CheckIcon />
        ) : loading ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress className={css.spinnerMiddle} />
          </Box>
        ) : (
          'Send request'
        )}
      </Button>
      {error && <div className={css.errorMsg}>{errorMessage}</div>}
    </div>
  );
};

export default ChargeCustomerForm;

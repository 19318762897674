import React, { Component } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isAnyFilterActive, isMainSearchTypeKeywords } from '../../util/search';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Draggable from 'react-draggable';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import SearchProductResultsPanel from './SearchProductResultsPanel/SearchProductResultsPanel';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import FilterComponent from './FilterComponent';
import {
  SearchMap,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  ModifiedSearchFilters,
  SubCategoryFilter,
} from '../../components';
import { TopbarContainer } from '../../containers';
import IconMap from '../../components/IconMap/IconMap';
import {
  searchListings,
  searchMapListings,
  setActiveListing,
  fetchAllListingReviews,
} from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import css from './SearchPage.css';
import SectionSearchHeader from './SectionSearchHeader/SectionSearchHeader';
import MobileHoverSubCategoryFilter from '../../components/ModifiedSearchFilters/Components/MobileHoverSubCategoryFilter/MobileHoverSubCategoryFilter';
import { LISTING_TYPES } from 'util/constants';
import FiltersSection from './FiltersSection/FiltersSection';
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 50;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const DRAG_LAYOUT_BREAKPOINT = 1024;
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const LISTING_DRAG_MAP_SHOW_OFFSET = 550;
const LISTING_DRAG_LIMIT_OFFSET = 120;

const validUrlQueryParamsFromProps = props => {
  const { location, productFilterConfig } = props;
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  return validURLParamsForExtendedData(searchInURL, productFilterConfig);
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, productFilterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    productFilterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: false,
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      listingDrawerPos: { x: 0, y: 0 },
      isMapIconVisible: false,
      dataCurrentPageResultIds: [],
      isMapOpen: false,
      keyWordValue: '',
      showConnetPro: false,
      showMobileFiltersTool: false,
      listingsModeValue: 'in_person',
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
  }

  isMobileLayout = () => {
    const isWindowDefined = typeof window !== 'undefined';
    return isWindowDefined && window.innerWidth < DRAG_LAYOUT_BREAKPOINT;
  };

  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  scrollFunction = () => {
    if (typeof window !== 'undefined') {
      const { isMapIconVisible, listingDrawerPos } = this.state;
      const { y } = listingDrawerPos;
      const isMobileLayout = this.isMobileLayout();
      const scrolled = window.scrollY;
      const totalHeight = window.innerHeight;
      const isScrolledEnough = scrolled > totalHeight;
      const dragLimit = totalHeight - LISTING_DRAG_MAP_SHOW_OFFSET;
      if (scrolled > 200) {
        this.setState({ showConnetPro: true });
      } else {
        this.setState({ showConnetPro: false });
      }

      if (isMobileLayout && scrolled > 450) {
        this.setState({ showMobileFiltersTool: true });
      } else {
        this.setState({ showMobileFiltersTool: false });
      }

      if (!isScrolledEnough && y > dragLimit && isMapIconVisible) {
        this.setState({ isMapIconVisible: false });
      } else if (isScrolledEnough && !isMapIconVisible) {
        this.setState({ isMapIconVisible: true });
      }
    }
  };

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('scroll', this.scrollFunction);
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    if (urlQueryParams && urlQueryParams.keywords && this.state.keyWordValue === '') {
      this.setState({ keyWordValue: urlQueryParams.keywords });
    }

    const isMobileLayout = this.isMobileLayout();
    //Add Chatra
    // if (typeof window !== 'undefined' && typeof document !== 'undefined' && !isMobileLayout) {
    //   (function(d, w, c) {
    //     w.ChatraID = '3jnywm2fKfLtHLfWp';
    //     var s = d.createElement('script');
    //     w[c] =
    //       w[c] ||
    //       function() {
    //         (w[c].q = w[c].q || []).push(arguments);
    //       };
    //     s.async = true;
    //     s.src = 'https://call.chatra.io/chatra.js';
    //     if (d.head) d.head.appendChild(s);
    //   })(document, window, 'Chatra');
    // } else {
    //   if (typeof document !== 'undefined') {
    //     const chatra = document.getElementById('chatra');
    //     if (chatra !== null) document.body.removeChild(chatra);
    //   }
    // }
  }

  componentDidUpdate() {
    if (
      this.props.currentPageResultIds.length !== 0 &&
      JSON.stringify(this.state.dataCurrentPageResultIds) !==
      JSON.stringify(this.props.currentPageResultIds)
    ) {
      this.props.onFetchAllListingReviews(this.props.currentPageResultIds);
      this.setState({ dataCurrentPageResultIds: this.props.currentPageResultIds });
    }
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('scroll', this.scrollFunction);
    // if (typeof document !== 'undefined') {
    //   const chatra = document.getElementById('chatra');
    //   if (chatra !== null) document.body.removeChild(chatra);
    // }
  }

  filters() {
    const {
      categoryConfig,
      subcategoryConfig,
      languageConfig,
      levelConfig,
      priceFilterConfig,
      keywordFilterConfig,
      dateRangeLengthFilterConfig,
      listingTypeConfig,
    } = this.props;

    // Note: "category" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    return {
      typeFilter: {
        paramName: 'pub_listing_type',
        options: listingTypeConfig,
      },
      categoryFilter: {
        paramName: 'pub_category',
        options: categoryConfig.filter(c => !c.hideFromFilters),
      },
      subcategoryFilter: {
        paramName: 'pub_sub_category',
        options: subcategoryConfig.filter(c => !c.hideFromFilters),
      },
      languageFilter: {
        paramName: 'pub_hosted_in',
        options: languageConfig.map(i => ({ ...i, key: `has_any:${i.key}` })),
      },
      levelFilter: {
        paramName: 'meta_level',
        options: levelConfig,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      dateRangeLengthFilter: {
        paramName: 'dates',
        minDurationParamName: 'minDuration',
        timesParamName: 'times',
        config: dateRangeLengthFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
    };
  }

  onClickDraggableHandler = () => {
    if (this.state.isMapOpen === true) {
      this.setState({ listingDrawerPos: { x: 0, y: 0 }, isMapOpen: false });
    }
  };

  renderDraggableHandler = isMobileLayout =>
    isMobileLayout ? (
      <div className={css.draggableHandler}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
        >
          <path
            d="M1.61133 7.36719H14.387"
            stroke="#233045"
            strokeWidth="1.68101"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.61133 1.65137H14.387"
            stroke="#233045"
            strokeWidth="1.68101"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ) : null;

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath = this.props.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...rest,
        ...validFilterParams(rest, this.filters()),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
      var mapElement = document.getElementById('search-map');
      const y = mapElement.getBoundingClientRect().top;
      if (typeof window !== 'undefined') window.scrollTo({ top: y - 150 });
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onControlledDragStop = (e, position) => {
    const { y } = position;
    this.setState({ listingDrawerPos: { x: 0, y } });
  };
  onControlledDrag = (e, position) => {
    if (typeof window !== 'undefined') {
      const { isMapIconVisible } = this.state;
      const { y } = position;
      const dragLimit = window.innerHeight - LISTING_DRAG_MAP_SHOW_OFFSET;

      if (y > dragLimit && isMapIconVisible) {
        this.setState({ isMapIconVisible: false });
      } else if (y <= dragLimit && !isMapIconVisible) {
        this.setState({ isMapIconVisible: true });
      }
    }
  };

  onMapButtonClick = () => {
    if (typeof window !== 'undefined') {
      const yPos = window.innerHeight - LISTING_DRAG_LIMIT_OFFSET;
      window.scrollTo(0, 0);
      this.setState({
        listingDrawerPos: { x: 0, y: yPos },
        isMapOpen: true,
        isMapIconVisible: false,
      });
    }
  };

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, sortConfig, productFilterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, productFilterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, productFilterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = productFilterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames, isLiveEdit) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
        return { ...acc, [paramName]: getInitialValue(paramName) };
      }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, sortConfig, productFilterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };
        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };
      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            sortConfig,
            productFilterConfig
          );
          search.pub_listing_type = 'product';
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  onClickModeItemHandler = value => {
    const { history } = this.props;
    const urlParams = parse(history.location.search);
    let queryParams = { ...urlParams, pub_listing_mode: value };
    this.setState({ listingsModeValue: value });
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  render() {
    const {
      intl,
      listings,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      productFilterConfig,
      listingAllReviews,
      allAuthorNumberOfListings,
      history,
      otherListings,
    } = this.props;

    if (typeof window === 'undefined') {
      return null;
    }
    const {
      listingDrawerPos,
      isMapIconVisible,
      isMapOpen,
      showConnetPro,
      showMobileFiltersTool,
    } = this.state;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const filters = this.filters();
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;
    let validQueryParams = validURLParamsForExtendedData(searchInURL, filters);
    if (validQueryParams.pub_listing_type === LISTING_TYPES.PRODUCT) {
      validQueryParams = validURLParamsForExtendedData(searchInURL, productFilterConfig);
    }
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = this.isMobileLayout();
    const windowHeight = isWindowDefined && window.innerHeight;
    const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && true);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;
    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;
    const selectedFilters = validFilterParams(urlQueryParams, filters);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const availableFilters = isKeywordSearch
      ? productFilterConfig.filter(f => f.type !== 'KeywordFilter')
      : productFilterConfig;

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    const hasSearchParams = location.search?.length > 0;
    const noResultsInfo = hasNoResult ? (
      <div className={css.noSearchResults}>
        <FormattedMessage id="SearchPage.noResults" />
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => this.resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
    ) : null;

    const isPartnersSearch = urlQueryParams?.pub_listing_type === LISTING_TYPES.COMPANY;
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        // isBottomMenuRequire={false}
        title={title}
        schema={schema}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            {/* {!showMobileFiltersTool ? ( */}
            <TopbarContainer
              className={topbarClasses}
              currentPage="SearchPage"
              currentSearchParams={urlQueryParams}
            // keywordSearchTopbar={true}
            />
            {/* ) : (
              <MobileHoverSubCategoryFilter
                urlQueryParams={urlQueryParams}
                redirectPageName={'SearchPage'}
                isMobileLayout={isMobileLayout}
                categoryFilter={filters.categoryFilter}
                subcategoryFilter={filters.subcategoryFilter}
                history={history}
              />
            )} */}
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <div className={css.pageWrapper}>
              {/* {!isMobileLayout && (
              <section>
                <ModifiedSearchFilters
                  urlQueryParams={urlQueryParams}
                  redirectPageName={'SearchPage'}
                  isMobileLayout={isMobileLayout}
                  categoryFilter={filters.categoryFilter}
                  subcategoryFilter={filters.subcategoryFilter}
                  priceFilter={filters.priceFilter}
                  languageFilter={filters.languageFilter}
                  history={history}
                />
              </section>
            )} */}

              {/* {!isMobileLayout && validQueryParams.pub_listing_type !== LISTING_TYPES.COMPANY && (
              <section>
                <SectionSearchHeader
                  urlQueryParams={urlQueryParams}
                  redirectPageName={'SearchPage'}
                  isMobileLayout={isMobileLayout}
                  location={location}
                  history={history}
                />
              </section>
            )} */}

              {/* old indian filters */}
              {/* {!isMobileLayout && validQueryParams.pub_listing_type !== LISTING_TYPES.COMPANY && (
              <SubCategoryFilter
                urlQueryParams={urlQueryParams}
                redirectPageName={'SearchPage'}
                isMobileLayout={isMobileLayout}
                categoryFilter={filters.categoryFilter}
                subcategoryFilter={filters.subcategoryFilter}
                priceFilter={filters.priceFilter}
                languageFilter={filters.languageFilter}
                history={history}
                listingType={validQueryParams.pub_listing_type}
              />
            )} */}
              {!isPartnersSearch && (
                <FiltersSection
                  currentQueryParams={validUrlQueryParamsFromProps(this.props)}
                  history={history}
                />
              )}
              <section className={css.sectionMapContainer}>
                {/* {isMobileLayout && (
                <div
                  className={classNames(css.mapBtn, isMapIconVisible && css.shown)}
                  onClick={this.onMapButtonClick}
                >
                  <IconMap />
                  Map
                </div>
              )} */}

                <div className={css.subContainer}>
                  {/* <Draggable
                  axis="y"
                  handle={`.${css.draggableHandler}`}
                  bounds={{ top: 0, bottom: windowHeight - LISTING_DRAG_LIMIT_OFFSET }}
                  position={listingDrawerPos}
                  onDrag={this.onControlledDrag}
                  onStop={this.onControlledDragStop}
                  onStart={this.onClickDraggableHandler}
                  disabled={!isMobileLayout}
                > */}
                  <div className={css.draggableWrapper}>
                    {/* {isMapOpen && <div>{this.renderDraggableHandler(isMobileLayout)}</div>} */}
                    <MainPanel
                      urlQueryParams={validQueryParams}
                      sort={sort}
                      listings={listings.filter(l => {
                        if (isPartnersSearch) {
                          return true;
                        } else {
                          return l?.attributes?.price?.amount > 0;
                        }
                      })}
                      otherListings={otherListings}
                      searchInProgress={searchInProgress}
                      searchListingsError={searchListingsError}
                      searchParamsAreInSync={searchParamsAreInSync}
                      onActivateListing={onActivateListing}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onOpenModal={this.onOpenMobileModal}
                      onCloseModal={this.onCloseMobileModal}
                      onMapIconClick={onMapIconClick}
                      pagination={pagination}
                      searchParamsForPagination={parse(location.search)}
                      showAsModalMaxWidth={MODAL_BREAKPOINT}
                      primaryFilters={{
                        typeFilter: filters.typeFilter,
                        categoryFilter: filters.categoryFilter,
                        subcategoryFilter: filters.subcategoryFilter,
                        languageFilter: filters.languageFilter,
                        levelFilter: filters.levelFilter,
                        priceFilter: filters.priceFilter,
                        dateRangeLengthFilter: filters.dateRangeLengthFilter,
                        keywordFilter: filters.keywordFilter,
                      }}
                      history={history}
                      allListingReview={listingAllReviews}
                      allAuthorNumberOfListings={allAuthorNumberOfListings}
                      isMobileLayout={isMobileLayout}
                    />

                    {validQueryParams.pub_listing_type === LISTING_TYPES.COMPANY ? (
                      <div className={css.partnershipWrapper}>
                        <p>
                          Own a Travel Agency or Business in one of these <a href='https://stripe.com/au/global' target='_blank'>supported countries</a> and would like to offer Trip Expert
                          Services on the Marketplace ?
                        </p>
                        <div className={css.partnershipButtonWrapper}>
                          <NamedLink
                            name="BecomeExpertOrPartnerPage"
                            className={css.partnershipButton}
                          >
                            Apply for Partnership
                          </NamedLink>
                        </div>
                      </div>
                    ) : null}
                    {validQueryParams.pub_listing_type === LISTING_TYPES.COMPANY ? (
                      <div className={css.businessText}>
                        <h3>
                          <FormattedMessage id="PartnerTravelAgencies.backgroudTitle" />
                        </h3>
                        <p className={css.topSpan}>
                          <FormattedMessage id="PartnerTravelAgencies.backgroundInfo" />
                        </p>
                        <h3>
                          <FormattedMessage id="PartnerTravelAgencies.leadFeeTitle" />
                        </h3>
                        <p className={css.topSpan}>
                          <FormattedMessage id="PartnerTravelAgencies.leadFeeInfo1" />
                        </p>
                        <p className={css.topSpan}>
                          <FormattedMessage id="PartnerTravelAgencies.leadFeeInfo2" />
                        </p>
                      </div>
                    ) : null}
                  </div>
                  {/* </Draggable> */}
                </div>
              </section>
            </div>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            {/* {!isMobileLayout && <Footer className={css.footer} />} */}
            <Footer className={css.footer} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  categoryConfig: config.custom.categories,
  subcategoryConfig: config.custom.subCategoriesFilterOptions,
  languageConfig: config.custom.languageFilterConfig,
  levelConfig: config.custom.levelFilterConfig,
  priceFilterConfig: config.custom.priceFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  dateRangeLengthFilterConfig: config.custom.dateRangeLengthFilterConfig,
  listingTypeConfig: config.custom.listingTypeFilterConfig,
  productFilterConfig: config.custom.productFilters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  categoryConfig: array,
  subcategoryConfig: array,
  languageConfig: array,
  levelConfig: array,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeLengthFilterConfig: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    otherCurrentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    listingAllReviews,
    allAuthorNumberOfListings,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  const otherListings = getListingsById(state, otherCurrentPageResultIds);
  return {
    listings: pageListings,
    otherListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentPageResultIds,
    listingAllReviews,
    allAuthorNumberOfListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onFetchAllListingReviews: listingRef => dispatch(fetchAllListingReviews(listingRef)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    address,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'description', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],

    // 'limit.images': 1,
  });
};

export default SearchPage;

import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import SearchHeaderForm from './SearchHeaderForm';
import { parse } from '../../../util/urlHelpers';
import routeConfiguration from '../../../routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';
import css from './SectionSearchHeader.css';
import config from '../../../config';
import { SectionSearch } from 'components';

const PromoCategories = config.custom.tripReasonLandingTitle;
const PromoCategoriesOptions = config.custom.tripReasonOptions;
function SectionSearchHeader(props) {
  const { location, history, redirectPageName } = props;

  const validQueryParams = history.location.search && parse(history.location.search);
  const tripReasonLabel = validQueryParams && validQueryParams.pub_trip_reason;

  const pub_trip_reason_key = validQueryParams?.pub_trip_reason;
  let pub_trip_reason_Value = PromoCategories.filter(cat => cat.key === pub_trip_reason_key)[0];

  const submitHandler = values => {
    const type = validQueryParams && validQueryParams.pub_category;
    if (
      !values.location &&
      JSON.parse(values.promoCategory).label === tripReasonLabel &&
      JSON.parse(values.promoCategory).type === type
    )
      return;
    let searchParams = {};
    if (values.location && values.location.search !== validQueryParams.address) {
      const { search = '', selectedPlace } = values.location;
      const { origin, bounds } = selectedPlace;
      searchParams = {
        ...validQueryParams,
        address: search,
        origin,
        bounds,
        pub_trip_reason: values.promoCategory
          ? JSON.parse(values.promoCategory).key
          : history.location.search && parse(history.location.search).pub_trip_reason,
        pub_category: null,
      };
    } else {
      searchParams = {
        ...validQueryParams,
        pub_trip_reason:
          JSON.parse(values.promoCategory).key ||
          (history.location.search && parse(history.location.search).pub_trip_reason),
        pub_category: null,
      };
    }
    if (values.promoCategory && JSON.parse(values.promoCategory).type) {
      searchParams['pub_category'] = JSON.parse(values.promoCategory).type;
    }

    history.push(
      createResourceLocatorString(redirectPageName, routeConfiguration(), {}, searchParams)
    );
  };

  const handleProviderQueryChange = providerQueryValue => {
    // props.searchListings({
    //   keywords: providerQueryValue,
    // });
  };

  return (
    <section className={css.hostedByProsVideoSection}>
      <div className={css.hostedByProsWrap}>
        <div className={css.hostedProsHeadingWrap}>
          <h3 className={css.hostedByProsTitle}>
            {pub_trip_reason_Value?.label || (
              <FormattedMessage id={'ModifiedSearchFilters.getawayLetSomeFun'} />
            )}
          </h3>
          <span className={css.hostedByProsSlogan}>Your idea, researched and planned for you.</span>
          <SectionSearch
            intl={props.intl}
            history={props.history}
            suggestedListings={PromoCategoriesOptions}
            handleProviderQueryChange={handleProviderQueryChange}
            title={''}
            location={location}
            searchPage={true}
            isMobile
          />
          {/* <SearchHeaderForm onSubmit={submitHandler} location={location} /> */}
        </div>

        <div className={css.videoWrapper}>
          <img
            src={pub_trip_reason_Value?.image || '/static/images/searchPage/searchPageBanner.png'}
            alt=""
          />
        </div>
      </div>
    </section>
  );
}

export default SectionSearchHeader;

import React from 'react';
import cns from 'classnames';
import {
    SecondaryButton,
    NamedLink,
} from 'components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './TopBlogsPage.css';

const SectionCustomerStories = props => {

    return (
        <div className={css.customerStoriesSection}>
            <div className={css.sectionWrapper}>
                <div className={css.customerStoriesTop}>
                    <h3 className={css.customerStoriesTitle}><FormattedMessage id={'SectionCustomerStories.benifitsOnlineBookingSoftware'} /></h3>
                    <SecondaryButton className={cns(css.allCustomerStoriesBtn, css.desktopButton)}>
                        <FormattedMessage id={'SectionCustomerStories.allCustomerStories'} />
                    </SecondaryButton>
                </div>
                <div className={css.customerStoriesContainer}>
                    <NamedLink name="ArticlePage" params={{id:'103'}} className={css.customerStoriesItem}>
                        <div className={css.customerStoriesImageContainer}>
                            <img src={'/static/images/topBlogsPage/onlineBookings.png'} alt="" />
                        </div>
                        <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionCustomerStories.customerStoriesTitle'} /></span>
                        <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionCustomerStories.bookingsFreelanceFitnessCoachingGigs'} /></p>
                    </NamedLink>
                    <NamedLink name="ArticlePage" params={{id:'104'}} className={css.customerStoriesItem}>
                        <div className={css.customerStoriesImageContainer}>
                            <img src={'/static/images/topBlogsPage/onlineBookingsSchedulingSoftware.png'} alt="" />
                        </div>
                        <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionCustomerStories.customerStoriesTitle'} /></span>
                        <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionCustomerStories.softwareFitnessStaffClients'} /></p>
                    </NamedLink>
                    <NamedLink name="ArticlePage" params={{id:'105'}} className={css.customerStoriesItem}>
                        <div className={css.customerStoriesImageContainer}>
                            <img src={'/static/images/topBlogsPage/howMuchDoesOnlineFitnessSchedulingSoftwareCost.png'} alt="" />
                        </div>
                        <span className={css.customerStoriesHeading}><FormattedMessage id={'SectionCustomerStories.customerStoriesTitle'} /></span>
                        <p className={css.customerStoriesInfo} ><FormattedMessage id={'SectionCustomerStories.howMuchDoesOnlineFitnessSchedulingSoftwareCost'} /></p>
                    </NamedLink>
                </div>
                <SecondaryButton className={cns(css.allCustomerStoriesBtn, css.mobileButton)}>
                    <FormattedMessage id={'SectionCustomerStories.allCustomerStories'} />
                </SecondaryButton>
            </div>
        </div>
    );
}

export default SectionCustomerStories;
import React, { useState } from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import { PlainTabs } from '../../../components';
import SubTabSectionDetailsContent from './SubTabSectionDetailsContent';
import ListingBookingSection from './ListingBookingSection';
import { PACKAGE_TYPES } from '../../../util/constants';

const ListingDetailsSectionSubTabs = props => {
  const { authorDetails, ...rest } = props;
  return (
    <div className={css.subDetailsStickyContainer}>
      <PlainTabs
        menuListClass={css.subDetailsTabMenu}
        data={[
          {
            id: 'basic',
            label: 'Basic',
            content: <SubTabSectionDetailsContent avatarDetails={authorDetails} tabName={PACKAGE_TYPES.BASIC} tabIndex={0} {...rest} />,
          },
          {
            id: 'detailed',
            label: 'Detailed',
            content: <SubTabSectionDetailsContent avatarDetails={authorDetails} tabName={PACKAGE_TYPES.DETAILED} tabIndex={1} {...rest} />,
          },
          {
            id: 'custom',
            label: 'Custom',
            content: <ListingBookingSection avatarDetails={authorDetails} tabName={PACKAGE_TYPES.CUSTOM} tabIndex={2} {...rest} />,
          },
        ]}
      />
    </div>
  );
};
export default ListingDetailsSectionSubTabs;

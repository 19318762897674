import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SearchTopIconsMobile.css';

import Activities from './images/Activities.png';
import Facilities from './images/Facilities.png';
import Health from './images/Health.png';
import Kids from './images/Kids.png';
import Online from './images/Online.png';
import Trainers from './images/Trainers.png';
import Gym from './images/gym.png';
import Triathlon from './images/triathlon.png';
import Dance from './images/dance.png';
import Physio from './images/physio.png';
import Bootcamp from './images/bootcamp.png';
import Karate from './images/karate.png';
import Tennis from './images/tennis.png';
import Yoga from './images/yoga.png';
import Boxing from './images/boxing.png';
import Swimming from './images/swimming.png';
import Crossfit from './images/crossfit.png';
import Pilates from './images/pilates.png';
import Golf from './images/golf.png';
import Martial from './images/martial.png';
const SearchTopIconsMobile = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.topIcons}>
                <div className={css.icon}>
                    <div className={css.top_icon_item}>
                        <img className={css.img_sec}  src={Trainers} alt="Trainers" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.trainers" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.top_icon_item}>
                        <img className={css.img_sec}  src={Health} alt="Health" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.health" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.top_icon_item}>
                        <img className={css.img_sec}  src={Kids} alt="Kids" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.kids" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.top_icon_item}>
                        <img className={css.img_sec} src={Activities} alt="Activities" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.activities" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.top_icon_item}>
                        <img className={css.img_sec}  src={Online} alt="Online" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.online" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.top_icon_item}>
                        <img  className={css.img_sec} src={Facilities} alt="Facilities" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.facilities" />
                        </p>
                    </div>
                </div>
            </div>
            <div className={css.topIcons}>
                <div className={css.filter_btn}>
                    <div className={css.icon_item}>
                        <p className={css.filter_Name}>
                            <FormattedMessage id="SearchTopFilter.style" />
                        </p>
                    </div>
                </div>
                <div className={css.filter_btn}>
                    <div className={css.icon_item}>
                        <p className={css.filter_Name}>
                            <FormattedMessage id="SearchTopFilter.seller" />
                        </p>
                    </div>
                </div>
                <div className={css.filter_btn}>
                    <div className={css.icon_item}>
                        <p className={css.filter_Name}>
                            <FormattedMessage id="SearchTopFilter.editable" />
                        </p>
                    </div>
                </div>
                <div className={css.filter_btn}>
                    <div className={css.icon_item}>
                        <p className={css.filter_Name}>
                            <FormattedMessage id="SearchTopFilter.scala" />
                        </p>
                    </div>
                </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
            </div>
            <div className={css.topIcons}>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img className={css.img}  src={Gym} alt="Gym" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.gym" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img className={css.img}  src={Tennis} alt="Tennis" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.tennis" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img className={css.img}  src={Yoga} alt="Yoga" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.yoga" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img className={css.img} src={Boxing} alt="Boxing" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.boxing" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img className={css.img}  src={Swimming} alt="Swimming" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.swimming" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Crossfit} alt="Crossfit" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.crossfit" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Pilates} alt="Pilates" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.pilates" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Golf} alt="Golf" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.golf" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Martial} alt="Martial" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.martial" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Triathlon} alt="Triathlon" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.triathlon" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Dance} alt="Dance" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.dance" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Physio} alt="Physio" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.physio" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Bootcamp} alt="Bootcamp" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.bootcamp" />
                        </p>
                    </div>
                </div>
                <div className={css.icon}>
                    <div className={css.icon_item}>
                        <img  className={css.img} src={Karate} alt="Karate" />
                        <p className={css.iconName}>
                            <FormattedMessage id="SectionTopIcon.karate" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

SearchTopIconsMobile.defaultProps = { rootClassName: null, className: null};

const { string } = PropTypes;

SearchTopIconsMobile.propTypes = {
    rootClassName: string,
    className: string,
};

export default SearchTopIconsMobile;
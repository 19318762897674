import React from 'react';
import {
  LocationAutocompleteInputField,
  Form,
  IconPractice,
  IconLocation,
  PrimaryButton,
  FieldSelect,
} from 'components';
import { parse } from '../../util/urlHelpers';
import { Form as FinalForm } from 'react-final-form';
import css from './PromoPage.css';
import IconSearch from '../../components/IconSearch/IconSearch'

const identity = v => v;

const PromoCategories = [
  { key: 'personal training', label: 'Personal Training' },
  { key: 'yoga', label: 'Yoga' },
  { key: 'pilates', label: 'Pilates' },
  { key: 'barre', label: 'Barre' },
  { key: 'crossfit', label: 'Crossfit' },
  { key: 'swimming', label: 'Swimming' },
  { key: 'bootcamp', label: 'Bootcamp' },
  { key: 'dance', label: 'Dance' },
  { key: 'olimpic lifting', label: 'Olimpic Lifting' },
  { key: 'tennis', label: 'Tennis' },
  { key: 'paddle tennis', label: 'Paddle Tennis' },
  { key: 'pickle ball', label: 'Pickle Ball' },
  { key: 'squash', label: 'Squash' },
  { key: 'badminton', label: 'Badminton' },
  { key: 'massage', label: 'Massage' },
  { key: 'physical Therapy', label: 'Physical Therapy' },
  { key: 'chiropractor', label: 'Chiropractor' },
  { key: 'meditation', label: 'Meditation' },
  { key: 'cryotherapy', label: 'Cryotherapy' },
  { key: 'TCM practise', label: 'TCM Practise' },
  { key: 'prenatal', label: 'Prenatal' },
  { key: 'acupuncture', label: 'Acupuncture' },
  { key: 'nutrition', label: 'Nutrition' },
  { key: 'martial_arts', label: 'Martial Arts' },
  { key: 'boxing', label: 'Boxing' },
  { key: 'kick boxing', label: 'Kick Boxing' },
  { key: 'jiu-jitsu', label: 'Jiu-jitsu' },
  { key: 'karate', label: 'Karate' },
  { key: 'rock climbing', label: 'Rock Climbing' },
  { key: 'skateboarding', label: 'Skateboarding' },
  { key: 'golf', label: 'Golf' },
  { key: 'obstacle sports', label: 'Obstacle Sports' },
  { key: 'wake boarding', label: 'Wake Boarding' },
  { key: 'cycling', label: 'Cycling' },
  { key: 'biking', label: 'Biking' },
  { key: 'running', label: 'Running' },
]

const PromoKidsCategories = [
  { key: 'tennis', label: 'Tennis', type: "kids" },
  { key: 'football', label: 'Football', type: "kids" },
  { key: 'cricket', label: 'Cricket', type: "kids" },
  { key: 'basketball', label: 'Basketball', type: "kids" },
  { key: 'boxing', label: 'Boxing', type: "kids" },
  { key: 'martial_arts', label: 'Martial Arts', type: "kids" },
  { key: 'kick boxing', label: 'Kick Boxing', type: "kids" },
  { key: 'golf', label: 'Golf', type: "kids" },
]

function PromoPageForm(props) {
  const { location } = props
  const type = (location && location.search && parse(location.search).pub_category) || "";
  const address = location && location.search && parse(location.search).address
  let defaultValue = {}
  if (type === "kids") {
    defaultValue = PromoKidsCategories.filter(cat => cat.key === props.subCategoryLabel)[0]
  }
  else {
    defaultValue = PromoCategories.filter(cat => cat.key === props.subCategoryLabel)[0]
  }
  let initialValues = { location: address ? { search: address } : null, promoCategory: defaultValue ? JSON.stringify(defaultValue) : "" }

  return(<FinalForm
        {...props}
        initialValues={initialValues}
        render={({ values, handleSubmit, subCategoryLabel }) => {
          const isValueChange = JSON.stringify(values) !== JSON.stringify(initialValues)
          return (
            <Form className={css.form} onSubmit={handleSubmit}>
              <h1>{subCategoryLabel} Activities hosted by <span className={css.blueText}>pros</span></h1>
              <h2>Book and Explore all Yoga at your finger tips.</h2>

              <div className={css.selectField}>

                <IconPractice className={css.practiceIcon} />
                <FieldSelect
                  id='promoCategory'
                  name='promoCategory'
                  selectClassName={css.fieldSelect}
                >
                  <option disabled value="">
                    Select Category
                  </option>
                  {PromoCategories.map(cat => (
                    <option value={JSON.stringify(cat)} key={cat.key}>
                      {cat.label}
                    </option>
                  ))}
                  <optgroup label="Kids">
                    {PromoKidsCategories.map(cat => (
                      <option value={JSON.stringify(cat)} key={cat.key}>
                        {cat.label}
                      </option>
                    ))}
                  </optgroup>
                </FieldSelect>
              </div>

              <div className={css.field}>
                <LocationAutocompleteInputField
                  className={css.location}
                  iconClassName={css.locationIcon}
                  autoFocus
                  name="location"
                  placeholder={'Select location'}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  iconComponent={<IconSearch />}
                />
              </div>

              {isValueChange && <PrimaryButton type="submit" className={css.submit}>
                Search
              </PrimaryButton>}
            </Form>
          );
        }}
      />);
}

export default PromoPageForm;

export const MAX_MOBILE_SCREEN_WIDTH = 768;

export const LISTING_TYPES = {
  LISTING: 'listing',
  ENQUIRY: 'enquiry',
  PRODUCT: 'product',
  COMPANY: 'company',
  CLASS: 'class',
  FACILITY: 'facility',
  Event: 'event',
};

export const PACKAGE_TYPES = {
  BASIC: 'basic',
  DETAILED: 'detailed',
  CUSTOM: 'custom',
};

export const PAYMENT_TYPES = {
  card: 'card',
  direct: 'direct',
  membership: 'membership',
  cash: 'cash',
  hitpay: 'hitpay',
};

// EditWizardTabs
export const OVERVIEW = 'overview';
export const PROFILE = 'profile';
export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const PHOTOS = 'photos';
export const AGREEMENT = 'agreement';
export const PAYMENTS = 'payments';
export const ADMIN = 'admin';
export const VIEWDRAFTBUSINESS = 'view_draft_business';
export const VIEWDRAFTLISTING = 'view_draft_listing';
export const CERTIFICATES = 'certificates';
export const EXPECTATIONS = 'expectations';
export const DETAILS = 'details';
export const PRICESTOCK = 'Pricing & Stock';
export const DELIVERY = 'delivery';
export const GALLERY = 'gallery';
export const BUSINESS_LINK = 'business link'

import React, { Component } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import css from './CompanyListingCard.css';
import { ensureListing } from 'util/data';
import { NamedLink, ResponsiveImage } from 'components/index';
import { lazyLoadWithDimensions } from 'util/contextHelpers';
import { createSlug } from 'util/urlHelpers';
import classNames from 'classnames';
import { context2Location } from 'util/location';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <img
      src={'/static/images/searchPage/company_Arrow.png'}
      className={css.ArrowRight}
      style={{ ...style, display: 'block', zIndex: '2' }}
      onClick={onClick}
      alt="NextArrow"
    />
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <img
      src={'/static/images/searchPage/company_Arrow.png'}
      className={css.ArrowLeft}
      style={{ ...style, display: 'block', zIndex: '2' }}
      onClick={onClick}
      alt="PrevArrow"
    />
  );
}
const sliderSettings = {
  appendDots: dots => (
    <div
      style={{
        backgroundColor: 'transparent',
        borderRadius: '10px',
        padding: '10px',
      }}
    >
      <ul style={{ margin: '0px' }}> {dots} </ul>
    </div>
  ),
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  swipe: false,
  dots: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 550,
      settings: {
        slidesToScroll: 1,
        swipe: true,
        arrows: false,
        dots: true,
      },
    },
  ],
};

export const CompanyListingCard = ({ listing, setActiveListing }) => {
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title, publicData } = currentListing.attributes;
  const author = currentListing.author;
  const slug = createSlug(title);
  const companyImages = currentListing.images;
  //remove background image
  if (companyImages.length > 1) {
    companyImages.shift();
  }

  const context = publicData?.Travel_business_address?.selectedPlace?.context;
  const currentAddress = context2Location(context) || 'undefined';

  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <NamedLink name="ListingPage" params={{ id, slug }} className={css.companyLink}>
      <div
        className={css.companyMain}
        onMouseEnter={setActiveListing && (() => setActiveListing(currentListing.id))}
        onMouseLeave={setActiveListing && (() => setActiveListing(null))}
      >
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <Slider {...sliderSettings}>
            {companyImages.map(image => (
              <div key={image.id.uuid} className={css.imageWrapper}>
                <div className={css.aspectWrapper}>
                  <LazyImage
                    rootClassName={css.rootForImage}
                    alt={listing.attributes.title}
                    className={css.popularImage}
                    image={image}
                    variants={['scaled-small', 'square-small2x']}
                    sizes={cardRenderSizes}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className={css.cardDetailsWrapper}>
          <a>
            <h4 className={css.companyInfo}>{title}</h4>
          </a>
          <div className={css.addressWrapper}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 12 13"
                fill="none"
              >
                <g clip-path="url(#clip0_173_866)">
                  <path
                    d="M10.5 5.21875C10.5 8.71875 6 11.7188 6 11.7188C6 11.7188 1.5 8.71875 1.5 5.21875C1.5 4.02528 1.97411 2.88068 2.81802 2.03677C3.66193 1.19286 4.80653 0.71875 6 0.71875C7.19347 0.71875 8.33807 1.19286 9.18198 2.03677C10.0259 2.88068 10.5 4.02528 10.5 5.21875Z"
                    stroke="#C13750"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 6.71875C6.82843 6.71875 7.5 6.04718 7.5 5.21875C7.5 4.39032 6.82843 3.71875 6 3.71875C5.17157 3.71875 4.5 4.39032 4.5 5.21875C4.5 6.04718 5.17157 6.71875 6 6.71875Z"
                    stroke="#C13750"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_173_866">
                    <rect width="12" height="12" fill="white" transform="translate(0 0.21875)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              <span>{currentAddress}</span>
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

CompanyListingCard.propTypes = {
  listing: propTypes.listing,
  setActiveListing: PropTypes.func,
};

export default CompanyListingCard;

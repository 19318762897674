import React, { Component } from 'react';
import moment from 'moment';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from 'util/sdkLoader';
import { sequence } from 'util/general';
import cns from 'classnames';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import {
  propTypes,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  DATE_TYPE_DATETIME,
  DATE_TYPE_DATE,
  LINE_ITEM_UNITS,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
  addonLabelIntoCode,
} from '../../util/data';
import { timeOfDayFromLocalToTimeZone, minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { formatMoney } from '../../util/currency';
import { WithoutPaymentForm, CheckoutTripForm, CheckoutDetailsForm } from '../../forms';
import {
  TRANSITION_ENQUIRE,
  txIsPaymentPending,
  txIsPaymentExpired,
  txHasPassedPaymentPending,
} from '../../util/transaction';
import {
  AvatarMedium,
  OrderBreakdown,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
  TripSummary,
  UserDisplayName,
} from '../../components';
import TaxJarInfo from '../../components/TaxJarInfo/TaxJarInfo';
import { TopbarContainer } from '../../containers';
import { StripePaymentForm } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { confirmCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import IconCreditCard from 'components/IconCreditCard/IconCreditCard';
import IconMembership from 'components/IconMembership/IconMembership';
import IconHitpay from 'components/IconHitpay/IconHitpay';
import IconBank from 'components/IconBank/IconBank';
import { manageDisableScrolling } from '../../ducks/UI.duck';
// import LiabilityWaiverModal from '../../components/LiabilityWaiverModal/LiabilityWaiverModal'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  addEventToCalendar,
  sendMessage,
  hitPay,
  initiateOrderAfterCustomerCharge,
} from './CheckoutPage.duck';
import { PAYMENT_TYPES } from 'util/constants';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import css from './CheckoutPage.css';
import { post } from '../../util/api';
import { LINE_ITEM_CARD_SERVICE_FEE } from 'util/types';
import ChargeCustomerForm from './ChargeCustomerForm/ChargeCustomerForm';
import WarningIcon from '../../assets/warning-error-svgrepo-com.svg';

const { UUID, Money } = sdkTypes;
const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const applyAsync = (acc, val) => acc.then(val);
const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

// Convert the picked date to moment that will represent the same time of day in UTC time zone.
const bookingDatesMaybe = bookingDates => {
  const apiTimeZone = 'Etc/UTC';
  return bookingDates
    ? {
        bookingDates: {
          bookingStart: timeOfDayFromLocalToTimeZone(bookingDates.bookingStart, apiTimeZone),
          bookingEnd: timeOfDayFromLocalToTimeZone(bookingDates.bookingEnd, apiTimeZone),
        },
      }
    : {};
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      selectedPaymentType: '',
      step: 1,
      isOpen: false,
      isModalAccepted: false,
      tripDetails: {},
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.customPricingParams = this.customPricingParams.bind(this);
    this.handelPaymentTypeSelecting = this.handelPaymentTypeSelecting.bind(this);
    this.handelStepChange = this.handelStepChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChargeCustomer = this.handleChargeCustomer.bind(this);
    this.handleSubmitChargeCustomer = this.handleSubmitChargeCustomer.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }

    if (localStorage.getItem('tripDetails')) {
      const tripData = JSON.parse(localStorage.getItem('tripDetails'));
      const estimatedMinBudget = tripData.estimatedMinBudget
        ? new Money(tripData.estimatedMinBudget.amount, tripData.estimatedMinBudget.currency)
        : '$0.00';
      const estimatedMaxBudget = tripData.estimatedMaxBudget
        ? new Money(tripData.estimatedMaxBudget.amount, tripData.estimatedMaxBudget.currency)
        : '$0.00';
      const currentStep = tripData.numberOfAdults ? 3 : 2;
      this.setState({
        tripDetails: { ...tripData, estimatedMinBudget, estimatedMaxBudget },
        step: currentStep,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.pageData) !== JSON.stringify(this.state.pageData)) {
      const { listing, transaction, fetchSpeculatedTransaction } = this.props;

      storeData(this.state.pageData?.orderData, listing, transaction, STORAGE_KEY);
      const tx = this.state.pageData ? this.state.pageData?.transaction : null;

      const shouldFetchSpeculatedTransaction =
        this.state.pageData &&
        this.state.pageData.listing &&
        this.state.pageData.listing.id &&
        this.state.pageData.orderData &&
        !txHasPassedPaymentPending(tx);

      if (shouldFetchSpeculatedTransaction) {
        const listingId = this.state.pageData.listing.id;
        const transactionId = tx ? tx.id : null;

        // Fetch speculated transaction for showing price in order breakdown
        // NOTE: if unit type is line-item/units, quantity needs to be added.
        // The way to pass it to checkout page is through pageData.orderData
        const quantity = this.state.pageData.orderData?.quantity;
        const quantityMaybe = quantity ? { quantity } : {};
        const packageData = this.state.pageData.orderData.packageData;

        fetchSpeculatedTransaction(
          {
            listingId,
            packageData,
            ...quantityMaybe,
            ...bookingDatesMaybe(this.state.pageData.orderData.bookingDates),
          },
          transactionId
        );
      }
    }
  }

  componentWillUnmount() {
    if (localStorage.getItem('tripDetails')) {
      localStorage.removeItem('tripDetails');
    }
  }

  handelStepChange(step) {
    this.setState({ step });
  }

  handelPaymentTypeSelecting(paymentType) {
    this.setState({ selectedPaymentType: paymentType });
    const { pageData } = this.state;
    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;
    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      pageData.bookingData.quantity &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { quantity, priceAddons, seats } = pageData.bookingData;
      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      this.props.fetchSpeculatedTransaction(
        this.customPricingParams({
          listing: pageData.listing,
          bookingStart,
          bookingEnd,
          quantity,
          priceAddons,
          seats,
          paymentType,
        })
      );
    }
    this.handelStepChange(1);
    // if (this.state.isModalAccepted === false) {
    //   this.setState({ isOpen: true })
    // }
    // else {
    //   this.handelStepChange(2)
    // }
  }

  onModalAccept = () => {
    this.setState({
      isModalAccepted: true,
      isOpen: false,
      step: 2,
    });
  };

  toggleModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  /**
   * Constructs a request params object that can be used when creating bookings
   * using custom pricing.
   * @param {} params An object that contains bookingStart, bookingEnd and listing
   * @return a params object for custom pricing bookings
   */

  customPricingParams(params) {
    const { bookingStart, bookingEnd, listing, quantity, priceAddons, seats, ...rest } = params;
    const { amount, currency } = listing ? listing.attributes.price : {};

    const unitType = config.bookingUnitType;
    const priceAddonsLineItems =
      priceAddons && priceAddons.length
        ? priceAddons.map(addon => {
            if (addon.code) {
              return addon;
            }
            return {
              code: addonLabelIntoCode(addon.label),
              unitPrice: new Money(addon.amount, addon.currency),
              quantity: quantity,
            };
          })
        : [];

    const addonsTotalPrice = priceAddonsLineItems.reduce((acc, curr) => {
      return acc + curr.unitPrice.amount * curr.quantity;
    }, 0);
    const totalPrice = amount * quantity * seats + addonsTotalPrice;
    const cardFeeAmount = totalPrice * config.creditCardExtraFee; // 5%
    const listingId = listing && listing.id ? new UUID(listing.id.uuid) : '';
    if (
      (rest && rest.paymentType === PAYMENT_TYPES.card) ||
      this.state.selectedPaymentType === PAYMENT_TYPES.card
    ) {
      return {
        listingId: listingId,
        bookingStart,
        bookingEnd,
        lineItems: [
          ...priceAddonsLineItems,
          {
            code: LINE_ITEM_CARD_SERVICE_FEE,
            unitPrice: new Money(cardFeeAmount, currency),
            quantity: 1,
          },
          {
            code: unitType,
            unitPrice: new Money(amount, currency),
            units: quantity,
            seats: Number(seats),
          },
        ],
        ...rest,
      };
    } else {
      return {
        listingId: listingId,
        bookingStart,
        bookingEnd,
        lineItems: [
          ...priceAddonsLineItems,
          {
            code: unitType,
            unitPrice: new Money(amount, currency),
            units: quantity,
            seats: Number(seats),
          },
        ],
        ...rest,
      };
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      orderData,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
    } = this.props;

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    // const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    const hasDataInProps = !!(orderData && listing && hasNavigatedThroughLink);
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(orderData, listing, transaction, STORAGE_KEY);
    }
    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps ? { orderData, listing, transaction } : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.orderData &&
      !txHasPassedPaymentPending(tx);

    if (shouldFetchSpeculatedTransaction) {
      const listingId = pageData.listing.id;
      const transactionId = tx ? tx.id : null;

      // Fetch speculated transaction for showing price in order breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.orderData
      const quantity = pageData.orderData?.quantity;
      const quantityMaybe = quantity ? { quantity } : {};
      const packageData = pageData.orderData?.packageData;

      fetchSpeculatedTransaction(
        {
          listingId,
          packageData,
          ...quantityMaybe,
          ...bookingDatesMaybe(pageData.orderData.bookingDates),
        },
        transactionId
      );
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onConfirmCardPayment,
      onConfirmPayment,
      onAddEventToCalendar,
      onSendMessage,
      onSavePaymentMethod,
    } = this.props;

    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
      kidsName,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { orderData, listing } = pageData;
        storeData(orderData, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      // const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};
      const stripeElementMaybe = card ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : onConfirmCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      return onConfirmPayment(fnParams);
    };

    const fnAddEventToCalendar = fnParams => {
      return onAddEventToCalendar(fnParams);
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      // fnAddEventToCalendar,
      fnSendMessage,
      fnSavePaymentMethod
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    const packageData = pageData.orderData?.packageData;
    const quantity = pageData.orderData?.quantity;

    const quantityMaybe = quantity ? { quantity } : {};

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const tripData = this.state.tripDetails;
    const estimatedMinBudget = {
      amount: tripData.estimatedMinBudget.amount,
      currency: tripData.estimatedMinBudget.currency,
    };
    const estimatedMaxBudget = {
      amount: tripData.estimatedMaxBudget.amount,
      currency: tripData.estimatedMaxBudget.currency,
    };
    const updateTripData = { ...tripData, estimatedMinBudget, estimatedMaxBudget };

    const orderParams = {
      listingId: pageData.listing.id,
      orderTripDetails: updateTripData || {},
      packageData,
      ...bookingDatesMaybe(pageData.orderData.bookingDates),
      ...quantityMaybe,
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleOrder(handlePaymentParams) {
    const {
      currentUser,
      onInitiateOrder,
      onHitPay,
      onConfirmPayment,
      onAddEventToCalendar,
      onSendMessage,
      onUpdateCredits,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentProofSsUrls,
      credits,
      membership,
      cash,
      hitpay,
      kidsName,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1
    const fnRequestOrder = fnParams => {
      return onInitiateOrder(
        {
          ...fnParams,
          withoutPayment: true,
          credits,
          paymentProofSsUrls,
          membership,
          cash,
          kidsName,
          hitpay,
        },
        storedTx.id
      );
    };

    // Step 2
    const fnHandleOrder = fnParams => {
      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      return Promise.resolve({ transactionId: order.id });
    };

    // Step 3
    const fnConfirmOrder = fnParams => {
      const { transactionId } = fnParams;
      const { bookingDates } = pageData;
      if (credits === 'true') {
        // const provider = pageData && pageData.listing;
        return onUpdateCredits({
          listingId: pageData.listing.id.uuid,
          currentUser: currentUser,
          transactionId: transactionId,
          bookingDates: bookingDates,
        })
          .then(response => {
            return onConfirmPayment(fnParams);
          })
          .catch(e => {
            this.setState({
              errorUpdateCredits: true,
            });
          });
      } else if (hitpay) {
        // In case of hitpay confirmation of order is from backend
        return onHitPay({
          listingId: pageData.listing.id.uuid,
          currentUser: currentUser,
          transactionId: transactionId,
          bookingDates: bookingDates,
        })
          .then(response => {
            if (response && response.data && typeof window !== 'undefined') {
              window.location.href = response.data;
            } else {
              throw new Error('HitPay payment request error');
            }
          })
          .catch(e => {
            throw e;
          });
      } else {
        return onConfirmPayment(fnParams);
      }
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    const handleOrderCreation = composeAsync(
      fnRequestOrder,
      fnHandleOrder,
      fnConfirmOrder,
      fnSendMessage
    );

    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    const priceAddons = tx.attributes.lineItems.filter(item => {
      const filterOutCodes = [
        LINE_ITEM_UNITS,
        LINE_ITEM_CUSTOMER_COMMISSION,
        LINE_ITEM_PROVIDER_COMMISSION,
      ];
      return !filterOutCodes.includes(item.code);
    });

    const orderParams = this.customPricingParams({
      listing: pageData.listing,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      quantity: pageData.bookingData ? pageData.bookingData.quantity : null,
      seats: pageData.bookingData ? pageData.bookingData.seats : null,
      priceAddons,
    });

    return handleOrderCreation(orderParams);
  }

  handleSubmitOrder(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, dispatch, params } = this.props;
    const { message, formValues, paymentProofSsUrls } = values;
    const { membership = null, kidsName = null } = formValues;

    const credits = params.credits;
    if (credits === 'true') {
      const { period = 1 } = this.state.pageData.bookingData;
      // set initial data for period equals to 1 week
      const variableDataArray = [
        {
          message,
          paymentProofSsUrls,
          credits: credits,
          pageData: this.state.pageData,
          speculatedTransaction: speculatedTransaction,
          membership,
          kidsName,
        },
      ];
      // if period is bigger, add entries of transaction and pageData with
      // adjusted dates for every extra week
      for (let i = 0; i < period - 1; i++) {
        const booking = { ...speculatedTransaction.booking };
        let bookingDates = { ...this.state.pageData.bookingDates };
        booking.attributes = {
          ...booking.attributes,
          displayEnd: moment(booking.attributes.displayEnd)
            .add(i + 1, 'week')
            .toDate(),
          displayStart: moment(booking.attributes.displayStart)
            .add(i + 1, 'week')
            .toDate(),
          start: moment(booking.attributes.start)
            .add(i + 1, 'week')
            .toDate(),
          end: moment(booking.attributes.end)
            .add(i + 1, 'week')
            .toDate(),
        };

        bookingDates = {
          bookingEnd: moment(bookingDates.bookingEnd)
            .add(i + 1, 'week')
            .toDate(),
          bookingStart: moment(bookingDates.bookingStart)
            .add(i + 1, 'week')
            .toDate(),
        };

        variableDataArray.push({
          message,
          paymentProofSsUrls,
          credits: credits,
          pageData: { ...this.state.pageData, bookingDates },
          speculatedTransaction: {
            ...speculatedTransaction,
            booking: booking,
          },
          membership,
          kidsName,
        });
      }

      sequence(variableDataArray, this.handleOrder)
        .then(res => {
          const { orderId, messageSuccess } = res;
          this.setState({ submitting: false });

          const routes = routeConfiguration();
          const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
          const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
            id: orderId.uuid,
          });
          const initialValues = {
            initialMessageFailedToTransaction,
          };

          initializeOrderPage(initialValues, routes, dispatch);
          clearData(STORAGE_KEY);
          history.push(orderDetailsPath);
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
        });
    } else {
      localStorage.setItem('membershipNumber', membership);
      const requestPaymentParams = {
        pageData: this.state.pageData,
        speculatedTransaction,
        message,
        paymentProofSsUrls,
        credits: this.props.params.credits,
        cash: this.props.params.paymentType === PAYMENT_TYPES.cash,
        hitpay: this.state.selectedPaymentType === PAYMENT_TYPES.hitpay,
        membership,
        kidsName,
      };

      this.handleOrder(requestPaymentParams)
        .then(res => {
          const { orderId, messageSuccess } = res;
          this.setState({ submitting: false });

          const routes = routeConfiguration();
          const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
          const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
            id: orderId.uuid,
          });
          const initialValues = {
            initialMessageFailedToTransaction,
          };

          initializeOrderPage(initialValues, routes, dispatch);
          clearData(STORAGE_KEY);
          history.push(orderDetailsPath);
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
        });
    }
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, currentUser, paymentIntent, dispatch } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
      kidsName,
    } = formValues;
    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      kidsName,
    };

    this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { orderId, messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);
        clearData(STORAGE_KEY);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  handleChargeCustomer(handlePaymentParams) {
    const { currentUser, stripeCustomerFetched, onInitiateOrderAfterCustomerCharge } = this.props;

    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
      kidsName,
      chargeId,
      speculatedTxId,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestChargeCustomer = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrderAfterCustomerCharge(fnParams, storedTx.id);
    };

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    const packageData = pageData.orderData?.packageData;
    const quantity = pageData.orderData?.quantity;

    const quantityMaybe = quantity ? { quantity } : {};

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const tripData = this.state.tripDetails;
    const estimatedMinBudget = {
      amount: tripData.estimatedMinBudget.amount,
      currency: tripData.estimatedMinBudget.currency,
    };
    const estimatedMaxBudget = {
      amount: tripData.estimatedMaxBudget.amount,
      currency: tripData.estimatedMaxBudget.currency,
    };
    const updateTripData = { ...tripData, estimatedMinBudget, estimatedMaxBudget };

    const orderParams = {
      listingId: pageData.listing.id,
      orderTripDetails: updateTripData || {},
      packageData,
      ...bookingDatesMaybe(pageData.orderData.bookingDates),
      ...quantityMaybe,
      ...optionalPaymentParams,
      chargeId,
    };

    return fnRequestChargeCustomer(orderParams)
      .then(resp => {
        const updatedTxId = resp?.id?.uuid;
        if (speculatedTxId && updatedTxId) {
          //we update the commission waiting item with the newly created transaction id
          const bodyParams = {
            oldTransactionId: speculatedTxId,
            newTransactionId: updatedTxId,
            tableName: 'commissions_waiting_list',
          };
          return post('/api/update-waiting-commission-item', bodyParams)
            .then(resp2 => {
              return resp;
            })
            .catch(e => {
              return resp;
            });
        } else {
          return resp;
        }
      })
      .catch(e => {
        throw e;
      });
  }

  handleSubmitChargeCustomer(data) {
    if (this.state.submitting) {
      return;
    }

    const { chargeId, speculatedTxId } = data;
    this.setState({ submitting: true });

    const { history, speculatedTransaction, currentUser, dispatch } = this.props;

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      chargeId,
      speculatedTxId,
    };

    this.handleChargeCustomer(requestPaymentParams)
      .then(res => {
        // const { orderId, messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });
        const orderId = res.id;
        const routes = routeConfiguration();
        // const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction: null,
          savePaymentMethodFailed: false,
        };

        initializeOrderPage(initialValues, routes, dispatch);
        clearData(STORAGE_KEY);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  onUpdatePackage(values) {
    this.setState(prevState => ({
      ...prevState,
      pageData: {
        ...prevState.pageData,
        orderData: {
          ...prevState.pageData.orderData,
          packageData: {
            ...prevState.pageData.orderData.packageData,
            collaborationTime: values.collaborationTime,
            deliveryTime: values.deliveryTime,
            packageLable: values.packageLable,
            items: values.items,
            participants: values.participants,
            price: values.price,
            itemsUnitPrice: values.itemsUnitPrice,
            participantsUnitPrice: values.participantsUnitPrice,
          },
        },
      },
    }));
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      confirmCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      recentTransactions,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, orderData, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <TopbarContainer
        className={css.topbar}
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        currentPage={`paymentpage`}
      />
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasRequiredData = !!(currentListing.id && currentAuthor.id);
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)

    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when (speculated?) transaction is loaded
    // (i.e. have an id and lineItems)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBookingMaybe = tx.booking?.id
      ? { booking: ensureBooking(tx.booking), dateType: DATE_TYPE_DATE }
      : {};
    const breakdown =
      tx.id && tx.attributes.lineItems?.length > 0 ? (
        <OrderBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={tx}
          listing={currentListing}
          {...txBookingMaybe}
          taxJar={<TaxJarInfo lineItems={tx.attributes.lineItems} listing={listing} />}
        />
      ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired
    );

    const featureImage =
      currentListing.images && currentListing.images.length > 1 ? currentListing.images[1] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing?.id?.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const packagePrice = storedData(STORAGE_KEY).orderData?.packageData?.price;
    const price = packagePrice || currentListing.attributes.price;
    const { bankDetails } = currentListing.attributes.publicData;

    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = `${formattedPrice}`;

    const showInitialMessageInput = !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

    // const selectedPaymentMethod = currentListing.attributes.publicData.payment_method &&
    //   currentListing.attributes.publicData.payment_method.length !== 0 ?
    //   currentListing.attributes.publicData.payment_method :
    //   ['cash', 'card', 'member', 'bank', 'hitpay']

    const selectedPaymentMethod = ['card'];

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };
    const credits = params.credits;
    const { step } = this.state;
    const DetailsFormInitialValues = {
      includeTransport: ['yes'],
      includeAccommodation: ['yes'],
    };
    //
    return (
      <Page {...pageProps} className={css.pageContainer}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
        />
        <div className={css.steps}>
          <div className={css.stepContainer}>
            <div className={cns(css.mdstepperhorizontal, css.activeColor)}>
              <div
                className={cns(
                  css.mdstep,
                  (step === 1 || step > 1) && css.active,
                  step > 1 && css.done
                )}
                onClick={() => {
                  this.setState({ step: 1 });
                }}
              >
                <div className={css.mdstepcircle}>{step > 1 ? '✓' : 1}</div>
                <div className={css.check}></div>
                <div className={css.mdsteptitle}>Details</div>
                <div className={css.mdstepbarleft}></div>
                <div className={css.mdstepbarright}></div>
              </div>
              <div
                className={cns(
                  css.mdstep,
                  (step === 2 || step > 2) && css.active,
                  step > 2 && css.done
                )}
                onClick={() => {
                  if (step > 1) {
                    this.setState({ step: 2 });
                  }
                }}
              >
                <div className={css.mdstepcircle}>{step > 2 ? '✓' : 2}</div>
                <div className={css.check}></div>
                <div className={css.mdsteptitle}>Trip</div>
                <div className={css.mdstepbarleft}></div>
                <div className={css.mdstepbarright}></div>
              </div>
              <div
                className={cns(css.mdstep, step === 3 && css.active)}
                onClick={() => {
                  if (step > 2) {
                    this.setState({ step: 3 });
                  }
                }}
              >
                <div className={css.mdstepcircle}>3</div>
                <div className={css.mdsteptitle}>Payment</div>
                <div className={css.mdstepbarleft}></div>
                <div className={css.mdstepbarright}></div>
              </div>
            </div>
          </div>
        </div>
        {step > 1 && (
          <div className={css.backButtonWrapper}>
            <ArrowBackIcon
              className={css.backButton}
              onClick={() => {
                this.setState({ step: step - 1 });
                window.scrollTo(0, 0);
              }}
            />
          </div>
        )}
        <div className={css.contentContainer}>
          <div className={step === 2 ? css.modifiedBookListingContainer : css.bookListingContainer}>
            {this.state.step === 1 && (
              <div className={css.paymentContainer}>
                <CheckoutDetailsForm
                  intl={intl}
                  listing={listing}
                  packageData={orderData?.packageData}
                  onBack={() => this.props.history.goBack()}
                  initialValues={{ ...DetailsFormInitialValues, ...this.state.tripDetails }}
                  submitButtonWrapperClassName={css.detailedFormButtonWrapper}
                  onChange={fieldRenderProps => {
                    localStorage.setItem('tripDetails', JSON.stringify(fieldRenderProps.values));
                    this.setState({ tripDetails: fieldRenderProps.values });
                  }}
                  lastTransaction={recentTransactions[0]}
                  onPrefillSubmit={() => {
                    const lastTripDetails =
                      recentTransactions[0].attributes.protectedData.tripDetails;

                    localStorage.setItem('tripDetails', JSON.stringify(lastTripDetails));
                    this.setState(prevState => ({ ...prevState, tripDetails: lastTripDetails }));
                  }}
                  onUpdatePackage={values => {
                    this.onUpdatePackage(values);
                  }}
                  onSubmit={value => {
                    const tripData = { ...this.state.tripDetails };
                    this.handelStepChange(2);
                    localStorage.setItem('tripDetails', JSON.stringify({ ...tripData, ...value }));
                    this.setState({ tripDetails: { ...tripData, ...value } });
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
            )}

            {this.state.step === 2 && (
              <div>
                <div>
                  <CheckoutTripForm
                    intl={intl}
                    listing={listing}
                    onBack={() => {
                      this.handelStepChange(1);
                      window.scrollTo(0, 0);
                    }}
                    initialValues={this.state.tripDetails}
                    submitButtonWrapperClassName={css.detailedFormButtonWrapper}
                    onChange={fieldRenderProps => {
                      localStorage.setItem('tripDetails', JSON.stringify(fieldRenderProps.values));
                      this.setState({ tripDetails: fieldRenderProps.values });
                    }}
                    onSubmit={value => {
                      this.setState({ selectedPaymentType: PAYMENT_TYPES.card });
                      this.handelStepChange(3);
                      localStorage.setItem('tripDetails', JSON.stringify(value));
                      this.setState({ tripDetails: value });
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
              </div>
            )}

            {this.state.step === 3 && (
              <section className={css.paymentContainer}>
                {initiateOrderErrorMessage}
                {listingNotFoundErrorMessage}
                {speculateErrorMessage}
                {/* testing only start */}
                <div className={css.testNotification}>
                  <div>
                    <img src={WarningIcon} alt="For testing only" height={150} />
                  </div>
                  <p className={css.notificationTitle}>
                    <FormattedMessage
                      id="ChargeCustomerForm.testNotificationTitle"
                      values={{
                        b: (...chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </p>
                  <FormattedMessage
                    id="ChargeCustomerForm.testNotificationInfo"
                    values={{
                      br: <br />,
                      em: (...chunks) => <em>{chunks}</em>,
                      a: (...chunks) => <a href="https://lyfshort.com/support/">{chunks}</a>,
                      b: (...chunks) => <b>{chunks}</b>,
                    }}
                  />
                </div>
                {/* testing only end */}
                {retrievePaymentIntentError ? (
                  <p className={css.orderError}>
                    <FormattedMessage
                      id="CheckoutPage.retrievingStripePaymentIntentFailed"
                      values={{ listingLink }}
                    />
                  </p>
                ) : null}
                {showPaymentForm ? (
                  <ChargeCustomerForm
                    onSubmit={this.handleSubmitChargeCustomer}
                    listing={currentListing}
                    pageData={this.state.pageData}
                    speculatedTransaction={tx}
                  />
                ) : // <StripePaymentForm
                //   className={css.paymentForm}
                //   onSubmit={this.handleSubmit}
                //   inProgress={this.state.submitting}
                //   formId="CheckoutPagePaymentForm"
                //   paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                //   authorDisplayName={currentAuthor.attributes.profile.displayName}
                //   showInitialMessageInput={false}
                //   initialValues={initalValuesForStripePayment}
                //   initiateOrderError={initiateOrderError}
                //   handleCardPaymentError={confirmCardPaymentError}
                //   confirmPaymentError={confirmPaymentError}
                //   hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                //   loadingData={!stripeCustomerFetched}
                //   defaultPaymentMethod={
                //     hasDefaultPaymentMethod
                //       ? currentUser.stripeCustomer.defaultPaymentMethod
                //       : null
                //   }
                //   paymentIntent={paymentIntent}
                //   onStripeInitialized={this.onStripeInitialized}
                //   onBack={() => this.handelStepChange(2)}
                //   submitButtonWrapperClassName={css.detailedFormButtonWrapper}
                //   listing={listing}
                // />
                null}
                {isPaymentExpired ? (
                  <p className={css.orderError}>
                    <FormattedMessage
                      id="CheckoutPage.paymentExpiredMessage"
                      values={{ listingLink }}
                    />
                  </p>
                ) : null}
              </section>
            )}

            {this.state.step === 2 &&
              (this.state.selectedPaymentType === PAYMENT_TYPES.direct ||
                this.state.selectedPaymentType === PAYMENT_TYPES.membership ||
                this.state.selectedPaymentType === PAYMENT_TYPES.hitpay) && (
                <section className={css.paymentContainer}>
                  {initiateOrderErrorMessage}
                  {listingNotFoundErrorMessage}
                  {speculateErrorMessage}
                  <div>
                    <FormattedMessage
                      id={'CheckoutPage.selectedPeriod'}
                      values={{ period: this.state.pageData.bookingData.period }}
                    />
                  </div>
                  {showPaymentForm ? (
                    <WithoutPaymentForm
                      className={css.paymentForm}
                      onSubmit={this.handleSubmitOrder}
                      inProgress={this.state.submitting}
                      formId="CheckoutPagePaymentForm"
                      credits={credits}
                      bankDetails={bankDetails}
                      paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                      authorDisplayName={currentAuthor.attributes.profile.displayName}
                      showInitialMessageInput={showInitialMessageInput}
                      initialValues={initalValuesForStripePayment}
                      initiateOrderError={initiateOrderError}
                      paymentType={this.state.selectedPaymentType}
                      listing={listing}
                    />
                  ) : null}
                </section>
              )}
          </div>

          <div className={css.detailsContainerDesktop}>
            <div className={css.detailsAspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={featureImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </div>
            <div className={css.ContainerLine}></div>
            <div className={css.Summary}>
              <div className={css.SummaryHead}>
                <div className={css.avatarWrapper}>
                  <AvatarMedium user={currentAuthor} disableProfileLink />
                </div>

                <div className={css.detailsHeadings}>
                  <h2 className={css.detailsTitle}>
                    <UserDisplayName user={currentAuthor} intl={intl} />
                  </h2>
                  <p className={css.detailsSubtitle}>{detailsSubTitle}</p>
                </div>
              </div>

              <div>
                <TripSummary
                  intl={intl}
                  packageData={(orderData && orderData?.packageData) || {}}
                  listing={listing}
                  {...this.state.tripDetails}
                />
              </div>

              {speculateTransactionErrorMessage}
              {breakdown}
            </div>
          </div>
        </div>
        {/* <LiabilityWaiverModal
          id="LiabilityWaiverModal"
          isOpen={this.state.isOpen}
          onSubmit={this.onModalAccept}
          onCloseModal={this.toggleModal}
          onManageDisableScrolling={onManageDisableScrolling}
          rootClassName={''}
          listingTitle={listingTitle}
        /> */}
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onConfirmCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  onHitPay: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // confirmCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  confirmCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    orderData,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
  } = state.CheckoutPage;
  const { currentUser, recentTransactions } = state.user;
  const { confirmCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    orderData,
    initiateOrderError,
    confirmCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    recentTransactions,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: (params, transactionId) =>
    dispatch(speculateTransaction(params, transactionId)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onInitiateOrderAfterCustomerCharge: (params, transactionId) =>
    dispatch(initiateOrderAfterCustomerCharge(params, transactionId)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onConfirmCardPayment: params => dispatch(confirmCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onAddEventToCalendar: params => dispatch(addEventToCalendar(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onHitPay: params => dispatch(hitPay(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  if (saveToSessionStorage) {
    const { listing, orderData } = initialValues;
    storeData(orderData, listing, null, STORAGE_KEY);
  }

  return setInitialValues(initialValues);
};

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;

import React from 'react';
import cns from 'classnames';
import {
    SecondaryButton,
} from 'components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './TopBlogsPage.css';

const SectionPodcasts = props => {

    return (
        <div className={css.podcastsSection}>
            <div className={css.sectionWrapper}>
                <div className={css.podcastsSectionTop}>
                    <h3 className={css.podcastsSectionTitle}><FormattedMessage id={'SectionPodcasts.podcasts'} /></h3>
                    <SecondaryButton className={cns(css.allPodcastsSectionBtn, css.desktopButton)}>
                        <FormattedMessage id={'SectionPodcasts.allEpisodes'} />
                    </SecondaryButton>
                </div>

                <div className={css.podcastDetailContainer} >
                    <div className={css.podcastCards}>
                        <div className={css.podcastCard}>
                            <div className={css.podcastsImageContainer}>
                                <div className={css.podcastImageDetail}>
                                    <span className={css.imageHashNumber}><FormattedMessage id={'SectionPodcasts.hashNumber'} /></span>
                                    <span className={css.imageAuthorName}><FormattedMessage id={'SectionPodcasts.authorName'} /></span>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                    <circle cx="24" cy="24" r="24" fill="white" />
                                    <path d="M24.2133 29.0869C26.2012 29.0844 28.107 28.2936 29.5127 26.8879C30.9184 25.4822 31.7092 23.5764 31.7117 21.5885V17.4984C31.7117 15.5097 30.9217 13.6025 29.5154 12.1962C28.1092 10.79 26.202 10 24.2133 10C22.2246 10 20.3173 10.79 18.9111 12.1962C17.5049 13.6025 16.7148 15.5097 16.7148 17.4984V21.5885C16.7173 23.5764 17.5081 25.4822 18.9138 26.8879C20.3195 28.2936 22.2253 29.0844 24.2133 29.0869ZM19.4415 17.4984C19.4415 16.2329 19.9443 15.0192 20.8391 14.1243C21.734 13.2294 22.9477 12.7267 24.2133 12.7267C25.4788 12.7267 26.6925 13.2294 27.5874 14.1243C28.4822 15.0192 28.985 16.2329 28.985 17.4984V21.5885C28.985 22.854 28.4822 24.0677 27.5874 24.9626C26.6925 25.8575 25.4788 26.3602 24.2133 26.3602C22.9477 26.3602 21.734 25.8575 20.8391 24.9626C19.9443 24.0677 19.4415 22.854 19.4415 21.5885V17.4984Z" fill="#0095B3" />
                                    <path d="M32.9366 27.7414C32.6344 27.5433 32.2661 27.4729 31.9121 27.5455C31.5581 27.6181 31.2472 27.8278 31.0474 28.1289C30.3112 29.2475 29.3117 30.1684 28.1366 30.8106C26.9615 31.4528 25.6468 31.7968 24.3077 31.8123C22.9687 31.8278 21.6463 31.5143 20.4566 30.8995C19.267 30.2847 18.2464 29.3872 17.4845 28.2859C17.2787 27.9886 16.9631 27.7853 16.6073 27.7206C16.2515 27.656 15.8846 27.7353 15.5873 27.9412C15.29 28.147 15.0866 28.4626 15.022 28.8184C14.9573 29.1742 15.0366 29.5411 15.2425 29.8384C16.1175 31.1007 17.2488 32.1642 18.5626 32.9597C19.8764 33.7552 21.3432 34.2647 22.8672 34.455C22.8651 34.485 22.85 34.5095 22.85 34.5395V35.9028C22.85 36.2644 22.9936 36.6112 23.2493 36.8669C23.505 37.1226 23.8518 37.2662 24.2134 37.2662C24.5749 37.2662 24.9217 37.1226 25.1774 36.8669C25.4331 36.6112 25.5767 36.2644 25.5767 35.9028V34.5395C25.5767 34.5098 25.5617 34.485 25.5598 34.4556C27.1264 34.2625 28.6323 33.7309 29.973 32.8978C31.3137 32.0646 32.4572 30.9498 33.3241 29.6306C33.523 29.3287 33.594 28.9601 33.5213 28.6058C33.4487 28.2516 33.2384 27.9407 32.9366 27.7414Z" fill="#0095B3" />
                                </svg>
                            </div>
                            <div className={css.podcastTextSection}>
                                <span className={css.podcastTitle}><FormattedMessage id={'SectionPodcasts.podcastTitle'} /></span>
                                <span className={css.podcastInfo}><FormattedMessage id={'SectionPodcasts.podcastInfo'} /></span>
                                <span className={css.podcastTime}><FormattedMessage id={'SectionPodcasts.podcastTimeInfo'} /></span>
                            </div>
                        </div>
                        <div className={css.podcastCard}>
                            <div className={css.podcastsImageContainer}>
                                <div className={css.podcastImageDetail}>
                                    <span className={css.imageHashNumber}><FormattedMessage id={'SectionPodcasts.hashNumber'} /></span>
                                    <span className={css.imageAuthorName}><FormattedMessage id={'SectionPodcasts.authorName'} /></span>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                    <circle cx="24" cy="24" r="24" fill="white" />
                                    <path d="M24.2133 29.0869C26.2012 29.0844 28.107 28.2936 29.5127 26.8879C30.9184 25.4822 31.7092 23.5764 31.7117 21.5885V17.4984C31.7117 15.5097 30.9217 13.6025 29.5154 12.1962C28.1092 10.79 26.202 10 24.2133 10C22.2246 10 20.3173 10.79 18.9111 12.1962C17.5049 13.6025 16.7148 15.5097 16.7148 17.4984V21.5885C16.7173 23.5764 17.5081 25.4822 18.9138 26.8879C20.3195 28.2936 22.2253 29.0844 24.2133 29.0869ZM19.4415 17.4984C19.4415 16.2329 19.9443 15.0192 20.8391 14.1243C21.734 13.2294 22.9477 12.7267 24.2133 12.7267C25.4788 12.7267 26.6925 13.2294 27.5874 14.1243C28.4822 15.0192 28.985 16.2329 28.985 17.4984V21.5885C28.985 22.854 28.4822 24.0677 27.5874 24.9626C26.6925 25.8575 25.4788 26.3602 24.2133 26.3602C22.9477 26.3602 21.734 25.8575 20.8391 24.9626C19.9443 24.0677 19.4415 22.854 19.4415 21.5885V17.4984Z" fill="#0095B3" />
                                    <path d="M32.9366 27.7414C32.6344 27.5433 32.2661 27.4729 31.9121 27.5455C31.5581 27.6181 31.2472 27.8278 31.0474 28.1289C30.3112 29.2475 29.3117 30.1684 28.1366 30.8106C26.9615 31.4528 25.6468 31.7968 24.3077 31.8123C22.9687 31.8278 21.6463 31.5143 20.4566 30.8995C19.267 30.2847 18.2464 29.3872 17.4845 28.2859C17.2787 27.9886 16.9631 27.7853 16.6073 27.7206C16.2515 27.656 15.8846 27.7353 15.5873 27.9412C15.29 28.147 15.0866 28.4626 15.022 28.8184C14.9573 29.1742 15.0366 29.5411 15.2425 29.8384C16.1175 31.1007 17.2488 32.1642 18.5626 32.9597C19.8764 33.7552 21.3432 34.2647 22.8672 34.455C22.8651 34.485 22.85 34.5095 22.85 34.5395V35.9028C22.85 36.2644 22.9936 36.6112 23.2493 36.8669C23.505 37.1226 23.8518 37.2662 24.2134 37.2662C24.5749 37.2662 24.9217 37.1226 25.1774 36.8669C25.4331 36.6112 25.5767 36.2644 25.5767 35.9028V34.5395C25.5767 34.5098 25.5617 34.485 25.5598 34.4556C27.1264 34.2625 28.6323 33.7309 29.973 32.8978C31.3137 32.0646 32.4572 30.9498 33.3241 29.6306C33.523 29.3287 33.594 28.9601 33.5213 28.6058C33.4487 28.2516 33.2384 27.9407 32.9366 27.7414Z" fill="#0095B3" />
                                </svg>
                            </div>
                            <div className={css.podcastTextSection}>
                                <span className={css.podcastTitle}><FormattedMessage id={'SectionPodcasts.podcastTitle'} /></span>
                                <span className={css.podcastInfo}><FormattedMessage id={'SectionPodcasts.podcastInfo'} /></span>
                                <span className={css.podcastTime}><FormattedMessage id={'SectionPodcasts.podcastTimeInfo'} /></span>
                            </div>
                        </div>
                    </div>
                    <div className={css.rightImageContainer}></div>
                </div>
            </div>
        </div>
    );
}

export default SectionPodcasts;
import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditBusinessViewDraftForm } from '../../forms';
import config from '../../config';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from '../EditListingDescriptionPanel/EditListingDescriptionPanel.css';
import { LISTING_TYPES } from 'util/constants';

const getCustomFieldValues = (category, values) => {
  const customFields = config.custom.categoryBasedFields
    .find(cc => cc.key === category)
    .fields.map(i => i.key);
  const customFieldsValues = {};
  customFields.forEach(item => {
    customFieldsValues[item] = values[item];
  });

  return customFieldsValues;
};

const titles = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionPanel.createListingTitle',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionPanel.createEnquiryTitle',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionPanel.createCompanyTitle',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionPanel.createClassTitle',
  [LISTING_TYPES.PRODUCT]: 'EditListingDescriptionPanel.createProductTitle',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionPanel.createFacilityTitle',
};

const EditBusinessViewDraftPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submittedButtonText,
    publishButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingType,
    backgroundImage,
    profileImage,
    actionImage,
  } = props;

  const classes = classNames(rootClassName || css.draftRoot, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData, privateData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditBusinessFinancialPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditBusinessFinancialPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditBusinessAdminPanel.createAdminTitle" />
  );
  return (
    <div className={classes}>
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditBusinessViewDraftForm
              className={css.form}
              currentListing={listing}
              submittedButtonText={submittedButtonText}
              publishActionMsg={publishButtonText}
              onSubmit={values => {
                console.log(values)
                onSubmit(values);
              }}
              backgroundImage={backgroundImage}
              profileImage={profileImage}
              actionImage={actionImage}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              category={publicData.category}
              subCategory={publicData.subCategory}
              activityLevel={config.custom.activityLevel}
              currentUser={currentUser}
              listingType={listingType}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditBusinessViewDraftPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditBusinessViewDraftPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  listingType: string,
};

export default EditBusinessViewDraftPanel;

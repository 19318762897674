import { AVAILABILITY, LISTING_TYPES, VIEWDRAFTLISTING } from 'util/constants';
import {
  ADMIN,
  CERTIFICATES,
  AGREEMENT,
  PAYMENTS,
  VIEWDRAFTBUSINESS,
  OVERVIEW,
  PROFILE,
  DESCRIPTION,
  EXPECTATIONS,
  LOCATION,
  PHOTOS,
  PRICING,
  DETAILS,
  PRICESTOCK,
  DELIVERY,
} from 'util/constants';
import config from 'config';

// Show availability calendar only if environment variable availabilityEnabled is true
const availabilityMaybe = config.enableAvailability ? [AVAILABILITY] : [];

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
// Note 3: in FTW-hourly template we don't use the POLICY tab so it's commented out.
// If you want to add a free text field to your listings you can enable the POLICY tab
export const TABS = [OVERVIEW, LOCATION, PHOTOS, PRICING, PROFILE, VIEWDRAFTLISTING];

export const ENQUIRY_TABS = [DESCRIPTION, LOCATION];
export const COMPANY_TABS = [DESCRIPTION, PHOTOS, AGREEMENT, ADMIN, PAYMENTS, VIEWDRAFTBUSINESS];
export const LISTING_TABS = [...TABS];
export const CLASS_TABS = [DESCRIPTION, LOCATION, PRICING, ...availabilityMaybe, PHOTOS];
export const PRODUCT_TABS = [DETAILS, PRICESTOCK, CERTIFICATES, EXPECTATIONS, DELIVERY, PHOTOS];

export const getTabs = listingType => {
  switch (listingType) {
    case LISTING_TYPES.LISTING:
      return LISTING_TABS;
    case LISTING_TYPES.FACILITY:
      return TABS;
    case LISTING_TYPES.ENQUIRY:
      return ENQUIRY_TABS;
    case LISTING_TYPES.COMPANY:
      return COMPANY_TABS;
    case LISTING_TYPES.CLASS:
      return CLASS_TABS;
    case LISTING_TYPES.PRODUCT:
      return PRODUCT_TABS;
    default:
      return TABS;
  }
};

import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_ACCEPT,
  TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD,
  TRANSITION_ACCEPT_EXTEND_DELIVERY_PERIOD,
  TRANSITION_AUTO_REQUEST_EXTEND_DELIVERY_PERIOD,
  TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD,
  TRANSITION_SET_DELIVERED,
  TRANSITION_REQUEST_CHANGES,
  TRANSITION_MARK_DELIVERED_CHANGES,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsEnquired,
  txIsRequested,
  txIsAccepted,
  txIsExtendRequested,
  txIsCanceled,
  txIsDeclined,
  txIsDelivered,
  txIsCompleted,
  txReviewsStarted,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import routeConfiguration from '../../routeConfiguration';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  PrimaryButton,
  SecondaryButton,
  TripSummary,
  Button,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
// These are internal components that make this file more readable.
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_REQUESTED_EXTEND,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_COLLABORATE,
  HEADING_COMPLETED,
} from './PanelHeading';

import css from './TransactionPanel.css';
import { post } from 'util/api';
import Timer from './components/Timer/Timer';
import Lyfshort_icon from '../../assets/Listing_Page/Lyfshort_icon.svg';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export const getTime = obj => {
  if (typeof obj === 'string') {
    return obj;
  } else {
    return obj.hours.toString();
  }
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    let { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    if (!reviewContent) {
      reviewContent = config.dummyReviewContent;
    }
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => {
        console.log(r);
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
      })
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf && typeof window !== 'undefined') {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      withHitpay,
      history,
      onSetDelivered,
      onSetCompleted,
      onUniversalTransition,
    } = this.props;
    const userName = this.props.transaction.customer.attributes.profile.publicData.userName;
    const isAccepted = transaction.attributes.transitions.some(
      transition => transition.transition == TRANSITION_ACCEPT
    );
    const routes = routeConfiguration();
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const collaborationTime = getTime(
      transaction?.attributes?.protectedData?.packageDetails?.collaborationTime
    );
    const deliveryTime = getTime(
      transaction?.attributes?.protectedData?.packageDetails?.deliveryTime
    );

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const hitpayPaymentSuccess =
      currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.hitpayPaymentStatus &&
      currentTransaction.attributes.metadata.hitpayPaymentStatus === 'completed';

    const { transitions } = currentTransaction?.attributes || {};
    const lastTransition = currentTransaction?.attributes?.lastTransition;
    const isPendingDeliveryExtensionRequest =
      lastTransition === TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD;
    const alreadyExtendedFromAccepted = transitions.find(t =>
      [
        TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD,
        TRANSITION_AUTO_REQUEST_EXTEND_DELIVERY_PERIOD,
      ].includes(t?.transition)
    );
    //
    const changesRequested = lastTransition === TRANSITION_REQUEST_CHANGES;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx) && !txIsExtendRequested(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showMarkAsDelivered: isProvider,
          hideExtendPeriodButton: alreadyExtendedFromAccepted,
        };
      } else if (txIsExtendRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED_EXTEND,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showMarkAsDelivered: isProvider,
          showPendingRequestDeliveryExtension: isPendingDeliveryExtensionRequest,
          hideExtendPeriodButton: alreadyExtendedFromAccepted,
        };
      } else if (txIsDelivered(tx)) {
        return {
          headingState: changesRequested ? HEADING_COLLABORATE : HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showMarkAsCompleted: isCustomer,
          showRequestChanges: isCustomer,
          changesRequested,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
          showSearchPageButton: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCompleted(tx)) {
        return {
          headingState: HEADING_COMPLETED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showReviewModal: true,
        };
      } else if (txReviewsStarted(tx)) {
        return {
          headingState: HEADING_COMPLETED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showReviewModal: true,
        };
      } else {
        return { headingState: 'unknown', showReviewModal: false };
      }
    };
    const stateData = stateDataFn(currentTransaction);
    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const { paymentProofs, membership, withCash } = currentTransaction.attributes.protectedData;

    const bookingSubTitle = `${formatMoney(intl, price)} ${intl.formatMessage({
      id: unitTranslationKey,
    })}`;

    const featureImage =
      currentListing.images && currentListing.images.length > 1 ? currentListing.images[1] : null;

    const handleAcceptSale = () => {
      const chargeId = currentTransaction?.attributes?.protectedData?.chargeId;
      const partnerId = currentUser?.attributes?.profile?.publicData?.adminId;
      const payoutAmount = currentTransaction?.attributes?.payoutTotal?.amount;
      const payinAmount = currentTransaction?.attributes?.payinTotal?.amount;

      if (!chargeId || !partnerId || !payoutAmount || !payinAmount) {
        return null;
      }
      onAcceptSale(currentTransaction);
      // return post('/api/charge-commission-from-partner', {
      //   chargeId,
      //   partnerId,
      //   payinAmount,
      // })
      //   .then(resp => {
      //     onAcceptSale(currentTransaction);
      //   })
      //   .catch(e => {
      //     onAcceptSale(currentTransaction);
      //     console.log(e);
      //   });
    };

    const handleDeclineSale = () => {
      const chargeId = currentTransaction?.attributes?.protectedData?.chargeId;

      return post('/api/refund-amount-to-expert', { chargeId })
        .then(resp => {
          return onDeclineSale({
            transaction: currentTransaction,
            listingId: currentListing.id,
            customerId: currentCustomer.id,
            sellerId: currentProvider.id,
          });
        })
        .catch(e => console.log(e));
    };

    const handleMsgBtnClick = () => {
      if (window) {
        // window.location.href = 'https://l.lyfshort.com/mkp_open_org_app';
        window.open('https://l.lyfshort.com/mkp_open_org_app', '_blank');
      }
    };

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={handleAcceptSale}
        onDeclineSale={handleDeclineSale}
      />
    );

    const saleButtonsOnlyDesktop = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={handleAcceptSale}
        onDeclineSale={handleDeclineSale}
        isOnlyDesktop={true}
      />
    );

    const lastTransitionedAt = currentTransaction?.attributes?.lastTransitionedAt || new Date();

    const collaborationStartedAt = transitions?.find(
      transition => transition.transition === TRANSITION_SET_DELIVERED
    )?.createdAt;
    const deliveryStartedAt = transitions?.find(
      transition => transition.transition === TRANSITION_ACCEPT
    )?.createdAt;

    const occasionalDeliveryTime = alreadyExtendedFromAccepted
      ? Number(deliveryTime) + 24
      : deliveryTime;

    const searchTripExpertButton = stateData.showSearchPageButton && (
      <PrimaryButton className={css.linkWrapperClass}>
        <NamedLink name="SearchPage">Find New Trip Expert</NamedLink>
      </PrimaryButton>
    );

    const markAsDeliveredButton = stateData.showMarkAsDelivered && (
      <div className={css.buttonsWrapper}>
        <div>
          <Button className={css.actionButton} onClick={() => onSetDelivered(currentTransaction)}>
            Mark as delivered
            <Timer date={deliveryStartedAt} hoursToAdd={occasionalDeliveryTime} />
          </Button>
          {!stateData.hideExtendPeriodButton && (
            <p style={{ fontSize: 15, textAlign: 'center' }}>
              {/* Extension link */}
              <FormattedMessage
                id="TransactionPanel.requestTimeMessage"
                values={{
                  br: <br />,
                  em: (...chunks) => <em>{chunks}</em>,
                  red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
                  a: (...chunks) => <a href="https://l.lyfshort.com/mkp_open_org_app">{chunks}</a>,
                  extendActionLink: (
                    <a
                      onClick={() =>
                        onUniversalTransition(
                          currentTransaction,
                          TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD
                        )
                      }
                    >
                      here
                    </a>
                  ),
                }}
              />
            </p>
          )}
        </div>
      </div>
    );

    const markAsCompletedButton = stateData.showMarkAsCompleted && !changesRequested && (
      <div className={css.buttonsWrapper}>
        <div className={css.actionButtonWrapper}>
          <Button className={css.actionButton} onClick={() => onSetCompleted(currentTransaction)}>
            Mark as Complete
          </Button>
        </div>
      </div>
    );

    const requestChangesButton = stateData.showRequestChanges && (
      <div className={css.buttonsWrapper}>
        <div>
          <SecondaryButton
            className={css.actionButton}
            disabled={changesRequested}
            onClick={() => onUniversalTransition(currentTransaction, TRANSITION_REQUEST_CHANGES)}
          >
            {changesRequested ? 'Requested Changes' : 'Request Changes'}
            <Timer date={collaborationStartedAt} hoursToAdd={collaborationTime} />
          </SecondaryButton>
          <p style={{ fontSize: '15px', textAlign: 'center' }}>
            <FormattedMessage
              id={
                stateData.showRequestChanges
                  ? 'TransactionPanel.requestChangesInfo'
                  : 'TransactionPanel.requestedChangesInfo'
              }
              values={{
                a: (...chunks) => (
                  <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                    {chunks}
                  </a>
                ),
                em: (...chunks) => <em>{chunks}</em>,
              }}
            />
          </p>
        </div>
      </div>
    );

    const markDeliveredChangesButton = changesRequested && isProvider && (
      <Button
        className={css.actionButton}
        onClick={() => onUniversalTransition(currentTransaction, TRANSITION_MARK_DELIVERED_CHANGES)}
      >
        {'Mark as delivered'}
      </Button>
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );
    const classes = classNames(rootClassName || css.root, className);
    const isWithoutPayment = currentTransaction.attributes.processName === 'unit-time-booking-free';
    var numberOfcredits = 0;
    if (isProvider) {
      numberOfcredits =
        currentCustomer &&
        currentCustomer.attributes &&
        currentCustomer.attributes.profile.privateData &&
        currentCustomer.attributes.profile.privateData.sessions &&
        currentCustomer.attributes.profile.privateData.sessions[currentListing.id.uuid]
          ? currentCustomer.attributes.profile.privateData.sessions[currentListing.id.uuid]
          : 0;
    } else {
      numberOfcredits =
        currentUser &&
        currentUser.attributes &&
        currentUser.attributes.profile.privateData &&
        currentUser.attributes.profile.privateData.sessions &&
        currentUser.attributes.profile.privateData.sessions[currentListing.id.uuid]
          ? currentUser.attributes.profile.privateData.sessions[currentListing.id.uuid]
          : 0;
    }

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={featureImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            <DetailCardHeadingsMaybe
              showDetailCardHeadings={stateData.showDetailCardHeadings}
              listingTitle={listingTitle}
              subTitle={bookingSubTitle}
              location={location}
              geolocation={geolocation}
              showAddress={stateData.showAddress}
              otherUserDisplayName={otherUserDisplayName}
              userName={currentProvider.attributes.profile.publicData.userName}
              isOnlyDesktop={false}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
                <div className={css.headerUserNameWrapper}>
                  <div className={css.imgWrap}>
                    {' '}
                    <img src={Lyfshort_icon} alt="lyfshort" />
                  </div>
                  <span className={css.topSubHeading1}>{userName}</span>
                </div>
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              currentTransaction={currentTransaction}
              listing={currentListing}
              currentUser={currentUser}
              lastTransitionedAt={deliveryStartedAt || collaborationStartedAt || lastTransitionedAt}
              deliveryTime={deliveryTime}
              collaborationTime={collaborationTime}
              isDeliveryExtended={alreadyExtendedFromAccepted}
              onOpenReviewModal={this.onOpenReviewModal}
            />

            {searchTripExpertButton}
            {markAsDeliveredButton}
            {stateData.showSaleButtons && (
              <div className={css.desktopActionButtons}>{saleButtonsOnlyDesktop}</div>
            )}
            {requestChangesButton}
            {markDeliveredChangesButton}
            {markAsCompletedButton}
            {/*
            {(currentCustomer || currentProvider) && (
              <WhatsappButton
                className={css.whatsappButtonWrapper}
                user={isProvider ? currentCustomer : currentProvider}
                isButton={true}
              />
            )} */}

            {isProvider && withHitpay ? (
              <div className={css.hitpay}>
                <div className={css.hitpayPaymentMethod}>
                  <FormattedMessage id="TransactionPanel.hitpayPaymentMethod" />
                </div>
                <div className={css.hitpayPaymentStatus}>
                  <FormattedMessage id="TransactionPanel.hitpayPaymentStatus" />
                  {hitpayPaymentSuccess ? (
                    <span className={css.paymentStatusSuccess}>
                      <FormattedMessage id="TransactionPanel.paymentStatusSuccess" />
                    </span>
                  ) : (
                    <span className={css.paymentStatusFail}>
                      <FormattedMessage id="TransactionPanel.paymentStatusFail" />
                    </span>
                  )}
                </div>
              </div>
            ) : null}
            {isWithoutPayment && isProvider && !withHitpay ? (
              <div className={css.note}>
                <FormattedMessage id="TransactionPanel.verifyPaymentNote" />
              </div>
            ) : null}

            {isWithoutPayment && membership && (
              <div className={css.membership}>
                <FormattedMessage id={'TransactionPanel.membership'} values={{ membership }} />
              </div>
            )}

            {withCash && (
              <div className={css.withCash}>
                <FormattedMessage id={'TransactionPanel.withCash'} />
              </div>
            )}

            {isWithoutPayment && paymentProofs && paymentProofs.length > 0 ? (
              <div className={css.paymentProofsContainer}>
                <h2 className={css.paymentScreenshots}>
                  <FormattedMessage id="TransactionPanel.paymentScreenshots" />
                </h2>
                <div className={css.paymentProofSsContainer}>
                  {paymentProofs.map(ss => {
                    return (
                      <a
                        className={css.paymentProofSs}
                        // eslint-disable-next-line
                        target="_blank"
                        href={ss}
                      >
                        <img src={ss} alt="paymentProofSs" />
                      </a>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {/* <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                transaction={currentTransaction}
                listing={currentListing}
                transactionRole={transactionRole}
              />
            </div> */}

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            {isCustomer && stateData.showPendingRequestDeliveryExtension && (
              <div className={css.actionButtonWrapper}>
                <SecondaryButton
                  className={css.actionButton}
                  onClick={() =>
                    onUniversalTransition(
                      currentTransaction,
                      TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD
                    )
                  }
                >
                  Cancel Order
                </SecondaryButton>

                <PrimaryButton
                  className={css.actionButton}
                  onClick={() =>
                    onUniversalTransition(
                      currentTransaction,
                      TRANSITION_ACCEPT_EXTEND_DELIVERY_PERIOD
                    )
                  }
                >
                  Accept 24hr Extension
                </PrimaryButton>
              </div>
            )}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />

            {stateData.showPendingRequestDeliveryExtension && isProvider && (
              <div className={css.pendingExtensionWrapper}>
                {isProvider && (
                  <p className={css.warningText}>
                    Waiting for the customer to accept or decline the extension request
                  </p>
                )}
              </div>
            )}

            {!isAccepted ? (
              showSendMessageForm ? (
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                />
              ) : (
                <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
              )
            ) : (
              <SecondaryButton rootClassName={css.submitButton} onClick={handleMsgBtnClick}>
                <div className={css.imgWrap}>
                  <img src={Lyfshort_icon} alt="lyfshort" />
                </div>
                <FormattedMessage
                  id={
                    isCustomer
                      ? 'TransactionPanel.messageTripExpert'
                      : 'TransactionPanel.messageCustomer'
                  }
                />
              </SecondaryButton>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={featureImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
                otherUserDisplayName={otherUserDisplayName}
                userName={currentProvider.attributes.profile.publicData.userName}
                isOnlyDesktop={true}
              />
              {currentTransaction.attributes.protectedData?.tripDetails?.estimatedMinBudget && (
                <div className={css.TripSummaryWrapper}>
                  <TripSummary
                    intl={intl}
                    {...currentTransaction.attributes.protectedData.tripDetails}
                    listing={currentListing}
                    packageData={currentTransaction.attributes.protectedData.packageDetails}
                  />
                </div>
              )}
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                />
              ) : null}
              <BreakdownMaybe
                // className={css.breakdownContainer}
                transaction={currentTransaction}
                listing={currentListing}
                transactionRole={transactionRole}
              />

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
            {/* <div className={css.BookAgainButton}>
              <PrimaryButton
                onClick={() =>
                  history.push(
                    createResourceLocatorString(
                      'ListingPage',
                      routes,
                      {
                        id: transaction.listing.id.uuid,
                        slug: createSlug(transaction.listing.attributes.title),
                      },
                      {}
                    )
                  )
                }
              >
                <FormattedMessage id="CheckoutPageWithoutPayment.bookAgain" />
              </PrimaryButton>
            </div> */}
          </div>
        </div>
        {stateData?.showReviewModal && (
          <ReviewModal
            id="ReviewOrderModal"
            isOpen={this.state.isReviewModalOpen}
            onCloseModal={() => this.setState({ isReviewModalOpen: false })}
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmitReview={this.onSubmitReview}
            revieweeName={otherUserDisplayName}
            reviewSent={this.state.reviewSubmitted}
            sendReviewInProgress={sendReviewInProgress}
            sendReviewError={sendReviewError}
          />
        )}
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;

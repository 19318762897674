import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import moment from 'moment';
import { Field, Form as FinalForm } from 'react-final-form';
import { Form, IconSearch, LocationAutocompleteInput } from '../../components';
import { compose } from 'redux';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import Autosuggest from 'react-autosuggest';
import { Tabs } from 'react-re-super-tabs';
import css from './SectionSearchForHomepage.css';
import AutocompleteCss from './LocationAutocompleteInput.css';
import debounce from 'lodash/debounce';
import Search_Bottom_Icon from '../../assets/Landing_Pages/Search_Bottom_Icon.png';
import Gray_Map_Icon from '../../assets/Landing_Pages/Gray_Map_Icon.png';
import Smile_Icon from '../../assets/Landing_Pages/Smile_Icon.svg';
import Map_Icon from '../../assets/Landing_Pages/Map_Icon.svg';
import { getPredictionsFromCategory } from './utils';
import Search_Line from '../../assets/Landing_Pages/Search_Line.png';
import { parse } from 'util/urlHelpers';

const categories = config.custom.categories.filter(c => !c.hideFromFilters);
const subCategories = config.custom.subCategoriesFilterOptions.filter(c => !c.hideFromFilters);
const PromoCategories = config.custom.tripReasonOptions;
const suggestionsOptions = config.custom.suggestionCategoryOptions;
const identity = v => v;
// Date range

// const initialDateRangeValue = (queryParams, paramName) => {
//   const dates = queryParams[paramName];
//   const rawValuesFromParams = !!dates ? dates.split(',') : [];
//   const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
//   const initialValues =
//     !!dates && valuesFromParams.length === 2
//       ? {
//         dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
//       }
//       : { dates: null };

//   return initialValues;
// };

// const initialValue = (queryParams, paramName) => {
//   return queryParams[paramName];
// };

class SectionSearchForHomepageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleQueryFocus = this.handleQueryFocus.bind(this);
    this.handleQueryFocusout = this.handleQueryFocusout.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.locationSearchInput = null;
    this.urlParams = parse(this.props.location.search);
    this.state = {
      selectedSuggestion: null,
      value: PromoCategories.filter(cat => cat.key === this.urlParams.pub_trip_reason)[0]?.label,
      availabilityQueryParams: {},
      urlQueryParams: this.urlParams,
      searchLocation: {
        predictions: [],
        search: this.urlParams.address,
        selectedPlace: {
          address: this.urlParams.address,
        },
      },
    };
  }

  componentDidUpdate() {
    if (this.searchInput) {
      if (this.focusedSearchInput) {
        this.searchInput.focus();
      }
    }
  }

  getSuggestionValue(suggestion) {
    return suggestion.keyword
      ? suggestion.label.split(' | ')[0]
      : suggestion.label
        ? suggestion.label
        : suggestion.title;
  }

  renderSuggestion(suggestion) {
    return (
      <div className={css.suggetionDotWrap}>
        {' '}
        <div className={css.suggetionDot} />
        {suggestion.label ? suggestion.label : suggestion.title}
      </div>
    );
  }

  shouldRenderSuggestions() {
    return true;
  }

  renderSectionTitle(section) {
    return section.title;
  }

  getSectionSuggestions(section) {
    return section.options;
  }

  onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    this.setState({
      selectedSuggestion: suggestion?.key,
    });
    if (suggestion.keyword) {
      console.log(suggestion, 'suggestion');
      const searchParams = { keywords: suggestionValue, pub_listing_type: 'listing' };
      this.props.history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    }

    if (suggestion.explore) {
      this.props.history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          { pub_listing_type: 'listing' }
        )
      );
    }
  }

  searchListings = debounce(value => {
    this.props.handleProviderQueryChange(value);
  }, 900);

  handleProviderQueryChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
    this.searchListings(newValue);
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    return false;
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleQueryFocus(e) {
    this.focusedSearchInput = e.target;
    e.target.focus();
    if (e && e.target) {
      e.target.closest("div[class*='searchQuery']").classList.add('active-search-query');
    }
  }

  handleQueryFocusout(e) {
    this.focusedSearchInput = null;
    if (e && e.target) {
      e.target.blur();
      e.target.closest("div[class*='searchQuery']").classList.remove('active-search-query');
    } else if (document.querySelector("div[class*='active-search-query']")) {
      document
        .querySelector("div[class*='active-search-query']")
        .classList.remove('active-search-query');
    }
  }

  onSubmit(e) {
    e.preventDefault();
    var categoryName = config.custom.tripReasonOptions?.find(c => c.label === this.state.value)
      ?.key;
    const { history, currentSearchParams } = this.props;
    const { search = null, selectedPlace = {} } = this.state?.searchLocation;
    const { origin = null, bounds = null } = selectedPlace;
    // const originMaybe = config.sortSearchByDistance && origin ? { origin } : { bounds };
    const originMaybe = config.sortSearchByDistance && { origin, bounds };
    let searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      pub_listing_type: 'listing',
    };

    if ((categoryName && typeof search === 'string') || categoryName) {
      const suggestions = getPredictionsFromCategory(categoryName);
      searchParams = {
        ...currentSearchParams,
        ...originMaybe,
        pub_suggestion_category: suggestions,
        // pub_trip_reason: categoryName,
        pub_listing_type: 'listing',
      };

      // if (this.state.selectedSuggestion) {
      //   searchParams = {
      //     ...currentSearchParams,
      //     ...originMaybe,
      //     pub_suggestion_category: 'has_any:' + this.state.selectedSuggestion,
      //   };
      // }

      return history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          {
            ...searchParams,
            ...(typeof search === 'string'
              ? {
                address: search,
              }
              : {}),
          }
        )
      );
    } else if (search) {
      if (this.state.selectedSuggestion) {
        searchParams = {
          ...currentSearchParams,
          ...originMaybe,
          pub_suggestion_category: 'has_any:' + this.state.selectedSuggestion,
        };
      }

      return history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          {
            ...searchParams,
            ...(typeof search === 'string'
              ? {
                address: search,
              }
              : {}),
          }
        )
      );
    } else if (search === null) {
      if (this.state.selectedSuggestion) {
        searchParams = {
          ...currentSearchParams,
          ...originMaybe,
          pub_suggestion_category: 'has_any:' + this.state.selectedSuggestion,
        };
      }

      return history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          {
            ...searchParams,
            ...(typeof search === 'string'
              ? {
                address: search,
              }
              : {}),
          }
        )
      );
    }
  }

  getSuggestions(value, suggestedListings) {
    const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const escapedValue = escapeRegexCharacters(value.trim());

    const regex = new RegExp('^' + escapedValue, 'i');

    const matchedCategories = categories.filter(c => regex.test(c.label));
    const matchedSubcategories = subCategories.filter(sc => regex.test(sc.label));

    const categoryData = config.custom.tripReasonOptions;
    let suggestions = [];

    // if (value === '') {
    if (categories && categories.length > 0) {
      suggestions.push({
        title: '',
        options: categoryData,
      });
    }
    // }

    // if (value === 'Learn & Explore') {
    //   suggestions.push({
    //     title: '',
    //     options: suggestionsOptions.filter(s => {
    //       const targetedKeys = [
    //         'backpacking',
    //         'camping',
    //         'clubs_&_pubs',
    //         'historical',
    //         'long_weekend',
    //         'nature_parks',
    //         'photography',
    //         'religion',
    //         'road_trip',
    //         'sightseeing',
    //         'volunteering',
    //       ];
    //       return targetedKeys.includes(s.key);
    //     }),
    //   });
    // }

    // if (value === 'Relax & Recharge') {
    //   suggestions.push({
    //     title: '',
    //     options: suggestionsOptions.filter(s => {
    //       const targetedKeys = [
    //         'beach',
    //         'cruise',
    //         'health_&_fitness',
    //         'long_weekend',
    //         'photography',
    //         'resort',
    //         'road_trip',
    //         'shopping',
    //         'snowsports',
    //         'spa_&_massage',
    //         'sporting_events',
    //         'watersports',
    //       ];
    //       return targetedKeys.includes(s.key);
    //     }),
    //   });
    // }

    // if (value === 'Family Getaway') {
    //   suggestions.push({
    //     title: '',
    //     options: suggestionsOptions.filter(s => {
    //       const targetedKeys = [
    //         'backpacking',
    //         'birthday',
    //         'health_&_fitness',
    //         'hiking_&_climbing',
    //         'historical',
    //         'nature_parks',
    //         'long_weekend',
    //         'resort',
    //         'road_trip',
    //         'snowsports',
    //         'watersports',
    //       ];
    //       return targetedKeys.includes(s.key);
    //     }),
    //   });
    // }

    // if (value === 'Escape & Reflect') {
    //   suggestions.push({
    //     title: '',
    //     options: suggestionsOptions.filter(s => {
    //       const targetedKeys = [
    //         'backpacking',
    //         'beach',
    //         'cruise',
    //         'festivals',
    //         'long_weekend',
    //         'music_events',
    //       ];
    //       return targetedKeys.includes(s.key);
    //     }),
    //   });
    // }

    // if (value === 'Work') {
    //   suggestions.push({
    //     title: '',
    //     options: suggestionsOptions.filter(s => {
    //       const targetedKeys = [
    //         'long_weekend',
    //         'resort',
    //         'spa_&_massage',
    //         'sightseeing',
    //         'historical',
    //         'health_&_fitness',
    //         'shopping',
    //         'restaurants_&_dining',
    //       ];
    //       return targetedKeys.includes(s.key);
    //     }),
    //   });
    // }

    // if (value === 'Visit Someone') {
    //   suggestions.push({
    //     title: '',
    //     options: suggestionsOptions.filter(s => {
    //       const targetedKeys = [
    //         'road_trip',
    //         'long_weekend',
    //         'clubs_&_pubs',
    //         'restaurants_&_dining',
    //       ];
    //       return targetedKeys.includes(s.key);
    //     }),
    //   });
    // }

    // if (value !== '') {
    //   suggestions.push({
    //     title: '',
    //     options: [{ keyword: true, label: value + ' | Search' }],
    //   });

    //   if (suggestedListings && suggestedListings.length > 0) {
    //     suggestions.push({
    //       title: '',
    //       options: suggestedListings,
    //     });
    //   }

    //   if (!this.props.searchPage) {
    //     if (matchedSubcategories && matchedSubcategories.length > 0) {
    //       suggestions.push({
    //         title: 'Subcategory',
    //         options: matchedSubcategories,
    //       });
    //     }
    //   }
    // } else if (value === '') {
    //   if (categories && categories.length > 0) {
    //     suggestions.push({
    //       title: '',
    //       options: categoryData,
    //     });
    //   }
    // }

    return suggestions;
  }

  onChange(location) {
    if (location.selectedPlace) {
      // this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      this.setState({ searchLocation: location });
      if (this.locationSearchInput) {
        this.locationSearchInput.blur();
      }
    }
  }

  render() {
    const { value = '' } = this.state;
    const { intl, suggestedListings, title } = this.props;
    const suggestions = this.getSuggestions(value, suggestedListings);

    // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    // const FILTER_DROPDOWN_OFFSET = isMobileLayout ? -35 : -14;
    const nameOrCategoryPlaceholder = intl.formatMessage({
      id: 'SectionSearch.nameOrCategoryQueryPlaceholder',
    });

    const inputProps = {
      placeholder: nameOrCategoryPlaceholder,
      value,
      onChange: this.handleProviderQueryChange,
      onClick: this.handleQueryFocus,
      onBlur: this.handleQueryFocusout,
      autoComplete: 'off',
      id: 'name-category-query',
      ref: function (inputEl) {
        this.searchInput = inputEl;
      }.bind(this),
    };

    return (
      <FinalForm
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const preventFormSubmit = e => e.preventDefault();
          const { desktopInputRoot, isMobile } = formRenderProps;
          const desktopInputRootClass = desktopInputRoot || AutocompleteCss.desktopInputRoot;

          return (
            <Form onSubmit={preventFormSubmit} className={css.formWrapper} autoComplete="off">
              <div className={this.props.searchPage ? css.searchForSearchPage : css.search}>
                <div className={css.searchQuery}>
                  <img src={Smile_Icon} alt="mapIcon" className={css.SmileIcon} />
                  <Autosuggest
                    suggestions={suggestions}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderSectionTitle={this.renderSectionTitle}
                    multiSection={true}
                    getSectionSuggestions={this.getSectionSuggestions}
                    onSuggestionSelected={this.onSuggestionSelected}
                    focusInputOnSuggestionClick={false}
                    inputProps={inputProps}
                  />
                  <img src={Search_Bottom_Icon} alt="icon" />
                </div>
                {/* <img
                          src={Search_Line}
                          alt="search"
                          className={css.searchPipeLine}
                        /> */}
                <div className={css.countyOrCitySearchQuery}>
                  <Field
                    name="location"
                    format={identity}
                    render={({ input, meta }) => {
                      const { onChange, ...restInput } = input;

                      // See: https://github.com/final-form/react-final-form/issues/159
                      const searchOnChange = value => {
                        onChange(value);
                        this.onChange(value);
                      };

                      const searchInput = { ...restInput, onChange: searchOnChange };
                      return (
                        <LocationAutocompleteInput
                          className={
                            isMobile ? AutocompleteCss.mobileInputRoot : desktopInputRootClass
                          }
                          iconClassName={
                            isMobile ? AutocompleteCss.mobileIcon : AutocompleteCss.desktopIcon
                          }
                          inputClassName={
                            isMobile ? AutocompleteCss.mobileInput : AutocompleteCss.desktopInput
                          }
                          predictionsClassName={
                            isMobile
                              ? AutocompleteCss.mobilePredictions
                              : AutocompleteCss.desktopPredictions
                          }
                          predictionsAttributionClassName={
                            isMobile ? AutocompleteCss.mobilePredictionsAttribution : null
                          }
                          placeholder={`${intl.formatMessage({
                            id: 'SectionSearch.nameOrCategoryQueryLabel',
                          })}?`}
                          closeOnBlur={!isMobile}
                          inputRef={node => {
                            this.locationSearchInput = node;
                          }}
                          input={searchInput}
                          meta={meta}
                          selectedLocation={this.state.searchLocation}
                          locationTypes={["country", "region", "locality", "district", "place"]}
                          iconComponent={
                            <img src={Map_Icon} alt="mapIcon" className={css.MapIcon} />
                          }
                        />
                      );
                    }}
                  />
                  <img src={Search_Bottom_Icon} alt="icon" className={css.searchBottomIcon} />
                </div>

                <div className={css.searchButton}>
                  <button type="submit" onClick={this.onSubmit}>
                    <IconSearch /> Search
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SectionSearchForHomepageComponent.defaultProps = {};

SectionSearchForHomepageComponent.propTypes = {};

const SectionSearchForHomepage = compose(
  withViewport,
  injectIntl
)(SectionSearchForHomepageComponent);

export default SectionSearchForHomepage;

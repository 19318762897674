import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cns from 'classnames'
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    PrimaryButton,
} from '../../components';
import {
    IconImage1,
    IconImage2,
    IconImage3,
    IconImage4,
    IconImage5,
    IconImage6,
    IconImage7,
    IconImage8,
    IconImage9,
    IconImage10,
    IconImage11,
    IconImage12,
    IconImage13,
    IconImage14,
    IconImage15,
    IconHeadBanner,
} from './IconSocialSearchMarketingPage'
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '..';
import css from './SocialSearchMarketingPage.css';

export class SocialSearchMarketingPageComponent extends Component {

    render() {
        const {
            scrollingDisabled,
        }= this.props
        return (
            <Page
             className={css.root}
             title={"Social search marketing"}
             scrollingDisabled={scrollingDisabled}
             >
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <TopbarContainer />
                    </LayoutWrapperTopbar>
                    <LayoutWrapperMain>

                        <div className={css.ImagesSection}>
                            <div className={css.ImagesContainer}>
                                <IconImage1 />
                                <IconImage2 />
                                <IconImage3 />
                                <IconImage4 />
                                <IconImage5 className={css.desktopSVG} />
                            </div>
                            <div className={css.ImagesContainer}>
                                <IconImage6 />
                                <IconImage7 />
                                <IconImage8 />
                                <IconImage9 />
                                <IconImage10 className={css.desktopSVG} />
                            </div>
                            <div className={css.ImagesContainer}>
                                <IconImage11 />
                                <IconImage12 />
                                <IconImage13 />
                                <IconImage14 />
                                <IconImage15 className={css.desktopSVG} />
                            </div>
                        </div>

                        <div className={css.mainContainer}>
                            <div className={css.madeEasySection}>
                                <h1 className={css.socialSearchMarketingTitle}><FormattedMessage id={"SocialSearchMarketingPage.SocialSearchMarketingTitle"} /></h1>
                                <span className={css.subHeading}><FormattedMessage id={"SocialSearchMarketingPage.subTitle"} /></span>
                                <div className={css.greyContainer}>
                                    <IconHeadBanner/>
                                </div>
                                <div className={css.textSection}>
                                    <div className={css.infoContainer}>
                                        <h4 className={css.infoTitle}><FormattedMessage id={"SocialSearchMarketingPage.challenge"} /></h4>
                                        <span className={css.infoBody}><FormattedMessage id={"SocialSearchMarketingPage.challengeBody"} /></span>
                                    </div>
                                    <div className={css.infoContainer}>
                                        <h4 className={css.infoTitle}><FormattedMessage id={"SocialSearchMarketingPage.solution"} /></h4>
                                        <span className={css.infoBody}><FormattedMessage
                                         id={"SocialSearchMarketingPage.solutionBody"} 
                                        values={{
                                            bold: header => <span className={css.highLights}>{header}</span>,
                                          }}
                                         /></span>
                                    </div>
                                    <div className={css.infoContainer}>
                                        <h4 className={css.infoTitle}><FormattedMessage id={"SocialSearchMarketingPage.result"} /></h4>
                                        <span className={css.infoBody}><FormattedMessage
                                         id={"SocialSearchMarketingPage.resultBody"}
                                         values={{
                                            bold: header => <span className={css.highLights}>{header}</span>,
                                          }}
                                         /></span>
                                    </div>
                                </div>

                                <div className={css.marketingPlanSection}>
                                    <div className={css.marketingPlan}>
                                        <div className={css.plan} >
                                            <div className={css.planWrap}>
                                                <div className={css.planCategoryContainer}>
                                                    <div className={css.planCategory}>
                                                        <span ><FormattedMessage id="SocialSearchMarketingPage.basic" /></span>
                                                    </div>
                                                </div>
                                                <div className={css.planPrice}>
                                                    <span>$70</span>
                                                </div>
                                                <div className={css.planInfoSection}>
                                                    <ul className={css.planInfoContainer}>
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.worthSpend" values={{price: "$70"}} /></li>
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.setAds" /></li>
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.googleAds" /></li>
                                                    </ul>
                                                </div>
                                                <div className={css.planBtn}>
                                                    <PrimaryButton>
                                                        <FormattedMessage id="SocialSearchMarketingPage.startMarketing" />
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={css.marketingPlan}>
                                        <div className={css.plan} >
                                            <div className={css.planWrap}>
                                                <div className={css.planCategoryContainer}>
                                                    <div className={css.planCategory}>
                                                        <span ><FormattedMessage id="SocialSearchMarketingPage.pro" /></span>
                                                    </div>
                                                </div>
                                                <div className={css.planPrice}>
                                                    <span>$140</span>
                                                </div>
                                                <div className={css.planInfoSection}>
                                                    <ul className={css.planInfoContainer}>
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.worthSpend" values={{price: "$140"}} /></li>
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.setAds" /></li>
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.googleAds" /></li>
                                                    </ul>
                                                </div>
                                                <div className={css.planBtn}>
                                                    <PrimaryButton>
                                                        <FormattedMessage id="SocialSearchMarketingPage.startMarketing" />
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={css.marketingPlan}>
                                        <div className={cns(css.plan, css.premiumPlan)} >
                                            <div className={css.planWrap}>
                                                <div className={css.planCategoryContainer}>
                                                    <div className={css.svgWrap}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                                            <path d="M18.7991 3.58963C18.5825 3.39682 18.2769 3.3562 18.0208 3.48622L14.1023 5.47584L10.4254 1.05439C10.2894 0.890829 10.092 0.796875 9.88445 0.796875C9.67686 0.796875 9.47951 0.890829 9.34348 1.05439L5.66665 5.47581L1.74807 3.48619C1.49196 3.3562 1.18641 3.39679 0.969789 3.58959C0.753172 3.78239 0.666669 4.09076 0.749485 4.37494L3.47062 13.7106C3.56232 14.0252 3.84048 14.2403 4.15574 14.2403H15.6132C15.9284 14.2403 16.2066 14.0252 16.2982 13.7107L19.0194 4.37498C19.1022 4.0908 19.0157 3.78243 18.7991 3.58963Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                    <div className={css.planCategory}>
                                                        <span ><FormattedMessage id="SocialSearchMarketingPage.premium" /></span>
                                                    </div>
                                                </div>
                                                <div className={css.planPrice}>
                                                    <span>$280</span>
                                                </div>
                                                <div className={css.planInfoSection}>
                                                    <ul className={css.planInfoContainer}>
                                                        {/* <p className={css.sub}><FormattedMessage id="PackagesModal.product" /></p> */}
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.worthSpend" values={{price: "$280"}} /></li>
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.setAds" /></li>
                                                        <li className={css.planInfo}><FormattedMessage id="SocialSearchMarketingPage.googleAds" /></li>
                                                    </ul>
                                                </div>
                                                <div className={css.planBtn}>
                                                    <PrimaryButton>
                                                        <FormattedMessage id="SocialSearchMarketingPage.startMarketing" />
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className={css.relatedContentsSection}>
                                    <h3 className={css.relatedTitle}><FormattedMessage id={"SocialSearchMarketingPage.popularExamplesTitle"} /></h3>
                                    <div className={css.relatedCards}>
                                        <div className={css.relatedItem}>
                                            <div className={css.smallImageContainer}>
                                            <img src={"/static/images/Icons/relatedImage1.png"} alt="relatedImage1" />
                                            </div>
                                            <div className={css.relatedInfoSection}>
                                                <span className={css.relatedHead}><FormattedMessage id={"SocialSearchMarketingPage.proTennisRelatedHead"} /></span>
                                                <span className={css.relatedBody}><FormattedMessage id={"SocialSearchMarketingPage.proListingRelatedBody"} /></span>
                                            </div>
                                        </div>
                                        <div className={css.relatedItem}>
                                            <div className={css.smallImageContainer}>
                                                <img src={"/static/images/Icons/relatedImage2.png"} alt="relatedImage2"/>
                                            </div>
                                            <div className={css.relatedInfoSection}>
                                                <span className={css.relatedHead}><FormattedMessage id={"SocialSearchMarketingPage.soundHealingRelatedHead"} /></span>
                                                <span className={css.relatedBody}><FormattedMessage id={"SocialSearchMarketingPage.wellnessRelatedBody"} /></span>
                                            </div>
                                        </div>
                                        <div className={css.relatedItem}>
                                            <div className={css.smallImageContainer}>
                                            <img src={"/static/images/Icons/relatedImage3.png"} alt="relatedImage3"/>
                                            </div>
                                            <div className={css.relatedInfoSection}>
                                                <span className={css.relatedHead}><FormattedMessage id={"SocialSearchMarketingPage.flowClassRelatedHead"} /></span>
                                                <span className={css.relatedBody}><FormattedMessage id={"SocialSearchMarketingPage.classesRelatedBody"} /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page >
        );
    }
}

const mapStateToProps = state => {
    return {
        scrollingDisabled: isScrollingDisabled(state),
    };
};

const SocialSearchMarketingPage = compose(
    connect(mapStateToProps),
)(SocialSearchMarketingPageComponent)

export default SocialSearchMarketingPage;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './MarketplaceDevelopmentPage.css';

const SectionSuperAddOns = props => {

    return (
        <div className={css.podcastsSection}>
            <div className={css.sectionWrapper}>
                <div className={css.podcastsSectionTop}>
                    <h3 className={css.podcastsSectionTitle}><FormattedMessage id={'MarketplaceDevelopmentPage.superAddOns'} /></h3>
                </div>

                <div className={css.podcastDetailContainer} >
                    <div className={css.podcastCards}>
                        <div className={css.podcastCard}>
                            <div className={css.podcastsImageContainer}>
                                <img src={'/static/images/MarketplaceDevelopmentPage/superAddOnsPackages.png'} alt="Super add ons" />
                            </div>
                            <div className={css.podcastTextSection}>
                                <span className={css.podcastTitle}><FormattedMessage id={'MarketplaceDevelopmentPage.packages'} /></span>
                                <span className={css.podcastInfo}><FormattedMessage id={'MarketplaceDevelopmentPage.sellingPackages'} /></span>
                    
                            </div>
                        </div>
                        <div className={css.podcastCard}>
                            <div className={css.podcastsImageContainer}>
                                <img src={'/static/images/MarketplaceDevelopmentPage/superAddOnsTransactionTracking.png'} alt="Super add ons" />
                            </div>
                            <div className={css.podcastTextSection}>
                                <span className={css.podcastTitle}><FormattedMessage id={'MarketplaceDevelopmentPage.transactionTracking'} /></span>
                                <span className={css.podcastInfo}><FormattedMessage id={'MarketplaceDevelopmentPage.customScedulesToTransactionTracker'} /></span>
                                
                            </div>
                        </div>
                    </div>
                    <div className={css.rightImageContainer}>
                    <img src={'/static/images/MarketplaceDevelopmentPage/SuperAddOnsPoster.png'} alt="Super add ons poster" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionSuperAddOns;
import React, { Component } from 'react';
import { uid } from 'react-uid';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { createSlug, getExtensionFromUrl } from 'util/urlHelpers';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import config from '../../config';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  FieldSelect,
  FieldPhoneNumberInput,
  NamedLink,
} from '../../components';
import {
  isChangeEmailTakenError,
  isChangeEmailWrongPassword,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';

import css from './ProfileSettingsForm.css';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const SHOW_EMAIL_SENT_TIMEOUT = 2000;

const uploadWidget = (callback, errorCallback) => {
  if (typeof window !== 'undefined') {
    window.cloudinary.openUploadWidget(
      { cloud_name: 'movementbuddy', upload_preset: 'movementbuddy' },
      (error, result) => {
        if (result && result.info && result.info.secure_url) {
          callback(result);
        }
        errorCallback && errorCallback(error);
      }
    );
  }
};

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
      promoImages: null,
      promoVideo: null,
      isPromoImagesDirty: false,
      isPromoVideoDirty: false,
      isPromoDataDirty: false,
      showVerificationEmailSentMessage: false,
      showResetPasswordMessage: false,
      prevEmail: null,
    };
    this.submittedValues = {};
    this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.promoImages || state.promoVideo === null) {
      return {
        promoImages: props.initialValues.promoImages,
        promoVideo: props.initialValues.promoVideo,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId =
        typeof window !== 'undefined' &&
        window.setTimeout(() => {
          this.setState({ uploadDelay: false });
        }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.clearTimeout(this.uploadDelayTimeoutId);
  }

  uploadWidgetPromoImages = e => {
    e.preventDefault();
    uploadWidget(result => {
      const stateData = this.state.promoImages || [];
      this.setState({
        promoImages: [...stateData, result.info.secure_url],
        isPromoDataDirty: true,
      });
    });
  };

  uploadWidgetPromoVideo = e => {
    e.preventDefault();
    uploadWidget(result => {
      this.setState({
        promoVideo: result.info.secure_url,
        isPromoDataDirty: true,
      });
    });
  };

  handlePromoImageDelete = (e, imgUrl) => {
    e.preventDefault();
    const { promoImages } = this.state;
    const updatedPromoImages = promoImages.filter(img => img !== imgUrl);
    this.setState({
      promoImages: updatedPromoImages,
      isPromoDataDirty: true,
    });
  };

  handlePromoVideoDelete = e => {
    e.preventDefault();
    this.setState({
      promoVideo: '',
      isPromoDataDirty: true,
    });
  };

  handleSubmit = values => {
    const { promoImages, promoVideo } = this.state;
    const { onSubmit } = this.props;

    onSubmit({ ...values, promoImages, promoVideo });
  };

  handleResetPassword() {
    this.setState({ showResetPasswordMessage: true });
    const email = this.props.currentUser.attributes.email;
    this.props.onResetPassword(email);
  }

  handleResendVerificationEmail() {
    this.setState({ showVerificationEmailSentMessage: true });
    if (typeof window !== 'undefined') {
      this.props.onResendVerificationEmail().then(() => {
        // show "verification email sent" text for a bit longer.
        this.emailSentTimeoutId = window.setTimeout(() => {
          this.setState({ showVerificationEmailSentMessage: false });
        }, SHOW_EMAIL_SENT_TIMEOUT);
      });
    }
  }

  render() {
    const { initialValues } = this.props;
    return (
      <FinalForm
        {...this.props}
        initialValues={{
          ...initialValues,
          email: this.state.prevEmail ? this.state.prevEmail : initialValues.email,
        }}
        onSubmit={this.handleSubmit}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            createdCompany,
            saveEmailError,
            sendVerificationEmailError,
            sendVerificationEmailInProgress,
            resetPasswordInProgress,
          } = fieldRenderProps;

          const { email, phoneNumber, countryCode } = values;

          const user = ensureCurrentUser(currentUser);
          const { email: currentEmail, emailVerified, pendingEmail, profile } = user.attributes;
          console.log(currentUser);
          // userName
          const userNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.userNameLabel',
          });
          const userNamePlaceholder = intl.formatMessage({
            id: 'SignupForm.userNamePlaceholder',
          });
          const userNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.userNameRequired',
          });
          const userNameRequired = validators.required(userNameRequiredMessage);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // email

          // has the email changed
          const emailChanged = currentEmail !== email;

          const emailLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.emailLabel',
          });

          const emailPlaceholder = currentEmail || '';

          const emailRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailRequired',
          });

          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const confirmClasses = classNames(css.confirmChangesSection, {
            [css.confirmChangesSectionVisible]: emailChanged,
          });

          // phone
          const phoneInvalidMessage = intl.formatMessage({
            id: 'ContactDetailsForm.phoneInvalid',
          });
          const phoneValid = validators.phoneNumberValid(phoneInvalidMessage);
          const publicData = profile.publicData || {};
          const currentPhoneNumber = publicData.phoneNumber;
          // has the phone number changed
          const phoneNumberChanged = currentPhoneNumber !== phoneNumber;
          const countryCodeChanged = publicData.countryCode !== countryCode;
          const phonePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.phonePlaceholder',
          });
          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

          // password
          const passwordLabel = intl.formatMessage({
            id: 'ContactDetailsForm.passwordLabel',
          });
          const passwordPlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.passwordPlaceholder',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.passwordRequired',
          });

          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'ContactDetailsForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );

          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );

          const passwordValidators = emailChanged
            ? validators.composeValidators(passwordRequired, passwordMinLength)
            : null;

          const passwordFailedMessage = intl.formatMessage({
            id: 'ContactDetailsForm.passwordFailed',
          });
          const passwordTouched = this.submittedValues.currentPassword !== values.currentPassword;
          const passwordErrorText = isChangeEmailWrongPassword(saveEmailError)
            ? passwordFailedMessage
            : null;

          //Gender
          const genderLabel = intl.formatMessage({
            id: 'SignupForm.genderLabel',
          });

          //optionalText
          const optionalText = intl.formatMessage({
            id: 'StripePaymentAddress.optionalText',
          });

          //houseNumber
          const houseNumberLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.houseNumberLabel',
          });

          const houseNumberPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.houseNumberPlaceholder',
          });

          //Street
          const streetLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.streetLabel',
          });
          const streetPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.streetPlaceholder',
          });

          //Suburb
          const suburbLabel = intl.formatMessage({ id: 'ProfileSettingsForm.suburbLabel' });
          const suburbPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.suburbPlaceholder',
          });

          //state
          const stateLabel = intl.formatMessage(
            { id: 'ProfileSettingsForm.stateLabel' },
            { optionalText: optionalText }
          );
          const statePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.statePlaceholder',
          });

          //postcode
          const postcodeLabel = intl.formatMessage({ id: 'ProfileSettingsForm.postcodeLabel' });
          const postcodePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.postcodePlaceholder',
          });

          //country
          const countryLabel = intl.formatMessage({ id: 'ProfileSettingsForm.countryLabel' });
          const countryPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.countryPlaceholder',
          });
          const countryRequired = validators.required(
            intl.formatMessage({
              id: 'ProfileSettingsForm.countryRequired',
            })
          );

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                rootClassName={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const isFormPristine = pristine && !this.state.isPromoDataDirty;
          const submitDisabled =
            invalid ||
            isFormPristine ||
            pristineSinceLastSubmit ||
            uploadInProgress ||
            submitInProgress;

          const countryCodes = getCountryCodes(config.locale);

          const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(
            sendVerificationEmailError
          );

          const emailTouched = this.state.prevEmail !== values.email;
          const emailTakenErrorText = isChangeEmailTakenError(saveEmailError)
            ? intl.formatMessage({ id: 'ContactDetailsForm.emailTakenError' })
            : null;

          let resendEmailMessage = null;
          if (tooManyVerificationRequests) {
            resendEmailMessage = (
              <span className={css.tooMany}>
                <FormattedMessage id="ContactDetailsForm.tooManyVerificationRequests" />
              </span>
            );
          } else if (
            sendVerificationEmailInProgress ||
            this.state.showVerificationEmailSentMessage
          ) {
            resendEmailMessage = (
              <span className={css.emailSent}>
                <FormattedMessage id="ContactDetailsForm.emailSent" />
              </span>
            );
          } else {
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            resendEmailMessage = (
              <span
                className={css.helperLink}
                onClick={this.handleResendVerificationEmail}
                role="button"
              >
                <FormattedMessage id="ContactDetailsForm.resendEmailVerificationText" />
              </span>
            );
            /* eslint-enable jsx-a11y/no-static-element-interactions */
          }

          // Email status info: unverified, verified and pending email (aka changed unverified email)
          let emailVerifiedInfo = null;

          if (emailVerified && !pendingEmail && !emailChanged) {
            // Current email is verified and there's no pending unverified email
            emailVerifiedInfo = (
              <span className={css.emailVerified}>
                <FormattedMessage id="ContactDetailsForm.emailVerified" />
              </span>
            );
          } else if (!emailVerified && !pendingEmail) {
            // Current email is unverified. This is the email given in sign up form

            emailVerifiedInfo = (
              <span className={css.emailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.emailUnverified"
                  values={{ resendEmailMessage }}
                />
              </span>
            );
          } else if (pendingEmail) {
            // Current email has been tried to change, but the new address is not yet verified

            const pendingEmailStyled = <span className={css.emailStyle}>{pendingEmail}</span>;
            const pendingEmailCheckInbox = (
              <span className={css.checkInbox}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailCheckInbox"
                  values={{ pendingEmail: pendingEmailStyled }}
                />
              </span>
            );

            emailVerifiedInfo = (
              <span className={css.pendingEmailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailUnverified"
                  values={{ pendingEmailCheckInbox, resendEmailMessage }}
                />
              </span>
            );
          }

          const sendPasswordLink = (
            <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
              <FormattedMessage id="ContactDetailsForm.resetPasswordLinkText" />
            </span>
          );

          const resendPasswordLink = (
            <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
              <FormattedMessage id="ContactDetailsForm.resendPasswordLinkText" />
            </span>
          );

          const resetPasswordLink =
            this.state.showResetPasswordMessage || resetPasswordInProgress ? (
              <>
                <FormattedMessage
                  id="ContactDetailsForm.resetPasswordLinkSent"
                  values={{
                    email: <span className={css.emailStyle}>{currentUser.attributes.email}</span>,
                  }}
                />{' '}
                {resendPasswordLink}
              </>
            ) : (
              sendPasswordLink
            );

          const baseUrl = typeof window !== 'undefined' && window.location.origin;
          const userId = currentUser && currentUser.id?.uuid;
          const isApproved = currentUser?.attributes?.profile?.protectedData?.approved;
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                this.setState({ prevEmail: values.email });
                handleSubmit(e);
              }}
            >
              {/* {isApproved && (
                <div className={css.inviteExpertWrapper}>
                  <h2 className={css.sectionTitle}>Grow your team using this link:</h2>
                  <p className={css.inviteLink}>
                    {baseUrl}/signup?admin={userId}
                  </p>
                </div>
              )} */}

              <div className={css.sectionContainer}>
                {/* <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourProfilePicture" />
                </h3> */}

                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
                <div className={css.tip}>
                  <FormattedMessage id="ProfileSettingsForm.tip" />
                </div>
                <div className={css.fileInfo}>
                  <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                </div>
              </div>
              <div className={css.sectionContainer}>
                {/* <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourName" />
                </h3> */}
                <FieldTextInput
                  className={css.userNameRoot}
                  type="text"
                  id={'userName'}
                  name="userName"
                  label={userNameLabel}
                  placeholder={userNamePlaceholder}
                  validate={userNameRequired}
                />

                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>

                <div className={css.contactDetailsSection}>
                  <FieldTextInput
                    type="email"
                    name="email"
                    id={'email'}
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                    validate={validators.composeValidators(emailRequired, emailValid)}
                    customErrorText={emailTouched ? null : emailTakenErrorText}
                  />
                  {emailVerifiedInfo}
                  <div className={css.phoneContainer}>
                    <label>{phoneLabel}</label>
                    <span className={css.extraLabel}>
                      Enter Country code followed by phone number. Don't allow any special
                      characters like _=+=-
                    </span>
                    <div className={css.phoneWrap}>
                      <FieldTextInput
                        id="countryCode"
                        className={css.countryCodeContenr}
                        name="countryCode"
                        type="number"
                        placeholder="Country code"
                      />

                      <FieldPhoneNumberInput
                        name="phoneNumber"
                        className={css.phone}
                        id={'phoneNumber'}
                        placeholder={phonePlaceholder}
                        validate={validators.composeValidators(phoneValid)}
                      />
                    </div>
                  </div>
                </div>

                <FieldSelect
                  className={css.genderField}
                  type="select"
                  name="gender"
                  id="gender"
                  label={genderLabel}
                >
                  <option value="">Select</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="non binary">Non Binary</option>
                </FieldSelect>
              </div>
              <div className={classNames(css.sectionContainer)}>
                <h3 className={css.sectionTitle}>Address</h3>

                <div className={css.formRow}>
                  <FieldTextInput
                    id={'houseNumber'}
                    name="houseNumber"
                    className={css.addressField}
                    type="text"
                    label={houseNumberLabel}
                    placeholder={houseNumberPlaceholder}
                  />

                  <FieldTextInput
                    id={'street'}
                    name={'street'}
                    className={css.addressField}
                    type="text"
                    autoComplete="billing address-line1"
                    label={streetLabel}
                    placeholder={streetPlaceholder}
                  />
                </div>

                <div className={css.formRow}>
                  <FieldTextInput
                    id={'suburb'}
                    name="suburb"
                    className={css.addressField}
                    type="text"
                    autoComplete="billing address-level2"
                    label={suburbLabel}
                    placeholder={suburbPlaceholder}
                  />

                  <FieldTextInput
                    id={`state`}
                    name="state"
                    className={css.addressField}
                    type="text"
                    autoComplete="billing address-level1"
                    label={stateLabel}
                    placeholder={statePlaceholder}
                  />
                </div>

                <div className={css.formRow}>
                  <FieldTextInput
                    id={'postcode'}
                    name="postcode"
                    className={css.addressField}
                    type="text"
                    autoComplete="billing postal-code"
                    label={postcodeLabel}
                    placeholder={postcodePlaceholder}
                  />

                  <FieldSelect
                    id={`country`}
                    name="country"
                    className={css.addressField}
                    label={countryLabel}
                    validate={countryRequired}
                  >
                    <option disabled value="">
                      {countryPlaceholder}
                    </option>
                    {countryCodes.map(country => {
                      return (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      );
                    })}
                  </FieldSelect>
                </div>
              </div>
              {/* <div className={classNames(css.sectionContainer)}>
                <h3 className={css.sectionTitle}>
                  Created Company
                </h3>
                {createdCompany ? (
                  <NamedLink
                    //className={css.createCompanyLink}
                    name="ListingPage"
                    params={{
                      slug: createSlug(createdCompany.attributes.title),
                      id: createdCompany.id.uuid,
                    }}
                  >
                    {createdCompany.attributes.title}
                  </NamedLink>
                ) : (
                  <NamedLink className={css.createCompanyLink} name="NewCompanyPage" params={{}}>
                    <FormattedMessage id="ProfileSettingsPage.createCompany" />
                  </NamedLink>
                )}
              </div> */}

              <div className={confirmClasses}>
                <h3 className={css.confirmChangesTitle}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesTitle" />
                </h3>
                <p className={css.confirmChangesInfo}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesInfo" />
                  <br />
                  <FormattedMessage
                    id="ContactDetailsForm.resetPasswordInfo"
                    values={{ resetPasswordLink }}
                  />
                </p>

                <FieldTextInput
                  className={css.password}
                  type="password"
                  name="currentPassword"
                  id={'currentPassword'}
                  autoComplete="current-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                  customErrorText={passwordTouched ? null : passwordErrorText}
                />
              </div>

              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  createdCompany: propTypes.listing,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;

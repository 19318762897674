import React from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { LISTING_TYPES } from 'util/constants';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  stripeAccountClearError,
  createStripeAccount,
  getStripeConnectAccountLink,
} from '../../ducks/stripeConnectAccount.duck';
import { EditListingWizard, Footer, NamedRedirect, Page, UserNav, LayoutWrapperFooter } from '../../components';
import { TopbarContainer } from '../../containers';
import { withViewport } from 'util/contextHelpers';
import {
  requestAddAvailabilityException,
  requestDeleteAvailabilityException,
  requestCreateListingDraft,
  requestPublishListingDraft,
  requestCloseListing,
  requestOpenListing,
  requestUpdateListing,
  requestImageUpload,
  updateImageOrder,
  removeListingImage,
  loadData,
  clearUpdatedTab,
  savePayoutDetails,
} from './EditListingPage.duck';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from './EditListingPage.css';

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';
const STRIPE_ONBOARDING_RETURN_URL_TYPES = [
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
];

const { UUID } = sdkTypes;

const getEditPageName = listingType => {
  switch (listingType) {
    case LISTING_TYPES.COMPANY:
      return 'EditCompanyPage';
    case LISTING_TYPES.FACILITY:
      return 'EditFacilityPage';
    case LISTING_TYPES.ENQUIRY:
      return 'EditInquirePage';
    case LISTING_TYPES.CLASS:
      return 'EditClassPage';
    case LISTING_TYPES.PRODUCT:
      return 'EditProductPage';
    case LISTING_TYPES.LISTING:
    default:
      return 'EditListingPage';
  }
};

const getNewPageName = listingType => {
  switch (listingType) {
    case LISTING_TYPES.COMPANY:
      return 'NewCompanyPage';
    case LISTING_TYPES.FACILITY:
      return 'NewFacilityPage';
    case LISTING_TYPES.ENQUIRY:
      return 'NewInquirePage';
    case LISTING_TYPES.CLASS:
      return 'NewClassPage';
    case LISTING_TYPES.PRODUCT:
      return 'NewProductPage';
    case LISTING_TYPES.LISTING:
    default:
      return 'NewListingPage';
  }
};

// N.B. All the presentational content needs to be extracted to their own components
export const EditListingPageComponent = props => {
  const {
    listingType,
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    createStripeAccountError,
    fetchInProgress,
    getOwnListing,
    history,
    intl,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onCreateListingDraft,
    onPublishListingDraft,
    onCloseListing,
    onOpenListing,
    onUpdateListing,
    onImageUpload,
    onRemoveListingImage,
    onManageDisableScrolling,
    onPayoutDetailsFormSubmit,
    onPayoutDetailsFormChange,
    onGetStripeConnectAccountLink,
    onUpdateImageOrder,
    onChange,
    page,
    params,
    scrollingDisabled,
    allowOnlyOneListing,
    stripeAccountFetched,
    stripeAccount,
    canCreateListing,
    canCreateListingRequestInProgress,
    uploadImageInProgress,
    viewport,
  } = props;

  const { id, type, returnURLType } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const listingId = page.submittedListingId || (id ? new UUID(id) : null);
  const listing = getOwnListing(listingId);
  const currentListing = ensureOwnListing(listing);
  const { state: currentListingState } = currentListing.attributes;

  const isPastDraft = currentListingState && currentListingState !== LISTING_STATE_DRAFT;
  // const shouldRedirect = (isNewListingFlow && listingId && isPastDraft) || (!canCreateListingRequestInProgress && !canCreateListing);
  const shouldRedirect = isNewListingFlow && listingId && isPastDraft;

  const hasStripeOnboardingDataIfNeeded = returnURLType ? !!(currentUser && currentUser.id) : true;
  const showForm =
    !canCreateListingRequestInProgress &&
    hasStripeOnboardingDataIfNeeded &&
    (isNewURI || currentListing.id);

  if (shouldRedirect) {
    const isPendingApproval =
      currentListing && currentListingState === LISTING_STATE_PENDING_APPROVAL;

    // If page has already listingId (after submit) and current listings exist
    // redirect to listing page
    const listingSlug =
      canCreateListing && currentListing ? createSlug(currentListing.attributes.title) : null;

    const redirectProps = !canCreateListing
      ? {
        name: 'SubscriptionPage',
      }
      : isPendingApproval
        ? {
          name: 'ListingPageVariant',
          params: {
            id: listingId.uuid,
            slug: listingSlug,
            variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
          },
        }
        : {
          name: 'ListingPage',
          params: {
            id: listingId.uuid,
            slug: listingSlug,
          },
        };

    return <NamedRedirect {...redirectProps} />;
  } else if (allowOnlyOneListing && isNewURI && currentUserListingFetched && currentUserListing) {
    // If we allow only one listing per provider, we need to redirect to correct listing.
    return (
      <NamedRedirect
        name={getEditPageName(listingType)}
        params={{
          id: currentUserListing.id.uuid,
          slug: createSlug(currentUserListing.attributes.title),
          type: LISTING_PAGE_PARAM_TYPE_EDIT,
          tab: 'description',
        }}
      />
    );
  } else if (showForm) {
    const {
      createListingDraftError = null,
      publishListingError = null,
      updateListingError = null,
      showListingsError = null,
      uploadImageError = null,
      fetchExceptionsError = null,
      addExceptionError = null,
      deleteExceptionError = null,
    } = page;
    const errors = {
      createListingDraftError,
      publishListingError,
      updateListingError,
      showListingsError,
      uploadImageError,
      createStripeAccountError,
      fetchExceptionsError,
      addExceptionError,
      deleteExceptionError,
    };
    // TODO: is this dead code? (shouldRedirect is checked before)
    const newListingPublished =
      isDraftURI && currentListing && currentListingState !== LISTING_STATE_DRAFT;

    // Show form if user is posting a new listing or editing existing one
    const disableForm = page.redirectToListing && !showListingsError;

    // Images are passed to EditListingForm so that it can generate thumbnails out of them
    const currentListingImages =
      currentListing && currentListing.images ? currentListing.images : [];

    // Images not yet connected to the listing
    const imageOrder = page.imageOrder || [];
    const unattachedImages = imageOrder.map(i => page.images[i]);
    const listingBackgroundImages = currentListingImages.splice(0, 3);
    const allImages = currentListingImages.concat(unattachedImages);
    const removedImageIds = page.removedImageIds || [];
    const images = allImages.filter(img => {
      return !removedImageIds.includes(img.id);
    });
    const backgroundImage = page.backgroundImage.imageId
      ? page.backgroundImage
      : listingBackgroundImages.length && removedImageIds.includes(listingBackgroundImages[0].id)
        ? {}
        : listingBackgroundImages[0];
    const profileImage = page.profileImage.imageId
      ? page.profileImage
      : listingBackgroundImages.length && removedImageIds.includes(listingBackgroundImages[1].id)
        ? {}
        : listingBackgroundImages[1];
    const actionImage =
      listingType === 'company'
        ? {}
        : page.actionImage.imageId
          ? page.actionImage
          : listingBackgroundImages.length && removedImageIds.includes(listingBackgroundImages[2]?.id)
            ? {}
            : listingBackgroundImages[2];

    const title = isNewListingFlow
      ? intl.formatMessage({ id: 'EditListingPage.titleCreateListing' })
      : intl.formatMessage({ id: 'EditListingPage.titleEditListing' });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        <CurrentUserContext.Provider value={currentUser}>
          <EditListingWizard
            id="EditListingWizard"
            type={listingType}
            className={css.wizard}
            params={params}
            disabled={disableForm}
            errors={errors}
            fetchInProgress={fetchInProgress}
            newListingPublished={newListingPublished}
            history={history}
            images={images}
            backgroundImage={backgroundImage}
            profileImage={profileImage}
            actionImage={actionImage}
            listing={currentListing}
            onAddAvailabilityException={onAddAvailabilityException}
            onDeleteAvailabilityException={onDeleteAvailabilityException}
            onUpdateListing={onUpdateListing}
            onCreateListingDraft={onCreateListingDraft}
            onPublishListingDraft={onPublishListingDraft}
            onCloseListing={onCloseListing}
            onOpenListing={onOpenListing}
            onPayoutDetailsFormChange={onPayoutDetailsFormChange}
            onPayoutDetailsSubmit={onPayoutDetailsFormSubmit}
            onGetStripeConnectAccountLink={onGetStripeConnectAccountLink}
            onImageUpload={onImageUpload}
            onUpdateImageOrder={onUpdateImageOrder}
            onRemoveImage={onRemoveListingImage}
            onChange={onChange}
            currentUser={currentUser}
            uploadImageInProgress={uploadImageInProgress}
            onManageDisableScrolling={onManageDisableScrolling}
            stripeOnboardingReturnURL={params.returnURLType}
            updatedTab={page.updatedTab}
            updateInProgress={page.updateInProgress || page.createListingDraftInProgress}
            fetchExceptionsInProgress={page.fetchExceptionsInProgress}
            availabilityExceptions={page.availabilityExceptions}
            payoutDetailsSaveInProgress={page.payoutDetailsSaveInProgress}
            payoutDetailsSaved={page.payoutDetailsSaved}
            stripeAccountFetched={stripeAccountFetched}
            stripeAccount={stripeAccount}
            viewport={viewport}
            listingBackgroundImages={listingBackgroundImages}
          />
        </CurrentUserContext.Provider>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
  } else {
    // If user has come to this page through a direct linkto edit existing listing,
    // we need to load it first.
    const loadingPageMsg = {
      id: 'EditListingPage.loadingListingData',
    };
    return (
      <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        {/* <UserNav
          selectedPageName={listing ? getEditPageName(listingType) : getNewPageName(listingType)}
          listing={listing}
        /> */}
        <div className={css.placeholderWhileLoading}>loading ...</div>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
  }
};

EditListingPageComponent.defaultProps = {
  createStripeAccountError: null,
  currentUser: null,
  currentUserHasOrders: null,
  listing: null,
  listingDraft: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  listingType: LISTING_TYPES.LISTING,
};

EditListingPageComponent.propTypes = {
  createStripeAccountError: propTypes.error,
  currentUser: propTypes.currentUser,
  getOwnListing: func.isRequired,
  currentUserListing: propTypes.listing,
  currentUserListingFetched: bool,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onPublishListingDraft: func.isRequired,
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onImageUpload: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsSubmit: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveListingImage: func.isRequired,
  onUpdateListing: func.isRequired,
  onChange: func.isRequired,
  page: object.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: string.isRequired,
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
  }).isRequired,
  scrollingDisabled: bool.isRequired,
  listingType: string,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.EditListingPage;

  const {
    getAccountLinkInProgress,
    createStripeAccountInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;

  const { currentUser, currentUserListing, currentUserListingFetched } = state.user;

  const {
    canCreateListing,
    createListingNotAllowedMessage,
    canCreateListingRequestInProgress,
    uploadImageInProgress,
  } = page;

  const fetchInProgress = createStripeAccountInProgress;

  const getOwnListing = id => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);

    return listings.length === 1 ? listings[0] : null;
  };
  return {
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    fetchInProgress,
    getOwnListing,
    page,
    canCreateListing,
    createListingNotAllowedMessage,
    canCreateListingRequestInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    uploadImageInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onAddAvailabilityException: params => dispatch(requestAddAvailabilityException(params)),
  onDeleteAvailabilityException: params => dispatch(requestDeleteAvailabilityException(params)),
  onUpdateListing: (tab, values) => dispatch(requestUpdateListing(tab, values)),
  onCreateListingDraft: values => dispatch(requestCreateListingDraft(values)),
  onPublishListingDraft: listing => dispatch(requestPublishListingDraft(listing)),
  onCloseListing: listing => dispatch(requestCloseListing(listing)),
  onOpenListing: listing => dispatch(requestOpenListing(listing)),
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsSubmit: values => dispatch(createStripeAccount(values)),
  onPayoutDetailsFormSubmit: (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall)),
  onGetStripeConnectAccountLink: params => dispatch(getStripeConnectAccountLink(params)),
  onUpdateImageOrder: imageOrder => dispatch(updateImageOrder(imageOrder)),
  onRemoveListingImage: imageId => dispatch(removeListingImage(imageId)),
  onChange: () => dispatch(clearUpdatedTab()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditListingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(EditListingPageComponent));

EditListingPage.loadData = loadData;

export default EditListingPage;

import React from 'react';
import css from './GroupRow.css';
import { InboxItemWithButtons } from 'components';
import { txState } from 'components/InboxItem/helpers';

function GroupRow(props) {
  const {
    entries,
    type,
    intl,
    currentUser,
  } = props;

  let stateData = txState(intl, entries[0], type);
  const InboxItemContain = tx => {
    stateData = txState(intl, tx, type);

    return stateData ? (
      <li
        key={tx.id.uuid}
        className={css.listItem}
      >
        <InboxItemWithButtons
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          currentUser={currentUser}
        />
      </li>
    ) : null;
  };

  return (
    <>
      <div className={css.contentInner}>
        {entries &&
          entries.map(tx => {
            return InboxItemContain(tx);
          })}
      </div>
    </>
  );
}

export default GroupRow;
